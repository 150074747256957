/**
 * External
 */
import React from 'react';
import jwtDecode from 'jwt-decode';
import { useRouteMatch } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/**
 * Components
 */
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/banners';

/* Assets */
import logo from '../../assets/dgo/svg/logo.svg';

/* Styles */
import './Banner.scss';

/* Other */
import {
  BUSINESS_CAMPAIGN,
  getAuthTokensData,
  useGetSelfCareUrl,
  formatContentForGtm,
  getLanderNameByCaption,
} from '../../utils';
import { GRAN_HERMANO_URL } from '../../utils/env';
import { getImage } from '../../theme/images';

function Banner() {
  const { idToken } = getAuthTokensData();
  const { iso2Code, msoProvider } = jwtDecode(idToken);
  const isArgentinaUser = (iso2Code === 'AR');
  const isGranHermanoUser = (BUSINESS_CAMPAIGN.has(msoProvider));
  const selfCareUrl = useGetSelfCareUrl();
  const user = useSelector((state) => state.user || {});
  const { gtmUserData } = useGtmContext();
  const { params: locationParams } = useRouteMatch();
  const { landerUri, pageId } = locationParams;

  const getUpsellLink = () => {
    const prefilledToken = btoa(JSON.stringify({
      nombre: user?.account?.givenName,
      apellido: user?.account?.familyName,
      email: user?.account?.profile?.email,
    }));

    return `${GRAN_HERMANO_URL}?token=${encodeURIComponent(prefilledToken)}`;
  };

  const handleClick = () => {
    const { hash, eventType, dimensions } = gtmDimensions.upsell_banner.manage_suscription;
    const dataToSend = formatContentForGtm(
      {},
      {
        tab: getLanderNameByCaption(landerUri || pageId),
      },
    );

    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataToSend,
    });

    const url = isArgentinaUser
      ? getUpsellLink()
      : selfCareUrl;

    window.open(url, '_blank').focus();
  };

  if (!isGranHermanoUser) {
    return null;
  }

  return (
    <div className="dtv-banner">
      <div className="dtv-banner_image">
        <img className="dtv-banner_title-image" src={getImage('logo') || logo} alt="" />
      </div>
      <div className="dtv-banner_text">
        <div className="dtv-banner_text-title">
          <FormattedMessage id={isArgentinaUser ? 'banner.title.GHAR' : 'banner.title'} defaultMessage="Disfruta la experiencia completa" />
        </div>
        <div className="dtv-banner_text-subtitle">
          <FormattedMessage id="banner.subtitle" defaultMessage="Gran Hermano con 3 cámaras exclusivas, además de canales en vivo, series, películas, deportes y más." />
        </div>
      </div>
      <div className="dtv-banner_button">
        <button type="button" className="dtv-btn-glass" onClick={() => handleClick()}>
          <FormattedMessage id={isArgentinaUser ? 'banner.btn' : 'banner.btn.GHAR'} defaultMessage="Gestionar plan" />
        </button>
      </div>
    </div>
  );
}

export default Banner;
