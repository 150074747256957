/* External */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import cloneDeepL from 'lodash-es/cloneDeep';
import classNames from 'classnames';
import { gtmEvent } from '@dtvgo/gtm-event-report';
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/pdp';

/* Components */
import DetailsFrame from '../detailsFrame/NewDetailsFrame';
import BlackoutScreen from '../blackoutScreen/BlackoutScreen';
import SeasonSelector from '../seasonSelector/SeasonSelector';
import GtmComponent from '../GtmContext/GtmComponent';
import AgeRating from '../ageRating/AgeRating';
import DetailsTab from './detailsTab/DetailsTab';
import MoreLikeThis from '../../pdp/moreLikeThis/MoreLikeThis';

/* Styles */
import './SeriesDetails.scss';

/* Other */
import {
  formatSeasonAndEpisode,
  formatSeconds,
  joinArrayWithSeparator,
  getUserLanguageFromEnvironmentPublicPdp, isMoreLikeThisActive,
} from '../../utils';
import { api } from '../../state/configureStore';
import { createBackendServicePublic } from '../../services/backend';

/* Constants */
const EPISODES_TAB_KEY = 'episodes';
const MORE_LIKE_THIS_TAB_KEY = 'moreLikeThis';
const DETAILS_TAB_KEY = 'details';

function SeriesDetails({
  assetId,
  onErrorAction,
  intl: { formatMessage },
  isPublic,
  dataPublic,
  regionPublic,
}) {
  const { country } = useParams() || {};
  const history = useHistory();
  const languageEnvironment = getUserLanguageFromEnvironmentPublicPdp(country);
  const {
    data: serieResults = {},
    isError: isAssetError,
    isLoading: isAssetLoading,
  } = !isPublic
    ? api.useGetSeriesDetailQuery({ assetId })
    : dataPublic;
  const { heartbeatOnGoing } = useSelector((state) => state.vrioHeartbeat);

  const { data: { contents: [continueWatchingEpisode] = [] } = {}, isLoading: isContinueWatchingLoading } = !isPublic ? api.useGetContinueWatchingDataQuery({ assetId, assetType: 'series' }, { skip: heartbeatOnGoing }) : {};
  const { data: seasonDetail = {} } = !isPublic ? api.useGetSeasonDetailQuery(
    { assetId, seasonNumber: continueWatchingEpisode?.episode?.seasonNumber },
    { skip: isContinueWatchingLoading || !continueWatchingEpisode },
  ) : {};
  const [selectedTab, setSelectedTab] = useState(EPISODES_TAB_KEY);
  const [yearsOnAirPublic, setYearsOnAirPublic] = useState('');
  const {
    gtmUserData, gtmContent,
  } = useGtmContext();
  let firstEpisode = {};
  let releaseYear;
  let finaleYear;
  const {
    seasons = [],
    genres = [],
    title: serieName,
    vrioAssetId: id = '',
    rating,
  } = serieResults;
  const sortedSeasons = cloneDeepL(seasons).sort(
    (a, b) => Number.parseInt(a.seasonNumber, 10) - Number.parseInt(b.seasonNumber, 10),
  );
  const seasonsWithEpisodes = sortedSeasons.filter(({ hasEpisodes }) => hasEpisodes === true);
  const firstSeasonNumber = seasonsWithEpisodes[0]?.seasonNumber;
  const { data: firstSeasonDetail = {} } = !isPublic ? api.useGetSeasonDetailQuery(
    { assetId, seasonNumber: firstSeasonNumber },
    { skip: !firstSeasonNumber && firstSeasonNumber !== 0 },
  ) : {};
  const lastSeasonNumber = seasonsWithEpisodes[seasonsWithEpisodes.length - 1]?.seasonNumber;

  const { data: lastSeasonDetail = {} } = !isPublic ? api.useGetSeasonDetailQuery(
    { assetId, seasonNumber: lastSeasonNumber },
    { skip: seasonsWithEpisodes.length <= 1 },
  ) : {};
  if (seasonsWithEpisodes) {
    const clonedFirstSeasonSortedEpisodes = cloneDeepL(firstSeasonDetail?.episodes);
    const firstSeasonSortedEpisodes = clonedFirstSeasonSortedEpisodes?.sort(
      (a, b) => (a?.episode?.number || 0) - (b?.episode?.number || 0),
    );
    firstEpisode = firstSeasonSortedEpisodes && firstSeasonSortedEpisodes?.[0];
    firstEpisode = {
      ...firstEpisode,
      seasonNumber: firstSeasonNumber,
    };
    releaseYear = firstSeasonSortedEpisodes && firstSeasonSortedEpisodes?.[0]?.vod?.releaseYear;
  }
  if (seasonsWithEpisodes.length > 1) {
    const clonedLastSeasonSortedEpisodes = cloneDeepL(lastSeasonDetail?.episodes);
    const lastSeasonSortedEpisodes = clonedLastSeasonSortedEpisodes?.sort(
      (a, b) => (a?.episode?.number || 0) - (b?.episode?.number || 0),
    );
    const lastEpisodeLastSeason = lastSeasonSortedEpisodes?.length;
    finaleYear = lastSeasonSortedEpisodes?.[lastEpisodeLastSeason - 1]?.vod?.releaseYear;
  }

  if (firstSeasonNumber === lastSeasonNumber) {
    const clonedLastSeasonSortedEpisodes = cloneDeepL(firstSeasonDetail?.episodes);
    const lastSeasonSortedEpisodes = clonedLastSeasonSortedEpisodes?.sort(
      (a, b) => (a?.episode?.number || 0) - (b?.episode?.number || 0),
    );
    const lastEpisodeLastSeason = lastSeasonSortedEpisodes?.length;
    finaleYear = lastSeasonSortedEpisodes?.[lastEpisodeLastSeason - 1]?.vod?.releaseYear;
  }

  useEffect(() => {
    async function fetchLastSeasonData() {
      const returnLastSeasonData = lastSeasonNumber ? await createBackendServicePublic.get(`/asset/show/${assetId}/seasons/${lastSeasonNumber}`, languageEnvironment, regionPublic) : {};
      const lastEpisodePublic = returnLastSeasonData?.data?.episodes?.length;
      const releaseYearSerie = serieResults?.releaseYear;
      const releaseLastYearSerie = returnLastSeasonData
        ?.data
        ?.episodes
        ?.[lastEpisodePublic - 1]?.releaseYear;
      const yearhOnAirPublic = releaseYearSerie && releaseLastYearSerie && (releaseYearSerie === releaseLastYearSerie ? releaseYearSerie : `${releaseYearSerie} - ${releaseLastYearSerie}`);
      setYearsOnAirPublic(yearhOnAirPublic);
    }
    if (isPublic) {
      fetchLastSeasonData();
    }
  }, [assetId, lastSeasonNumber, serieResults, isPublic, languageEnvironment, regionPublic]);

  useEffect(() => {
    setSelectedTab(EPISODES_TAB_KEY);
  }, [id]);

  if (isAssetError && !isPublic) {
    return (
      <BlackoutScreen
        mainButtonText={formatMessage({ id: 'common.goBack', defaultMessage: 'Regresar' })}
        title={formatMessage({
          id: 'player.errorEntitlementsByStation.title',
          defaultMessage: '¡Lo sentimos!',
        })}
        subtitle={formatMessage({
          id: 'errorPage.serie',
          defaultMessage:
            'Esta serie no está disponible por el momento. Te invitamos a que disfrutes de otro de nuestros contenidos.',
        })}
        onMainButtonClick={onErrorAction}
      />
    );
  }

  if (isAssetError && isPublic) history.replace('/404');

  const tabs = {
    [EPISODES_TAB_KEY]: formatMessage({
      id: 'serie.episodes',
      defaultMessage: 'Episodios',
    }),
    ...((isMoreLikeThisActive && !isPublic) ? {
      [MORE_LIKE_THIS_TAB_KEY]: formatMessage({
        id: 'serie.moreLikeThis',
        defaultMessage: 'Contenidos Relacionados',
      }),
    } : {}),
    [DETAILS_TAB_KEY]: formatMessage({
      id: 'serie.details',
      defaultMessage: 'Detalles',
    }),
  };

  const genre = genres?.[0];
  const episodeToPlay = continueWatchingEpisode || firstEpisode || {};
  let description = isPublic ? serieResults.description : episodeToPlay.description;
  const { episode } = episodeToPlay;
  const formattedSeasonAndEpisode = formatSeasonAndEpisode(
    episode?.seasonNumber,
    episode?.number,
    formatMessage,
  );
  let episodeName;
  let subtitle;
  let metadata;
  let pdpPlayButtonText;
  let progress;
  if (continueWatchingEpisode) {
    const { vod = {} } = continueWatchingEpisode;
    if (vod) ({ progress } = vod);

    const continueWatchingEpisodeInSeasonDetail = seasonDetail?.episodes?.find(
      ({ vrioAssetId }) => vrioAssetId === continueWatchingEpisode.vrioAssetId,
    );
    description = continueWatchingEpisodeInSeasonDetail?.description || '';

    subtitle = joinArrayWithSeparator([formattedSeasonAndEpisode, continueWatchingEpisodeInSeasonDetail?.title], ' • ');
    episodeName = continueWatchingEpisodeInSeasonDetail?.title;

    const formattedDuration = formatSeconds(continueWatchingEpisodeInSeasonDetail?.vod?.duration);
    const internalRating = continueWatchingEpisodeInSeasonDetail?.rating;
    metadata = (internalRating || formattedDuration) && (
      <>
        <AgeRating rating={internalRating} />
        <span>{formattedDuration}</span>
      </>
    );

    pdpPlayButtonText = progress > 0
      ? formatMessage(
        {
          id: 'serie.resume',
          defaultMessage: 'Reanudar',
        },
        { seasonAndEpisode: formattedSeasonAndEpisode },
      )
      : formatMessage(
        {
          id: 'serie.watchNextEpisode',
          defaultMessage: 'Ver próximo episodio',
        },
        { seasonAndEpisode: formattedSeasonAndEpisode },
      );
  } else {
    const yearsOnAir = releaseYear && finaleYear && (releaseYear === finaleYear ? releaseYear : `${releaseYear} - ${finaleYear}`);
    const yearsOnAirWithGenre = joinArrayWithSeparator([yearsOnAir || yearsOnAirPublic, genre], ' • ');
    metadata = (rating || yearsOnAir || genre) && (
      <>
        <AgeRating rating={rating} />
        <span>{yearsOnAirWithGenre}</span>
      </>
    );

    pdpPlayButtonText = formatMessage(
      {
        id: 'serie.startWatching',
        defaultMessage: 'Comenzar a ver',
      },
      { seasonAndEpisode: formattedSeasonAndEpisode },
    );
    if (isPublic) {
      pdpPlayButtonText = formatMessage({
        id: 'public.pdp.playButton',
        defaultMessage: 'Pruébalo gratis por {days} días',
      }, { days: 7 });
    }
    // eslint-disable-next-line unicorn/consistent-destructuring
    episodeName = episodeToPlay?.title;
  }
  const sendAsset = episodeToPlay;
  const pdpPlayButtonIcon = isPublic || sendAsset.shouldDisplayLockIcon ? 'dtv-icon-lock' : 'dtv-icon-play';

  const hanleClickSeasonSelector = (tabKey) => {
    setSelectedTab(tabKey);
    gtmEvent({
      hash: gtmDimensions.pdp_series.episodes.hash,
      eventType: gtmDimensions.pdp_series.episodes.eventType,
      dimensions: gtmDimensions.pdp_series.episodes.dimensions,
      userData: gtmUserData,
      data: { ...gtmContent },
    });
  };

  const { vrioAssetId } = episodeToPlay;

  return (
    <div className="dtv-series-details">
      {!isAssetLoading && vrioAssetId && episodeName && (
        <GtmComponent
          assetToPlay={sendAsset}
          config={{
            title: serieName,
            playSource: 'pdp',
            serieName,
            transmissionType: 'serie',
            contentType: 'episode',
            contentPlayerType: 'serie',
            filterPdp: 'episodes',
            episodeName,
          }}
          hash={gtmDimensions.pdp_series.page_view.hash}
          eventType={gtmDimensions.pdp_series.page_view.eventType}
          dimensions={gtmDimensions.pdp_series.page_view.dimensions}
        />
      )}
      <DetailsFrame
        assetId={assetId}
        assetToPlay={!isPublic ? sendAsset : serieResults}
        description={description}
        isContinueWatchingAsset={!!continueWatchingEpisode}
        isLoading={isAssetLoading || isContinueWatchingLoading || heartbeatOnGoing}
        metadata={metadata}
        pdpPlayButtonIcon={pdpPlayButtonIcon}
        pdpPlayButtonText={pdpPlayButtonText}
        progress={progress}
        showMyListButton
        subtitle={subtitle}
        title={serieName}
        isPublic={isPublic}
      />
      <div
        className="dtv-series-details-tab-selector"
      >
        {Object.keys(tabs).map((tabKey) => {
          const tabText = tabs[tabKey];

          return (
            <span
              className={classNames({
                active: tabKey === selectedTab,
              })}
              key={tabKey}
              onClick={() => hanleClickSeasonSelector(tabKey)}
            >
              {tabText}
            </span>
          );
        })}
      </div>
      {selectedTab === EPISODES_TAB_KEY && (
        <SeasonSelector
          continueWatchingEpisode={continueWatchingEpisode}
          isLoading={isAssetLoading}
          playSource={`PDP-${serieName}`}
          seasons={seasonsWithEpisodes}
          assetId={assetId}
          isPublic={isPublic}
          regionPublic={regionPublic}
        />
      )}
      {/* A Feature flag vai ser um pouco diferente aqui, pois essa tela já tinha TABS */}
      {/* Então é só pra remover a Tab da Spideo */}
      {selectedTab === MORE_LIKE_THIS_TAB_KEY && isMoreLikeThisActive && !isPublic && (
        <MoreLikeThis contentId={assetId} />
      )}
      {selectedTab === DETAILS_TAB_KEY && (
        <DetailsTab asset={serieResults} />
      )}
    </div>
  );
}

SeriesDetails.propTypes = {
  onErrorAction: PropTypes.func,
  intl: intlShape.isRequired,
  isPublic: PropTypes.bool,
  dataPublic: PropTypes.shape({}),
  regionPublic: PropTypes.string,
};

SeriesDetails.defaultProps = {
  onErrorAction() {},
  isPublic: false,
  dataPublic: {},
  regionPublic: '',
};

export default injectIntl(SeriesDetails);
