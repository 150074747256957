/**
 * External
 */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

/**
 * Styles
 */
import './inputPassword.scss';

const handleCopy = (e) => {
  e.preventDefault();
  e.nativeEvent.stopImmediatePropagation();
};

const handlePaste = (e) => {
  e.preventDefault();
  e.nativeEvent.stopImmediatePropagation();
};

function InputPassword({
  name,
  value,
  onChange,
}) {
  const [toggleType, setToggleType] = useState('password');
  const [eyeIconClass, setEyeIconClass] = useState('dtv-icon-eye');

  const handleToggleShowPassword = () => {
    setToggleType(toggleType === 'text' ? 'password' : 'text');
  };

  useEffect(() => {
    setEyeIconClass(toggleType === 'text' ? 'dtv-icon-eye' : 'dtv-icon-eye-blind');
  }, [toggleType]);

  return (
    <div className="dtv-input-password">
      <input
        className="form-control"
        type={toggleType}
        name={name}
        value={value}
        onCopy={handleCopy}
        onPaste={handlePaste}
        onChange={onChange}
      />
      <i
        style={{ visibility: value ? 'visible' : 'hidden' }}
        className={classNames('eye-icon', eyeIconClass)}
        onClick={handleToggleShowPassword}
      />
    </div>
  );
}

export default InputPassword;
