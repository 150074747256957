/* External */
import React from 'react';

/* Assets */
import logo from '@/assets/theme/svg/logo.svg';

import { getImage } from '../theme/images';

/* Styles */
import './NavBarProfile.scss';

export default function NavBarProfile() {
  return (
    <div className="dtv-nav-bar">
      <div className="dtv-nav-bar-content">
        <div className="dtv-nav-bar-content-left">
          <img src={getImage('logo') || logo} alt="Logo" height="50" />
        </div>
      </div>
    </div>
  );
}
