/* External */
import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router';

/* Ott-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Components */
import BackButton from '../../components/backButton/BackButton';
import VodCollection from '../../components/VodCollection';
import TabsPage from '../../components/TabsPage';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/header-footer';

/* Assets */
import Generic2x1 from '../../assets/images/Generic2x1.png';

/* Other */
import {
  useImage,
  GRADIENTS,
  getWebAction,
  findMatchingCollection,
  CollectionText,
  SPORT_PAGE_SET,
  formatContentForGtm,
} from '../../utils';
import { api } from '../../state/configureStore';
import useQuery from '../../utils/redirection';
import { getImage } from '../../theme/images';

/* Styles */
import './CollectionPage.scss';

function CollectionPage() {
  const { setGtmCollectionName, gtmPlaySource, gtmUserData } = useGtmContext();
  const { url } = useRouteMatch();
  const { sourceCarouselType, sourceCarouselId, target } = useParams();
  const query = useQuery();
  const { data: { collections } = {} } = api.useGetCarouselQuery({
    source: 'Vrio',
    sourceCarouselId,
    type: sourceCarouselType,
  });

  const collection = useMemo(() => findMatchingCollection(
    collections,
    target,
    query,
  ), [collections, query, target]);

  const { text = null } = Array.isArray(collection?.actions) && getWebAction(collection);
  const isSportLander = SPORT_PAGE_SET.has(text);

  const content = useMemo(() => {
    if (!collection) return null;

    const action = getWebAction(collection);

    if (!action?.text) return null;

    switch (action?.text) {
      case CollectionText.SPORT_PAGE:
      case CollectionText.SPORT_SECTION:
        return <TabsPage key={url} className="dtv-collection-catalog__tabs-page" pageId={action.target} sportIds={collection.sportIds} />;
      default:
        return <VodCollection className="dtv-collection-catalog-assets" collection={collection} />;
    }
  }, [collection, url]);

  const isBarNotificationShown = useSelector(({ notifications }) => notifications.get('barNotification').show);
  const [isFixedGoBack, setIsFixedGoBack] = useState(true);

  const { imageRef, imageStyle } = useImage(
    collection?.backGroundImages,
    getImage('generic2x1') || Generic2x1,
    isSportLander ? '' : GRADIENTS.CARD16X9_PDP_INTERSTITIAL,
    '16:9',
  );

  /**
   * @method userScroll
   * @description Method that validates if go back button must be fixed position
   *              or not when user is scrolling.
   */
  const userScroll = useCallback(() => {
    const scrollPosition = document.documentElement.scrollTop || window.pageYOffset;
    const headerHeight = document.getElementById('dtv-collection-catalog-banner')?.clientHeight;
    const bannerTextsHeight = 170;
    setIsFixedGoBack(scrollPosition < headerHeight - bannerTextsHeight);
  }, []);

  /**
   * @description Fetches data and adds userScroll listener
   * @cleanup Removes userScroll listener
   */
  useEffect(() => {
    window.addEventListener('scroll', userScroll);
    userScroll();

    return () => {
      window.removeEventListener('scroll', userScroll);
    };
  }, [userScroll]);

  useEffect(() => {
    if (collection) {
      setGtmCollectionName(collection.title);

      const { hash, eventType, dimensions } = gtmDimensions.collections.page_view;

      const dataTosend = formatContentForGtm(
        {},
        {
          collectionName: collection.title,
          playSource: gtmPlaySource,
        },
      );

      gtmEvent({
        hash,
        eventType,
        dimensions,
        userData: gtmUserData,
        data: dataTosend,
      });
    }
  }, [collection, gtmUserData, gtmPlaySource, setGtmCollectionName]);

  return (
    <div
      className={`dtv-collection-catalog ${isSportLander ? 'dtv-collection-catalog-sport-lander' : ''}`}
      ref={imageRef}
      style={imageStyle}
    >
      <BackButton
        className="dtv-collection-catalog-goback"
        style={{
          position: `${isFixedGoBack ? 'fixed' : 'absolute'}`,
          top: `${isBarNotificationShown ? '12vw' : '7vw'}`,
        }}
      />
      <div id="dtv-collection-catalog-banner" className="dtv-collection-catalog-banner">
        <div className="dtv-collection-catalog-banner-title">{collection?.title}</div>
        {!isSportLander
        && <div className="dtv-collection-catalog-banner-subtitle">{collection?.description}</div>}
      </div>
      {content}
    </div>
  );
}

export default CollectionPage;
