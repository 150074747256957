/**
 * External
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  differenceInMinutes, isBefore, isWithinInterval, setSeconds, format,
} from 'date-fns';
import classNames from 'classnames';

/**
 * Components
 */
import LiveIndicator from '../liveIndicator/LiveIndicator';

/**
 * Styles
 */
import './LiveCardTag.scss';

/**
 * Other
 */
import { getDaySelectedText } from '../../utils';

class LiveCardTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLiveContent: true,
    };

    this.getScheduleText = this.getScheduleText.bind(this);
    this.setIsLiveContent = this.setIsLiveContent.bind(this);
  }

  /**
   * @method componentDidMount
   * @description React lifecycle method. Used to set isLiveContent and update interval
   */
  componentDidMount() {
    this.setIsLiveContent();

    this.isLiveContentInterval = setInterval(this.setIsLiveContent, 1000);
  }

  /**
   * @method componentWillUnmount
   * @description React lifecycle method. Used to clear isLiveContent update interval
   */
  componentWillUnmount() {
    if (this.isLiveContentInterval) clearInterval(this.isLiveContentInterval);
  }

  /**
   * @method getScheduleText
   * @description Returns the schedule text
   */
  getScheduleText() {
    const {
      startTime,
      intl: { formatDate, formatMessage },
    } = this.props;

    if (startTime) {
      const day = getDaySelectedText(startTime, formatMessage, formatDate);
      const start = format(new Date(startTime), 'HH:mm');

      return `${day} ${start}`;
    }

    return '';
  }

  /**
   * @method setIsLiveContent
   * @description Updates isLiveContent and clears the update interval if it is false
   */
  setIsLiveContent() {
    const { startTime, endTime } = this.props;

    const currentDate = new Date();
    const start = setSeconds(new Date(startTime), 0);
    const end = setSeconds(new Date(endTime), 0);
    let isLiveContent = false;
    if (isBefore(start, end) && isWithinInterval(currentDate, { start, end })) {
      isLiveContent = !!(differenceInMinutes(end, currentDate) + 1);
    }

    if (!isLiveContent) clearInterval(this.isLiveContentInterval);

    this.setState({ isLiveContent });
  }

  render() {
    const { className } = this.props;
    const { isLiveContent } = this.state;

    return (
      <div className={classNames('dtv-card-live-tag', className)}>
        {isLiveContent ? <LiveIndicator /> : <h4 className="dtv-card-live-tag-schedule">{this.getScheduleText()}</h4>}
      </div>
    );
  }
}

LiveCardTag.propTypes = {
  className: PropTypes.string,
  endTime: PropTypes.string,
  intl: intlShape.isRequired,
  startTime: PropTypes.string,
};

LiveCardTag.defaultProps = {
  className: '',
  endTime: '',
  startTime: '',
};

export default injectIntl(LiveCardTag);
