/**
 * External
 */
import Immutable from 'immutable';

/**
 * Actions
 */
import { ACTION_TYPES } from './actions';

const initialState = Immutable.Map({
  pathname: '',
});

export default function history(state = initialState, { type, payload } = {}) {
  switch (type) {
    case ACTION_TYPES.SET_HISTORY_PATHNAME:
      return state.set('pathname', payload.pathname);
    default:
      return state;
  }
}
