/* External */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

/* Actions */
import { resetUser as resetUserAction } from '../../state/user/actions';

/* Styles */
import './ExpiredSession.scss';

class ExpiredSession extends Component {
  componentDidMount() {
    const { resetUser } = this.props;
    if (resetUser) resetUser();
    // if chromecast is connected, it'll end current session.
    if (window.cast) window.cast.framework.CastContext.getInstance().endCurrentSession(true);
  }

  render() {
    return (
      <div className="dtv-expired-session">
        <div className="dtv-error-content dtv-text-center">
          <p>
            <FormattedMessage id="common.attention" defaultMessage="¡Attention!" />
          </p>
          <p>
            <FormattedMessage id="page.expiredSession" defaultMessage="This session has expired" />
          </p>
          <p className="dtv-error-description dtv-text-center">
            <FormattedMessage
              id="page.expiredSession.loggedInMoreDevices"
              defaultMessage="It seems that you have logged in more than 5 devices, if you want to log in here, the oldest one will be automatically closed."
            />
          </p>
          <button type="button" className="dtv-back-btn" onClick={goBack}>
            <FormattedMessage id="page.expiredSession.goBack" defaultMessage="Go back to DIRECTV Gok" />
          </button>
        </div>
      </div>
    );
  }
}

export default connect(null, { resetUser: resetUserAction })(ExpiredSession);

/**
 * @method
 * @description Method that redirect to login or url to redirect.
 */
function goBack() {
  window.location.replace('/login');
}
