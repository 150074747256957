/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Assets */
import closeIcon from '../../../assets/icons/close-white-icon.svg';

/* Styles */
import './CloseToastButton.scss';

function CloseToastButton({ closeToast = () => {} }) {
  return (
    <button
      className="close-toast-button"
      type="button"
      onClick={closeToast}
    >
      <img
        className="close-toast-button__icon"
        src={closeIcon}
        alt="close notification"
      />
    </button>
  );
}

CloseToastButton.propTypes = {
  // eslint-disable-next-line react/require-default-props
  closeToast: PropTypes.func,
};

export default CloseToastButton;
