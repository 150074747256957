import { userConstants } from './userConstants';

export const gtmDimensions = {
  component_type: 'myList',
  card_series: {
    watch: {
      hash: 'e-033.001.001.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_search_terms',
        'v_section_name',
        'v_list_position',
        'v_transmission_type',
        'v_content_locked',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
      ],
    },
  },
  card_vod: {
    watch: {
      hash: 'e-033.001.001.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_section_name',
        'v_list_position',
        'v_transmission_type',
        'v_content_locked',
        'v_content_player_type',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
      ],
    },
  },
  page_view: {
    hash: 'p-033.001.000.000.000.000',
    eventType: 'pages',
    dimensions: [
      ...userConstants.constans,
    ],
  },
  watch: {
    hash: 'e-033.001.001.000.000.009',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
      'v_content_name',
      'v_content_type',
      'v_content_id',
      'v_genre',
      'v_play_source',
      'v_start_progress_bar',
      'v_channel_name',
      'v_serie_name',
      'v_season',
      'v_search_terms',
      'v_section_name',
      'v_list_position',
      'v_transmission_type',
      'v_content_locked',
      'v_channel_id',
      'v_timestamp',
      'v_episode_number',
      'v_content_player_type',
      'v_episode_name',
      'v_change_progress',
      'v_is_sports',
      'v_content_age_rating',
      'v_age_restricted_content',
      'v_video_duration',
      'v_change_progress_percentual',
    ],
  },
  edit: {
    hash: 'e-033.001.001.000.000.068',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
    ],
  },
  delete_action: {
    hash: 'e-033.001.001.000.000.145',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
    ],
  },
  cancel: {
    hash: 'e-033.001.001.000.000.051',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
    ],
  },
  update: {
    hash: 'e-033.001.012.000.000.148',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
    ],
  },
  delete_content: {
    hash: 'e-033.001.001.000.000.146',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
    ],
  },
  content_info: {
    hash: 'e-033.001.001.000.000.147',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
    ],
  },
};
