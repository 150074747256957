/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

/**
 * Components
 */
import DockButton from './DockButton';
import DockDescription from './DockDescription';
import DockAgeRating from './dockRating/DockAgeRating';
import { gtmDimensions } from '../../components/GtmContext/dimensions/player';

/**
 * Styles
 */
import './PlayerDock.scss';

/**
 * Other
 */
import {
  goBackFromPlayer,
  getAssetNameWithQualifier,
  getVrioImageUrl,
  getGtmEventConfig,
} from '../../utils';
import { history } from '../../state/configureStore';

/**
 * Component PlayerDock
 * @description Component to display content info on Player
 */
function PlayerDock({
  asset,
  intl: { formatMessage },
  className,
  isLive,
  nextAsset,
  isDelayed,
  gtmInteraction,
  openMiniEPG,
}) {
  const epgRedirectionData = useSelector((state) => state.epgRedirectionData) || {};
  const {
    episode, live, title,
    rating, ratingDescription, ratingWarning,
  } = asset || {};
  const { showName } = episode || {};
  const { channelLogo, channelName, qualifiers } = live || {};
  const { title: nextTitle } = nextAsset || {};
  const { showName: nextShowName } = nextAsset?.episode || {};
  const { qualifiers: nextQualifiers } = nextAsset?.live || {};

  const nextAssetTitle = getAssetNameWithQualifier(
    nextQualifiers,
    nextShowName || nextTitle,
    formatMessage,
  );

  const handleGtmEvent = (type) => {
    const { hash, dimensions, eventType } = getGtmEventConfig(
      asset.type,
      gtmDimensions,
      'player',
      type,
    );
    gtmInteraction(asset, hash, eventType, dimensions);
  };

  const goBack = () => {
    handleGtmEvent('close');
    goBackFromPlayer({ history, epgRedirectionData });
  };

  return (
    <div className={`dtv-dock ${className}`}>
      <DockButton
        className="dtv-dock-button-back dtv-icon-arrow-back"
        onClick={goBack}
        controlText={formatMessage({ id: 'player.back' })}
      />
      <div className="dtv-dock-left">
        <DockAgeRating
          rating={rating}
          ratingDescription={ratingDescription}
          ratingWarning={ratingWarning}
        />
        <div className="dtv-dock-title">
          {getAssetNameWithQualifier(
            qualifiers,
            showName || title,
            formatMessage,
          )}
        </div>
        <div className="dtv-dock-description">
          <DockDescription asset={asset} isDelayed={isDelayed} isLive={isLive} />
        </div>
      </div>
      {isLive && (
        <>
          <div className="dtv-dock-next-program">
            {nextAssetTitle && (
              <div className="dtv-dock-next">
                {formatMessage({ id: 'player.next' })}
                <p title={nextAssetTitle}>{nextAssetTitle}</p>
              </div>
            )}
            {channelLogo && (
              <img
                src={getVrioImageUrl(channelLogo, '4:3', { width: 50, height: 37 })}
                alt={channelName}
                className="dtv-dock-icon"
              />
            )}
          </div>
          <DockButton
            className="dtv-icon-schedule"
            controlText={formatMessage({ id: 'player.dock.viewMoreChannels' })}
            onClick={() => { openMiniEPG(); handleGtmEvent('more_channels'); }}
          />
        </>
      )}
    </div>
  );
}

PlayerDock.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string,
  isLive: PropTypes.bool,
  isDelayed: PropTypes.bool,
  asset: PropTypes.shape({
    type: PropTypes.string,
  }),
  nextAsset: PropTypes.shape({
    episode: PropTypes.shape({}),
    live: PropTypes.shape({}),
  }),
  gtmInteraction: PropTypes.func,
  openMiniEPG: PropTypes.func,
};

PlayerDock.defaultProps = {
  className: '',
  isLive: false,
  isDelayed: false,
  asset: {},
  nextAsset: {
    name: '',
    showName: '',
  },
  gtmInteraction: () => {},
  openMiniEPG: null,
};

export default injectIntl(PlayerDock);
