/* External */
import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

/* Ott-common */
import GroupStandingOtt from '@dtvgo/group-standing';
import '@dtvgo/group-standing/dist/main.css';

/* Styles */
import './GroupStanding.scss';

/** Other */
import { IS_BRAZIL } from '../../utils';

function GroupStanding({
  standing,
  intl: { formatMessage },
}) {
  return (
    <GroupStandingOtt
      className={`dtv-common-c-group-standing--web ${!IS_BRAZIL ? 'dtv-group-standing--color-hispam' : ''}`}
      standing={standing}
      completedText={formatMessage({ id: 'sports.complete', defaultMessage: 'FINALIZADO' })}
    />
  );
}

GroupStanding.propTypes = {
  standing: PropTypes.shape({}).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(GroupStanding);
