/* External */
import React from 'react';
import { injectIntl } from 'react-intl';
import { format } from 'date-fns';
import getL from 'lodash-es/get';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';

/* Components */
import Button from '../button/Button';
import TimeLeftText from '../timeLeftText/TimeLeftText';
import AssetToPlay from '../assetToPlay/AssetToPlay';
import LiveTag from '../liveTag/LiveTag';
import AgeRating from '../ageRating/AgeRating';
import { useGtmContext } from '../GtmContext';

/* Styles */
import './Card16X9.scss';

/* Other */
import {
  buildEpisodeSecondaryDataString,
  formatSeconds,
  getAssetNameWithQualifier,
  getDaySelectedText,
  getGenre,
  isPastPresentOrFuture,
  joinArrayWithSeparator,
  LIVE_PROGRAM_TYPES,
  useImage,
  getPLayButtonConfig,
} from '../../utils';
import { gtmWatchCarouselEventHandler } from '../GtmContext/dimensions/carousels/eventHandlers';

/* Constants */
const CLASS_ICON_LIVE = 'mr-3 dtv-card-16X9-left-first-line-icon';

function Card16X9({
  infoCard: asset,
  infoCard: {
    description = '',
    entitled = false,
    episode,
    genres,
    images = [],
    isLive,
    live,
    name,
    pictureID,
    playSource = '',
    rating,
    title: originalTitle,
    type = '',
    vod,
    vrioAssetId = '',
    shouldDisplayLockIcon = false,
  } = {},
  genericCard,
  gradient = '',
  intl: { formatMessage, formatDate },
  carouselInfo,
}) {
  const {
    gtmUserData,
    setGtmPlaySource,
    setGtmSectionName,
    setGtmComponentType,
    setGtmCarouselVerticalPosition,
  } = useGtmContext();
  const { params: locationParams } = useRouteMatch();

  const { imageRef, imageStyle } = useImage(
    images,
    genericCard,
    gradient,
    '16:9',
  );
  const { releaseYear = 0, duration = 0 } = vod || {};
  const { number: episodeNumber, seasonNumber, showName: showNameEp } = episode || {};
  const {
    startTime = '', endTime = '', channelName = '', qualifiers = [], channelId = '',
  } = live || {};
  const title = originalTitle || name;
  const showName = showNameEp || title;

  const getFourthLine = () => (
    <>
      {rating && <AgeRating {...{ rating }} />}
      {(startTime && endTime) && (
        isPastPresentOrFuture(startTime, endTime) === LIVE_PROGRAM_TYPES.PRESENT ? (
          <TimeLeftText endTime={new Date(endTime)} isTextShort={false} withSeparator={false} />
        ) : (
          `${getDaySelectedText(startTime, formatMessage, formatDate)}, ${format(
            new Date(startTime),
            'HH:mm',
          )} - ${format(new Date(endTime), 'HH:mm')}`
        )
      )}
      {channelName && ` |  ${channelName}`}
    </>
  );

  const getAssetData = () => {
    const { btnClass, btnTitle } = getPLayButtonConfig({
      isLocked: shouldDisplayLockIcon,
      isFutureEvent: isPastPresentOrFuture(startTime, endTime) === LIVE_PROGRAM_TYPES.FUTURE,
      isLiveEvent: isLive,
    });

    const detailsLabelButton = formatMessage({ id: 'slider.seeDetails', defaultMessage: 'Ver Detalles' });
    const textUnavailable = formatMessage({ id: 'common.unavailable', defaultMessage: 'No disponible' });
    const buttonClass = btnClass;

    let eventIsPastPresentOrFuture;
    let futureEvent;

    if (isLive) {
      eventIsPastPresentOrFuture = isPastPresentOrFuture(startTime, endTime);
      futureEvent = eventIsPastPresentOrFuture === LIVE_PROGRAM_TYPES.FUTURE;
    }

    let data = {
      buttonClass: '',
      firstLine: '',
      fourthLine: '',
      logo: '',
      secondLine: '',
      seeDetails: false,
      labelButton: formatMessage({ id: btnTitle, defaultMessage: 'slider.watchNow' }),
    };

    switch (type) {
      case assetTypes.movie:
      case assetTypes.show:
      case assetTypes.sport:
        data = {
          ...data,
          buttonClass,
          secondLine: title,
          fourthLine: description,
          fourthLineClass: '',
          seeDetails: false,
        };

        break;
      case assetTypes.liveMovie:
      case assetTypes.liveShow:
      case assetTypes.liveSport: {
        const baseName = type === assetTypes.liveMovie ? title : showName;
        data = {
          ...data,
          buttonClass,
          firstLine: (
            <LiveTag classIcon={CLASS_ICON_LIVE} endTime={endTime} startTime={startTime} />
          ),
          secondLine: getAssetNameWithQualifier(qualifiers, baseName, formatMessage),
          fourthLine: getFourthLine(),
          fourthLineClass: 'live-sports-fourth-line',
          classMy: 'mb-0',
          futureEvent,
          seeDetails: false,
        };

        break;
      }
      case assetTypes.episode:
        data = {
          ...data,
          buttonClass,
          secondLine: showName,
          fourthLine: description,
          fourthLineClass: '',
          seeDetails: false,
        };

        break;
      case assetTypes.liveEpisode:
        data = {
          ...data,
          buttonClass,
          firstLine: (
            <LiveTag classIcon={CLASS_ICON_LIVE} endTime={endTime} startTime={startTime} />
          ),
          secondLine: getAssetNameWithQualifier(qualifiers, showName, formatMessage),
          fourthLine: getFourthLine(),
          fourthLineClass: 'live-sports-fourth-line',
          classMy: 'mb-0',
          futureEvent,
          seeDetails: false,
        };

        break;
      case assetTypes.serie:
        data = {
          ...data,
          buttonClass,
          secondLine: title,
          fourthLine: description,
          labelButton: detailsLabelButton,
          seeDetails: true,
        };

        break;
      case assetTypes.channel:
        data = {
          ...data,
          buttonClass,
          firstLine: <LiveTag classIcon={CLASS_ICON_LIVE} />,
          secondLine: title,
          logo: (
            <img
              className="dtv-card-16X9-logo"
              src={pictureID}
              alt="Logo"
            />
          ),
          classMy: 'my-0',
          seeDetails: false,
        };

        break;
      default:
        data = {
          firstLine: textUnavailable,
          secondLine: textUnavailable,
          fourthLine: textUnavailable,
          buttonClass: '',
        };

        break;
    }

    return data;
  };

  const getThirdLine = () => {
    let text = '';
    const genre = getGenre(genres);

    switch (type) {
      case assetTypes.movie:
      case assetTypes.show:
      case assetTypes.sport:
      case assetTypes.liveMovie:
        text = joinArrayWithSeparator([releaseYear, genre, formatSeconds(duration)]);

        break;
      case assetTypes.serie:
        text = joinArrayWithSeparator([formatMessage({ id: 'common.series', defaultMessage: 'Serie' }), genre]);
        break;
      case assetTypes.episode:
      case assetTypes.liveEpisode:
        text = buildEpisodeSecondaryDataString(seasonNumber, episodeNumber, title, formatMessage);
        break;
      case assetTypes.liveSport:
      case assetTypes.liveShow:
        text = buildEpisodeSecondaryDataString(
          seasonNumber,
          episodeNumber,
          title,
          formatMessage,
          true,
        );
        break;
      default:
        return null;
    }

    return (
      <>
        {!isLive && <AgeRating {...{ rating }} />}
        <span>{text}</span>
      </>
    );
  };

  const data = getAssetData();

  const handleClick = () => {
    setGtmCarouselVerticalPosition(carouselInfo.carouselVerticalPosition);
    setGtmPlaySource(playSource);
    setGtmSectionName(carouselInfo.sectionTitle);
    setGtmComponentType(carouselInfo.type);
    gtmWatchCarouselEventHandler({
      asset,
      gtmUserData,
      locationParams,
      carouselInfo: { ...carouselInfo, verticalPositionCarousel: 1 },
    });
  };

  return (
    <div ref={imageRef} className="dtv-card-16X9" style={imageStyle}>
      <div className="dtv-card-16X9-gradient" />
      {data.logo}
      <div className="dtv-card-16X9-left dtv-text-left">
        <div className="dtv-card-16X9-left-first-line">{data.firstLine}</div>
        <div className="dtv-card-16X9-left-second-line">{data.secondLine}</div>
        <div className={`dtv-card-16X9-left-third-line ${data.classMy || ''}`}>{getThirdLine()}</div>
        <div className={`${data.fourthLineClass ? data.fourthLineClass : 'dtv-card-16X9-left-fourth-line'}`}>
          {data.fourthLine}
        </div>
        <AssetToPlay
          asset={{
            assetId: vrioAssetId,
            type,
            channelId,
            playSource,
          }}
          isLive={isLive}
          redirectToDetailsFlag={data.seeDetails}
          entitled={entitled}
        >
          <Button
            className={`dtv-btn-watch-now ${data.futureEvent ? 'dtv-btn-watch-future' : ''}`}
            icon={data.buttonClass}
            tabIndex="-1"
            onClick={handleClick}
            rating={rating}
          >
            {getL(data, 'labelButton', '')}
          </Button>
        </AssetToPlay>
      </div>
    </div>
  );
}

Card16X9.propTypes = {
  genericCard: PropTypes.string,
  gradient: PropTypes.string,
  infoCard: PropTypes.shape(),
  carouselInfo: PropTypes.shape(),
  isInFeaturedCarousel: PropTypes.bool,
  pictureID: PropTypes.string,
};

Card16X9.defaultProps = {
  genericCard: '',
  gradient: '',
  infoCard: {},
  carouselInfo: {},
  isInFeaturedCarousel: true,
  pictureID: '',
};

export default injectIntl(Card16X9);
