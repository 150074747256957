/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmptyL from 'lodash-es/isEmpty';

/**
 * Styles
 */
import './Button.scss';

/* Other */
import {
  lockIconSelector,
} from '../../utils';

function Button({
  children,
  onClick,
  icon,
  className,
  textClassName,
  disabled,
  ghost,
  tabIndex,
  rating,
}) {
  const iconClassName = {
    lock: 'dtv-icon-lock',
    parental: 'dtv-icon-parental',
    play: 'dtv-icon-play',
  };

  const iconListed = Object.values(iconClassName);

  const { lockIconType } = lockIconSelector({
    shouldDisplayLockIcon: icon === iconClassName.lock,
    assetRating: rating,
  });

  const showIcon = !isEmptyL(icon);

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={(e) => onClick && onClick(e)}
      className={classNames('dtv-button', 'dtv-btn-primary', className, {
        'with-icon': showIcon,
        disabled,
        ghost,
      })}
      tabIndex={tabIndex}
    >
      {showIcon && <span className={`${iconListed.includes(icon) ? `dtv-icon-${lockIconType}` : icon} dtv-button-icon`} />}
      <span className={classNames('dtv-button-text', textClassName)}>{children}</span>
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  children: [],
  onClick() {},
  icon: '',
  className: '',
  textClassName: '',
  disabled: false,
};

export default Button;
