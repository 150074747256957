/* global UA */

/**
 * External
 */
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { differenceInDays } from 'date-fns';

/* Assets */
import DGOSKYlogo from '../../assets/images/dgo-sky-logo.svg';
import GranHermanologo from '../../assets/images/gran-hermano.svg';

/* Other */
import {
  BUSINESS_CAMPAIGN,
  COUNTRY_CODES,
  GRAN_HERMANO_MODAL_FLAG,
  MODAL_GRAN_HERMANO_SHOWN_LIST,
  NOTIFICATION_PERMISSION,
  PROFILES_STORAGE, SKYMAIS_MODAL_FLAG,
  environmentToRedirectSKY,
  getAuthTokensData,
  isBrazil,
} from '../../utils';
import { hideModal, showModal } from '../../state/modal/actions';
import { getImage } from '../../theme/images';

function HomeModal({
  intl: { formatMessage },
}) {
  const dispatch = useDispatch();
  const { modalGranHermanoShownList, profileID, userCountry } = getCurrentProfile();
  const uid = JSON.parse(localStorage.getItem('user')).uid || {};
  const notificationsPermission = JSON.parse(localStorage.getItem('notificationsPermission')) || {};
  const { allow = false, lastRequested = '' } = notificationsPermission[uid] || {};
  const userAllowedNotifications = (
    window.Notification.permission !== NOTIFICATION_PERMISSION.DENIED && allow
  );
  const now = new Date();
  const MAX_DAYS_NOTIFICATIONS_PERMISSION = 30;

  function showGranHermanoModal() {
    function closeGranHermanoModal() {
      modalGranHermanoShownList.push(profileID);

      localStorage.setItem(MODAL_GRAN_HERMANO_SHOWN_LIST, JSON.stringify(
        modalGranHermanoShownList,
      ));

      dispatch(hideModal());
    }

    const { idToken } = getAuthTokensData();
    const { msoProvider } = jwtDecode(idToken);
    const isGranHermanoUser = (BUSINESS_CAMPAIGN.has(msoProvider));

    dispatch(
      showModal({
        titleImageSrc: getImage('granHermano') || GranHermanologo,
        title: formatMessage({
          id: (isGranHermanoUser ? 'notifications.userGranHermano.title' : 'notifications.granHermano.title'),
          defaultMessage: '¡Gran Hermano Chile ya disponible!',
        }),
        message: formatMessage({
          id: (isGranHermanoUser ? 'notifications.userGranHermano.subtitle' : 'notifications.granHermano.subtitle'),
          defaultMessage: 'Ahora puedes disfrutar del reality más famoso del mundo sin perderte nada y de manera exclusiva.',
        }),
        primaryText: formatMessage({
          id: 'common.understood',
          defaultMessage: 'Entiendo',
        }),
        primaryAction: closeGranHermanoModal,
        onClose: closeGranHermanoModal,
      }),
    );
  }

  function showSKYModal() {
    function redirectToSKY() {
      const environment = import.meta.env.REACT_APP_ENV_NAME;
      window.location.href = `https://${environmentToRedirectSKY[environment]}.skymais.com.br`;

      dispatch(hideModal());
    }

    function denyNotificationsPermissionRequest() {
      dispatch(hideModal());
    }

    dispatch(
      showModal({
        titleImageSrc: getImage('dgoSkyLogo') || DGOSKYlogo,
        title: formatMessage({
          id: 'notifications.dgo.sky',
          defaultMessage: 'DGO ahora es SKY+',
        }),
        message: formatMessage({
          id: 'notifications.description.sky',
          defaultMessage: 'Para seguir disfrutando de tu TV paga favorita y contenido streaming, visita el sitio web de SKY+',
        }),
        primaryText: formatMessage({
          id: 'common.enter.sky',
          defaultMessage: 'Inicie sesión en SKY+',
        }),
        primaryAction: redirectToSKY,
        onClose: denyNotificationsPermissionRequest,
      }),
    );
  }

  function showDefaultModal() {
    const modalTitle = '';
    const modaldescription = '';
    const modalLanderToShow = '';

    const validModalParams = validateModalParams(
      modalTitle,
      modaldescription,
      modalLanderToShow,
    );

    function denyNotificationsPermissionRequest() {
      dispatch(hideModal());
      setNotificationsPermission(false);
    }

    async function allowNotificationsPermissionRequest() {
      dispatch(hideModal());

      try {
        const sdk = await UA;
        await sdk.register();

        sdk.channel.namedUser.set(uid);
        setNotificationsPermission(true);
      } catch {
        setNotificationsPermission(false);
      }
    }

    dispatch(
      showModal({
        title: validModalParams
          ? modalTitle
          : formatMessage({
            id: 'notifications.dontMiss',
            defaultMessage: '¡No te pierdas de nada!',
          }),
        message: validModalParams
          ? modaldescription
          : formatMessage({
            id: 'notifications.activateNotifications',
            defaultMessage:
                'Activa las notificaciones de DIRECTV GO y entérate de los próximos eventos, estrenos y episodios disponibles.',
          }),
        primaryText: formatMessage({
          id: 'common.activate',
          defaultMessage: 'Activar',
        }),
        primaryAction: allowNotificationsPermissionRequest,
        secondaryText: formatMessage({
          id: 'common.noThanks',
          defaultMessage: 'No, gracias',
        }),
        secondaryAction: denyNotificationsPermissionRequest,
        onClose: denyNotificationsPermissionRequest,
      }),
    );
  }

  useEffect(() => {
    if (
      !userAllowedNotifications
          && showModalInCurrentLander()
          && (!lastRequested
            || differenceInDays(now, new Date(lastRequested))
            >= MAX_DAYS_NOTIFICATIONS_PERMISSION)
    ) {
      showDefaultModal();
    }

    if (isBrazil && SKYMAIS_MODAL_FLAG) {
      showSKYModal();
    }

    if (userCountry !== COUNTRY_CODES.brazil
      && userCountry !== COUNTRY_CODES.mexico
      && !modalGranHermanoShownList.includes(profileID)
      && GRAN_HERMANO_MODAL_FLAG) {
      showGranHermanoModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function getCurrentProfile() {
  try {
    const modalGranHermanoShownList = JSON.parse(
      localStorage.getItem(MODAL_GRAN_HERMANO_SHOWN_LIST),
    ) || [];
    const profileID = JSON.parse(localStorage.getItem(PROFILES_STORAGE.profile)).id || null;
    const userCountry = JSON.parse(localStorage.getItem('user')).country.toLowerCase() || {};
    return {
      modalGranHermanoShownList,
      profileID,
      userCountry,
    };
  } catch {
    return {
      modalGranHermanoShownList: [],
      profileID: null,
      userCountry: null,
    };
  }
}

function validateModalParams(modalTitle, modaldescription, modalLanderToShow) {
  const MODAL_LANDERS = { ALL: 'all', SPORTS: 'sports' };
  return (
    modalTitle
    && modaldescription
    && modalLanderToShow
    && Object.values(MODAL_LANDERS).includes(modalLanderToShow)
  );
}

function setNotificationsPermission(allow) {
  const uid = JSON.parse(localStorage.getItem('user')).uid || {};
  const notificationsPermission = JSON.parse(localStorage.getItem('notificationsPermission')) || {};
  const now = new Date();
  localStorage.setItem(
    'notificationsPermission',
    JSON.stringify({ ...notificationsPermission, [uid]: { allow, lastRequested: now } }),
  );
}

function showModalInCurrentLander() {
  const {
    modalConfiguration: {
      title: modalTitle, description: modaldescription, show: modalLanderToShow,
    } = {},
    match: { params: { section = '' } = {} } = {},
  } = '';
  const MODAL_LANDERS = { ALL: 'all', SPORTS: 'sports' };
  // If AEM has no curated data, ask for notifications in any lander
  if (
    modalTitle === undefined && modaldescription === undefined && modalLanderToShow === undefined
  ) return true;

  return (
    !!validateModalParams(modalTitle, modaldescription, modalLanderToShow)
    && modalLanderToShow.length > 0
    && (modalLanderToShow === MODAL_LANDERS.ALL || modalLanderToShow === section)
  );
}

HomeModal.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

HomeModal.defaultProps = {
  intl: {
    formatMessage: () => {},
  },
};

export default injectIntl(HomeModal);
