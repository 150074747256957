import jsonSKY from '../../wonderwall-project-SKY.json';
import jsonDGO from '../../wonderwall-project-DGO.json';
import { APP_THEME, WONDERWALL_FEATURE_FLAG } from '../utils';

const root = document.documentElement;

// passing colors to RGB in order that you need some transparency
function hexToRgb(hex) {
  const r = Number.parseInt(hex.slice(1, 3), 16);
  const g = Number.parseInt(hex.slice(3, 5), 16);
  const b = Number.parseInt(hex.slice(5, 7), 16);
  return `${r}, ${g}, ${b}`;
}

if (WONDERWALL_FEATURE_FLAG) {
  const theme = APP_THEME;
  const json = theme === 'skymais' ? jsonSKY : jsonDGO; // DGO default

  // Neutral Texts
  root.style.setProperty('--neutral-text-primary', json.colors.neutralTextPrimary);
  root.style.setProperty('--neutral-text-secondary', json.colors.neutralTextSecondary);
  root.style.setProperty('--neutral-text-disable', json.colors.neutralTextDisable);
  root.style.setProperty('--neutral-text-active', json.colors.neutralTextActive);

  // Neutral Texts Cross
  root.style.setProperty('--neutral-text-cross-white-primary', json.colors.neutralTextCrossWhitePrimary);
  root.style.setProperty('--neutral-text-cross-white-secondary', json.colors.neutralTextCrossWhiteSecondary);
  root.style.setProperty('--neutral-text-cross-gray', json.colors.neutralTextCrossGray);
  root.style.setProperty('--neutral-text-cross-black-primary', json.colors.neutralTextCrossBlackPrimary);
  root.style.setProperty('--neutral-text-cross-black-secondary', json.colors.neutralTextCrossBlackSecondary);

  // Neutral Background
  root.style.setProperty('--neutral-bg-primary', json.colors.neutralBgPrimary);
  root.style.setProperty('--neutral-bg-primary-opacity', hexToRgb(json.colors.neutralBgPrimary));
  root.style.setProperty('--neutral-bg-secondary', json.colors.neutralBgSecondary);
  root.style.setProperty('--neutral-bg-secondary-opacity', hexToRgb(json.colors.neutralBgSecondary));
  root.style.setProperty('--neutral-bg-tertiary', json.colors.neutralBgTertiary);
  root.style.setProperty('--neutral-bg-tertiary-opacity', hexToRgb(json.colors.neutralBgTertiary));
  root.style.setProperty('--neutral-bg-disable', json.colors.neutralBgDisable);
  root.style.setProperty('--neutral-bg-active', json.colors.neutralBgActive);

  // Hover Neutral Background
  root.style.setProperty('--neutral-hover-bg-primary', json.colors.neutralHoverBgPrimary);
  root.style.setProperty('--neutral-hover-bg-secondary', json.colors.neutralHoverBgSecondary);
  root.style.setProperty('--neutral-hover-bg-tertiary', json.colors.neutralHoverBgTertiary);
  root.style.setProperty('--neutral-hover-bg-disable', json.colors.neutralHoverBgDisable);
  root.style.setProperty('--neutral-hover-bg-active', json.colors.neutralHoverBgActive);

  // Neutral Background Cross
  root.style.setProperty('--neutral-bg-cross-white-primary', json.colors.neutralBgCrossWhitePrimary);
  root.style.setProperty('--neutral-bg-cross-white-secondary', json.colors.neutralBgCrossWhiteSecondary);
  root.style.setProperty('--neutral-bg-cross-white-secondary-opacity', hexToRgb(json.colors.neutralBgCrossWhiteSecondary));
  root.style.setProperty('--neutral-bg-cross-gray', json.colors.neutralBgCrossGray);
  root.style.setProperty('--neutral-bg-cross-black-primary', json.colors.neutralBgCrossBlackPrimary);
  root.style.setProperty('--neutral-bg-cross-black-primary-opacity', hexToRgb(json.colors.neutralBgCrossBlackPrimary));
  root.style.setProperty('--neutral-bg-cross-black-secondary', json.colors.neutralBgCrossBlackSecondary);
  root.style.setProperty('--neutral-bg-cross-black-secondary-opacity', hexToRgb(json.colors.neutralBgCrossBlackSecondary));

  // Neutral Border
  root.style.setProperty('--neutral-border-primary', json.colors.neutralBorderPrimary);
  root.style.setProperty('--neutral-border-secondary', json.colors.neutralBorderSecondary);
  root.style.setProperty('--neutral-border-secondary-opacity', hexToRgb(json.colors.neutralBorderSecondary));

  // Neutral Border Cross
  root.style.setProperty('--neutral-border-cross-black-primary', json.colors.neutralBorderCrossBlackPrimary);
  root.style.setProperty('--neutral-border-cross-black-secondary', json.colors.neutralBorderCrossBlackSecondary);
  root.style.setProperty('--neutral-border-cross-black-secondary-opacity', hexToRgb(json.colors.neutralBorderCrossBlackSecondary));
  root.style.setProperty('--neutral-border-cross-white-primary', json.colors.neutralBorderCrossWhitePrimary);
  root.style.setProperty('--neutral-border-cross-white-secondary', json.colors.neutralBorderCrossWhiteSecondary);

  // Main
  root.style.setProperty('--main-text-primary', json.colors.mainTextPrimary);
  root.style.setProperty('--main-bg-primary', json.colors.mainBgPrimary);
  root.style.setProperty('--main-bg-primary-opacity', hexToRgb(json.colors.mainBgPrimary));
  root.style.setProperty('--main-bg-active', json.colors.mainBgActive);
  root.style.setProperty('--main-border', json.colors.mainBorder);
  root.style.setProperty('--main-border-opacity', hexToRgb(json.colors.mainBorder));
  root.style.setProperty('--main-brand', json.colors.mainBrand);

  // Main Hover Background
  root.style.setProperty('--main-hover-bg-primary', json.colors.mainHoverBgPrimary);
  root.style.setProperty('--main-hover-bg-active', json.colors.mainHoverBgActive);

  // Complementary
  root.style.setProperty('--complementary-text-primary', json.colors.complementaryTextPrimary);
  root.style.setProperty('--complementary-bg-primary', json.colors.complementaryBgPrimary);
  root.style.setProperty('--complementary-hover-bg-primary', json.colors.complementaryHoverBgPrimary);
  root.style.setProperty('--complementary-border', json.colors.complementaryBorder);
  root.style.setProperty('--complementary-brand', json.colors.complementaryBrand);

  // Success
  root.style.setProperty('--success-text-primary', json.colors.successTextPrimary);
  root.style.setProperty('--success-bg-primary', json.colors.successBgPrimary);
  root.style.setProperty('--success-hover-bg-primary', json.colors.successHoverBgPrimary);
  root.style.setProperty('--success-border', json.colors.successBorder);

  // Warning
  root.style.setProperty('--warning-text-primary', json.colors.warningTextPrimary);
  root.style.setProperty('--warning-bg-primary', json.colors.warningBgPrimary);
  root.style.setProperty('--warning-hover-bg-primary', json.colors.warningHoverBgPrimary);
  root.style.setProperty('--warning-border', json.colors.warningBorder);

  // Error
  root.style.setProperty('--error-text-primary', json.colors.errorTextPrimary);
  root.style.setProperty('--error-bg-primary', json.colors.errorBgPrimary);
  root.style.setProperty('--error-hover-bg-primary', json.colors.errorHoverBgPrimary);
  root.style.setProperty('--error-border', json.colors.errorBorder);

  // Informative
  root.style.setProperty('--informative-text-primary', json.colors.informativeTextPrimary);
  root.style.setProperty('--informative-bg-primary', json.colors.informativeBgPrimary);
  root.style.setProperty('--informative-hover-bg-primary', json.colors.informativeHoverBgPrimary);
  root.style.setProperty('--informative-border', json.colors.informativeBorder);

  // Avatar
  root.style.setProperty('--avatar-bg-1', json.colors.avatarBg1);
  root.style.setProperty('--avatar-bg-2', json.colors.avatarBg2);
  root.style.setProperty('--avatar-bg-3', json.colors.avatarBg3);
  root.style.setProperty('--avatar-bg-4', json.colors.avatarBg4);
  root.style.setProperty('--avatar-bg-5', json.colors.avatarBg5);

  // Opacity White
  root.style.setProperty('--opacity-white-weakest', json.colors.opacityWhiteWeakest);
  root.style.setProperty('--opacity-white-weak', json.colors.opacityWhiteWeak);
  root.style.setProperty('--opacity-white-medium', json.colors.opacityWhiteMedium);
  root.style.setProperty('--opacity-white-strong', json.colors.opacityWhiteStrong);
  root.style.setProperty('--opacity-white-strongest', json.colors.opacityWhiteStrongest);

  // Opacity Black
  root.style.setProperty('--opacity-black-weakest', json.colors.opacityBlackWeakest);
  root.style.setProperty('--opacity-black-weak', json.colors.opacityBlackWeak);
  root.style.setProperty('--opacity-black-medium', json.colors.opacityBlackMedium);
  root.style.setProperty('--opacity-black-strong', json.colors.opacityBlackStrong);
  root.style.setProperty('--opacity-black-strongest', json.colors.opacityBlackStrongest);
}
