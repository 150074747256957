/**
 * External
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/* OTT-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';

/**
 * Components
 */
import Button from '../button/Button';
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/errors';

/**
 * Styles
 */
import './BlackoutScreen.scss';

/**
 * Assets
 */
import logo from '../../assets/images/logo/dgo.png';

/* Other */
import { formatContentForGtm } from '../../utils';

/**
 * Renders a blue screen with directvgo logo, title, subtitle and actions
 */
function BlackoutScreen({
  className,
  mainButtonText,
  onMainButtonClick,
  onSecondaryButtonClick,
  secondaryButtonText,
  subtitle,
  title,
}) {
  const { gtmUserData } = useGtmContext();
  const handleClick = (buttonAction) => {
    const { hash, eventType, dimensions } = gtmDimensions.error.ok;
    const dataToSend = formatContentForGtm();
    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataToSend,
    });

    buttonAction();
  };

  useEffect(() => {
    const { hash, eventType, dimensions } = gtmDimensions.error.page_view;
    const dataToSend = formatContentForGtm();
    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataToSend,
    });
  }, [gtmUserData]);

  return (
    <div className={`dtv-blackout-screen${className ? ` ${className}` : ''}`}>
      <img className="dtv-blackout-screen-logo" src={logo} alt="Logo" />
      <h2 className="dtv-blackout-screen-title">{title}</h2>
      <h3 className="dtv-blackout-screen-subtitle">{subtitle}</h3>
      <div className="dtv-blackout-screen-actions">
        {mainButtonText && onMainButtonClick && (
          <Button className="dtv-blackout-screen-btn dtv-blackout-screen-btn-main" onClick={() => handleClick(onMainButtonClick)}>
            {mainButtonText}
          </Button>
        )}
        {secondaryButtonText && onSecondaryButtonClick && (
          <Button
            className="dtv-blackout-screen-btn dtv-blackout-screen-btn-secondary"
            ghost
            onClick={() => handleClick(onSecondaryButtonClick)}
          >
            {secondaryButtonText}
          </Button>
        )}
      </div>
    </div>
  );
}

BlackoutScreen.propTypes = {
  className: PropTypes.string,
  mainButtonText: PropTypes.string,
  onMainButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

BlackoutScreen.defaultProps = {
  className: '',
  mainButtonText: '',
  onMainButtonClick: () => {},
  onSecondaryButtonClick: () => {},
  secondaryButtonText: '',
  subtitle: '',
  title: '',
};

export default BlackoutScreen;
