/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import CircleItem from '../../circleItem/CircleItem';
import TeamFlag from '../../teamFlag/TeamFlag';

/* Styles */
import './Teams.scss';

function Teams({ teams = [], teamIdSelected = '', onSelectingTeam = () => {} }) {
  return (
    <div className="dtv-sport-players-teams">
      {teams.map((team) => {
        const teamFlagUrl = team.images?.length > 0 ? team.images[0]?.url : '';
        return (
          <CircleItem
            key={team.fifaTeamId}
            isItemSelected={teamIdSelected === team.fifaTeamId}
            entityId={team.fifaTeamId}
            handleClick={onSelectingTeam}
          >
            <TeamFlag teamFlagUrl={teamFlagUrl} teamName={team.name} />
          </CircleItem>
        );
      })}
    </div>
  );
}

Teams.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  teamIdSelected: PropTypes.string.isRequired,
  onSelectingTeam: PropTypes.func.isRequired,
};

export default Teams;
