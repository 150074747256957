/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Components
 */
import LiveCardTag from '../LiveCardTag/LiveCardTag';

/**
 * Styles
 */
import './LiveStationLine.scss';

function LiveStationLine(
  {
    startTime, endTime, channelName,
  },
) {
  return (
    <div className="dtv-live-station-line">
      <LiveCardTag startTime={startTime} endTime={endTime} />
      {channelName && <h4 className="dtv-live-station-line-text">{channelName}</h4>}
    </div>
  );
}

LiveStationLine.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  channelName: PropTypes.string,
};

LiveStationLine.defaultProps = {
  startTime: '',
  endTime: '',
  channelName: '',
};

export default LiveStationLine;
