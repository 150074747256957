/* External */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

/* Components */
import Switch from '../switch/Switch';
import StepClassification from '../stepClassification/StepClassification';
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/profile';

/* Other */
import { showModal, hideModal } from '../../state/modal/actions';
import { contentAllowedBR } from './ContentAllowedBR';
import { contentAllowedSSLA } from './ContentAllowedSSLA';

/* Utils */
import { IS_BRAZIL, isBrazil, userGtmEventHandler } from '../../utils';

/* Styles */
import './ParentalMenu.scss';

function ParentalMenu(
  {
    activeStep,
    setActiveStep,
    contentWithoutClassification,
    setContentWithoutClassification,
    profilesAdmin,
    setIsProfileConfigEdited,
    intl: { formatMessage },
  },
) {
  const dispatch = useDispatch();
  const [contentAllowed, setContentAllowed] = useState([]);
  const { gtmUserData } = useGtmContext();
  const stepLength = isBrazil ? 6 : 7;

  useState(() => {
    userGtmEventHandler(gtmDimensions.edit_profile.page_view, gtmUserData);
  }, []);

  useState(() => {
    if (IS_BRAZIL) {
      setContentAllowed(contentAllowedBR);
    } else {
      setContentAllowed(contentAllowedSSLA);
    }
  });

  const handlePrimaryActionModal = () => {
    setIsProfileConfigEdited(true);
    userGtmEventHandler(gtmDimensions.edit_profile.continue, gtmUserData);
    dispatch(hideModal());
  };

  const handleCloseModal = () => {
    userGtmEventHandler(gtmDimensions.edit_profile.back, gtmUserData);
    dispatch(hideModal());
  };

  const handleHideModal = () => {
    dispatch(hideModal());
  };

  function changeContent() {
    dispatch(
      showModal({
        title: `${formatMessage({ id: 'profiles.parentalControl.content.allow', defaultMessage: 'Contenidos permitidos' })}`,
        message: classificationContent(),
        primaryText: `${formatMessage({ id: 'profiles.parentalControl.continue', defaultMessage: 'Continuar' })}`,
        primaryAction: () => { handlePrimaryActionModal(); },
        onClose: () => { handleCloseModal(); },
        onHide: () => { handleHideModal(); },
        className: 'dtv-modal-container__parental-control',
      }),
    );
  }

  function classificationContent() {
    return (
      <div>
        <div className="dtv-web-parental-control__classification-title">
          {formatMessage({ id: 'profiles.parentalControl.limit.classification', defaultMessage: 'Límite de clasificación' })}
        </div>
        <hr className="dtv-web-parental-control__horizontal-bar" />
        <div className="dtv-web-parental-control__classification-select">
          <div>
            {formatMessage({ id: 'profiles.parentalControl.select.age.limit', defaultMessage: 'Selecciona el límite de edad para el contenido que se puede ver dentro de este perfil.' })}
          </div>
          <div>
            {formatMessage({ id: 'profiles.parentalControl.select.age.limit.2', defaultMessage: '' })}
          </div>
        </div>

        <StepClassification
          activeStep={activeStep || stepLength}
          setActiveStep={setActiveStep}
        />

        <div className="dtv-web-parental-others-title">
          {formatMessage({
            id: 'profiles.parentalControl.others.restriction',
            defaultMessage: 'Otras restricciones',
          })}
        </div>
        <hr className="dtv-web-parental-control__horizontal-bar" />
        <div className="dtv-web-parental-control__content-without-classification">
          <div>
            <div className="dtv-web-parental-control__content-without-classification-title">
              {formatMessage({
                id: 'profiles.parentalControl.content.without.classification',
                defaultMessage: 'Contenidos sin clasificación',
              })}
            </div>
            <div className="dtv-web-parental-control__content-without-classification-subtitle">
              {formatMessage({ id: 'profiles.parentalControl.allow.contents', defaultMessage: 'Permitir Contenidos' })}
            </div>
          </div>
          <Switch
            className="dtv-web-parental-control__switch"
            data={contentWithoutClassification !== undefined ? contentWithoutClassification : true}
            setData={setContentWithoutClassification}
          />

        </div>
        <div className="dtv-web-parental-control__info">
          {formatMessage({ id: 'profiles.parentalControl.content.info', defaultMessage: 'Son contenidos que no han sido clasificados por la productora o no necesitan tener clasificación, como noticieros o eventos deportivos.' })}
        </div>
      </div>
    );
  }

  return (
    <div className="dtv-web-parental-control">
      <div className="dtv-web-parental-control__menu">
        <div className="dtv-web-parental-control__menu-title">
          {formatMessage({ id: 'profiles.parentalControl.title', defaultMessage: 'Control parental' })}
        </div>
        <hr className="dtv-web-parental-control__horizontal-bar" />
        <div className="dtv-web-parental-control__category">
          <div>
            <div className="dtv-web-parental-control__category-title">
              {formatMessage({ id: 'profiles.parentalControl.content.allow', defaultMessage: 'Contenidos permitidos' })}
            </div>
            <div className="dtv-web-parental-control__category-subtitle">{!activeStep || activeStep === '' ? contentAllowed[6] : contentAllowed[activeStep]}</div>
          </div>
          {profilesAdmin === true ? ('') : (
            <button type="button" onClick={changeContent} className="dtv-web-parental-control__category-change">
              {formatMessage({ id: 'profiles.parentalControl.change', defaultMessage: 'Cambiar' })}
            </button>
          )}
        </div>
        {profilesAdmin === true ? (
          <div className="dtv-web-parental-control__admin">
            {formatMessage({ id: 'profiles.parentalControl.admin.restriction', defaultMessage: 'Para configurar restricciones, debes crear por lo menos un perfil adicional.' })}
          </div>
        ) : ('')}
      </div>
    </div>
  );
}

ParentalMenu.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  activeStep: PropTypes.string,
  setActiveStep: PropTypes.func,
  contentWithoutClassification: PropTypes.bool,
  setContentWithoutClassification: PropTypes.func,
  profilesAdmin: PropTypes.bool,
  setIsProfileConfigEdited: PropTypes.func,
};

ParentalMenu.defaultProps = {
  intl: {
    formatMessage: () => {},
  },
  activeStep: '',
  setActiveStep: undefined,
  contentWithoutClassification: false,
  setContentWithoutClassification: undefined,
  profilesAdmin: false,
  setIsProfileConfigEdited: () => {},
};

export default injectIntl(ParentalMenu);
