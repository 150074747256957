/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Assets */
import genericCard16X9 from '@/assets/theme/images/16x9-GenericCard.png';

/* Ott-common */
import ImageBannerOtt from '@dtvgo/image-banner';

/* Components */
import Button from '../button/Button';

/* Utils */
import { CollectionText, getCollectionRedirectUrl, getVrioImageUrl } from '../../utils';
import { history } from '../../state/configureStore';

/* Styles */
import './ImageBanner.scss';
import { getImage } from '../../theme/images';

function ImageBanner({
  collection,
  containerCarouselId,
  containerCarouselType,
}) {
  if (!collection) return null;
  const defaultImage = getImage('16x9GenericCard') || genericCard16X9;
  const handleOnClick = () => {
    const redirectUrl = getCollectionRedirectUrl(
      collection,
      containerCarouselId,
      containerCarouselType,
    );

    if (redirectUrl) history.push(redirectUrl);
  };

  return (
    <ImageBannerOtt
      className="dtv-webpriv-c-image-banner"
      headline={collection.headline}
      title={collection.subtitle}
      subtitle={collection.description}
      imageUrl={getVrioImageUrl(collection.iconImages, '16:9', {}) || defaultImage}
      backgroundImageUrl={getVrioImageUrl(collection.cardImages, '16:9', {}) || defaultImage}
    >
      <Button
        className="dtv-webpriv-c-image-banner__button"
        onClick={handleOnClick}
      >
        {collection.buttonText}
      </Button>
    </ImageBannerOtt>
  );
}

ImageBanner.propTypes = {
  collection: PropTypes.shape({
    headline: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    cardImages: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
    })).isRequired,
    iconImages: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
    })).isRequired,
    backGroundImages: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
    })),
    actions: PropTypes.arrayOf(PropTypes.shape({
      target: PropTypes.string,
      text: PropTypes.oneOf([CollectionText.SPORT_PAGE]),
      type: PropTypes.string,
    })).isRequired,
  }).isRequired,
  containerCarouselId: PropTypes.string.isRequired,
  containerCarouselType: PropTypes.string.isRequired,
};

export default ImageBanner;
