/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import HBSWidget from '../HBSWidget';

/* Styles */
import './MatchResultCard.scss';

function MatchResultCard({ fifaMatchId }) {
  if (!fifaMatchId) return null;

  return (
    <div className="dtv-match-result-card">
      <HBSWidget type="matchbox" props={{ type: 'match', id: fifaMatchId, matchboxSize: 'medium' }} />
    </div>
  );
}

MatchResultCard.propTypes = {
  fifaMatchId: PropTypes.string.isRequired,
};

export default MatchResultCard;
