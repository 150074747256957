import { userConstants } from './userConstants';

export const gtmDimensions = {
  component_type: 'search',
  search_bar: {
    hash: 'e-012.007.001.000.000.032',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
      'v_tab',
      'v_provider_name',
      'v_collection_name',
    ],
  },
  search_series: {
    result: {
      hash: 'e-008.036.001.000.000.112',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_search_terms',
        'v_section_name',
        'v_list_position',
        'v_transmission_type',
        'v_content_locked',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
      ],
    },
  },
  search_live: {
    result: {
      hash: 'e-008.036.001.000.000.112',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_season',
        'v_search_terms',
        'v_section_name',
        'v_list_position',
        'v_transmission_type',
        'v_content_locked',
        'v_channel_id',
        'v_episode_number',
        'v_serie_name',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
      ],
    },
  },
  search_vod: {
    result: {
      hash: 'e-008.036.001.000.000.112',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_search_terms',
        'v_section_name',
        'v_list_position',
        'v_transmission_type',
        'v_content_locked',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
      ],
    },
  },
  clear: {
    hash: 'e-008.036.001.000.000.040',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
      'v_search_terms',
    ],
  },
  pre_search: {
    hash: 'e-008.036.001.000.000.220',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
      'v_search_terms',
      'v_list_position',
      'v_provider_name',
      'v_collection_name',
    ],
  },
  not_found: {
    hash: 'e-008.036.001.000.000.042',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
      'v_search_terms',
    ],
  },
};
