/* External */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

/* Styles */
import './ChannelImage.scss';

function ChannelImage({ imageUrl, title }) {
  return (
    <div className={cn('dtv-channel-image')}>
      <img
        alt={title}
        title={title}
        className={cn('dtv-channel-image__img')}
        src={imageUrl}
      />
    </div>
  );
}

ChannelImage.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
};

ChannelImage.defaultProps = {
  imageUrl: '',
  title: '',
};

export default ChannelImage;
