import React from 'react';
import PropTypes from 'prop-types';

import { useIsDelayed, useLiveProgressBarInfo } from '@dtvgo/player-utils-react';
import { ProgressBar as CommonProgressBar } from '@dtvgo/web-player-ui';

import ProgressBar from '../ProgressBar';
import { ClassNames } from '../../../utils';

import './LiveProgressBar.scss';

const LiveProgressBarClassNames = ClassNames.component('live-progress-bar');

export default function LiveProgressBar({ className }) {
  const {
    progress, duration, seekable, handleSeek,
  } = useLiveProgressBarInfo();
  const delayed = useIsDelayed();

  return (
    <ProgressBar
      className={LiveProgressBarClassNames.block({ delayed }, className)}
      progress={progress}
      duration={duration}
      seekable={seekable}
      onSeek={handleSeek}
    >
      <CommonProgressBar.SeekableEndDot className={LiveProgressBarClassNames.element('seekable-end-dot')} />
    </ProgressBar>
  );
}

LiveProgressBar.propTypes = {
  className: PropTypes.string,
};

LiveProgressBar.defaultProps = {
  className: '',
};
