/**
 * External
 */
import Immutable from 'immutable';

/**
 * Initial state
 */
const initialState = Immutable.Map({
  showStores: true,
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}
