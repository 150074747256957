/**
 * External
 */
import isEmptyL from 'lodash-es/isEmpty';

/**
 * Other
 */
import { isValidString } from '../misc';
import * as apiConfig from './apiConfig';
import * as staticUrls from './staticUrls';

export const API = { ...apiConfig };

export const STATIC_URLS = { ...staticUrls };

/**
 * Get the full URL with parameters to make a request
 * @param {*} urlWithoutParams
 * @param {*} params
 */
export function getURLWithParams(urlWithoutParams, params) {
  if (!urlWithoutParams) throw new Error('utils>urls>index-getURLWithParams-Missing URL!');

  const errorMessageMissingParams = 'utils>urls>index-getURLWithParams-Missing params!';
  const regexp = /{(.*?)}/g;
  const match = [...urlWithoutParams.matchAll(regexp)];
  const nameOfParams = match.map((item) => item[1]);

  if (nameOfParams.length > 0 && isEmptyL(params)) throw new Error(errorMessageMissingParams);

  const eachParamExists = nameOfParams.every((name) => isValidString(params[name]));

  if (!eachParamExists) throw new Error(errorMessageMissingParams);

  let urlWithParams = urlWithoutParams;
  nameOfParams.forEach((name) => {
    urlWithParams = urlWithParams.replace(`{${name}}`, params[name]);
  });

  return urlWithParams;
}
