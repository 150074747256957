/* External */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { format, setSeconds, startOfDay } from 'date-fns';

/* Ott-common */
import { ProgressBar } from '@dtvgo/web-player-ui';

/* Assets */
import liveIcon from '@/assets/theme/icons/live-icon.png';

/* Others */
import { getImage } from '../../theme/images';

const getCurrentTimeText = (currentTime) => format(setSeconds(startOfDay(new Date()), currentTime), 'H:mm:ss');

function Progress({
  progress,
  duration,
  seekable,
  onSeek,
  isLive,
  intl: { formatMessage },
}) {
  const handleRewind = () => {
    onSeek(progress - 30);
  };

  return (
    <div className="dtv-chromecast-bar-player-progressbar">
      {!isLive && (
        <button
          type="button"
          className="dtv-chromecast-bar-player-rewind"
          onClick={handleRewind}
          title={formatMessage({ id: 'player.rewind30', defaultMessage: 'Retroceder 30 segundos' })}
        >
          <span className="dtv-icon-seconds-playback" />
        </button>
      )}

      <ProgressBar
        className="dtv-chromecast-bar-player-progress"
        seekable={seekable}
        progress={progress}
        duration={duration}
        onSeek={onSeek}
      >
        {useMemo(() => (
          <>
            <ProgressBar.ProgressFill />
            <ProgressBar.ProgressDot />
            {isLive && <ProgressBar.SeekableEndFill />}
            <ProgressBar.MouseOver />
          </>
        ), [isLive])}
      </ProgressBar>

      <div className="dtv-chromecast-bar-player-duration">
        {isLive ? (
          <span>
            <img alt="Live" src={getImage('iconLive') || liveIcon} />
            {formatMessage({ id: 'player.live', defaultMessage: 'EN VIVO' })}
          </span>
        ) : getCurrentTimeText(Math.abs(duration - progress))}
      </div>
    </div>
  );
}

Progress.propTypes = {
  progress: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  seekable: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
  }),
  onSeek: PropTypes.func.isRequired,
  isLive: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

Progress.defaultProps = {
  seekable: undefined,
};

export default injectIntl(memo(Progress));
