/**
 * External
 */
import Immutable from 'immutable';

/**
 * Actions
 */
import { ACTION_TYPES } from './actions';

const initialState = Immutable.Map({
  toastNotification: null,
  barNotification: {
    containerId: null,
    show: false,
    type: 'info',
    message: '',
    messageId: '',
  },
  notificationHeight: 0,
  navBarHeight: 0,
});

export default function notifications(state = initialState, { type, payload } = {}) {
  switch (type) {
    case ACTION_TYPES.SHOW_TOAST_NOTIFICATION:
      return state.set('toastNotification', payload);
    case ACTION_TYPES.SHOW_BAR_NOTIFICATION:
      return state.set('barNotification', payload);
    case ACTION_TYPES.SET_HEIGHT_NOTIFICATION:
      return state.set('notificationHeight', payload.notificationHeight).set('navBarHeight', payload.navBarHeight);
    default:
      return state;
  }
}
