/**
 * External
 */
import React from 'react';
import { gtmEvent } from '@dtvgo/gtm-event-report';
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/pdp';

/**
 * Components
 */
import AssetToPlay from '../assetToPlay/AssetToPlay';
import Button from '../button/Button';
import ProgressBar from '../VOD/progressBar/ProgressBar';

/**
 * Styles
 */
import './PdpPlayButton.scss';

/** Other */
import {
  getGtmEventConfig,
} from '../../utils';

function PdpPlayButton({
  assetProps, duration, progress, text, icon,
}) {
  const {
    gtmUserData, gtmContent,
  } = useGtmContext();

  const formattingDataForGtm = (content) => {
    const gtmData = {
      ...content,
    };

    const isResume = (progress > 0);
    if (isResume) gtmData.content.progress = progress;

    const { hash, dimensions } = getGtmEventConfig(
      assetProps.asset.type,
      gtmDimensions,
      'pdp',
      isResume ? 'resume' : 'watch',
    );

    return {
      gtmData,
      hash,
      dimensions,
    };
  };
  const handleClick = () => {
    const sendToGtm = formattingDataForGtm(gtmContent);
    gtmEvent({
      hash: sendToGtm.hash,
      eventType: 'interactions',
      userData: gtmUserData,
      dimensions: sendToGtm.dimensions,
      data: sendToGtm.gtmData,
    });
  };

  return (
    <AssetToPlay className="dtv-pdp-play-button" asset={assetProps.asset} isLive={assetProps.isLive}>
      <Button icon={icon} onClick={handleClick} rating={assetProps.asset.rating}>
        {text}
        {progress > 0 && (
          <ProgressBar className="dtv-pdp-play-button-progress" duration={duration} progress={progress} />
        )}
      </Button>
    </AssetToPlay>
  );
}

export default PdpPlayButton;
