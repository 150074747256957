/* External */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

/* Components */
import SeriesDetails from '../components/seriesDetails/SeriesDetails';
import VodContent from './vodContent/VodContent';
import LiveContent from './liveContent/LiveContent';

/* Styles */
import './Pdp.scss';

/* Other */
import { history } from '../state/configureStore';
import {
  PUBLIC_PDP_ASSET_TYPES,
  getUserLanguageFromEnvironmentPublicPdp,
  IS_BRAZIL,
  REGIONS,
} from '../utils';
import { createBackendServicePublic } from '../services/backend';

function getRegionPublicPage() {
  if (IS_BRAZIL) {
    return REGIONS.BR;
  }

  return REGIONS.SSLA;
}

function PublicPdp({
  match: {
    params: {
      country: countryQuery,
      assetId,
      assetType,
    },
  },
}) {
  const languageEnvironment = getUserLanguageFromEnvironmentPublicPdp(countryQuery);
  const isPublic = true;
  const containerRef = useRef();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const startTimeParam = queryParams.get('startTime');
  const showBackParam = queryParams.get('showBack');
  const [dataPublic, setDataPublic] = useState({});
  const regionPublic = getRegionPublicPage();
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => document.body.classList.remove('no-scroll');
  }, []);
  useEffect(() => {
    containerRef.current.scroll(0, 0);
  }, [assetId]);

  useEffect(() => {
    async function fetchData() {
      switch (assetType) {
        case PUBLIC_PDP_ASSET_TYPES.live: {
          const returnPublicLive = await createBackendServicePublic.get(`/live/schedules?channelId=${assetId}&startTime=${startTimeParam}`, languageEnvironment, regionPublic);
          setDataPublic({
            data: {
              ...returnPublicLive?.data,
              shouldDisplayLockIcon: true,
            },
            isError: (!returnPublicLive.data && returnPublicLive?.code !== 200),
          });
          break;
        }
        default: {
          const returnPublic = await createBackendServicePublic.get(`/asset/${assetId}`, languageEnvironment, regionPublic);
          setDataPublic({
            data: {
              ...returnPublic?.data,
              shouldDisplayLockIcon: true,
            },
            isError: (!returnPublic.data && returnPublic?.code !== 200),
          });
          break;
        }
      }
    }
    fetchData();
  }, [assetId, assetType, startTimeParam, languageEnvironment, regionPublic]);

  const getContent = () => {
    switch (assetType) {
      case PUBLIC_PDP_ASSET_TYPES.live:
        return (
          <LiveContent
            channelId={assetId}
            resourceType={assetType}
            isPublic={isPublic}
            dataPublic={dataPublic}
          />
        );
      case PUBLIC_PDP_ASSET_TYPES.series:
        return (
          <SeriesDetails
            assetId={assetId}
            isPublic={isPublic}
            dataPublic={dataPublic}
            regionPublic={regionPublic}
          />
        );
      case PUBLIC_PDP_ASSET_TYPES.movieBR:
      case PUBLIC_PDP_ASSET_TYPES.movieES:
      case PUBLIC_PDP_ASSET_TYPES.movieEN:
      case PUBLIC_PDP_ASSET_TYPES.sportsES:
        return (
          <VodContent
            assetId={assetId}
            isPublic={isPublic}
            dataPublic={dataPublic}
          />
        );
      default:
        return null;
    }
  };

  const content = getContent();

  return (
    <div className="dtv-details" ref={containerRef}>
      <div className="dtv-details-icons justify-end">
        {(showBackParam && showBackParam === 'false')
          ? ''
          : <span onClick={() => history.goBack()} className="dtv-details-icons-icon dtv-icon-close" />}
      </div>
      <div className="dtv-details-content">
        {content}
      </div>
    </div>
  );
}

PublicPdp.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      assetId: PropTypes.string,
      assetType: PropTypes.string,
    }),
  }).isRequired,
};

export default PublicPdp;
