/**
 * External
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import window from 'global/window';
import { isBefore, subSeconds } from 'date-fns';
import { injectIntl } from 'react-intl';

/**
 * Other
 */
import { showToastNotification as showToastNotificationAction } from '../state/notifications/actions';

const SLOW_CONNECTION_SECONDS = 21;

class SlowConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastTimeEventHandled: subSeconds(new Date(), SLOW_CONNECTION_SECONDS),
    };

    this.bindSlowConnectionEvent = this.bindSlowConnectionEvent.bind(this);
    this.handleSlowConectionEvent = this.handleSlowConectionEvent.bind(this);
    this.throttleSlowConnectionEvent = this.throttleSlowConnectionEvent.bind(this);
  }

  /**
   * @method componentDidMount
   * @description React lifecycle method.
   *              Used to call the method that add the event listener.
   */
  componentDidMount() {
    this.bindSlowConnectionEvent();
  }

  /**
   * @method componentWillUnmount
   * @description React lifecycle method.
   *              Used to clean toast notifications.
   */
  componentWillUnmount() {
    const { showToastNotification } = this.props;
    showToastNotification(null);
    clearTimeout(this.clearToastNotification);
  }

  /**
   * @method handleSlowConectionEvent
   * @description Used to handle the slow connection event by showing a toast notification with
   *              a warning message for the user.
   */
  handleSlowConectionEvent() {
    const {
      showToastNotification,
      intl: { formatMessage },
    } = this.props;
    showToastNotification({
      type: 'info',
      time: 20_000,
      message: formatMessage({
        id: 'notifications.slowConnection',
        defaultMessage:
          'La página está tardando más de lo usual para cargar. Es probable que presentes videos con baja calidad o interrupciones. Por favor verifica tu conexión a internet.',
      }),
    });
    this.clearToastNotification = setTimeout(() => {
      showToastNotification(null);
    }, 500);
  }

  /**
   * @method bindSlowConnectionEvent
   * @description Used to add an event listener for slow connections.
   */
  bindSlowConnectionEvent() {
    window.addEventListener('SLOW_CONNECTION', this.throttleSlowConnectionEvent, false);
  }

  /**
   * @method throttleSlowConnectionEvent
   * @description Used to avoid multiple slow connection toast notifications from being rendered at
   * the same time.
   */
  throttleSlowConnectionEvent() {
    const { lastTimeEventHandled } = this.state;
    if (
      isBefore(lastTimeEventHandled, subSeconds(new Date(), SLOW_CONNECTION_SECONDS))
    ) {
      this.handleSlowConectionEvent();
      this.setState({ lastTimeEventHandled: new Date() });
    }
  }

  render() {
    return <div />;
  }
}

function mapStateToProps({ notifications }) {
  return { notifications };
}

export default connect(mapStateToProps, {
  showToastNotification: showToastNotificationAction,
})(injectIntl(SlowConnection));
