export const ACTION_TYPES = {
  CHANGE_PASSWORD: 'user/changePassword',
  REQUEST: 'user/CHANGE_PASSWORD_REQUEST',
  SUCCESS: 'user/CHANGE_PASSWORD_SUCCESS',
  FAILURE: 'user/CHANGE_PASSWORD_FAILURE',
  RESET: 'user/CHANGE_PASSWORD_RESET',
};

export const changePassword = (userName, iso2Code, oldPassword, newPassword) => ({
  type: ACTION_TYPES.CHANGE_PASSWORD,
  payload: {
    userName, iso2Code, oldPassword, newPassword,
  },
});

export const userRequest = () => ({ type: ACTION_TYPES.REQUEST });

export const userSuccess = (results) => ({
  type: ACTION_TYPES.SUCCESS,
  payload: { ...results },
});

export const userFailure = (error) => ({
  type: ACTION_TYPES.FAILURE,
  payload: { ...error },
});

export const userReset = () => ({ type: ACTION_TYPES.RESET });
