/* External */
import React,
{
  useEffect, useRef, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/* Ott-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Components */
import MiniChannelCard from './MiniChannelCard/MiniChannelCard';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/miniEpg';

/* Styles */
import './MiniEPG.scss';

/* Other */
import {
  AEM_PLATFORM, EPG, useNormalizedDate, formatContentForGtm, getEpgFilters,
} from '../../utils';
import { useGetChannels } from '../../hooks/useGetChannels';
import { api } from '../../state/configureStore';
import EpgFilter from '../../components/epgFilter/EpgFilter';
import ButtonScroll from '../../components/buttonScroll/ButtonScroll';

function MiniEpg({
  asset, progress, close, intl: { formatMessage },
}) {
  const { data: AEMConfiguration } = api.useGetAEMConfigurationQuery(AEM_PLATFORM);
  const recentlyWatchedChannelsBatchSize = AEMConfiguration?.content?.miniepg?.recentlyWatchedLimit;
  const startTime = useNormalizedDate();
  const [filtersEpg, setFilterEpg] = useState([]);

  const selectedFilter = filtersEpg?.find((item) => item.status);

  const {
    channels, completeChannelsList,
  } = useGetChannels({
    recentlyWatchedChannelsBatchSize,
    schedulesSizeByChannel: 1,
    startTime,
    schedulesPerRequest: EPG.SCHEDULES_PER_REQUEST,
    selectedFilter: selectedFilter?.value,
  });

  const filterList = useMemo(
    () => getEpgFilters(completeChannelsList),
    [completeChannelsList],
  );

  const channelsWithSchedules = channels.filter(({ schedules }) => schedules?.length);

  const gtmRef = useRef(false);
  const { gtmUserData } = useGtmContext();

  useEffect(() => {
    if (!gtmRef.current) {
      gtmRef.current = true;

      const dataToSend = formatContentForGtm(asset);

      gtmEvent({
        hash: gtmDimensions.page_view.hash,
        eventType: gtmDimensions.page_view.eventType,
        dimensions: gtmDimensions.page_view.dimensions,
        userData: gtmUserData,
        data: dataToSend,
      });
    }
  }, [gtmUserData, asset]);

  function handleFilterClick(id) {
    const updatedFilters = filtersEpg.map((item) => ({
      ...item,
      status: item.value === id,
    }));
    setFilterEpg(updatedFilters);
  }

  const filterButtons = filtersEpg.map((filterEpg) => (
    <EpgFilter
      key={filterEpg.value}
      title={filterEpg.title ? formatMessage({ id: filterEpg.title }) : filterEpg.value}
      status={filterEpg.status}
      onClick={() => handleFilterClick(filterEpg.value)}
    />
  ));

  useEffect(() => {
    setFilterEpg(filterList);
  }, [filterList]);

  return (
    <div className="dtv-miniepg">
      <div className="dtv-miniepg-container">
        <div className="dtv-miniepg-cards">
          <span className="dtv-icon-close" onClick={close} />
          <div className="dtv-miniepg-filter">
            <ButtonScroll buttons={filterButtons} />
          </div>
          <div className="dtv-miniepg-channels scroll" type="button" onClick={close}>

            {channelsWithSchedules.length > 0 && (
            <>
              {channelsWithSchedules.map((channel, index) => (
                <MiniChannelCard
                  channel={channel}
                  epgIndex={index}
                  key={channel.channelId}
                  progress={progress}
                />
              ))}
            </>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

MiniEpg.propTypes = {
  asset: PropTypes.shape(),
  close: PropTypes.func,
  progress: PropTypes.number,
};

MiniEpg.defaultProps = {
  asset: {},
  close: () => {},
  progress: 0,
};

export default injectIntl(MiniEpg);
