/**
 * External
 */
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Assets
 */
import success from '@/assets/theme/pinLogin/TV-code-8-Success.svg';
import fail from '@/assets/theme/pinLogin/TV-code-8-Error.svg';

/**
 * Styles
 */
import './ResultInsertPin.scss';

/**
 * Other
 */
import { SUCCESSFUL_RESULT } from './InsertPin';
import { history } from '../state/configureStore';
import {
  PIN_LOGIN_URLS, PIN_LOGIN_URLS_BR, IS_BRAZIL, userGtmEventHandler,
} from '../utils';
import { gtmDimensions } from '../components/GtmContext/dimensions/tvValidation';
import { useGtmContext } from '../components/GtmContext';
import { getImage } from '../theme/images';

const pinLoginUrls = IS_BRAZIL ? { ...PIN_LOGIN_URLS_BR } : { ...PIN_LOGIN_URLS };

/**
 * Component for the successful confirmation of the pin
 */
function SuccessfulResult() {
  const { gtmUserData } = useGtmContext();

  useEffect(() => {
    userGtmEventHandler(gtmDimensions.web_code_validation_success.page_view, gtmUserData);
  }, [gtmUserData]);

  return (
    <div className="dtv-result-insert-pin">
      <img src={getImage('tvCode8Success') || success} className="dtv-img-result" alt="Success result" />
      <p className="dtv-result-insert-pin-title">
        <FormattedMessage id="pinLogin.wellDone" defaultMessage="¡BIEN HECHO!" />
      </p>
      <p className="dtv-result-insert-pin-subtitle">
        <FormattedMessage id="pinLogin.hasBeeVerified" defaultMessage="Tu televisor ha sido verificado." />
      </p>
      <p className="dtv-result-insert-pin-text">
        <FormattedMessage
          id="pinLogin.youCanEnjoy"
          defaultMessage="Ahora puedes disfrutar de DIRECTV Go como nunca lo imaginaste."
        />
      </p>
    </div>
  );
}

/**
 * Component for the unsuccessful confirmation of the pin
 */
function FailedResult() {
  const { gtmUserData } = useGtmContext();

  useEffect(() => {
    userGtmEventHandler(
      gtmDimensions.web_code_validation_error.page_view,
      gtmUserData,
      {
        errorCode: 'tv-activation-pin',
        errorType: 'tv-activation-pin',
      },
    );
  }, [gtmUserData]);

  return (
    <div className="dtv-result-insert-pin">
      <img src={getImage('tvCode8Error') || fail} className="dtv-img-result" alt="Fail result" />
      <p className="dtv-result-insert-pin-title">
        <FormattedMessage id="pinLogin.problemHasOccurred" defaultMessage="Ha ocurrido un problema" />
      </p>
      <p className="dtv-result-insert-pin-subtitle">
        <FormattedMessage id="pinLogin.notBeenAble" defaultMessage="No hemos podido verificar tu televisor." />
      </p>
      <p className="dtv-result-insert-pin-text">
        <FormattedMessage
          id="pinLogin.pinHasExpired"
          defaultMessage="Es probable que el pin haya caducado o que no coincida. Por favor refresca el pin en tu televisor y vuelve a intentarlo."
        />
      </p>
      <button type="button" className="dtv-btn-go-back" onClick={() => history.push(pinLoginUrls.main)}>
        <FormattedMessage id="common.goBack" defaultMessage="Regresar" />
      </button>
    </div>
  );
}

function ResultInsertPin({ match }) {
  const { result = '' } = match.params;
  return result === SUCCESSFUL_RESULT ? <SuccessfulResult /> : <FailedResult />;
}

export default ResultInsertPin;
