import { userConstants } from './userConstants';

export const gtmDimensions = {
  component_type: 'pdp',
  pdp_series: {
    page_view: {
      hash: 'p-019.037.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_component_type',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    watch: {
      hash: 'e-019.037.001.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_transmission_type',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    my_list: {
      hash: 'e-019.037.001.000.000.136',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_transmission_type',
        'v_transmission_type',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_filter_pdp',
      ],
    },
    episodes: {
      hash: 'e-019.037.001.000.000.013',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_list_position',
        'v_transmission_type',
        'v_episode_number',
        'v_episode_name',
        'v_content_player_type',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_filter_pdp',
      ],
    },
    season: {
      hash: 'e-019.037.001.000.000.105',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_list_position',
        'v_transmission_type',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_filter_pdp',
      ],
    },
    episode: {
      hash: 'e-019.037.001.000.000.030',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_list_position',
        'v_transmission_type',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    resume: {
      hash: 'e-019.037.001.000.000.043',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_transmission_type',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
  },
  pdp_live: {
    page_view: {
      hash: 'p-019.037.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_component_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
      ],
    },
    watch: {
      hash: 'e-019.037.001.000.000.110',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
  },
  pdp_vod: {
    page_view: {
      hash: 'p-019.037.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_component_type',
        'v_content_player_type',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    watch: {
      hash: 'e-019.037.001.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_content_player_type',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    resume: {
      hash: 'e-019.037.001.000.000.043',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_content_player_type',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    my_list: {
      hash: 'e-019.037.001.000.000.136',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_content_player_type',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_filter_pdp',
      ],
    },
  },
};
