/* External */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import groupBy from 'lodash-es/groupBy';

/* Components */
import TrackList from '../TrackList';

/* Other */
import { IS_BRAZIL, TRACKS_LANGUAGES } from '../../../utils';

const DOLBY_DIGITAL_AUDIO_CODECS = new Set(['ac-3', 'ec-3']);
const DOLBY_DIGITAL_AUDIO_TEXT = '[Dolby Digital]';

const availableLanguages = Object.values(TRACKS_LANGUAGES);

function AudioTracksList({
  tracks = [],
  onTrackSelect,
}) {
  return (
    <TrackList title={<FormattedMessage id="player.languages" />}>
      {useMemo(() => {
        let undefinedAudiosCounter = 0;

        return sortTracks(tracks).map(({ track, active }) => {
          const isDolbyDigital = DOLBY_DIGITAL_AUDIO_CODECS.has(track.audioTrackInfo?.audioCodec);
          const isAnAvailableLanguage = availableLanguages.includes(track.language);

          if (!isAnAvailableLanguage) undefinedAudiosCounter += 1;

          return (
            <TrackList.Item
              key={track.trackId}
              track={track}
              text={isAnAvailableLanguage ? (
                <>
                  <FormattedMessage id={`languages.${track.language}`} />
                  <span className="dtv-tracks-menu__item-subtitle">{isDolbyDigital ? DOLBY_DIGITAL_AUDIO_TEXT : ''}</span>
                </>
              ) : (
                <>
                  <FormattedMessage
                    id="languages.undefinedAudioWithCounter"
                    values={{ number: undefinedAudiosCounter }}
                  />
                  <span className="dtv-tracks-menu__item-subtitle">{isDolbyDigital ? DOLBY_DIGITAL_AUDIO_TEXT : ''}</span>
                </>
              )}
              active={active}
              onTrackSelect={onTrackSelect}
            />
          );
        });
      }, [onTrackSelect, tracks])}
    </TrackList>
  );
}

AudioTracksList.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape({
    track: PropTypes.shape({
      trackId: PropTypes.number.isRequired,
      language: PropTypes.string.isRequired,
      audioTrackInfo: PropTypes.shape({
        audioCodec: PropTypes.string,
      }),
    }),
    active: PropTypes.bool,
  })),
  onTrackSelect: PropTypes.func.isRequired,
};

AudioTracksList.defaultProps = {
  tracks: [],
};

export default memo(AudioTracksList);

/**
 * @method sortTracks
 * @description Method that sorts a tracks array
 * @param {array} tracks array of tracks to be sorted
 */
function sortTracks(tracks) {
  const grouppedTracks = groupBy(tracks, ({ track: { language } }) => (availableLanguages.includes(language) ? language : 'undefined'));

  return IS_BRAZIL ? [
    ...grouppedTracks[TRACKS_LANGUAGES.PORTUGUESE] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.PORTUGUESE_DESCRIPTIVE] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.ENGLISH] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.ENGLISH_DESCRIPTIVE] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.SPANISH] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.SPANISH_DESCRIPTIVE] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.ORIGINAL_AUDIO] || [],
    ...grouppedTracks.undefined || [],
  ] : [
    ...grouppedTracks[TRACKS_LANGUAGES.SPANISH] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.SPANISH_DESCRIPTIVE] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.ENGLISH] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.ENGLISH_DESCRIPTIVE] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.PORTUGUESE] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.PORTUGUESE_DESCRIPTIVE] || [],
    ...grouppedTracks[TRACKS_LANGUAGES.ORIGINAL_AUDIO] || [],
    ...grouppedTracks.undefined || [],
  ];
}
