/**
 * External
 */
import React from 'react';

/**
 * Styles
 */
import './ProgressBar.scss';

function ProgressBar({
  styles: parentStyles, duration, progress, className,
}) {
  return (
    <div className={`dtv-container-progress-bar ${className}`} style={parentStyles}>
      <div className="dtv-progress-vod">
        <div className="dtv-percentage-seen" style={{ width: `${(progress / duration) * 100}%` }} />
      </div>
    </div>
  );
}

export default ProgressBar;
