/* External */
import { useRef } from 'react';
import {
  addSeconds,
  differenceInMilliseconds,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  format,
  setMilliseconds,
  setSeconds,
  startOfDay,
} from 'date-fns';

/**
 * @description
 *   Time formater
 *   examples:
 *   IN: 600, OUT: "10min"
 *   IN: 6000, OUT: "1h40min"
 *   IN: 7200, OUT: "2hs"
 * @since DGD-45226 use of (hour&minutes)Format is disregard
 * @param {number} seconds - AKA duration in seconds
 * @param {object} [_arg] - Deprecated By DGD-45226
 * @param {string} [_arg.hoursFormat] - Deprecated
 * @param {string} [_arg.minutesFormat] - Deprecated
 * @returns {string} - formated by hours and minutes
 */
export function formatSeconds(seconds) {
  if (!seconds) return '';
  const date = addSeconds(new Date(Date.UTC(0)), seconds);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const hoursFormat = hours <= 1 ? 'h' : 'hs';
  const minutesFormat = 'min';
  const h = hours > 0;
  const m = minutes > 0;
  return `${h ? `${hours} ${hoursFormat}${m ? ' ' : ''}` : ''}${m ? `${minutes} ${minutesFormat}` : ''}`;
}

/**
 * @description Method that convert a number to HHMMSS format
 * @method secondsToHHMMSS
 * @param {number} seconds
 */
export function secondsToHHMMSS(seconds) {
  return format(setSeconds(startOfDay(new Date()), seconds), 'H:mm:ss');
}

/**
 * @description Get a difference duration object between two dates
 * @param {date} dateLeft
 * @param {date} dateRight
 * @returns {object}
 */
export function getDiffDuration(dateLeft, dateRight) {
  const diffMonths = differenceInMonths(dateLeft, dateRight);
  const diffDays = differenceInDays(dateLeft, dateRight);
  const diffHours = differenceInHours(dateLeft, dateRight);
  const diffMinutes = differenceInMinutes(dateLeft, dateRight);
  const diffSeconds = differenceInSeconds(dateLeft, dateRight);
  const diffMilliseconds = differenceInMilliseconds(dateLeft, dateRight);
  const years = differenceInYears(dateLeft, dateRight);
  const months = diffMonths - years * 365;
  const days = diffDays - months * 31;
  const hours = diffHours - diffDays * 24;
  const minutes = diffMinutes - diffHours * 60;
  const seconds = diffSeconds - diffMinutes * 60;
  const milliseconds = diffMilliseconds - diffSeconds * 1000;

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
}

/**
 * @description Convert duration object to milliseconds
 * @param {object} duration
 * @returns {number}
 */
export function durationToMilliseconds(duration) {
  let result;
  const {
    milliseconds,
    seconds,
    minutes,
    hours,
    days,
    months,
    years,
  } = duration;
  result = milliseconds;
  result += seconds * 1000;
  result += minutes * 1000 * 60;
  result += hours * 1000 * 60 * 60;
  result += days * 1000 * 60 * 60 * 24;
  result += months * 1000 * 60 * 60 * 24 * 31;
  result += years * 1000 * 60 * 60 * 24 * 31 * 365;
  return result;
}

/**
 * @description Convert milliseconds to duration object
 * @param {object} duration
 * @returns {object}
 */
export function millisecondsToDuration(mseconds) {
  const oneSecond = 1000;
  const oneMinute = oneSecond * 60;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;
  const oneMonth = oneDay * 31;
  const oneYear = oneMonth * 12;
  const diffSeconds = Math.trunc(mseconds / oneSecond);
  const diffMinutes = Math.trunc(mseconds / oneMinute);
  const diffHours = Math.trunc(mseconds / oneHour);
  const diffDays = Math.trunc(mseconds / oneDay);
  const diffMonths = Math.trunc(mseconds / oneMonth);
  const diffYears = Math.trunc(mseconds / oneYear);
  const milliseconds = Math.round((mseconds / oneSecond - diffSeconds) * oneSecond);
  const seconds = diffSeconds - diffMinutes * 60;
  const minutes = diffMinutes - diffHours * 60;
  const hours = diffHours - diffDays * 60;
  const days = diffDays - diffMonths * 31;
  const months = diffMonths - diffYears * 365;
  const years = diffYears;

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
}

/**
 * @description Add minutes to a duration object
 * @param {object} duration
 * @param {number} value
 * @returns {object}
 */
export function addDurationMinutes(duration, minutes) {
  const oneSecond = 1000;
  const oneMinute = oneSecond * 60;
  const milliseconds = durationToMilliseconds(duration) + minutes * oneMinute;

  return millisecondsToDuration(milliseconds);
}

/**
 * @description Returns the formatted text for a specific unit date
 * @method getTimeLeft
 * @param {object} keyFormattedText
 * @param {date} startTime
 * @param {function} formatMessage
 * @param {boolean} isShort
 * @returns {object}
 */
export function getTimeLeft(keyFormattedText, startTime, formatMessage, isShort = false) {
  const keyMessageShort = startTime ? keyFormattedText.short : keyFormattedText.shortLeft;
  const keyMessage = isShort ? keyMessageShort : keyFormattedText.normal;
  return keyFormattedText.value
    ? formatMessage(
      {
        id: keyMessage,
        defaultMessage: keyFormattedText.defaultValue,
      },
      {
        value: keyFormattedText.value,
      },
    )
    : null;
}

/**
 * @description Delivers the remaining time contemplating hours and minutes
 * @method getTimeRemaining
 * @param {date} endTime
 * @param {function} formatMessage
 * @param {boolean} isShort
 * @returns {string}
 */
export function getTimeRemaining(endTime, formatMessage, isShort = false) {
  const end = setSeconds(new Date(endTime), 0);
  const currentDate = new Date();
  const timeLeft = getDiffDuration(end, currentDate);
  const diffDuration = addDurationMinutes(timeLeft, 1);
  const keysFormattedTexts = [
    {
      defaultValue: '{value}h',
      normal: 'common.hours',
      short: 'common.hoursShort',
      shortLeft: 'common.hoursShort',
      value: diffDuration.hours,
    },
    {
      defaultValue: ' y ',
      normal: 'common.and',
      short: 'common.and',
      shortLeft: 'common.and',
      value: diffDuration.minutes && diffDuration.hours,
    },
    {
      defaultValue: '{value}m',
      normal: 'common.minutesShortLeft',
      short: 'common.minutesShort',
      shortLeft: 'common.minutesShortLeft',
      value: diffDuration.minutes,
    },
  ];

  const timeLeftText = keysFormattedTexts
    .map((keyFormattedText) => getTimeLeft(keyFormattedText, end, formatMessage, isShort))
    .join(' ');

  const buildTimeLeft = formatMessage(
    {
      id: 'live.timeLeft',
      defaultMessage: 'Termina en {timeLeft}',
    },
    { timeLeft: timeLeftText },
  );

  return buildTimeLeft;
}

/**
 * @method toUnixTimestamp
 * @description Method that converts a Date object to a Unix (seconds) timestamp
 * @param {Date} date Date to convert
 */
export const toUnixTimestamp = (date) => Math.floor(date.getTime() / 1000);

/**
 * @method getTimeZoneOffset
 * @description Returns timezone offset in hours
 */
export function getTimeZoneOffset() {
  const now = new Date();
  let tzMin = now.getTimezoneOffset();
  let sign = '+';
  if (tzMin < 0) {
    sign = '-';
    tzMin *= -1;
  }
  const tzHours = tzMin / 60;
  const tzHoursString = `${tzHours < 10 ? '0' : ''}${tzHours}:00`;
  return `${sign}${tzHoursString}`;
}

export const useNormalizedDate = (date) => {
  const dateRef = useRef(setMilliseconds(setSeconds(date || new Date(), 0), 0));

  return dateRef.current;
};
