/* External */
import { useEffect, useState, useRef } from 'react';
import findIndexL from 'lodash-es/findIndex';
import isNilL from 'lodash-es/isNil';
import isNanL from 'lodash-es/isNaN';
import queryString from 'query-string';
import capitalizeL from 'lodash-es/capitalize';

/* Ott-common */
import { createImageResizer } from '@dtvgo/image-resizers';
import { assetTypes } from '@dtvgo/rtk-query-api';

/* Other */
import {
  CollectionText,
  CAROUSEL_TYPES,
  DEFAULT_SEPARATOR,
  landerUris,
  QUALIFIERS,
} from './constants';
import { AEM } from './urls/staticUrls';

const { getImageUrl: ImgResizer } = createImageResizer();

export function validateEmail(email) {
  const emailRegex = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;
  return emailRegex.test(email);
}

/**
 * @method getAssetTitle;
 * @description Method that return the title of an asset o empty when do not have.
 * @param {*} asset
 */
export function getAssetTitle(asset = {}) {
  return asset?.episode?.showName || asset?.title || asset?.showName || asset?.name || '';
}

/**
 * @description Method that returns the title for any type of show in the EPG
 * @method getEpgShowTitle
 * @param {object} schedule
 * @param {function} formatMessage
 * @param formatMessage
 */
export function getEpgShowTitle(schedule = {}, formatMessage = () => {}) {
  const title = getAssetTitle(schedule);
  if (title) {
    return title;
  }
  return formatMessage({
    id: 'common.dataNotAvailable',
    defaultMessage: 'Información no disponible',
  });
}

/**
 * Join elements of array with a separator else uses a default separator.
 * @param {array} arr Elements to join
 * @param {string} separator
 */
export const joinArrayWithSeparator = (
  arr = [],
  separator = DEFAULT_SEPARATOR,
) => arr.filter((value) => !!value).join(separator);

/**
 * @description Method that returns the subtitle for a show in the EPG when it is an episode
 * @method getEpgEpisodeSubtitle
 * @param schedule
 * @param formatMessage
 */
export function getEpgEpisodeSubtitle(schedule = {}, formatMessage = () => {}, showGenre = false) {
  const {
    description, episode, title, genres,
  } = schedule;
  const { number: episodeNumber, seasonNumber, showName } = episode || {};
  const genre = genres?.[0];
  const defaultSubtitle = formatSeasonAndEpisode(seasonNumber, episodeNumber, formatMessage);
  if (showName) {
    return joinArrayWithSeparator([defaultSubtitle, title]);
  }
  return showGenre ? genre : description;
}

/**
 * @description Method that formats season and episode
 * @method formatSeasonAndEpisode
 * @param season
 * @param episode
 * @param formatMessage
 * @param {boolean} isZeroIncluded true if you want to include 0 as a valid value for season and
 * episode
 */
export function formatSeasonAndEpisode(
  seasonStr,
  episodeStr,
  formatMessage,
  isZeroIncluded = false,
) {
  const season = Number.parseInt(seasonStr, 10);
  const episode = Number.parseInt(episodeStr, 10);
  const zeroCondition = (number) => (isZeroIncluded ? number >= 0 : number > 0);

  const formattedSeason = !isNilL(season) && !isNanL(season) && zeroCondition(season)
    ? `${formatMessage({
      id: 'vod.seasonFirstChar',
      defaultMessage: 'T',
    })}${season}`
    : '';

  const formattedEpisode = !isNilL(episode) && !isNanL(episode) && zeroCondition(episode)
    ? ` ${formatMessage({
      id: 'vod.episodeFirstChar',
      defaultMessage: 'E',
    })}${episode}`
    : '';

  const seasonAndEpisodeFinalString = `${formattedSeason} ${formattedEpisode}`;

  return (!formattedSeason || !formattedEpisode)
    ? seasonAndEpisodeFinalString.trim()
    : seasonAndEpisodeFinalString;
}

/*
 * @description Compares two strings alphabetically
 * @method compareStrings
 * @param {string} a
 * @param {string} b
 * @param {string} sorting
 */
export function compareStrings(a, b, sorting) {
  const firstString = a.toLowerCase();
  const secondString = b.toLowerCase();
  if (sorting === 'a-z') {
    return Intl.Collator().compare(firstString, secondString);
  }
  return Intl.Collator().compare(secondString, firstString);
}

/**
 * @method generateRandomStringId
 * @description Generates a random unique ID string
 */
export function generateRandomStringId() {
  return (Date.now() + Math.random() * 100).toString(32);
}

/**
 * @method buildEpisodeMainDataString
 * @description Builds the string that contains a VOD episode's first part data
 * @param seasonAndEpisode
 * @param name
 * @param formattedDuration
 * @param lander
 */
export function buildEpisodeMainDataString(
  seasonAndEpisode,
  name,
  formattedDuration,
  lander = false,
) {
  return lander
    ? joinArrayWithSeparator([seasonAndEpisode, name])
    : joinArrayWithSeparator([seasonAndEpisode, formattedDuration]);
}

/**
 * @method buildEpisodeSecondaryDataString
 * @description Builds the string that contains a live episode's data
 * @param episode
 * @param name
 * @param season
 * @param formatMessage
 * @param {boolean} isZeroIncluded true if you want to include 0 as a valid value for season and
 * episode
 */
export function buildEpisodeSecondaryDataString(
  seasonNumber,
  episodeNumber,
  episodeName,
  formatMessage,
  isZeroIncluded = false,
) {
  return joinArrayWithSeparator([
    formatSeasonAndEpisode(seasonNumber, episodeNumber, formatMessage, isZeroIncluded),
    episodeName,
  ]);
}

/**
 * @method shouldShowTermsAndConditions
 * @description Validate if terms and conditions should be showed
 * @param {object} statusTermsAndConditions
 * @param {object} userTermsOfService
 * @param {object} termsAndConditionsVersion
 * @param {object} userPrivacyPolicy
 * @param {object} privacyPolicyVersion
 */
export function shouldShowTermsAndConditions(
  configurationAEMStatusIsLoading,
  userTermsOfService,
  termsAndConditionsVersion,
  userPrivacyPolicy,
  privacyPolicyVersion,
) {
  return (
    !configurationAEMStatusIsLoading
    // Valid if there is a version
    && termsAndConditionsVersion
    && userTermsOfService
    // Valid if the version of privacy policy or terms and conditions is different from the last
    // accepted
    && (userPrivacyPolicy.length === 0
      || findIndexL(userPrivacyPolicy, ['version', privacyPolicyVersion]) === -1
      || userTermsOfService.length === 0
      || findIndexL(userTermsOfService, ['version', termsAndConditionsVersion]) === -1)
  );
}

/**
 * @method getGenre
 * @description Receives an array of genres and returns the first genre
 * @param {array} genres Array of objects with id and name props
 */
export function getGenre(genres) {
  if (genres && genres.length > 0) {
    const name = typeof genres[0] === 'string' ? genres[0] : genres[0].name;
    return name;
  }

  return '';
}

/**
 * @method getCardTitle
 * @description Receives data of an asset and returns a card title
 */
export function getCardTitle(assetData) {
  const { type = '', title = '', episode: { showName = '' } = {} } = assetData;

  if (type === assetTypes.episode) return showName;

  return title;
}

export function getWebAction(collection) {
  return collection.actions.find(({ type }) => type === 'web');
}

export function isLanderPage(collection) {
  return [CollectionText.PAGE].includes(getWebAction(collection)?.text);
}

export function findMatchingCollection(collections, target, query) {
  return collections?.find((collection) => {
    const action = getWebAction(collection);
    return (
      action?.target === target
        && (
          action?.text !== CollectionText.SPORT_SECTION
            || Object.entries(query).every(([key, value]) => collection.sportIds?.[key] === value)
        )
    );
  });
}

/**
 * @method getLanderRedirectUrl
 * @description Method that returns the URL of a browse lander or a collection
 * @param {object} action Web action returned from API
 * @param {boolean} shouldRedirectToLander True if it has to redirect to a lander instead of a
 * collection
 * @param {string} tab Actual lander URI
 * @param {string} containerCarouselId Swimlane URI
 */
export function getCollectionRedirectUrl(collection, containerCarouselId, containerCarouselType) {
  const action = getWebAction(collection);
  if (action && action.target) {
    if ([CollectionText.PAGE].includes(action.text)) {
      const { target } = action;
      return `/home/lander/${target}`;
    }

    const query = action.text === CollectionText.SPORT_SECTION && collection.sportIds
      ? `?${queryString.stringify(collection.sportIds)}`
      : '';

    return `/home/collection/${containerCarouselType}/${containerCarouselId}/${action.target}${query}`;
  }
  return '';
}

/**
 * @method getLanderLogoSrc
 * @description Method that returns the source URL of a browse lander logo
 * @param {object} action Web action returned from API
 */
export function getLanderLogoSrc(collection) {
  if (!isLanderPage(collection)) return null;

  const { target } = getWebAction(collection) || {};
  const targetToUse = target;
  return targetToUse ? `${AEM.stations}/${targetToUse}.png` : '';
}

/**
 * @description Returns url for a card image with resize for Vrio
 * @param {array} images
 */
export function getVrioImageUrl(images, ratio, resizer) {
  if (images?.length === 1) return ImgResizer(images[0].url, resizer);
  const imageUrl = typeof images === 'string' ? images : images?.find((img) => (img.ratio === ratio || img.type === ratio))?.url || '';
  return ImgResizer(imageUrl, resizer);
}

/**
 * @param {Array} images
 * @param {String} genericCard Generic image imported from assets folder
 * @param {String} gradient
 * @returns {Object} React ref and styles to use in container
 */
export function useImage(images, genericCard, gradient, ratio) {
  const imageRef = useRef(null);
  const [imageSize, setImageSize] = useState(null);
  const [imageStyle, setImageStyle] = useState({
    backgroundImage: `${gradient ? `${gradient}, ` : ''}url("${genericCard}")`,
  });

  /**
   * @description Uses a ref to measure container and sets the value in state
   */
  useEffect(() => {
    if (imageRef.current && !imageSize) {
      setImageSize({
        height: imageRef.current?.clientHeight,
        width: imageRef.current?.clientWidth,
      });
    }
  }, [imageSize]);

  /**
   * @description Creates styles once the container was measured
   */
  useEffect(() => {
    if (!imageSize || !images?.length) return;
    setImageStyle({
      backgroundImage: `${gradient ? `${gradient}, ` : ''}url("${getVrioImageUrl(images, ratio, imageSize) || genericCard}")`,
    });
  }, [imageSize, gradient, images, ratio, genericCard]);

  return { imageRef, imageStyle };
}

/**
 * @method getAssetNameWithQualifier
 * @description Method that returns an asset name with a qualifier prefix
 * @param {array} qualifiers Asset qualifiers to be used to decide the prefix
 * @param {string} baseName Asset name to show
 * @param {function} formatMessage i18n translate function
 */
export function getAssetNameWithQualifier(qualifiers, baseName, formatMessage) {
  if (!baseName) return '';

  let qualifier = '';

  if (qualifiers && qualifiers.includes(QUALIFIERS.REPEAT)) {
    qualifier = `${formatMessage({ id: 'live.replay', default: 'Repetición' })}: `;
  }

  return `${qualifier}${baseName}`;
}

export function getAssetDescription(asset, formatMessage, withRating = true) {
  const {
    episode,
    genres = [],
    rating,
    title,
    type = '',
    vod,
  } = asset || {};
  const { number: episodeNumber, seasonNumber } = episode || {};
  const { releaseYear } = vod || {};
  const genreName = typeof genres[0] === 'string' ? genres[0] : genres[0]?.title;
  const separatorFromSubtitleOnly = ' ';
  let subtitleWithoutRating = '';
  switch (type) {
    case assetTypes.movie:
      subtitleWithoutRating = joinArrayWithSeparator([
        joinArrayWithSeparator([genreName], separatorFromSubtitleOnly),
        releaseYear,
      ]);
      break;
    case assetTypes.episode: {
      const seasonValue = seasonNumber || 0;
      const episodeValue = episodeNumber || 0;
      const seasonAndEpisodeString = formatSeasonAndEpisode(
        seasonValue,
        episodeValue,
        formatMessage,
      );
      subtitleWithoutRating = joinArrayWithSeparator([
        title,
        seasonAndEpisodeString,
      ]);
      break;
    }
    default:
      break;
  }
  if (withRating) {
    return joinArrayWithSeparator([subtitleWithoutRating, rating]);
  }
  return subtitleWithoutRating;
}

export const getCarouselLander = ({ landerUri }) => {
  let landerName = '';

  switch (landerUri) {
    case landerUris.home:
      landerName = 'Home';
      break;

    case landerUris.onDemand:
      landerName = 'OnDemand';
      break;

    default:
      landerName = landerUri || '';
      break;
  }
  landerName = `${capitalizeL(landerName)}Lander`;
  return landerName;
};

export const getCarouselPlaySource = (landerUri, carouselType, carouselTitle, carouselId) => {
  const landerPart = getCarouselLander({ landerUri });
  let carouselPart = '';

  switch (carouselType) {
    case CAROUSEL_TYPES.RECENTLY_WATCHED:
      carouselPart = 'RecentlyWatchedCarousel';

      break;
    case CAROUSEL_TYPES.CONTINUE_WATCHING:
      carouselPart = 'ResumeWatchingCarousel';

      break;
    case CAROUSEL_TYPES.MY_LIST:
      carouselPart = 'MyListCarousel';

      break;
    default:
      carouselPart = carouselTitle || carouselId;

      break;
  }

  return `${landerPart}-${carouselPart}`;
};

export const formatMoreLikeThisEPGJson = (apiJson = {}) => ({
  ...apiJson,
  type: `LIVE_${apiJson?.type}`,
  live: {
    channelId: apiJson?.channelId,
    channelName: null,
    startTime: apiJson?.startTime,
    endTime: apiJson?.endTime,
    blackout: null,
    qualifiers: [],
  },
  episode: {
    number: apiJson?.episodeNumber,
    seasonNumber: apiJson?.seasonNumber,
    showName: apiJson?.title,
  },
  genres: [
    ...(apiJson?.genre ? apiJson.genre.map((genre) => genre.name) : []),
  ],
});
