/* External */
import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bowser from 'bowser';

/* Ott-common */
import { Errors, getPlaybackInfoWithAuthorizerV3 } from '@dtvgo/player-utils';
import { useGetAndSet, useGetSync } from '@dtvgo/react-utils';
import { rtkQueryTags } from '@dtvgo/rtk-query-api';

/* Components */
import Player from '../Player';

/* Other */
import { backendService } from '../../services';
import { usePlaybackHeartbeatController } from '../utils/hooks';
import { api } from '../../state/configureStore';
import { USE_LOW_LATENCY_STREAMS } from '../../utils/env';
import { startHeartbeat, stopHeartbeat } from '../../state/player/streamHeartbeat/vrioHeartbeatSlice';
import { checkToken } from '../../services/auth';

function VrioPlayer({
  isLive,
  playbackId,
  savedProgress,
  asset,
  onReadyToPlay,
  onPlay,
  onProgress,
  onEnded,
  onAutoplayFailed,
  onError,
  onAuthorizerError,
  onAuthorizerResponseReceived,
  children,
}) {
  const getAsset = useGetSync(asset);
  const getOnAuthorizerError = useGetSync(onAuthorizerError);
  const getOnAuthorizerResponseReceived = useGetSync(onAuthorizerResponseReceived);
  const dispatch = useDispatch();
  const [sources, setSources] = useState([]);
  const clearRefreshTimeoutRef = useRef(null);

  const { streamedTimeCounter, playbackHeartbeatController } = usePlaybackHeartbeatController();

  const [getIsPlaying, setIsPlaying] = useGetAndSet(false);

  const handlePlaybackStopped = useCallback(async (playingFalse = true) => {
    if (playingFalse) {
      setIsPlaying(false);
    }
    streamedTimeCounter?.stop();
    if (clearRefreshTimeoutRef.current) clearRefreshTimeoutRef.current();
    await playbackHeartbeatController?.stop().then((data) => {
      dispatch(stopHeartbeat());
      return data;
    });
    streamedTimeCounter?.reset();
  }, [dispatch, playbackHeartbeatController, setIsPlaying, streamedTimeCounter]);

  const handleAuthorizerError = useCallback((error) => {
    getOnAuthorizerError()?.(Errors.parseAuthorizerError(error));
  }, [getOnAuthorizerError]);

  const handleError = useCallback(
    (error) => {
      handlePlaybackStopped();
      onError(error);
    },
    [handlePlaybackStopped, onError],
  );

  const handlePlaying = useCallback(() => {
    streamedTimeCounter.start();
    setIsPlaying(true);
  }, [setIsPlaying, streamedTimeCounter]);

  const handlePause = useCallback(() => {
    streamedTimeCounter.stop();
    setIsPlaying(false);
  }, [setIsPlaying, streamedTimeCounter]);

  const handleProgress = useCallback((progress) => {
    playbackHeartbeatController.updateProgress(progress);
    onProgress(progress);
  }, [onProgress, playbackHeartbeatController]);

  const handleDurationChange = useCallback((duration) => {
    playbackHeartbeatController.updateDuration(duration);
  }, [playbackHeartbeatController]);

  const handleBuffering = useCallback(() => {
    streamedTimeCounter.stop();
  }, [streamedTimeCounter]);

  const handleBufferingEnded = useCallback(() => {
    if (getIsPlaying) {
      streamedTimeCounter.start();
    }
  }, [getIsPlaying, streamedTimeCounter]);

  const handleEnded = useCallback(() => {
    handlePlaybackStopped();
    onEnded();
  }, [handlePlaybackStopped, onEnded]);

  const getAssetAndScheduleForHearbeat = useCallback(() => {
    const currentAsset = getAsset();
    if (!currentAsset) {
      return { asset: undefined, schedule: undefined };
    }
    return {
      asset: {
        vmsId: currentAsset.vmsId,
        vrioAssetId: currentAsset.vrioAssetId,
        duration: currentAsset.duration,
      },
      schedule: {
        guideId: currentAsset.guideId,
        startTime: currentAsset.live?.startTime && new Date(currentAsset.live?.startTime),
        endTime: currentAsset.live?.endTime && new Date(currentAsset.live?.endTime),
      },
    };
  }, [getAsset]);

  useEffect(() => {
    async function initAuthorizer() {
      try {
        const {
          sources: newSources,
          clearRefreshTimeout,
          ...authorizerResponse
        } = await getPlaybackInfoWithAuthorizerV3(
          backendService,
          {
            isLive,
            contentId: playbackId,
          },
          {
            onAuthorizerError: handleAuthorizerError,
          },
          checkToken,
        );
        clearRefreshTimeoutRef.current = clearRefreshTimeout;
        const filteredSources = USE_LOW_LATENCY_STREAMS
          ? newSources
          : newSources?.filter(({ isLowLatency }) => !isLowLatency);

        setSources(filteredSources.map((source) => ({
          ...source,
          keySystemsConfig: source.keySystemsConfig ? {
            ...source.keySystemsConfig,
            preferredKeySystems: ['widevine'],
            widevine: {
              ...source.keySystemsConfig.widevine,
              persistentStateRequired: !bowser.linux,
            },
          } : {},
        })));

        getOnAuthorizerResponseReceived()?.(authorizerResponse);
      } catch (error) {
        handleAuthorizerError(error);
      }
    }

    if (playbackId) {
      initAuthorizer();
    }
  }, [
    dispatch,
    playbackId,
    isLive,
    handleAuthorizerError,
    handlePlaybackStopped,
    playbackHeartbeatController,
    getOnAuthorizerResponseReceived,
    getAssetAndScheduleForHearbeat,
  ]);

  useEffect(() => {
    async function initHeartbeat() {
      try {
        await handlePlaybackStopped(false);

        playbackHeartbeatController?.start({
          playbackId,
          isLive,
          ...getAssetAndScheduleForHearbeat(),
        }).then((data) => {
          dispatch(startHeartbeat());
          return data;
        });

        if (getIsPlaying()) {
          streamedTimeCounter.start();
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (playbackId) {
      initHeartbeat();
    }
  }, [
    dispatch,
    playbackId,
    isLive,
    handlePlaybackStopped,
    playbackHeartbeatController,
    getAssetAndScheduleForHearbeat,
    getIsPlaying,
    streamedTimeCounter,
  ]);

  /* When asset changes update the content for heartbeat */
  useEffect(() => {
    const { asset: assetForHeartbeat, schedule } = getAssetAndScheduleForHearbeat();
    playbackHeartbeatController?.setContent({
      asset: assetForHeartbeat,
      schedule,
      isLive,
    });
  }, [
    asset?.guideId,
    asset?.vrioAssetId,
    getAssetAndScheduleForHearbeat,
    isLive,
    playbackHeartbeatController,
  ]);

  /* When component is unmounted invalidates ThinkAnalytics endpoints caché so other components
     can get the latest results */
  useEffect(
    () => () => {
      dispatch(api.util.invalidateTags([rtkQueryTags.thinkAnalytics]));
    },
    [dispatch],
  );

  /* When component is unmounted, stop playback */
  useEffect(() => () => {
    handlePlaybackStopped();
  }, [handlePlaybackStopped]);

  /* When page is unloaded, stop playback */
  useEffect(() => {
    const handleUnload = () => {
      handlePlaybackStopped();
    };
    document.addEventListener('unload', handleUnload);
    return () => {
      document.removeEventListener('unload', handleUnload);
    };
  }, [handlePlaybackStopped]);

  return (
    <Player
      isLive={isLive}
      sources={sources}
      savedProgress={savedProgress}
      onReadyToPlay={onReadyToPlay}
      onPlay={onPlay}
      onPlaying={handlePlaying}
      onPause={handlePause}
      onBuffering={handleBuffering}
      onBufferingEnded={handleBufferingEnded}
      onProgress={handleProgress}
      onEnded={handleEnded}
      onDurationChange={handleDurationChange}
      onAutoplayFailed={onAutoplayFailed}
      onError={handleError}
    >
      {children}
    </Player>
  );
}

VrioPlayer.propTypes = {
  isLive: PropTypes.bool,
  playbackId: PropTypes.string,
  savedProgress: PropTypes.number,
  asset: PropTypes.shape({
    guideId: PropTypes.string,
    vmsId: PropTypes.string,
    vrioAssetId: PropTypes.string,
    live: PropTypes.shape({
      startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    }),
  }),
  onReadyToPlay: PropTypes.func,
  onPlay: PropTypes.func,
  onProgress: PropTypes.func,
  onEnded: PropTypes.func,
  onAutoplayFailed: PropTypes.func,
  onError: PropTypes.func,
  onAuthorizerError: PropTypes.func,
  onAuthorizerResponseReceived: PropTypes.func,
  children: PropTypes.node,
};

VrioPlayer.defaultProps = {
  isLive: false,
  playbackId: undefined,
  savedProgress: undefined,
  asset: undefined,
  onReadyToPlay: () => {},
  onProgress: () => {},
  onEnded: () => {},
  onPlay: () => {},
  onAutoplayFailed: () => {},
  onError: () => {},
  onAuthorizerError: () => {},
  onAuthorizerResponseReceived: () => {},
  children: null,
};

export default VrioPlayer;
