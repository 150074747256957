/* External */
import {
  createToolboxService,
  createToolboxTvService,
  shouldUpdateToken,
} from '@dtvgo/toolbox-service';
import jwtDecode from 'jwt-decode';

/* Ott-common */
import { getUserTypeByBusinessUnit } from '@dtvgo/utils';
import { createAxiosWithAuth } from '@dtvgo/axios-interceptor-retry-auth';

/* Actions */
import { setUser } from '../state/user/actions';

/* Other */
import {
  isBrazil,
  AppTheme,
  SERVICES_CLIENT_ID,
  USER_TYPES,
  getAuthTokensData,
  setAuthTokensData,
  getDeviceId,
  APP_VERSION,
  ENVIRONMENT,
  IS_BRAZIL,
  redirectToAuthErrorPage,
  ErrorCodes,
} from '../utils';

import store from '../state/configureStore';

export const tokens = {
  get: () => {
    const { accessToken, idToken, refreshToken } = getAuthTokensData();
    return {
      access_token: accessToken,
      id_token: idToken,
      refresh_token: refreshToken,
    };
  },
  set: (response) => {
    const { user: { account: { msoProvider = '' } = {} } = {} } = store.getState();
    const tokensData = {
      accessToken: response.access_token,
      idToken: response.id_token,
      refreshToken: response.refresh_token,
    };
    const { businessUnit, msoProvider: idTokenMsoProvider } = jwtDecode(tokensData.idToken);
    const npawAccountType = getUserTypeByBusinessUnit(businessUnit, idTokenMsoProvider);

    // Update Local Storage items
    setAuthTokensData(tokensData);

    // Update Redux store
    store.dispatch(
      setUser({
        account: {
          isTVE: npawAccountType === USER_TYPES.TVE,
          msoProvider: msoProvider || idTokenMsoProvider,
          userType: npawAccountType,
          businessUnit,
        },
      }),
    );
  },
  fail: (error) => {
    redirectToAuthErrorPage(error);
  },
  version: 'v3',
};

const headers = {
  clientId: SERVICES_CLIENT_ID,
  appVersion: APP_VERSION,
  profileToken: () => getAuthTokensData().profileToken || '',
  environment: ENVIRONMENT,
  appName: IS_BRAZIL ? AppTheme.SKYMAIS.toLowerCase() : AppTheme.DGO.toLowerCase(),
};

export const authService = createToolboxService({
  host: import.meta.env.REACT_APP_TOOLBOX_HOST_LOGIN_MSO,
  deviceId: getDeviceId(),
  clientHeadersOptions: headers,
  region: isBrazil ? 'br' : 'ssla',
  tokens,
});

export const authInterceptor = createAxiosWithAuth(
  authService.axiosInstance,
  tokens,
  () => (isBrazil ? 'br' : 'ssla'),
);

export const tvService = createToolboxTvService({
  host: import.meta.env.REACT_APP_TOOLBOX_HOST,
  deviceId: getDeviceId(),
  clientHeadersOptions: headers,
  region: isBrazil ? 'br' : 'ssla',
  tokens,
  authInterceptor,
  authorization: import.meta.env.REACT_APP_TOOLBOX_OPEN_AM_KEY,
});

export const refreshIdToken = async () => {
  const { refreshToken, idToken } = getAuthTokensData() || {};
  const { businessUnit, msoProvider } = jwtDecode(idToken);
  await authService.v3.refreshToken(refreshToken, { businessUnit, msoProvider });
};

export const checkToken = async () => {
  const offsetMinutes = import.meta.env.REACT_APP_MINUTES_BEFORE_TOKEN_REFRESH;
  const { refreshToken, idToken } = getAuthTokensData() || {};
  const {
    isIdTokenExpired, isIdTokenExpiring, isRefreshTokenExpired,
  } = shouldUpdateToken({ refreshToken, idToken, offsetMinutes });

  if (isRefreshTokenExpired) {
    redirectToAuthErrorPage({
      axiosError: { response: { data: { code: ErrorCodes.TOO_MUCH_TIME_AWAY } } },
    });
    return;
  }
  if (!isIdTokenExpired && isIdTokenExpiring) {
    await refreshIdToken();
  }
};

export default authService;
