/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */
import './ChannelLineSkeleton.scss';

function ChannelLineSkeleton({ cardsToRender }) {
  const renderCards = (size) => {
    const cards = [];
    for (let i = 1; i <= size; i += 1) {
      cards.push(<div key={`skeleton_card_${i}`} className="dtv-epg-channel-line-skeleton__card" />);
    }
    return cards;
  };

  return (
    <div className="dtv-epg-channel-line-skeleton">
      <div className="dtv-epg-channel-line-skeleton__image" />
      {renderCards(cardsToRender).map((card) => card)}
    </div>
  );
}

ChannelLineSkeleton.propTypes = {
  cardsToRender: PropTypes.number,
};

ChannelLineSkeleton.defaultProps = {
  cardsToRender: 5,
};

export default ChannelLineSkeleton;
