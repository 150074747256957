/**
 * External
 */
import React from 'react';
import { useRouteMatch } from 'react-router';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Assets */
import iconNews from '@/assets/theme/icons/news.svg';

/**
 * Components
 */
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/banners';

/* Styles */
import './CurableBanner.scss';

/* Other */
import {
  formatContentForGtm,
  getLanderNameByCaption,
} from '../../utils';
import { getImage } from '../../theme/images';

function CurableBanner({
  buttonLabel,
  description,
  fixedPhrase,
  icon,
  link,
  title,
}) {
  const { gtmUserData } = useGtmContext();
  const { params: locationParams } = useRouteMatch();
  const { landerUri, pageId } = locationParams;
  const defaultIcon = getImage('iconNews') || iconNews;

  const handleClick = () => {
    const { hash, eventType, dimensions } = gtmDimensions.upsell_banner.manage_suscription;
    const dataToSend = formatContentForGtm(
      {},
      {
        tab: getLanderNameByCaption(landerUri || pageId),
      },
    );

    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataToSend,
    });

    window.open(link, '_blank').focus();
  };

  return (
    <div className="dtv-banner">
      <div className="dtv-banner_image">
        <img
          className="dtv-banner_title-image"
          src={icon || defaultIcon}
          alt="Icone"
          onError={(e) => {
            e.target.onError = null;
            e.target.src = defaultIcon;
          }}
        />
      </div>
      <div className="dtv-banner_text">
        <div className="dtv-banner_text-title">
          <span>{title}</span>
        </div>
        <div className="dtv-banner_text-subtitle">
          <span>{description}</span>
        </div>
        <div className="dtv-banner_text-fixedPhrase">
          <span>{fixedPhrase}</span>
        </div>
      </div>
      <div className="dtv-banner_button">
        <button type="button" className="dtv-btn-glass" onClick={() => handleClick()}>
          <span>{buttonLabel}</span>
        </button>
      </div>
    </div>
  );
}

export default CurableBanner;
