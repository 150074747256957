/* External */
import React from 'react';
import { gtmEvent } from '@dtvgo/gtm-event-report';
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/pdp';

/* Styles */
import './AddOrRemoveFromMyListButton.scss';

/* Other */
import { api } from '../../state/configureStore';
import {
  gtmTransmissionType,
  isLearnActionV4Active,
} from '../../utils';

function AddOrRemoveFromMyListButton({ assetId, type, assetToPlayId }) {
  const { data: { contents: myListAssets } = {} } = api.useGetMyListQuery();
  const [addToMyList] = isLearnActionV4Active ? api.useAddToMyListV4Mutation()
    : api.useAddToMyListMutation();
  const [removeFromMyList] = api.useRemoveFromMyListMutation();
  const isAdded = myListAssets?.some(
    ({ vmsId, vrioAssetId }) => [vmsId, vrioAssetId].includes(assetId),
  );

  const {
    gtmUserData, gtmContent,
  } = useGtmContext();
  const handleClick = () => {
    if (isAdded) {
      removeFromMyList({ id: assetId, type });
      return;
    }

    addToMyList({
      id: assetId,
      type,
      ...(isLearnActionV4Active && { vrioAssetId: assetToPlayId }),
    });

    const { hash, dimensions } = type === gtmTransmissionType.movie
      ? gtmDimensions.pdp_vod.my_list
      : gtmDimensions.pdp_series.my_list;

    gtmEvent({
      hash,
      eventType: 'interactions',
      userData: gtmUserData,
      dimensions,
      data: { ...gtmContent },
    });
  };

  return (
    <div className="dtv-add-or-remove-from-my-list-button" onClick={handleClick}>
      <span className={isAdded ? 'dtv-icon-check' : 'dtv-icon-add'} />
    </div>
  );
}

export default AddOrRemoveFromMyListButton;
