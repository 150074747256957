/* External */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/* Components */
import DetailsFrame from '../../components/detailsFrame/NewDetailsFrame';
import GtmComponent from '../../components/GtmContext/GtmComponent';
import { gtmDimensions } from '../../components/GtmContext/dimensions/pdp';
import AgeRating from '../../components/ageRating/AgeRating';
import DetailsTab from '../../components/seriesDetails/detailsTab/DetailsTab';
import MoreLikeThis from '../moreLikeThis/MoreLikeThis';

/* Styles */
import './VodContent.scss';

/* Other */
import {
  formatSeconds,
  joinArrayWithSeparator,
  isMoreLikeThisActive,
} from '../../utils';
import { api } from '../../state/configureStore';

/* Constants */
const MORE_LIKE_THIS_TAB_KEY = 'moreLikeThis';
const DETAILS_TAB_KEY = 'details';

function VodContent({
  assetId,
  intl: { formatMessage },
  isPublic = false,
  dataPublic = {},
}) {
  const {
    data = {},
    isError: isAssetError,
    isLoading: isAssetLoading,
    isSuccess: isAssetSuccess,
  } = isPublic ? dataPublic : api.useGetAssetDetailQuery({ assetId });
  const asset = data;
  const { heartbeatOnGoing } = useSelector((state) => state.vrioHeartbeat);
  const [selectedTab, setSelectedTab] = useState(MORE_LIKE_THIS_TAB_KEY);
  const {
    data: continueWatchingData = {}, isLoading: isContinueWatchingLoading,
  } = api.useGetContinueWatchingDataQuery(
    { assetId: asset?.vrioAssetId, assetType: 'movies', vmsId: asset?.vmsId },
    { skip: !isAssetSuccess || heartbeatOnGoing },
  );

  const history = useHistory();

  useEffect(() => {
    if (isAssetError) history.replace('/404');
  }, [history, isAssetError]);

  const isLoading = isAssetLoading || isContinueWatchingLoading || heartbeatOnGoing;
  const { progress } = continueWatchingData;
  const {
    description, genres, rating, title, vod, vmsId, shouldDisplayLockIcon,
  } = asset;
  const { duration, releaseYear } = (isPublic) ? asset : vod || {};
  const formattedDuration = duration && formatSeconds(duration);
  const genre = genres?.[0];

  const metadata = (rating || releaseYear || duration || genre) && (
    <>
      <AgeRating rating={rating} />

      <span>{joinArrayWithSeparator([releaseYear, formattedDuration, genre], ' • ')}</span>
    </>
  );

  function getIconButtonPlayer() {
    if (isPublic || shouldDisplayLockIcon) {
      return 'dtv-icon-lock';
    }
    return 'dtv-icon-play';
  }

  function getFormatTextButton() {
    if (isPublic) {
      return formatMessage({
        id: 'public.pdp.playButton',
        defaultMessage: 'Pruébalo gratis por {days} días',
      }, { days: 7 });
    }

    if (progress > 0) {
      return formatMessage({
        id: 'vod.resume',
        defaultMessage: 'Reanudar',
      });
    }

    return formatMessage({
      id: 'interstitial.watchNow',
      defaultMessage: 'Ver ahora',
    });
  }

  const pdpPlayButtonIcon = getIconButtonPlayer();

  const pdpPlayButtonText = getFormatTextButton();
  const { description: assetDescription } = asset;

  const tabs = {
    [MORE_LIKE_THIS_TAB_KEY]: formatMessage({
      id: 'movie.moreLikeThis',
      defaultMessage: 'Contenidos Relacionados',
    }),
    [DETAILS_TAB_KEY]: formatMessage({
      id: 'movie.details',
      defaultMessage: 'Detalles',
    }),
  };

  const hanleClickSeasonSelector = (tabKey) => {
    setSelectedTab(tabKey);
  };

  return (
    <div className="dtv-vod-content">
      {assetDescription && (
        <GtmComponent
          assetToPlay={asset}
          config={{
            playSource: 'pdp',
            transmissionType: 'movie',
            type: 'movie',
            contentPlayerType: 'movie',
          }}
          hash={gtmDimensions.pdp_vod.page_view.hash}
          eventType={gtmDimensions.pdp_vod.page_view.eventType}
          dimensions={gtmDimensions.pdp_vod.page_view.dimensions}
        />
      )}
      <DetailsFrame
        assetId={vmsId}
        assetToPlay={asset}
        description={description}
        fullHeight
        isContinueWatchingAsset={!!progress}
        isLoading={isLoading}
        metadata={metadata}
        pdpPlayButtonIcon={pdpPlayButtonIcon}
        pdpPlayButtonText={pdpPlayButtonText}
        progress={progress}
        playSource={`PDP-${title}`}
        showMyListButton
        title={title}
        isPublic={isPublic}
      />
      {
        (isMoreLikeThisActive && !isPublic) ? (
          <>
            <div className="dtv-series-details-tab-selector">
              {Object.keys(tabs).map((tabKey) => {
                const tabText = tabs[tabKey];

                return (
                  <span
                    className={classNames({
                      active: tabKey === selectedTab,
                    })}
                    key={tabKey}
                    onClick={() => hanleClickSeasonSelector(tabKey)}
                  >
                    {tabText}
                  </span>
                );
              })}
            </div>

            {selectedTab === MORE_LIKE_THIS_TAB_KEY && (
              <MoreLikeThis contentId={assetId} />
            )}
            {selectedTab === DETAILS_TAB_KEY && (
              <DetailsTab asset={asset} />
            )}
          </>
        ) : (
          <DetailsTab asset={asset} />
        )
      }
    </div>
  );
}

VodContent.propTypes = {
  assetId: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  isPublic: PropTypes.bool,
  dataPublic: PropTypes.shape({}),
};

VodContent.defaultProps = {
  isPublic: false,
  dataPublic: {},
};

export default injectIntl(VodContent);
