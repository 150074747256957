// Numbers
export const [
  LL_TARGET_LATENCY,
  LL_CATCHUP_PLAYBACK_RATE,
  LL_CATCHUP_PLAYBACK_RATE_THRESHOLD,
  LL_CATCHUP_SEEK_THRESHOLD,
  LL_FALLBACK_PLAYBACK_RATE,
  LL_FALLBACK_PLAYBACK_RATE_THRESHOLD,
  LL_FALLBACK_SEEK_THRESHOLD,
  LL_BUFFER_BACKWARD,
  LL_BUFFER_FORWARD,
  PLAYER_REQUEST_TIMEOUT,
  PLAYER_REQUEST_STALL_TIMEOUT,
  PLAYER_REQUEST_CONNECTION_TIMEOUT,
  PLAYER_REQUEST_MAX_ATTEMPTS,
  PLAYER_REQUEST_BASE_DELAY,
  PLAYER_REQUEST_BACKOFF_FACTOR,
  PLAYER_REQUEST_FUZZ_FACTOR,
  PLAYER_RECOVERY_MAX_ATTEMPTS,
  PLAYER_RECOVERY_BASE_DELAY,
  PLAYER_RECOVERY_BACKOFF_FACTOR,
  PLAYER_RECOVERY_FUZZ_FACTOR,
  PLAYER_RECOVERY_RESET_TIME,
  PLAYER_HEARTBEAT_TIME_MINUTES,
  PLAYER_PRESENTATION_DELAY,
  SUSPENDED_MINUTES_TO_EXIT_PLAYER,
  epgChannelsToLoad,
] = [
  import.meta.env.REACT_APP_LL_TARGET_LATENCY,
  import.meta.env.REACT_APP_LL_CATCHUP_PLAYBACK_RATE,
  import.meta.env.REACT_APP_LL_CATCHUP_PLAYBACK_RATE_THRESHOLD,
  import.meta.env.REACT_APP_LL_CATCHUP_SEEK_THRESHOLD,
  import.meta.env.REACT_APP_LL_FALLBACK_PLAYBACK_RATE,
  import.meta.env.REACT_APP_LL_FALLBACK_PLAYBACK_RATE_THRESHOLD,
  import.meta.env.REACT_APP_LL_FALLBACK_SEEK_THRESHOLD,
  import.meta.env.REACT_APP_LL_BUFFER_BACKWARD,
  import.meta.env.REACT_APP_LL_BUFFER_FORWARD,
  import.meta.env.REACT_APP_PLAYER_REQUEST_TIMEOUT,
  import.meta.env.REACT_APP_PLAYER_REQUEST_STALL_TIMEOUT,
  import.meta.env.REACT_APP_PLAYER_REQUEST_CONNECTION_TIMEOUT,
  import.meta.env.REACT_APP_PLAYER_REQUEST_MAX_ATTEMPTS,
  import.meta.env.REACT_APP_PLAYER_REQUEST_BASE_DELAY,
  import.meta.env.REACT_APP_PLAYER_REQUEST_BACKOFF_FACTOR,
  import.meta.env.REACT_APP_PLAYER_REQUEST_FUZZ_FACTOR,
  import.meta.env.REACT_APP_PLAYER_RECOVERY_MAX_ATTEMPTS,
  import.meta.env.REACT_APP_PLAYER_RECOVERY_BASE_DELAY,
  import.meta.env.REACT_APP_PLAYER_RECOVERY_BACKOFF_FACTOR,
  import.meta.env.REACT_APP_PLAYER_RECOVERY_FUZZ_FACTOR,
  import.meta.env.REACT_APP_PLAYER_RECOVERY_RESET_TIME,
  import.meta.env.REACT_APP_PLAYER_HEARTBEAT_TIME_MINUTES,
  import.meta.env.REACT_APP_PLAYER_PRESENTATION_DELAY,
  import.meta.env.REACT_APP_SUSPENDED_MINUTES_TO_EXIT_PLAYER,
  import.meta.env.REACT_APP_EPG_CHANNELS_TO_LOAD,
].map(parseNumber);

// Strings
export const [
  FORGEROCK_SSO_TOKEN_AUTH_INDEX_VALUE,
  FORGE_ROCK_USERNAME,
  ENVIRONMENT,
  APP_THEME,
  GRAN_HERMANO_URL,
  PPV_BOCA_URL,
  MI_DIRECTV_URL,
  PPV_START_DATE,
  PPV_END_DATE,
  X_API_KEY_TOOLBOX,
  TOOLBOX_URL,
  TOOLBOX_HOST_LOGIN_MSO,
  TOOLBOX_VERSION,
  RECAPTCHA_SITE_KEY,
  RECEIVER_ID,
  moreLikeThisVodTitle,
  moreLikeThisEpgTitle,
] = [
  import.meta.env.REACT_APP_FORGEROCK_SSO_TOKEN_AUTH_INDEX_VALUE,
  import.meta.env.REACT_APP_FORGE_ROCK_USERNAME,
  import.meta.env.REACT_APP_ENV_NAME,
  import.meta.env.REACT_APP_THEME,
  import.meta.env.REACT_APP_GRAN_HERMANO_URL,
  import.meta.env.REACT_APP_PPV_BOCA_URL,
  import.meta.env.REACT_APP_MI_DIRECTV_URL,
  import.meta.env.REACT_APP_PPV_START_DATE,
  import.meta.env.REACT_APP_PPV_END_DATE,
  import.meta.env.REACT_APP_X_API_KEY_TOOLBOX,
  import.meta.env.REACT_APP_TOOLBOX_URL,
  import.meta.env.REACT_APP_TOOLBOX_HOST_LOGIN_MSO,
  import.meta.env.REACT_APP_TOOLBOX_VERSION,
  import.meta.env.REACT_APP_RECAPTCHA_SITE_KEY,
  import.meta.env.REACT_APP_RECEIVER_ID,
  import.meta.env.REACT_APP_MORE_LIKE_THIS_VOD_TITLE,
  import.meta.env.REACT_APP_MORE_LIKE_THIS_EPG_TITLE,
];

// Booleans
export const [
  PLAYER_SCHEDULES_EVENT_TYPE,
  USE_LOW_LATENCY_STREAMS,
  IGNORE_TEXT_STREAM_FAILURES,
  isBrazil,
  showMyListFlag,
  LL_CATCH_UP_ACTIVE,
  SKYMAIS_MODAL_FLAG,
  GRAN_HERMANO_MODAL_FLAG,
  UPSELL_FLAG,
  PPV_FLAG,
  TOOLBOX_IS_TEST_REFRESH_TOKEN,
  USE_STREAMING_V4,
  MQTT_FLAG,
  MQTT_REALTIME_ENTITLEMENT_FLAG,
  USE_LAYOUT_PAGES_V4,
  IS_CURABLE_BANNER_ACTIVE,
  WONDERWALL_FEATURE_FLAG,
  isLearnActionV4Active,
  isPagesV5Active,
  isMoreLikeThisActive,
  isUpsellMsoMessageActive,
] = [
  import.meta.env.REACT_APP_PLAYER_SCHEDULES_EVENT_TYPE,
  import.meta.env.REACT_APP_USE_LOW_LATENCY_STREAMS,
  import.meta.env.REACT_APP_PLAYER_RECOVERY_IGNORE_TEXT_STREAM_FAILURES,
  import.meta.env.REACT_APP_BRAZIL,
  import.meta.env.REACT_APP_ENABLE_MY_LIST,
  import.meta.env.REACT_APP_LL_CATCH_UP_ACTIVE,
  import.meta.env.REACT_APP_SKYMAIS_MODAL,
  import.meta.env.REACT_APP_GRAN_HERMANO_MODAL,
  import.meta.env.REACT_APP_UPSELL,
  import.meta.env.REACT_APP_PPV_FLAG,
  import.meta.env.REACT_APP_TOOLBOX_IS_TEST_REFRESH_TOKEN,
  import.meta.env.REACT_APP_USE_STREAMING_V4,
  import.meta.env.REACT_APP_MQTT,
  import.meta.env.REACT_APP_MQTT_REALTIME_ENTITLEMENT,
  import.meta.env.REACT_APP_USE_LAYOUT_PAGES_V4,
  import.meta.env.REACT_APP_IS_CURABLE_BANNER_ACTIVE,
  import.meta.env.REACT_APP_WONDERWALL_FEATURE_FLAG,
  import.meta.env.REACT_APP_IS_LEARN_ACTION_V4_ACTIVE,
  import.meta.env.REACT_APP_IS_PAGES_V5_ACTIVE,
  import.meta.env.REACT_APP_IS_MORE_LIKE_THIS_ACTIVE,
  import.meta.env.REACT_APP_IS_UPSELL_MSO_MESSAGE_ACTIVE,
].map(parseBoolean);

// Sets
export const [
  PARENTAL_COUNTRIES,
  PPV_CHANNELS_CAMPAIGN,
  PPV_COUNTRIES_CAMPAIGN,
] = [
  import.meta.env.REACT_APP_PARENTAL_CONTROL_COUNTRIES,
  import.meta.env.REACT_APP_PPV_CHANNELS,
  import.meta.env.REACT_APP_PPV_COUNTRIES,
].map(parseSet);

/**
 * @method getBooleanFromEnvVariable
 * @description Gets a boolean value from an evironment variable
 * @param {string} name
 */
export function getBooleanFromEnvVariable(name) {
  return parseBoolean(import.meta.env[name]);
}

export function parseBoolean(value) {
  return value === 'true';
}

export function parseNumber(value) {
  const parsedValue = Number.parseFloat(value);

  // eslint-disable-next-line unicorn/no-useless-undefined
  if (Number.isNaN(parsedValue)) return undefined;
  return parsedValue;
}

export function parseSet(value) {
  return new Set(value?.split(',') || []);
}
