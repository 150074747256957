/* External */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/* Ott-common */
import {
  Errors, Npaw, Tracks, getCDNTokenizationConfig, hasTokenization,
} from '@dtvgo/player-utils';
import { useSourceSelector } from '@dtvgo/player-utils-react';
import { useGetAndSet } from '@dtvgo/react-utils';

/* Components */
import ShakaPlayer from './ShakaPlayer';

/* Utils */
import {
  NPAW_PLATFORM, IS_BRAZIL, LANGUAGES,
  LL_BUFFER_BACKWARD, LL_BUFFER_FORWARD, LL_CATCHUP_PLAYBACK_RATE_THRESHOLD,
  LL_CATCHUP_SEEK_THRESHOLD, LL_CATCH_UP_ACTIVE, LL_FALLBACK_PLAYBACK_RATE,
  LL_FALLBACK_PLAYBACK_RATE_THRESHOLD, LL_FALLBACK_SEEK_THRESHOLD, LL_TARGET_LATENCY,
  PLAYER_RECOVERY_BACKOFF_FACTOR, PLAYER_RECOVERY_BASE_DELAY, PLAYER_RECOVERY_FUZZ_FACTOR,
  PLAYER_RECOVERY_MAX_ATTEMPTS, PLAYER_RECOVERY_RESET_TIME, SUSPENDED_MINUTES_TO_EXIT_PLAYER,
  getPlayerLanguagePreferences, goBackFromPlayer, onSuspendedForMoreThan,
} from '../utils';
import { useBufferConfig } from './utils/hooks';
import { history } from '../state/configureStore';

/* Styles */
import './Player.scss';

const LOW_LATENCY_CONFIG = {
  targetLatency: LL_TARGET_LATENCY,
  catchup: {
    playbackRate: LL_CATCHUP_PLAYBACK_RATE_THRESHOLD,
    seekThreshold: LL_CATCHUP_SEEK_THRESHOLD,
    playbackRateThreshold: LL_CATCHUP_PLAYBACK_RATE_THRESHOLD,
  },
  fallback: {
    playbackRate: LL_FALLBACK_PLAYBACK_RATE,
    seekThreshold: LL_FALLBACK_SEEK_THRESHOLD,
    playbackRateThreshold: LL_FALLBACK_PLAYBACK_RATE_THRESHOLD,
  },
};

const LOW_LATENCY_BUFFER_CONFIG = {
  backward: LL_BUFFER_BACKWARD,
  forward: LL_BUFFER_FORWARD,
};

// Recovery options for Player
const recoveryOptions = {
  reload: {
    backoffOptions: {
      maxAttempts: PLAYER_RECOVERY_MAX_ATTEMPTS,
      baseDelay: PLAYER_RECOVERY_BASE_DELAY,
      backoffFactor: PLAYER_RECOVERY_BACKOFF_FACTOR,
      fuzzFactor: PLAYER_RECOVERY_FUZZ_FACTOR,
      resetTime: PLAYER_RECOVERY_RESET_TIME,
    },
  },
};

const npawAdapterOptions = Npaw.getAdapterOptions(NPAW_PLATFORM);

function Player({
  className,
  isLive,
  sources,
  savedProgress,
  onReadyToPlay,
  onPlay,
  onPlaying,
  onPause,
  onProgress,
  onDurationChange,
  onBuffering,
  onBufferingEnded,
  onEnded,
  onAutoplayFailed,
  onError,
  children,
}) {
  const ref = useRef();
  const [getProgress, setProgress] = useGetAndSet(savedProgress);
  const { source, nextSource, startOffset } = useSourceSelector(sources, { getProgress });
  const [shouldPlay, setShouldPlay] = useState(false);

  const setDefaultLanguages = useCallback((tracks) => {
    const { audioTracks, subtitleTracks } = tracks;
    const marketLanguage = IS_BRAZIL ? LANGUAGES.PORTUGUESE_LANGUAGE : LANGUAGES.SPANISH_LANGUAGE;

    const {
      audio: audioPreference,
      descriptiveAudio: descriptiveAudioPreference,
      subtitles: subtitlesPreference,
      closedCaption: closedCaptionPreference,
    } = getPlayerLanguagePreferences();

    const audioPreferenceTrack = audioPreference
      && audioTracks.find(({ iso }) => audioPreference === iso);
    const descriptiveAudioPreferenceTrack = descriptiveAudioPreference
      && audioTracks.find(({ iso, isDescriptive }) => (
        descriptiveAudioPreference === iso && isDescriptive
      ));

    const closedCaptionPreferenceTrack = closedCaptionPreference
      && subtitleTracks.find(({ iso, isClosedCaption }) => (
        closedCaptionPreference === iso && isClosedCaption
      ));
    const subtitlesPreferenceTrack = subtitlesPreference
      && subtitleTracks.find(({ iso }) => subtitlesPreference === iso);

    // When asset isLive, prioritize descriptive audio
    let audioTrack = (isLive && descriptiveAudioPreferenceTrack) || audioPreferenceTrack;

    // When asset isLive, just consider closed captions
    let subtitlesTrack = isLive ? closedCaptionPreferenceTrack : subtitlesPreferenceTrack;

    if (!audioTrack) {
      const marketAudioTrack = audioTracks.find(({ iso }) => marketLanguage === iso);

      if (marketAudioTrack) {
        audioTrack = marketAudioTrack;
      } else {
        const englishAudioTrack = audioTracks.find(({ iso }) => LANGUAGES.ENGLISH_LANGUAGE === iso);

        if (englishAudioTrack) {
          audioTrack = englishAudioTrack;

          if (!isLive && !subtitlesTrack) {
            const marketSubtitlesTrack = subtitleTracks.find(({ iso }) => marketLanguage === iso);

            if (marketSubtitlesTrack) subtitlesTrack = marketSubtitlesTrack;
          }
        } else {
          [audioTrack] = audioTracks;
        }
      }
    }

    if (audioTrack) ref.current?.selectAudioTrack(audioTrack);
    if (subtitlesTrack) ref.current?.selectSubtitleTrack(subtitlesTrack);
  }, [isLive]);

  const handleSourceLoaded = useCallback(({ audioTracks, subtitleTracks }) => {
    const parsedAudioTracks = Tracks.addIndexToUnknownTracks(Tracks.parseAudioTracks(audioTracks));
    const parsedSubtitleTracks = Tracks.addIndexToUnknownTracks(
      Tracks.parseTextTracks(subtitleTracks),
    );
    const tracks = { audioTracks: parsedAudioTracks, subtitleTracks: parsedSubtitleTracks };
    setDefaultLanguages(tracks);
  }, [setDefaultLanguages]);

  const handleReadyToPlay = useCallback(() => {
    setShouldPlay(true);
    onReadyToPlay();
  }, [onReadyToPlay]);

  const handleProgress = useCallback((progress) => {
    setProgress(progress);
    onProgress(progress);
  }, [onProgress, setProgress]);

  const { onRequest, onResponseReceived } = useMemo(() => {
    if (hasTokenization({ manifestUrl: source?.dash || source?.hls })) {
      return getCDNTokenizationConfig(['header']);
    }
    return {};
  }, [source?.dash, source?.hls]);

  const handleError = useCallback((error) => {
    onError(Errors.parsePlayerError(error));
  }, [onError]);

  const handleNonPlayableSources = useCallback((error) => {
    if (!nextSource()) {
      handleError(error);
    }
  }, [handleError, nextSource]);

  const bufferConfig = useBufferConfig(isLive);

  useEffect(() => onSuspendedForMoreThan(SUSPENDED_MINUTES_TO_EXIT_PLAYER * 60 * 1000, () => {
    goBackFromPlayer({ history });
  }), []);

  /* When sources change, set shouldPlay to false */
  useEffect(() => {
    setShouldPlay(false);
  }, [sources]);

  return (
    <ShakaPlayer
      className={classnames('dtv-web-c-player', className)}
      isLive={isLive}
      source={isLive || savedProgress !== undefined ? source : undefined}
      keySystemsConfig={source?.keySystemsConfig}
      startOffset={startOffset !== null ? startOffset : savedProgress}
      shouldPlay={shouldPlay}
      bufferConfig={source?.isLowLatency ? LOW_LATENCY_BUFFER_CONFIG : bufferConfig}
      lowLatency={LOW_LATENCY_CONFIG}
      isCatchUpActive={LL_CATCH_UP_ACTIVE}
      recovery={recoveryOptions}
      npawAdapterOptions={npawAdapterOptions}
      onSourceLoaded={handleSourceLoaded}
      onReadyToPlay={handleReadyToPlay}
      onPlay={onPlay}
      onPlaying={onPlaying}
      onPause={onPause}
      onProgress={handleProgress}
      onDurationChange={onDurationChange}
      onBuffering={onBuffering}
      onBufferingEnded={onBufferingEnded}
      onEnded={onEnded}
      onRequest={onRequest}
      onResponseReceived={onResponseReceived}
      onAutoplayFailed={onAutoplayFailed}
      onNonPlayableSources={handleNonPlayableSources}
      onHDCPError={handleError}
      onFailedLicense={handleError}
      onUnrecoverableError={handleError}
      ref={ref}
    >
      {children}
    </ShakaPlayer>
  );
}

Player.propTypes = {
  className: PropTypes.string,
  isLive: PropTypes.bool,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      dash: PropTypes.string,
      hls: PropTypes.string,
      isLowLatency: PropTypes.bool,
    }),
  ),
  savedProgress: PropTypes.number,
  onReadyToPlay: PropTypes.func,
  onPlay: PropTypes.func,
  onPlaying: PropTypes.func,
  onPause: PropTypes.func,
  onProgress: PropTypes.func,
  onDurationChange: PropTypes.func,
  onBuffering: PropTypes.func,
  onBufferingEnded: PropTypes.func,
  onEnded: PropTypes.func,
  onAutoplayFailed: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.node,
};

Player.defaultProps = {
  className: '',
  isLive: false,
  sources: [],
  savedProgress: undefined,
  onReadyToPlay: () => {},
  onPlay: () => {},
  onPlaying: () => {},
  onPause: () => {},
  onProgress: () => {},
  onDurationChange: () => {},
  onBuffering: () => {},
  onBufferingEnded: () => {},
  onEnded: () => {},
  onAutoplayFailed: () => {},
  onError: () => {},
  children: null,
};

export default Player;
