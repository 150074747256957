/* External */
import React, { Component } from 'react';
import qs from 'qs';

/* Components */
import NavBar from '../../navBar/NavBar';
import NavBarMobile from '../../navBarMobile/NavBarMobile';

/* Other */
import { isMobile } from '../../utils';

class MenuBar extends Component {
  constructor() {
    super();
    this.state = {
      isResponsive: false,
    };
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    this.setState({ isResponsive: window.innerWidth < 965 });
  }

  render() {
    const { search = '' } = this.props;
    const { isResponsive } = this.state;
    const parsedQueryString = qs.parse(search.slice(1));

    if (parsedQueryString.showBack) return '';

    return isMobile().any() || isResponsive
      ? <NavBarMobile />
      : <NavBar />;
  }
}

export default MenuBar;
