/* External */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Style */
import './Switch.scss';

function Switch(props) {
  const { data, setData } = props;
  const [toggle, setToggle] = useState(data);
  const onToggle = () => {
    setToggle((prevToggle) => !prevToggle);
    setData((prevData) => !prevData);
  };

  return (
    <label htmlFor={data} className="toggle-switch">
      <input type="checkbox" checked={toggle} onChange={onToggle} />
      <span className="switch" />
    </label>
  );
}

Switch.propTypes = {
  data: PropTypes.bool,
  setData: PropTypes.func,
};

Switch.defaultProps = {
  data: false,
  setData: undefined,
};

export default Switch;
