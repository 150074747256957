import React, {
  createContext, useState, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { gtmEvent } from '@dtvgo/gtm-event-report';

const GtmContext = createContext({});

function GtmProvider({ children }) {
  const [gtmUserData, setGtmUserData] = useState(null);
  const [gtmContent, setGtmContent] = useState(null);
  const [gtmPlaySource, setGtmPlaySource] = useState(null);
  const [gtmSectionName, setGtmSectionName] = useState(null);
  const [gtmComponentType, setGtmComponentType] = useState(null);
  const [gtmCarouselVerticalPosition, setGtmCarouselVerticalPosition] = useState(null);
  const [gtmProviderName, setGtmProviderName] = useState(null);
  const [gtmCollectionName, setGtmCollectionName] = useState(null);

  const providerValue = useMemo(() => ({
    gtmEvent,
    gtmUserData,
    gtmContent,
    gtmPlaySource,
    gtmSectionName,
    gtmComponentType,
    gtmCarouselVerticalPosition,
    gtmProviderName,
    gtmCollectionName,
    setGtmCollectionName,
    setGtmProviderName,
    setGtmPlaySource,
    setGtmSectionName,
    setGtmComponentType,
    setGtmUserData,
    setGtmContent,
    setGtmCarouselVerticalPosition,
  }), [
    gtmUserData,
    gtmContent,
    gtmPlaySource,
    gtmSectionName,
    gtmComponentType,
    gtmCarouselVerticalPosition,
    gtmProviderName,
    gtmCollectionName,
  ]);

  return (
    <GtmContext.Provider value={providerValue}>
      {children}
    </GtmContext.Provider>
  );
}

const useGtmContext = () => {
  const context = useContext(GtmContext);
  if (!context) {
    throw new Error('useGtmContext need a GtmProvider');
  }
  return context;
};

GtmProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { GtmProvider, useGtmContext };
