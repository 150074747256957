/* External */
import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import cloneDeepL from 'lodash-es/cloneDeep';
import uniqueIdL from 'lodash-es/uniqueId';
import queryString from 'query-string';
import { useRouteMatch } from 'react-router';

/* Ott-common */
import OttCarousel from '@dtvgo/carousel';

/* Assets */
import genericCard2X3 from '@/assets/theme/images/2x3-GenericCard.png';
import genericCard4X3 from '@/assets/theme/images/4x3-GenericCard.png';
import genericCard16X9 from '@/assets/theme/images/16x9-GenericCard.png';
import genericCard1X1 from '@/assets/images/CollectionGenericCard.png';

/* Components */
import Card2X3 from '../card2X3/Card2X3';
import Card4X3 from '../card4X3/Card4X3';
import Card16X9 from '../card16X9/Card16X9';
import ChannelCard from '../cardChannel/ChannelCard';
import CardChannelWithSchedule from '../cardChannelWithSchedule/CardChannelWithSchedule';
import SportResults from '../sportResults/SportResults';
import SportSection from '../sportSection/SportSection';
import SportTeamFormation from '../sportTeamFormation/SportTeamFormation';
import Card1X1 from '../card1X1/Card1X1';
import ImageBanner from '../imageBanner/ImageBanner';
import LinkButton from '../linkButton/LinkButton';
import ScheduleCard from '../ScheduleCard/ScheduleCard';
import SportPlayers from '../SportPlayers/SportPlayers';
import SportHighlights from '../sportHighlights/SportHighlights';
import SportGameStatistics from '../sportGameStatistics/SportGameStatistics';
import CurableBanner from '../curableBanner/CurableBanner';
import Banner from '../banner/Banner';

/* Styles */
import './Carousel.scss';

/* Other */
import {
  getWebAssetAction,
  CAROUSEL_TYPES,
  CAROUSEL_SIZE,
  featuredCarouselTypes,
  liveCarouselTypes,
  PATHS,
  portraitCarouselTypes,
  useNormalizedDate,
  getCarouselPlaySource,
  addPlaySourceToAssets,
  CollectionText,
  getCarouselQueryByFeatureFlag,
} from '../../utils';
import { api } from '../../state/configureStore';
import PlayOffs from '../playOffs/PlayOffs';
import GroupStanding from '../groupStanding/GroupStanding';
import { gtmSeeMoreCarouselEventHandler } from '../GtmContext/dimensions/carousels/eventHandlers';
import { useGtmContext } from '../GtmContext';
import { USE_LAYOUT_PAGES_V4, IS_CURABLE_BANNER_ACTIVE, isPagesV5Active } from '../../utils/env';
import { getImage } from '../../theme/images';

function Carousel({
  id,
  intl: { formatMessage },
  landerUri,
  loadedAssets,
  loadedTitle,
  onFetched,
  onAvailable,
  shown,
  source,
  type,
  filters,
  carouselVerticalPosition,
  recommendationType,
  isCheddar,
}) {
  const isFeaturedCarousel = featuredCarouselTypes.includes(type);
  const isLiveCarousel = liveCarouselTypes.includes(type);
  const isMyListCarousel = type === CAROUSEL_TYPES.MY_LIST;
  const isCurableBanner = type === CAROUSEL_TYPES.CURABLE_BANNER;
  const isPortraitCarousel = portraitCarouselTypes.includes(type);
  const isRecentlyWatchedCarousel = type === CAROUSEL_TYPES.RECENTLY_WATCHED;
  const carouselAvailable = useRef(false);
  const { gtmUserData } = useGtmContext();
  const { params: locationParams } = useRouteMatch();
  const homePage = locationParams.landerUri === 'main';
  const granHermanoPage = locationParams.pageId === 'granhermano';

  const useGetCarouselQuery = useMemo(
    () => getCarouselQueryByFeatureFlag(api, isCheddar),
    [isCheddar],
  );

  const {
    data: carousel,
    isFetching: isFetchingCarousel,
    isError,
  } = useGetCarouselQuery(
    {
      source,
      sourceCarouselId: id,
      type,
      filters,
      recommendationType,
    },
    { skip: isCurableBanner },
  );

  const { data: bannerContent = {} } = api.useGetCurableBannerQuery(
    { bannerId: id },
    { skip: !isCurableBanner || !IS_CURABLE_BANNER_ACTIVE },
  );

  const { data: { channels = [] } = {}, isFetching: isFetchingChannels } = api.useGetChannelsQuery(
    { assetToken: false },
    { skip: !isLiveCarousel },
  );
  const startTime = useNormalizedDate();

  let assets = carousel?.contents || carousel?.collections || loadedAssets;

  const { data: schedules = [], isFetching: isFetchingSchedules } = api.useGetSchedulesQuery(
    {
      channelId: assets?.map(({ channelId }) => channelId),
      pageSize: assets?.length,
      startTime,
      assetToken: false,
    },
    { skip: !isRecentlyWatchedCarousel },
  );

  const isCarouselDataFetched = useRef();
  const [carouselKey, setCarouselKey] = useState(id);

  useEffect(() => {
    if ((carousel || isError) && !carouselAvailable.current) {
      carouselAvailable.current = true;
      if (onAvailable) onAvailable(carousel);
    }
  }, [carousel, isError, onAvailable]);

  useEffect(() => {
    if (
      isFetchingCarousel
      || isFetchingChannels
      || isFetchingSchedules
      || isCarouselDataFetched.current
    ) return;

    isCarouselDataFetched.current = true;
    if (onFetched) onFetched(assets);
  }, [
    assets,
    isFetchingCarousel,
    isFetchingChannels,
    isFetchingSchedules,
    onFetched,
  ]);

  useEffect(() => {
    if (!isMyListCarousel) return;

    setCarouselKey(`${id}_${uniqueIdL()}`);
  }, [assets, id, isMyListCarousel]);

  if (isCurableBanner && (USE_LAYOUT_PAGES_V4 || isPagesV5Active) && IS_CURABLE_BANNER_ACTIVE) {
    const {
      buttonLabel, description, icon, link, title, fixedPhrase,
    } = bannerContent || {};

    return (
      <CurableBanner
        buttonLabel={buttonLabel}
        description={description}
        fixedPhrase={fixedPhrase}
        icon={icon}
        link={link}
        title={title}
      />
    );
  }

  if (type === CAROUSEL_TYPES.IMAGE_BANNER) {
    return (
      <ImageBanner
        collection={assets?.[0]}
        containerCarouselId={id}
        containerCarouselType={type}
      />
    );
  }

  const title = carousel?.title || loadedTitle;
  const getComponent = () => {
    switch (type) {
      case CAROUSEL_TYPES.SPORT_PLAYERS_DYNAMIC:
      case CAROUSEL_TYPES.SPORT_PLAYERS:
        return (
          <SportPlayers teams={carousel?.sport?.contentFilter?.filters ?? []} />
        );
      case CAROUSEL_TYPES.SPORT_HIGHLIGHTS:
        return (
          <SportHighlights match={carousel?.sport?.match} />
        );
      case CAROUSEL_TYPES.SPORT_TEAM_FORMATION:
        return (
          <SportTeamFormation match={carousel?.sport?.match} />
        );
      case CAROUSEL_TYPES.SPORT_GAME_STATISTICS:
        return (
          <SportGameStatistics match={carousel?.sport?.match} />
        );
      case CAROUSEL_TYPES.SPORT_RESULTS_DYNAMIC:
      case CAROUSEL_TYPES.SPORT_RESULTS:
        return (
          <SportResults filter={carousel?.sport?.contentFilter} />
        );
      case CAROUSEL_TYPES.SPORT_STANDING_PLAYOFF:
        return <PlayOffs standing={carousel?.sport?.standing} />;
      case CAROUSEL_TYPES.SPORT_STANDING_GROUP:
      case CAROUSEL_TYPES.SPORT_STANDING_GROUP_DYNAMIC:
        return <GroupStanding standing={carousel?.sport?.standing} />;
      default:
        return null;
    }
  };

  const componentToDisplay = getComponent();
  if (componentToDisplay) {
    return (
      <div className={isFeaturedCarousel ? 'dtv-feature-carousel' : 'dtv-carousel'}>
        {!isFeaturedCarousel && title && (
          <div className="dtv-carousel-header">
            <p className="dtv-carousel-title dtv-text-left" data-type={type} data-vertical-position={carouselVerticalPosition}>{title}</p>
          </div>
        )}
        <div className="dtv-carousel-widget-container">
          {componentToDisplay}
        </div>
      </div>
    );
  }

  if (!assets?.length || !shown) return null;

  const getCarouselSizeByType = CAROUSEL_SIZE[Object.keys(CAROUSEL_TYPES).find(
    (prop) => CAROUSEL_TYPES[prop] === type,
  )];
  const maxCarouselSize = getCarouselSizeByType || 20;

  const playSource = getCarouselPlaySource(landerUri, type, title, id);
  let containerId = `slider${id}`;
  let customSliderSettings;

  assets = addPlaySourceToAssets(cloneDeepL(assets), playSource);

  const getCards = () => assets?.slice(0, maxCarouselSize).map((asset, index) => {
    const assetId = asset.vrioAssetId;
    // Add 1 to index in order to start the count from 1 instead 0
    const cardPositionNumber = index + 1;

    if (isFeaturedCarousel) {
      containerId = 'featureSlider';

      customSliderSettings = {
        autoCycle: assets.length > 1,
        cycleInterval: 5000,
        dots: assets.length > 1,
        sideSize: 0,
        slidesToScroll: 1,
        slidesToShow: 1,
      };

      return (
        <Card16X9
          key={assetId || asset.channelId}
          infoCard={asset}
          genericCard={getImage('16x9GenericCard') || genericCard16X9}
          carouselInfo={{
            type,
            sourceCarouselId: id,
            sectionTitle: title,
            cardPositionNumber,
            carouselVerticalPosition,
          }}
        />
      );
    }

    if (isPortraitCarousel) {
      customSliderSettings = {
        sideSize: 0.28,
        slidesToScroll: 6,
        slidesToShow: 6,
      };

      return (
        <Card2X3
          genericCard={getImage('2x3GenericCard') || genericCard2X3}
          infoCard={asset}
          isInMyList={isMyListCarousel}
          key={asset.vrioAssetId || asset.id}
          carouselInfo={{
            type,
            sourceCarouselId: id,
            sectionTitle: title,
            cardPositionNumber,
            carouselVerticalPosition,
          }}
        />
      );
    }

    switch (type) {
      case CAROUSEL_TYPES.CHANNELS:
        return <ChannelCard infoCard={asset} key={assetId} />;
      case CAROUSEL_TYPES.RECENTLY_WATCHED: {
        customSliderSettings = {
          sideSize: 0.18,
        };

        const channel = channels.find(({ channelId }) => channelId === asset.channelId);
        const schedule = schedules.find(({ channelId }) => channelId === asset.channelId);

        const assetWithLiveData = {
          ...asset,
          images: channel?.images || [],
          schedules: schedule?.schedules || [],
        };

        return (
          <CardChannelWithSchedule
            infoCard={assetWithLiveData}
            key={assetId}
            carouselInfo={{
              type,
              sourceCarouselId: id,
              sectionTitle: title,
              cardPositionNumber,
              carouselVerticalPosition,
            }}
          />
        );
      }
      case CAROUSEL_TYPES.LIVE_AUTOMATIC:
      case CAROUSEL_TYPES.LIVE_EDITORIAL:
      case CAROUSEL_TYPES.NOW_NEXT:
      case CAROUSEL_TYPES.TA_LIVE:
      case CAROUSEL_TYPES.SPORT_LIVE_DYNAMIC:
        return (
          <ScheduleCard
            channelName={
              channels?.find(({ channelId }) => channelId === asset.live?.channelId)?.title
            }
            infoCard={asset}
            key={assetId}
            carouselInfo={{
              type,
              sourceCarouselId: id,
              sectionTitle: title,
              cardPositionNumber,
              carouselVerticalPosition,
            }}
          />
        );
      case CAROUSEL_TYPES.CONTINUE_WATCHING:
      case CAROUSEL_TYPES.LANDSCAPE:
        return (
          <Card4X3
            genericCard={getImage('4x3GenericCard') || genericCard4X3}
            infoCard={asset}
            isContinueWatching={type === CAROUSEL_TYPES.CONTINUE_WATCHING}
            key={assetId}
            carouselInfo={{
              type,
              sourceCarouselId: id,
              sectionTitle: title,
              cardPositionNumber,
              carouselVerticalPosition,
            }}
          />
        );
      case CAROUSEL_TYPES.SPORT_VOD_BANNER_DYNAMIC:
      case CAROUSEL_TYPES.COLLECTIONS: {
        const bannerWebAction = getWebAssetAction(asset?.actions);

        if (!bannerWebAction) return null;
        if (bannerWebAction.text === CollectionText.SPORT_SECTION) {
          customSliderSettings = {
            sideSize: 0.2,
            slidesToScroll: 7,
            slidesToShow: 7,
          };

          return (
            <SportSection
              collection={asset}
              containerCarouselId={id}
              containerCarouselType={type}
            />
          );
        }

        return (
          <Card1X1
            collection={asset}
            containerCarouselId={id}
            containerCarouselType={type}
            genericCard={getImage('collectionGenericCard') || genericCard1X1}
            key={`${id}_${asset.title}`}
          />
        );
      }
      default:
        return null;
    }
  });

  let cards = [];
  if (!isRecentlyWatchedCarousel || schedules.length > 0) {
    cards = getCards();
  }
  const infinitive = type !== CAROUSEL_TYPES.RECENTLY_WATCHED;
  const showSeeAllButton = isMyListCarousel
    || ((type === CAROUSEL_TYPES.PORTRAIT
        || type === CAROUSEL_TYPES.LANDSCAPE
        || type === CAROUSEL_TYPES.NOW_NEXT
        || type === CAROUSEL_TYPES.LIVE_EDITORIAL)
      && assets.length > maxCarouselSize);

  // Send type for SeeAllPage
  const typeCarouselSeeAll = type || CAROUSEL_TYPES.PORTRAIT;

  const query = queryString.stringify(filters);
  const seeAllUrl = `/home/${landerUri || 'lander'}/carousels/${id}/${typeCarouselSeeAll}${query ? `?${query}` : ''}`;

  const seeMoreButtonClickHandler = () => {
    gtmSeeMoreCarouselEventHandler({
      playSource,
      gtmUserData,
      locationParams,
      carouselInfo: {
        type,
        sectionTitle: title,
        verticalPositionCarousel: carouselVerticalPosition,
      },
    });
  };

  return (
    <>
      <div className={isFeaturedCarousel ? 'dtv-feature-carousel' : 'dtv-carousel'} id={containerId}>
        {!isFeaturedCarousel && (title || showSeeAllButton) && (
          <div className="dtv-carousel-header">
            <p className="dtv-carousel-title dtv-text-left" data-type={type} data-vertical-position={carouselVerticalPosition}>{title}</p>

            {showSeeAllButton && (
              <LinkButton
                icon="dtv-icon-arrow-foward"
                url={
                  isMyListCarousel
                    ? PATHS.MY_LIST
                    : seeAllUrl
                }
                onClick={seeMoreButtonClickHandler}
              >
                {isMyListCarousel
                  ? formatMessage({ id: 'myList', defaultMessage: 'Mi lista' })
                  : formatMessage({ id: 'slider.showAll', defaultMessage: 'Ver todos' })}
              </LinkButton>
            )}
          </div>
        )}

        <OttCarousel
          key={carouselKey}
          id={id}
          size={assets?.length}
          slidesSpacing={isFeaturedCarousel ? 0 : 4.5}
          sliderSettings={{
            lazyLoad: !isFeaturedCarousel,
            showSides: true,
            sideSize: 0.2,
            slidesToScroll: 4,
            slidesToShow: 4,
            ...customSliderSettings,
          }}
          infinitive={infinitive}
        >
          {cards}
        </OttCarousel>
      </div>
      {isFeaturedCarousel && (homePage || granHermanoPage)
      && !IS_CURABLE_BANNER_ACTIVE && <Banner />}
    </>
  );
}

export default injectIntl(Carousel);
