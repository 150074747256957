/* External */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Assets */
import teamFlagPlaceholder from '../../assets/icons/team-flag-placeholder-golden-border.svg';

/* Styles */
import './TeamFlag.scss';

function TeamFlag({ teamFlagUrl, teamName = '' }) {
  const [teamFlagSrc, setTeamFlagSrc] = useState(teamFlagUrl || teamFlagPlaceholder);
  return (
    <div className="dtv-team-flag">
      <img
        className="dtv-team-flag-image"
        src={teamFlagSrc}
        alt={`${teamName} flag`}
        onError={() => {
          setTeamFlagSrc(teamFlagPlaceholder);
        }}
      />
    </div>
  );
}

TeamFlag.propTypes = {
  teamFlagUrl: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
};

export default TeamFlag;
