//* global UA */
/* External */
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import Loading from '@dtvgo/loading';
import jwtDecode from 'jwt-decode';

/* Actions */
import { resetUser } from '../state/user/actions';

/* Other */
import {
  isBrazil,
  userGtmEventHandler,
  getAuthTokensData,
} from '../utils';
import '../utils/localStoragePolyfill';
import { clearState } from '../utils/localStorageState';
import store from '../state/configureStore';

/* GTM */
import { useGtmContext } from '../components/GtmContext';

export function Logout() {
  const location = useLocation();
  const { gtmUserData } = useGtmContext();

  useEffect(() => {
    /*
      It was necessary to create this constant, as we were
      experiencing cache errors and causing errors in some environments.
      We use the GTM object pattern that is used in other files,
      so that there is no error during execution.
    */
    const gtmDimensionsLogout = {
      component_type: 'logout',
      error: {
        page_view: {
          hash: 'p-041.023.000.000.000.000',
          eventType: 'pages',
          dimensions: [
            'v_client_id',
            'v_user_id',
            'v_billing_status',
            'v_mso',
            'v_language',
            'v_admin_profile',
            'v_profile_id',
            'v_app_name',
            'v_login_type',
            'v_isocode',
            'v_profile_custom_age',
            'v_profile_unrated_content',
            'v_error_code', /* 30 */
            'v_error_type', /* 38 */
            'v_api', /* 80 */
          ],
        },
      },
    };
    const stateLocation = location?.state || {};
    const { error: errorApi } = stateLocation || {};

    if (errorApi) {
      const { response } = errorApi || {};
      const dataError = response?.data;
      const errorCode = response?.status;
      const errorType = dataError?.error_description;
      const api = response?.request?.responseURL;

      const gtmData = {
        errorCode: errorCode?.toString(),
        errorType: errorType?.toString(),
        api: api?.toString(),
      };

      userGtmEventHandler(
        gtmDimensionsLogout?.error?.page_view,
        gtmUserData,
        gtmData,
      );
    }

    const logout = async () => {
      const { idToken } = getAuthTokensData();
      store.dispatch(resetUser());
      clearState();
      /*
        It was necessary to add a timeout because
        we don't have an error page to log the user out on the web,
        as we do in HTML5, and we weren't able to find the GTM triggers on the logout page.
        This was currently necessary, however, we can change the
        flow and remove the timeout from the code.
      */
      const timeToTimeout = errorApi ? 500 : 0;
      setTimeout(() => {
        const { origin } = window.location;
        const sendToLogin = location.state?.sendToLogin;

        if (!sendToLogin) {
          window.location.replace(`${origin}/`);
          return;
        }

        const environment = import.meta.env.REACT_APP_ENV_NAME;

        if (environment === 'local') {
          window.location.replace(`${origin}/login`);
          return;
        }
        if (isBrazil) {
          window.location.replace(`${origin}/acessar`);
          return;
        }
        const { iso2Code } = jwtDecode(idToken);
        const countryCode = iso2Code.toLowerCase();
        window.location.replace(`${origin}/${countryCode}/acceder-${countryCode}`);
      }, timeToTimeout);
      if (window.cast) window.cast.framework.CastContext.getInstance().endCurrentSession(true);
      try {
        // eslint-disable-next-line no-undef
        const sdk = await (UA.catch(console.error));
        sdk?.channel?.optOut?.();
      } catch {
        // Nothing to do
      }
    };

    if (errorApi) {
      /*
        It was necessary to add a timeout because
        we don't have an error page to log the user out on the web,
        as we do in HTML5, and we weren't able to find the GTM triggers on the logout page.
        This was currently necessary, however, we can change the
        flow and remove the timeout from the code.
      */
      setTimeout(() => {
        logout();
      }, 5000);
    } else {
      logout();
    }
  }, [gtmUserData, location?.state]);
  /*
    A loading page was added so that the user experience is not impacted.
  */
  // eslint-disable-next-line react/react-in-jsx-scope
  return (<div className="dtv-div-background__loader-screen"><Loading /></div>);
}
