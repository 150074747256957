/* External */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

function TracksMenu({ audioTracksListElement, textTracksListElement }) {
  return (
    <div className="dtv-chromecast-bar-player-subtitles">
      <span className="dtv-icon-subtitles" />

      <div className="dtv-menu-button-container">
        <div className="dtv-chromecast-bar-player-subtitles-options">
          <div className="dtv-sub-menu audio">
            {audioTracksListElement}
          </div>

          <div className="dtv-sub-menu text">
            {textTracksListElement}
          </div>
        </div>
      </div>
    </div>
  );
}

TracksMenu.propTypes = {
  audioTracksListElement: PropTypes.node.isRequired,
  textTracksListElement: PropTypes.node.isRequired,
};

export default memo(TracksMenu);
