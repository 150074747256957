/* External */
import isEmptyL from 'lodash-es/isEmpty';
import window from 'global/window';
import jwtDecode from 'jwt-decode';

/* Ott-common */
import { getUserTypeByBusinessUnit } from '@dtvgo/utils';

/* Other */
import '../localStoragePolyfill';
import { MODAL_GRAN_HERMANO_SHOWN_LIST, PROFILES_STORAGE } from '../constants';

/* Constants */
const STORAGE_NAME = 'state';
const DEVICE_ID = 'deviceId';
const USER = 'user';
const NOTIFICATIONS_PERMISSION = 'notificationsPermission';
const PLAYER_LANGUAGE_PREFERENCES = 'playerLanguagePreferences';
const RENEW_ENTITLEMENTS = 'renewEntitlements';
const FR_ACCESS_TOKEN = 'frToken';
const FR_ID_TOKEN = 'sessionToken';
const SSO_TOKEN = 'ssoToken';

export const PLAYER_LANGUAGE_PREFERENCES_KEYS = {
  AUDIO: 'audio',
  CLOSED_CAPTION: 'closedCaption',
  DESCRIPTIVE_AUDIO: 'descriptiveAudio',
  SUBTITLES: 'subtitles',
};

export const purgeStateForMobileRedirection = () => {
  const whiteListedKeys = new Set([DEVICE_ID, USER]);

  Object.keys(localStorage).forEach((key) => {
    if (!whiteListedKeys.has(key)) delete localStorage[key];
  });
};

export const getAuthTokensData = () => {
  const renewEntitlements = JSON.parse(localStorage.getItem(RENEW_ENTITLEMENTS));
  const idToken = localStorage.getItem(FR_ID_TOKEN);
  const profileToken = JSON.parse(localStorage.getItem(PROFILES_STORAGE.profile))?.profileToken || '';
  if (!idToken) return {};

  return {
    accessToken: localStorage.getItem(FR_ACCESS_TOKEN),
    idToken,
    refreshToken: renewEntitlements?.refreshToken || renewEntitlements?.refresh_token,
    ssoToken: localStorage.getItem(SSO_TOKEN),
    profileToken,
  };
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STORAGE_NAME) || {};
    const deviceId = localStorage.getItem(DEVICE_ID) || '';
    const user = localStorage.getItem(USER) || {};
    let state = {};
    const { idToken } = getAuthTokensData();

    if (!isEmptyL(serializedState)) {
      state = JSON.parse(serializedState);
    }

    if (!isEmptyL(user) && idToken) {
      const parsedUser = JSON.parse(user);
      const {
        businessUnit, iso2Code, msoProvider, givenName, familyName, sub,
      } = jwtDecode(idToken) || {};

      const userType = getUserTypeByBusinessUnit(businessUnit, msoProvider);

      state = {
        user: {
          account: {
            ...parsedUser,
            businessUnit,
            iso2Code,
            msoProvider,
            userType,
            givenName,
            familyName,
            email: sub,
          },
          deviceId,
        },
        ...state,
      };
    }

    return state;
  } catch (error) {
    console.error('localStorage - loadState error:', error);
    return {};
  }
};

export const saveState = (state) => {
  const {
    user: { account, deviceId },
    ...rest
  } = state;
  try {
    const hasDeviceId = localStorage.getItem(DEVICE_ID);

    // Serialize
    const serializedAccount = !isEmptyL(account) ? JSON.stringify(account) : null;
    const serializedState = !isEmptyL(rest) ? JSON.stringify(rest) : null;

    // Save
    if (serializedAccount) localStorage.setItem(USER, serializedAccount);
    if (deviceId && !hasDeviceId) localStorage.setItem(DEVICE_ID, deviceId);
    if (serializedState) localStorage.setItem(STORAGE_NAME, serializedState);
  } catch (error) {
    console.error('localStorage - saveState error:', error);
  }
};

export const clearState = () => {
  try {
    const notificationsPermission = JSON.parse(
      localStorage.getItem(NOTIFICATIONS_PERMISSION),
    ) || {};
    const playerLanguagePreferences = localStorage.getItem(PLAYER_LANGUAGE_PREFERENCES);
    const deviceId = localStorage.getItem(DEVICE_ID);
    const modalGranHermanoShownList = localStorage.getItem(MODAL_GRAN_HERMANO_SHOWN_LIST);

    localStorage.clear();
    localStorage.setItem(NOTIFICATIONS_PERMISSION, JSON.stringify(notificationsPermission));
    localStorage.setItem(DEVICE_ID, deviceId);
    localStorage.setItem(MODAL_GRAN_HERMANO_SHOWN_LIST, modalGranHermanoShownList);

    if (playerLanguagePreferences) {
      localStorage.setItem(PLAYER_LANGUAGE_PREFERENCES, playerLanguagePreferences);
    }
  } catch (error) {
    console.error(`utils-localStorageState-clearState: ${error}`);
  }
};

export const addStorageListener = (callback) => {
  window.addEventListener('storage', callback, { passive: true });
};

const getDefaultPlayerLanguagePreferences = () => (
  Object.fromEntries(Object.values(PLAYER_LANGUAGE_PREFERENCES_KEYS).map((trackType) => [trackType, '']))
);

export const savePlayerLanguagePreferences = (isLive, trackType, trackIso) => {
  if (
    trackIso === null
    || typeof trackIso === 'undefined'
    || (isLive && trackType === PLAYER_LANGUAGE_PREFERENCES_KEYS.SUBTITLES)
  ) return;

  const rawLanguagePreferences = localStorage.getItem(PLAYER_LANGUAGE_PREFERENCES);

  const languagePreferences = rawLanguagePreferences
    ? JSON.parse(rawLanguagePreferences)
    : getDefaultPlayerLanguagePreferences();

  if (isLive && trackType === PLAYER_LANGUAGE_PREFERENCES_KEYS.AUDIO) {
    languagePreferences[PLAYER_LANGUAGE_PREFERENCES_KEYS.DESCRIPTIVE_AUDIO] = '';
  }

  languagePreferences[trackType] = trackIso;
  localStorage.setItem(PLAYER_LANGUAGE_PREFERENCES, JSON.stringify(languagePreferences));
};

export const getPlayerLanguagePreferences = () => {
  const rawLanguagePreferences = localStorage.getItem(PLAYER_LANGUAGE_PREFERENCES);

  if (rawLanguagePreferences) return JSON.parse(rawLanguagePreferences);

  return getDefaultPlayerLanguagePreferences();
};

export const disablePlayerSubtitles = (isLive) => {
  savePlayerLanguagePreferences(isLive, PLAYER_LANGUAGE_PREFERENCES_KEYS.SUBTITLES, '');
  if (isLive) savePlayerLanguagePreferences(isLive, PLAYER_LANGUAGE_PREFERENCES_KEYS.CLOSED_CAPTION, '');
};

export const getDeviceId = () => localStorage.getItem(DEVICE_ID);

export const getUserProfile = () => JSON.parse(localStorage.getItem(USER))?.profile || {};

export const getUserSession = () => JSON.parse(localStorage.getItem(USER)) || {};

export const setAuthTokensData = (newTokensData) => {
  if (newTokensData.accessToken) localStorage.setItem(FR_ACCESS_TOKEN, newTokensData.accessToken);
  if (newTokensData.idToken) localStorage.setItem(FR_ID_TOKEN, newTokensData.idToken);
  if (newTokensData.ssoToken) localStorage.setItem(SSO_TOKEN, newTokensData.ssoToken);
  if (newTokensData.refreshToken) {
    localStorage.setItem(
      RENEW_ENTITLEMENTS,
      JSON.stringify({
        refreshToken: newTokensData.refreshToken,
      }),
    );
  }
};
