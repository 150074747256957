/**
 * Actions
 */
import { ACTION_TYPES } from './actions';

/**
 * Other
 */
import { createBaseReducer } from '../../../../../utils/factories';

const initialStateValues = {
  result: {},
  error: {},
};

export default createBaseReducer(ACTION_TYPES, 'result', initialStateValues);
