/* External */
import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

/* Ott-common */
import InfiniteScrollContainer from '@dtvgo/infinite-scroll-container';
import '@dtvgo/infinite-scroll-container/dist/main.css';

/* Components */
import Carousel from '../carousel/Carousel';

const CAROUSEL_BATCH_FETCH = 3;

function CarouselList({
  carousels,
  landerUri,
}) {
  const [carouselsToDisplay, setCarouselsToDisplay] = useState(CAROUSEL_BATCH_FETCH);
  const [availableCarousels, setAvailableCarousels] = useState(0);

  const handleCarouselAvailable = useCallback(() => {
    setAvailableCarousels((current) => current + 1);
  }, []);

  const handleBottomReached = useCallback(() => {
    setCarouselsToDisplay((current) => current + CAROUSEL_BATCH_FETCH);
  }, []);

  const slicedCarousels = useMemo(() => (
    carousels.slice(0, carouselsToDisplay)
  ), [carousels, carouselsToDisplay]);

  return (
    <InfiniteScrollContainer
      onBottomReached={handleBottomReached}
      shouldObserveBottom={
        carouselsToDisplay === availableCarousels
          && carouselsToDisplay < carousels.length
      }
    >
      {useMemo(() => slicedCarousels.map(({
        id, source, type, filters, recommendationType, isCheddar,
      }) => (
        <Carousel
          key={id}
          id={id}
          source={source}
          type={type}
          filters={filters}
          landerUri={landerUri}
          shown
          onAvailable={handleCarouselAvailable}
          recommendationType={recommendationType}
          isCheddar={isCheddar}
        />
      )), [slicedCarousels, landerUri, handleCarouselAvailable])}
    </InfiniteScrollContainer>
  );
}

CarouselList.propTypes = {
  carousels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    source: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  landerUri: PropTypes.string,
};

CarouselList.defaultProps = {
  landerUri: '',
};

export default memo(CarouselList);
