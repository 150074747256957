/* External */
import {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeepL from 'lodash-es/cloneDeep';
import isEmptyL from 'lodash-es/isEmpty';

/* Other */
import {
  DEFAULT_MOST_WATCHED_CHANNELS_BATCH_SIZE,
  CHANNEL_LINE_HEIGHT_IN_PX,
  EPG,
  EPG_VALUES,
} from '../utils';
import { api } from '../state/configureStore';

const getRecentlyWatchedChannels = (allChannels, watchedChannels) => watchedChannels
  .map((watchedChannel) => allChannels.find(
    (channel) => channel.channelId === watchedChannel.channelId,
  ))
  .filter(Boolean);

export const useGetChannels = ({
  channelsBatchSize,
  filter,
  mostWatchedChannelsBatchSize,
  schedulesPerRequest,
  schedulesSizeByChannel,
  startTime,
  endTime,
  selectedFilter,
}) => {
  const dispatch = useDispatch();
  const { fromEpgRedirectionIndex } = useSelector((state) => state.epgRedirectionData) || {};
  const [mostWatchedChannelsWithSchedules, setMostWatchedChannelsWithSchedules] = useState([]);
  const epgIndexPage = Math.floor(fromEpgRedirectionIndex / channelsBatchSize);
  const [isFetchingChannels, setIsFetchingChannels] = useState(false);
  const [hasInitialLoadBeenDone, setHasInitialLoadBeenDone] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    data,
  } = api.useGetChannelsQuery({ assetToken: false });

  const recentlyWatched = api.useGetCarouselQuery(
    {
      source: 'ThinkAnalytics',
      sourceCarouselId: 'UCTARecentlyWatchedChannels',
      type: 'recently-watched',
      filter: { teamId: null, groupId: null, matchId: null },
    },
  );

  const [channelsList, setChannelsList] = useState(useMemo(
    () => (data?.channels?.filter(
      ({ categories, isEntitled }) => isEntitled && (!filter || categories?.includes(filter)),
    ) || []),
    [data, filter],
  ));
  const [completeChannelsList, setCompleteChannelsList] = useState([]);

  const loadMostWatchedChannels = useCallback(
    async (start, end, channels) => {
      try {
        const {
          data: { contents: mostWatchedChannels = [] } = {},
        } = await dispatch(api.endpoints.getMostWatchedChannels.initiate());

        if (!mostWatchedChannels || mostWatchedChannels.length === 0) { throw new Error('getMostWatchedChannels call failed'); }

        const { data: mostWatchedSchedules } = await dispatch(
          api.endpoints.getSchedules.initiate({
            channelId: mostWatchedChannels
              .slice(0, mostWatchedChannelsBatchSize || DEFAULT_MOST_WATCHED_CHANNELS_BATCH_SIZE)
              .map((channel) => channel.vrioAssetId),
            startTime: start,
            endTime: end,
            pageSize: mostWatchedChannels.length * (schedulesSizeByChannel || schedulesPerRequest),
            assetToken: false,
          }),
        );

        const newMostWatchedChannelsWithSchedules = mostWatchedSchedules
          ? mostWatchedSchedules
            .map(({ channelId, schedules }) => {
              const channel = channels.find(
                ({ channelId: internalChannelId }) => internalChannelId === channelId,
              );
              const mostWatchedDataChannel = mostWatchedChannels.find(
                ({ vrioAssetId }) => vrioAssetId === channelId,
              );

              return channel
                ? {
                  ...channel,
                  schedules,
                  rank: mostWatchedDataChannel.rank,
                }
                : {};
            })
            .sort((a, b) => b.rank - a.rank)
          : [];

        setMostWatchedChannelsWithSchedules(newMostWatchedChannelsWithSchedules);
      } catch (error) {
        console.error('Epg-loadMostWatchedChannels', error);
      }
    },
    [dispatch, mostWatchedChannelsBatchSize, schedulesSizeByChannel, schedulesPerRequest],
  );

  const fetchChannels = useCallback(
    async ({
      start, end, direction, shouldLoadMostWatched,
    }) => {
      setIsFetchingChannels(true);

      const channels = cloneDeepL(channelsList);
      const fetchedChannels = channels.filter(({ schedules }) => schedules);
      const channelsToFetch = channels.filter(({ schedules }) => !schedules);
      const channelsLength = channelsToFetch.length;
      const batchSize = channelsBatchSize || channelsLength;
      let channelIds = [];

      let channelIdToLookFor = null;
      let calculatedIndex = 0;

      switch (direction) {
        case EPG.SCHEDULES_FETCHING_DIRECTIONS.UP:
          channelIdToLookFor = fetchedChannels[0].channelId;
          calculatedIndex = channels.findIndex(({ channelId }) => channelId === channelIdToLookFor);
          channelIds = channels.slice(calculatedIndex - batchSize, calculatedIndex).map(({
            channelId,
          }) => channelId);
          break;
        case EPG.SCHEDULES_FETCHING_DIRECTIONS.DOWN:
          channelIdToLookFor = fetchedChannels.at(-1).channelId;
          calculatedIndex = channels.findIndex(({ channelId }) => channelId === channelIdToLookFor);
          channelIds = channels.slice(calculatedIndex + 1, calculatedIndex + 1 + batchSize).map(({
            channelId,
          }) => channelId);
          break;
        default:
          calculatedIndex = epgIndexPage * batchSize || 0;
          channelIds = channels.slice(calculatedIndex, calculatedIndex + batchSize).map(({
            channelId,
          }) => channelId);
          break;
      }

      if (!isEmptyL(channelIds)) {
        try {
          if (shouldLoadMostWatched) loadMostWatchedChannels(start, end, channels);

          const { data: fetched = [], isError: isSchedulesRequestError } = await dispatch(
            api.endpoints.getSchedules.initiate({
              channelId: channelIds,
              startTime: start,
              endTime: end,
              pageSize: channelIds.length * (schedulesSizeByChannel || schedulesPerRequest),
              assetToken: false,
            }),
          );
          const fetchedChannelIds = new Set(fetched.map(
            (channel) => (channel.channelId),
          ));
          const failedChannels = channelIds.filter(
            (channelId) => !fetchedChannelIds.has(channelId),
          );

          let channel;
          if (isSchedulesRequestError) {
            channelIds.forEach((id) => {
              channel = channels.find(({ channelId }) => channelId === id);
              channel.schedules = [];
            });
          } else {
            fetched.forEach(
              ({
                channelId: fetchedChannelId,
                schedules: fetchedSchedules,
              }) => {
                channel = channels.find(({ channelId }) => channelId === fetchedChannelId);
                channel.schedules = fetchedSchedules;
              },
            );
            if (failedChannels) {
              failedChannels.forEach((id) => {
                channel = channels.find(({ channelId }) => channelId === id);
                channel.schedules = [];
              });
            }
          }

          if (direction === EPG.SCHEDULES_FETCHING_DIRECTIONS.UP) {
            window.scrollBy(0, batchSize * CHANNEL_LINE_HEIGHT_IN_PX);
          }
        } catch (error) {
          console.error('Epg-fetchChannels', error);
          setIsError(true);
        }
      }
      if (!hasInitialLoadBeenDone) {
        setCompleteChannelsList(channels);
      }

      setChannelsList(channels);
      setIsFetchingChannels(false);
    },
    [
      setIsFetchingChannels,
      channelsList,
      channelsBatchSize,
      epgIndexPage,
      loadMostWatchedChannels,
      dispatch,
      schedulesSizeByChannel,
      schedulesPerRequest,
      hasInitialLoadBeenDone,
    ],
  );

  /**
     * @description Executes the filter on the list of channels
     */
  useEffect(() => {
    let channelsToShow = [];

    if (selectedFilter === EPG_VALUES.mostWatched) {
      channelsToShow = mostWatchedChannelsWithSchedules;
    } else if (selectedFilter === 'Recents') {
      channelsToShow = getRecentlyWatchedChannels(
        completeChannelsList,
        recentlyWatched.data.contents,
      );
    } else {
      channelsToShow = completeChannelsList.filter(
        ({ categories, isEntitled }) => isEntitled && (
          !selectedFilter || categories?.includes(selectedFilter)
        ),
      );
    }

    setChannelsList(channelsToShow);
  }, [completeChannelsList, selectedFilter, mostWatchedChannelsWithSchedules, recentlyWatched]);

  /*
     * After refreshing the page, check the API call to update the channelsList
    */
  useEffect(() => {
    if (hasInitialLoadBeenDone) return;
    if (data) {
      const updatedChannelsList = data?.channels?.filter(
        ({ categories, isEntitled }) => isEntitled && (!filter || categories?.includes(filter)),
      ) || [];
      setChannelsList(updatedChannelsList);
    }
  }, [data, filter, hasInitialLoadBeenDone]);

  /**
     * @description Executes the first fetchChannels
     */
  useEffect(() => {
    if (hasInitialLoadBeenDone) return;
    if (channelsList.length > 0) {
      fetchChannels({
        start: startTime, end: endTime, direction: null, shouldLoadMostWatched: true,
      });
      setHasInitialLoadBeenDone(true);
    }
  }, [hasInitialLoadBeenDone, fetchChannels, channelsList, startTime, endTime]);

  return {
    fetchChannels,
    channels: channelsList,
    completeChannelsList,
    isError,
    channelsList,
    mostWatchedChannels: mostWatchedChannelsWithSchedules,
    isFetchingChannels,
  };
};
