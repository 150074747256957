import {
  useEffect, useCallback, useState,
} from 'react';

import { PROFILES_STORAGE, getAuthTokensData } from '../../utils';

export async function getCredentials() {
  const { idToken } = getAuthTokensData();
  const selectedProfile = JSON.parse(localStorage.getItem(PROFILES_STORAGE.profile));

  return {
    forgerockTokensData: {
      idToken,
      profileToken: selectedProfile.profileToken,
    },
    selectedProfile,
  };
}

function createRemotePlayer() {
  if (!window.cast) return null;

  const { RemotePlayer, RemotePlayerController } = window.cast.framework;
  const remotePlayer = new RemotePlayer();
  const controller = new RemotePlayerController(remotePlayer);

  if (!remotePlayer.controller) {
    remotePlayer.controller = controller;
  }

  return remotePlayer;
}

export function useRemotePlayer(isAvailable) {
  const [player, setPlayer] = useState(createRemotePlayer);

  useEffect(() => {
    if (!isAvailable) return;

    setPlayer((current) => {
      if (current) return current;

      return createRemotePlayer();
    });
  }, [isAvailable]);

  return player;
}

export function useCastState(isAvailable) {
  const [castState, setCastState] = useState(() => (
    window.cast?.framework.CastContext.getInstance().getCastState()
  ));
  useEffect(() => {
    if (!isAvailable) return () => {};

    const { CastContext, CastContextEventType } = window.cast.framework;
    const context = CastContext.getInstance();

    const listener = (event) => {
      setCastState(event.castState);
    };
    context.addEventListener(CastContextEventType.CAST_STATE_CHANGED, listener);

    return () => {
      context.removeEventListener(CastContextEventType.CAST_STATE_CHANGED, listener);
    };
  }, [isAvailable]);

  return castState;
}

export function useCurrentSession(isAvailable) {
  const [currentSession, setCurrentSession] = useState(() => (
    window.cast?.framework.CastContext.getInstance().getCurrentSession() || undefined
  ));

  useEffect(() => {
    if (!isAvailable) return () => {};

    const {
      CastContext, CastContextEventType,
    } = window.cast.framework;
    const context = CastContext.getInstance();

    const listener = () => {
      setCurrentSession(context.getCurrentSession() || undefined);
    };

    context.addEventListener(CastContextEventType.SESSION_STATE_CHANGED, listener);

    return () => {
      context.removeEventListener(CastContextEventType.SESSION_STATE_CHANGED, listener);
    };
  }, [isAvailable]);

  return currentSession;
}

/**
 * @param {cast.framework.RemotePlayer} player The player object.
 */
export function usePlayerData(player) {
  const [playerData, setPlayerData] = useState(() => ({ ...player }));

  useEffect(() => {
    if (!player?.controller) return () => {};

    setPlayerData({ ...player });

    const { RemotePlayerEventType } = window.cast.framework;
    const listener = ({ field, value }) => {
      setPlayerData((previous) => {
        if (previous[field] === value) return previous;

        return { ...previous, [field]: value };
      });
    };

    player.controller.addEventListener(RemotePlayerEventType.ANY_CHANGE, listener);

    return () => {
      player.controller.removeEventListener(RemotePlayerEventType.ANY_CHANGE, listener);
    };
  }, [player]);

  return playerData;
}

/**
 * @param {cast.framework.CastSession | undefined} session
 */
export function useCurrentMediaSession(session) {
  const [mediaSession, setMediaSession] = useState(() => (
    session?.getMediaSession() || undefined
  ));

  useEffect(() => {
    if (!session) return () => {};

    const { SessionEventType } = window.cast.framework;
    const listener = () => {
      setMediaSession(session.getMediaSession() || undefined);
    };

    session.addEventListener(SessionEventType.MEDIA_SESSION, listener);

    return () => {
      session.removeEventListener(SessionEventType.MEDIA_SESSION, listener);
    };
  }, [session]);

  return mediaSession;
}

/**
 * @param {cast.framework.CastSession | undefined} session
 * @returns {string} The friendly name of the device
 */
export function useCastDeviceName(session) {
  return session?.getCastDevice().friendlyName || '';
}

/**
 * @template {string} Namespace
 * @template {any} MessageType
 * @param {cast.framework.CastSession | undefined} session
 * @param {Namespace} namespace
 * @param {(namespace: Namespace, message: MessageType) => void} callback
 */
export function useOnSessionMessage(session, namespace, callback) {
  useEffect(() => {
    if (!session) return () => {};

    const listener = (receivedNamespace, message) => {
      callback(receivedNamespace, JSON.parse(message));
    };
    session.addMessageListener(namespace, listener);

    return () => {
      session.removeMessageListener(namespace, listener);
    };
  }, [callback, namespace, session]);
}

/**
 * @param {chrome.cast.media.Media} mediaSession
 * @returns {[number[], (getTrackIds: (currentTrackIds: number[]) => number[]) => void]}
 */
export function useActiveTrackIds(mediaSession) {
  const [activeTrackIds, setActiveTrackIds] = useState(() => (
    mediaSession?.activeTrackIds || []
  ));

  useEffect(() => {
    if (!mediaSession) {
      setActiveTrackIds([]);

      return () => {};
    }

    const listener = (isAlive) => {
      if (!isAlive) setActiveTrackIds([]);

      setActiveTrackIds(mediaSession.activeTrackIds || []);
    };
    mediaSession.addUpdateListener(listener);

    return () => {
      mediaSession.removeUpdateListener(listener);
    };
  }, [mediaSession]);

  const changeActiveTrackIds = useCallback(
    /** @param {(currentTrackIds: number[]) => number[]} getNewTrackIds */
    (getNewTrackIds) => {
      const currentMediaSession = window.cast.framework.CastContext.getInstance()
        .getCurrentSession()?.getMediaSession();

      if (!currentMediaSession) return;

      const currentTrackIds = currentMediaSession.activeTrackIds;
      const newTrackIds = getNewTrackIds(currentTrackIds);
      setActiveTrackIds(newTrackIds); // Optimisitic change

      currentMediaSession.editTracksInfo(
        new window.chrome.cast.media.EditTracksInfoRequest(newTrackIds),
        () => {},
        (error) => {
          console.error('Error while editing active tracks', error);
          // Restore previous state on failure
          setActiveTrackIds(currentTrackIds);
        },
      );
    },
    [],
  );

  return [activeTrackIds, changeActiveTrackIds];
}
