/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import classNames from 'classnames';

/**
 * Styles
 */
import './BackButton.scss';

function BackButton({
  className,
  onClick,
  style,
  history,
  backIcon,
}) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (history) {
      history.goBack();
    }
  };
  return (
    <button
      type="button"
      className={classNames('dtv-back-button', className)}
      onClick={handleClick}
      style={style}
    >
      {backIcon}
    </button>
  );
}

BackButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape(),
  onClick: PropTypes.func,
  backIcon: PropTypes.node,
};

BackButton.defaultProps = {
  className: undefined,
  style: undefined,
  onClick: undefined,
  backIcon: <span className="dtv-icon-arrow-back" />,
};

export default withRouter(BackButton);
