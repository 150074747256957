import { userConstants } from '../userConstants';

export const gtmDimensions = {
  component_type: 'carousels',
  carousels_series: {
    watch: {
      hash: 'e-029.044.001.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_list_position',
        'v_tab',
        'v_transmission_type',
        'v_provider_name',
        'v_collection_name',
        'v_content_locked',
        'v_component_type',
        'v_section_id',
        'v_episode_number',
        'v_episode_name',
        'v_content_player_type',
        'v_primary_genre',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
      ],
    },
  },
  carousels_live: {
    watch: {
      hash: 'e-029.044.001.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_section_name',
        'v_list_position',
        'v_tab',
        'v_transmission_type',
        'v_provider_name',
        'v_collection_name',
        'v_content_locked',
        'v_component_type',
        'v_section_id',
        'v_channel_id',
        'v_channel_name',
        'v_episode_number',
        'v_episode_name',
        'v_primary_genre',
        'v_content_player_type',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
      ],
    },
  },
  carousels_vod: {
    watch: {
      hash: 'e-029.044.001.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_season',
        'v_section_name',
        'v_list_position',
        'v_tab',
        'v_transmission_type',
        'v_provider_name',
        'v_collection_name',
        'v_content_locked',
        'v_component_type',
        'v_section_id',
        'v_primary_genre',
        'v_content_player_type',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
      ],
    },
  },
  seeMore: {
    hash: 'e-029.044.001.000.000.134',
    eventType: 'interactions',
    dimensions: [
      ...userConstants.constans,
      'v_play_source',
      'v_section_name',
      'v_list_position',
      'v_tab',
      'v_component_type',
      'v_vertical_position_carousel',
    ],
  },
};
