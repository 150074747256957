/* External */
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

/* Assets */
import logo from '@/assets/theme/svg/logo.svg';

/* Styles */
import './InsertPin.scss';

/* Other */
import {
  IS_BRAZIL,
  PIN_LOGIN_URLS_BR,
  PIN_LOGIN_URLS,
  userGtmEventHandler,
  getAuthTokensData,
} from '../utils';
import { useGtmContext } from '../components/GtmContext';
import { gtmDimensions } from '../components/GtmContext/dimensions/tvValidation';
import { tvService } from '../services';
import { getImage } from '../theme/images';

/**
 * Constants
 */
export const SUCCESSFUL_RESULT = 'exitoso';
const FAILED_RESULT = 'fallido';
const pinLoginUrls = IS_BRAZIL ? PIN_LOGIN_URLS_BR : PIN_LOGIN_URLS;

function InsertPin({ intl: { formatMessage }, location: { search } }) {
  const { gtmUserData } = useGtmContext();
  const [pin, setPin] = useState('');
  const history = useHistory();

  const queryParams = new URLSearchParams(search);
  const pinFromQueryParams = queryParams.get('pinCode');

  useEffect(() => {
    userGtmEventHandler(gtmDimensions.web_enter_code.page_view, gtmUserData);
  }, [gtmUserData]);

  const activeTvToolbox = useCallback(async (pinCode) => {
    try {
      await tvService.v2.tv.activateDevice(
        pinCode,
        getAuthTokensData().idToken,
      );
      history.push(`${pinLoginUrls.main}/${SUCCESSFUL_RESULT}`);
    } catch {
      history.push(`${pinLoginUrls.main}/${FAILED_RESULT}`);
    }
  }, [history]);

  useEffect(() => {
    if (pinFromQueryParams) {
      activeTvToolbox(pinFromQueryParams);
    }
  }, [activeTvToolbox, pinFromQueryParams]);

  const confirmPin = async (e) => {
    if (e) e.preventDefault();
    activeTvToolbox(pin);
  };

  const handlePin = ({ target: { value } }) => {
    const pattern = /^[\da-z]+$/i;
    if (value === '' || pattern.test(value)) {
      setPin(value);
    }
  };

  function handleClick() {
    userGtmEventHandler(
      gtmDimensions.web_enter_code.enter_validation_code,
      gtmUserData,
    );
  }

  return (
    <form className="dtv-insert-pin" onSubmit={confirmPin}>
      <img src={getImage('logo') || logo} className="dtv-img-logo" alt="Logo" />
      <p className="dtv-insert-pin-text">
        <FormattedMessage id="pinLogin.insert" defaultMessage="Inserta el pin que figura en tu televisor" />
      </p>
      <div className="dtv-image-container" />
      <input
        type="text"
        className="dtv-input"
        onChange={handlePin}
        placeholder={formatMessage({
          id: 'pinLogin.activationPin',
          defaultMessage: 'Pin de activación',
        })}
        value={pin}
        maxLength="8"
      />
      <button type="submit" onClick={() => handleClick()} className="dtv-btn-done" disabled={!pin}>
        <FormattedMessage id="pinLogin.done" defaultMessage="¡HECHO!" />
      </button>
    </form>
  );
}

InsertPin.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

InsertPin.defaultProps = {};

export default injectIntl(InsertPin);
