/* External */
import React, { useEffect, useRef, useState } from 'react';
import Html5NpawAdapter from 'youbora-adapter-html5';
import { useLocation, useRouteMatch } from 'react-router';

/* ott-common */
import { NpawAdapterProvider } from '@dtvgo/npaw-context';
import Loading from '@dtvgo/loading';

/* Utils */
import { initFifaPlayer } from './utils';
import { getBooleanFromEnvVariable } from '../../utils';

/* Components */
import CloseFifaPlayerButton from './closeFifaPlayerButton/CloseFifaPlayerButton';

/* Styles */
import './FifaVideoPlayer.scss';

/* Others */
import NpawMonitoringContainer from '../../player/NpawMonitoring.container';
import { api } from '../../state/configureStore';

let hbsPlayerInstance = null;

function FifaVideoPlayer() {
  const { search } = useLocation();
  const { params } = useRouteMatch();
  const [showCloseButton, setShowCloseButton] = useState(false);
  let timeOut = null;

  const displayCloseButton = () => {
    if (!showCloseButton) {
      setShowCloseButton(true);
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        setShowCloseButton(false);
      }, 2500);
    }
  };

  const startTime = new URLSearchParams(search).get('startTime');
  const startTimeParam = new Date(startTime);
  const { channelId } = params;

  const { data: scheduleData = {}, isLoading: isLoadingSchedules } = api.useGetSchedulesQuery({
    channelId: [channelId],
    startTime: startTimeParam,
    pageSize: 1,
  });

  const isFifaPlayerNpawIntegrationActive = getBooleanFromEnvVariable('REACT_APP_ENABLE_FIFA_PLAYER_NPAW_INTEGRATION');

  const [npawAdapter, setNpawAdapter] = useState(null);
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [player, setPlayer] = useState(null);

  const fifaPlayerRef = useRef(null);

  useEffect(() => {
    async function renderFifaPlayer() {
      const [schedule] = scheduleData[0].schedules;
      const fifaMatchId = schedule.sport?.sportIds?.fifaMatchId || '';
      setCurrentSchedule(schedule);
      hbsPlayerInstance = await initFifaPlayer({
        fifaMatchId, fifaPlayerTarget: fifaPlayerRef?.current,
      });

      setPlayer(hbsPlayerInstance);

      const videoElement = fifaPlayerRef?.current.querySelector('video');
      setNpawAdapter(new Html5NpawAdapter(videoElement));
    }
    if (!isLoadingSchedules) {
      renderFifaPlayer();
    }

    return () => {
      if (hbsPlayerInstance) {
        hbsPlayerInstance.destroy();
      }
    };
  }, [isLoadingSchedules, scheduleData]);

  return (
    <div className="dtv-fifa-video-player">
      <CloseFifaPlayerButton showCloseButton={showCloseButton} />
      {player == null && (
        <div className="dtv-fifa-video-player__loader-screen">
          <Loading />
        </div>
      )}
      <div ref={fifaPlayerRef} className="dtv-fifa-video-player__overlay" onMouseMove={displayCloseButton} />
      {isFifaPlayerNpawIntegrationActive
        && (
          <NpawAdapterProvider adapter={npawAdapter}>
            <NpawMonitoringContainer
              isLive
              asset={{
                live: currentSchedule?.live,
                sport: currentSchedule?.sport,
                title: currentSchedule?.title,
                description: currentSchedule?.description,
                duration: currentSchedule?.duration,
                genres: currentSchedule?.genres,
              }}
            />
          </NpawAdapterProvider>
        )}
    </div>
  );
}

export default FifaVideoPlayer;
