/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';

/* Other */
import { formatSeasonAndEpisode, joinArrayWithSeparator } from './metadata';

/**
 * @method buildPlayerAssetMetadata
 * @description Build the asset metadata based on content to be displayed in the player
 * @param {object} content Asset data
 * @param {function} formatMessage i18n translate function
 */
export const buildPlayerAssetMetadata = (asset, formatMessage) => {
  const {
    episode,
    genres,
    title,
    type,
    vod,
  } = asset || {};

  const { number: episodeNumber, seasonNumber } = episode || {};
  const { releaseYear } = vod || {};
  const genreName = genres?.[0];

  switch (type) {
    case assetTypes.movie:
    case assetTypes.liveMovie:
      return joinArrayWithSeparator([genreName, releaseYear]);
    case assetTypes.episode:
    case assetTypes.liveEpisode:
      return joinArrayWithSeparator([
        formatSeasonAndEpisode(
          seasonNumber,
          episodeNumber,
          formatMessage,
        ),
        title,
      ]);
    case assetTypes.show:
    case assetTypes.liveShow:
    case assetTypes.sport:
    case assetTypes.liveSport:
      return genreName;
    default:
      return '';
  }
};
