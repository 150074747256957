/* External */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { format } from 'date-fns';
import isEmptyL from 'lodash-es/isEmpty';

/* Ott-common */
import Loading from '@dtvgo/loading';
import { assetTypes } from '@dtvgo/rtk-query-api';

/* Styles */
import './LoaderScreen.scss';

/* Other */
import { getAssetNameWithQualifier, buildPlayerAssetMetadata } from '../../utils';

/**
 * @function getTitle
 * @description Returns the title of the loader
 * @param {object} content The content info
 * @param {Function} formatMessage A function to get translations
 */
function getTitle(asset, formatMessage) {
  const {
    episode, live, title,
  } = asset || {};

  const { showName } = episode || {};
  const { qualifiers } = live || {};

  return getAssetNameWithQualifier(
    qualifiers,
    showName || title,
    formatMessage,
  );
}

/**
 * @function getGenreAndYear
 * @description Retrieves and returns the genre of the content plus the release year
 * joint by a separator
 * @param {object} content The content info
 * @returns The genre name and release year with a separator
 */
function getGenreAndYear(asset) {
  const { genres, vod: { releaseYear } = {} } = asset;
  const genreName = genres?.[0] || '';
  const unifiedReleaseYear = releaseYear || '';

  return `${genreName}${genreName && unifiedReleaseYear && ' | '}${unifiedReleaseYear}`;
}

/**
 * @function getSchedule
 * @description Returns the formatted schedule for a live content that should
 * be displayed in the loader screen
 * @param {object} content The content info
 * @param {Function} formatMessage A function to get translations
 * @returns {string} The formatted schedule
 */
function getSchedule(asset, formatMessage) {
  const startTime = asset.live?.startTime || asset.startTime;
  const endTime = asset.live?.endTime || asset.endTime;
  const formatTime = 'h:mm a';

  if (!startTime || !endTime) return '';

  const formattedStartTime = format(new Date(startTime), formatTime);
  const formattedEndTime = format(new Date(endTime), formatTime);

  return `${formatMessage({
    id: 'common.today',
    defaultMessage: 'Hoy',
  })}, ${formattedStartTime} - ${formattedEndTime}`;
}

/**
 * Component LoaderScreen
 * @description Shows a loader page while content is loaded
 */
function LoaderScreen({
  asset, intl: { formatMessage }, isLive,
}) {
  const loading = isEmptyL(asset);
  const { type, live } = asset;
  const { channelName } = live || {};

  const title = loading
    ? formatMessage({
      id: 'player.loaderScreen.loadingContent',
      defaultMessage: 'Estamos cargando tu contenido',
    })
    : getTitle(asset, formatMessage);

  const description = loading
    ? formatMessage({
      id: 'player.loaderScreen.pleaseWait',
      defaultMessage: 'Espera por favor',
    })
    : buildPlayerAssetMetadata(asset, formatMessage);

  const schedule = isLive && getSchedule(asset, formatMessage);
  const otherInfo = type === assetTypes.episode && getGenreAndYear(asset);

  return (
    <div className="dtv-loader-screen">
      <Loading />
      <div>
        <h1 className="dtv-loader-screen__content-title">{title}</h1>
        <div className="dtv-loader-screen__content-description">
          {description && <p>{description}</p>}
          {!loading && isLive && (
            <>
              {channelName && <p>{channelName}</p>}
              {schedule && <p>{schedule}</p>}
            </>
          )}
          {!loading && otherInfo && <p>{otherInfo}</p>}
        </div>
      </div>
    </div>
  );
}

LoaderScreen.propTypes = {
  intl: intlShape.isRequired,
  isLive: PropTypes.bool,
  asset: PropTypes.shape(),
};

LoaderScreen.defaultProps = {
  isLive: false,
  asset: {},
};

export default injectIntl(LoaderScreen);
