/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import OttCarousel from '@dtvgo/carousel';
import HBSWidget from '../../HBSWidget';

/* Styles */
import './Statistics.scss';

function Statistics({ teamIdSelected = '' }) {
  if (teamIdSelected) {
    return (
      <div className="sport-statistics">
        <OttCarousel
          key={teamIdSelected}
          size={3}
          sliderSettings={{
            showSides: true,
            sideSize: 0.2,
            slidesToScroll: 1,
            slidesToShow: 2,
          }}
          infinitive
        >
          <HBSWidget className="sport-statistics-widget" type="topstats" props={{ type: 'goals', teamId: teamIdSelected }} />
          <HBSWidget className="sport-statistics-widget" type="topstats" props={{ type: 'shots', teamId: teamIdSelected }} />
          <HBSWidget className="sport-statistics-widget" type="topstats" props={{ type: 'passes_accuracy', teamId: teamIdSelected }} />
        </OttCarousel>
      </div>
    );
  }

  return null;
}

Statistics.propTypes = {
  teamIdSelected: PropTypes.string.isRequired,
};

export default Statistics;
