/* global HBSWidgets */
const HBS_CONFIG = {
  theme: 'dark',
  'api-url': import.meta.env.REACT_APP_HBS_API_URL,
  'account-key': import.meta.env.REACT_APP_HBS_ACCOUNT_KEY,
  'competition-id': import.meta.env.REACT_APP_HBS_COMPETITION_ID,
  season: import.meta.env.REACT_APP_HBS_SEASON,
  language: 'es',
  standing: {
    extended: true,
  },
};

let hbsApiPromise;
export async function renderWidget(element, type, props) {
  try {
    if (!hbsApiPromise) hbsApiPromise = HBSWidgets && HBSWidgets.init(HBS_CONFIG.language);
    const hbsApi = await hbsApiPromise;
    hbsApi?.renderWidget(element, HBS_CONFIG, type, props);
  } catch (error) {
    console.error('HBSWidget>renderWidget', error);
  }
}
