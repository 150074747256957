/* External */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

function TrackList({
  title,
  children,
}) {
  return (
    <ul className="dtv-tracks-menu">
      <li className="dtv-tracks-menu__title">{title}</li>
      {children}
    </ul>
  );
}

TrackList.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
};

TrackList.defaultProps = {
  title: '',
};

export default memo(TrackList);
