/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import HBSWidget from '../HBSWidget';

/* Styles */
import './SportResults.scss';

function SportResults({ filter = null }) {
  let dataType = '';
  let dataId = '';
  const resultIds = filter?.filters?.[0];

  if (!resultIds?.fifaTeamId
    && !resultIds?.fifaGroupId
    && !resultIds?.fifaStageId) {
    return null;
  }

  switch (filter?.type) {
    case 'team': {
      dataType = 'team';
      dataId = resultIds?.fifaTeamId;
      break;
    }
    case 'group': {
      dataType = 'group';
      dataId = resultIds?.fifaGroupId;
      break;
    }
    case 'stage': {
      dataType = 'round';
      dataId = resultIds?.fifaStageId;
      break;
    }
    default:
      dataType = '';
      dataId = '';
  }

  return (
    <HBSWidget className="sports-results-widget" type="matchbox" props={{ type: dataType, id: dataId, matchboxSize: 'medium' }} />
  );
}

SportResults.propTypes = {
  props: PropTypes.shape({
    type: PropTypes.string,
    filter: PropTypes.arrayOf(PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.string),
      optaTeamId: PropTypes.string,
      fifaTeamId: PropTypes.string,
      optaGroupId: PropTypes.string,
      fifaGroupId: PropTypes.string,
      optaStageId: PropTypes.string,
      fifaStageId: PropTypes.string,
      name: PropTypes.string,
    })),
  }),
};

SportResults.defaultProps = {
  props: {
    type: '',
    filter: [
      {
        images: [],
        optaTeamId: null,
        fifaTeamId: null,
        optaGroupId: null,
        fifaGroupId: null,
        optaStageId: null,
        fifaStageId: null,
        name: '',
      },
    ],
  },
};

export default SportResults;
