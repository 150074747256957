/* External */
import { format } from 'date-fns';
import {
  isBrazil,
  PPV_START_DATE,
  PPV_END_DATE,
  PPV_CHANNELS_CAMPAIGN,
  PPV_COUNTRIES_CAMPAIGN,
  epgChannelsToLoad,
} from './env';

export const APP_VERSION = import.meta.env.REACT_APP_VERSION || '0.0.0-local';

export const ACCOUNT_TYPES = {
  FRONT_PORCH: 'front porch',
  FREE_TRIAL: 'free trial',
  PAID: 'paid',
};

export const NEW_CLIENT = 'new-client';

export const BUSINESS_CAMPAIGN = new Set([
  'Gran Hermano',
  'newClient',
]);

export const CAROUSEL_TYPES = {
  CHANNELS: 'live-channels',
  COLLECTIONS: 'banners-collection',
  CURABLE_BANNER: 'banner',
  CONTINUE_WATCHING: 'continue-watching',
  HERO_NOW_NEXT: 'hero-now-next',
  HIGHLIGHTS: 'highlights',
  LANDSCAPE: 'carousel-landscape',
  LIVE_AUTOMATIC: 'live-automatic',
  LIVE_EDITORIAL: 'live-editorial',
  LIVE_HERO_AUTOMATIC: 'live-hero-automatic',
  LIVE_HERO_EDITORIAL: 'live-hero-editorial',
  MOST_WATCHED: 'most-watched',
  MY_LIST: 'my-list',
  NOW_NEXT: 'now-next',
  PORTRAIT: 'carousel-portrait',
  RECENTLY_WATCHED: 'recently-watched',
  TA_HERO: 'ta-hero',
  TA_LIVE: 'ta-live',
  TA_VOD: 'ta-vod',
  IMAGE_BANNER: 'image-banner',
  SPORT_STANDING_PLAYOFF: 'sports-standings-playoff',
  SPORT_STANDING_GROUP: 'sports-standings-groups',
  SPORT_STANDING_GROUP_DYNAMIC: 'sports-standings-groups-dynamic',
  SPORT_RESULTS: 'sports-results',
  SPORT_RESULTS_DYNAMIC: 'sports-results-dynamic',
  SPORT_PLAYERS: 'sports-players',
  SPORT_PLAYERS_DYNAMIC: 'sports-players-dynamic',
  SPORT_TEAM_FORMATION: 'sports-team-formation',
  SPORT_HIGHLIGHTS: 'sports-game-highlights',
  SPORT_GAME_STATISTICS: 'sports-game-statistics',
  SPORT_LIVE_DYNAMIC: 'sports-live-dynamic',
  SPORT_VOD_BANNER_DYNAMIC: 'sports-vod-banner-dynamic',
  SPORT_VOD_PORTRAIT_DYNAMIC: 'sports-vod-portrait-dynamic',
};

export const CAROUSEL_SIZE = {
  COLLECTIONS: 32,
};

export const IMAGE_RESIZERS = {
  BACKGROUND_16x9: { width: '1920', height: '1080' },
  COVER_VERTICAL_1x1: { width: '1400', height: '1400' },
  RECTANGLE_VERTICAL_2x3: { width: '960', height: '1440' },
  COVER_HORIZONTAL_3x4: { width: '1080', height: '1440' },
  COVER_VERTICAL_4x3: { width: '1440', height: '1080' },
  CARD_2x3: { width: '128', height: '192' },
  CARD_16x9: { width: '128', height: '72' },
};

export const USER_TYPES = {
  OTT_D2C: 'OTT-D2C',
  TVE: 'TVE',
  OTT_B2B2C: 'OTT-B2B2C',
  FREE: 'FREE',
};

export const STATUS_TYPES = {
  UNLOAD_STATE: 'notLoaded',
  INIT_STATE: 'initState',
  SUCCEED_STATE: 'succeedState',
  FAILED_STATE: 'failedState',
};

export const FINISHED_STATUS_TYPES = [STATUS_TYPES.SUCCEED_STATE, STATUS_TYPES.FAILED_STATE];

export const LANGUAGES = {
  ANY_LANGUAGE: null,
  DEFAULT_LANGUAGE: null,
  ENGLISH_LANGUAGE: 'en',
  PORTUGUESE_LANGUAGE: 'pt',
  PORTUGUESE_SKYMAIS_LANGUAGE: 'pt-skymais',
  SPANISH_LANGUAGE: 'es',
  DESCRIPTIVE_ENGLISH_LANGUAGE: 'en-desc',
  DESCRIPTIVE_PORTUGUESE_LANGUAGE: 'pt-desc',
  DESCRIPTIVE_SPANISH_LANGUAGE: 'es-desc',
};

export const TRACKS_LANGUAGES = {
  DISABLED: 'off',
  ENGLISH_DESCRIPTIVE: 'qaf',
  ENGLISH: 'en',
  ORIGINAL_AUDIO: 'qaa',
  PORTUGUESE_DESCRIPTIVE: 'qad',
  PORTUGUESE: 'pt',
  SPANISH_DESCRIPTIVE: 'qae',
  SPANISH: 'es',
};

export const LANGUAGES_COUNTRIES = {
  ar: 'rp_spanish',
  co: 'spanish',
  cl: 'spanish',
  DEFAULT: 'spanish',
};

export const BROWSER = {
  FIREFOX: 'Firefox',
  SAFARI: 'Safari',
  IE: 'IE',
  EDGE: 'Edge',
  CHROME: 'Chrome',
  UNKNOWN: 'Unknown',
};

export const RESOURCE_TYPES_ORBIS = {
  MOVIE: 'movie',
  STATION: 'station',
  STATIONS: 'stations',
  EPG_STATIONS: 'epg/stations',
  EPG_MOVIES: 'epg/movies',
  EPG_EVENTS: 'epg/events',
  EPG_COMPETITIONS: 'epg/competitions',
  EPG_TEAMCOMPETITIONS: 'epg/teamCompetitions',
  EPISODES: 'epg/episodes',
  SHOW_EPISODE: 'showEpisode',
  VOD_EPISODE: 'vod/episodes',
  VOD_EPISODES: 'EPISODE',
  VOD_MOVIE: 'MOVIE',
  VOD_MOVIES: 'vod/movies',
  VOD_EVENTS: 'vod/events',
  VOD_COMPETITIONS: 'vod/competitions',
  VOD_TEAM_COMPETITIONS: 'vod/teamCompetitions',
  SHOWS: 'shows',
  SHOW: 'show',
  BANNERS: 'banners',
  LIVE: 'LIVE',
};

export const TIME_UNITS_AS_MILLISECONDS = {
  SECOND: 1000,
  MINUTE: 60_000,
  HOUR: 3_600_000,
  DAY: 86_400_000,
  WEEK: 604_800_000,
  MONTH: 2_628_000_000,
};

export const RESOURCES_BY_TYPE = {
  vod: [
    RESOURCE_TYPES_ORBIS.SHOWS,
    RESOURCE_TYPES_ORBIS.VOD_COMPETITIONS,
    RESOURCE_TYPES_ORBIS.VOD_EPISODES,
    RESOURCE_TYPES_ORBIS.VOD_MOVIES,
    RESOURCE_TYPES_ORBIS.VOD_TEAM_COMPETITIONS,
    RESOURCE_TYPES_ORBIS.VOD_EVENTS,
  ],
  epg: [
    RESOURCE_TYPES_ORBIS.EPG_COMPETITIONS,
    RESOURCE_TYPES_ORBIS.EPG_EVENTS,
    RESOURCE_TYPES_ORBIS.EPG_MOVIES,
    RESOURCE_TYPES_ORBIS.EPG_TEAMCOMPETITIONS,
    RESOURCE_TYPES_ORBIS.EPISODES,
  ],
};

export const DEFAULT_VALUES = {
  LANGUAGE: 'es',
  SUBTITLES: null,
  AUDIO: null,
  COUNTRY: 'co',
};

export const TIME_TO_PLAY_BACK = 3;
export const CHUNK_DURATION = 10_000;
export const GAP = 250;

export const LIVE_PROGRAM_TYPES = {
  PAST: 'PROGRAM_PAST',
  PRESENT: 'PROGRAM_PRESENT',
  FUTURE: 'PROGRAM_FUTURE',
};

export const CARD_TYPES = {
  [LIVE_PROGRAM_TYPES.PAST]: {
    class: 'dtv-program-past',
    type: LIVE_PROGRAM_TYPES.PAST,
  },
  [LIVE_PROGRAM_TYPES.PRESENT]: {
    class: 'dtv-program-airing-right-now',
    type: LIVE_PROGRAM_TYPES.PRESENT,
  },
  [LIVE_PROGRAM_TYPES.FUTURE]: {
    class: 'dtv-program-future',
    type: LIVE_PROGRAM_TYPES.FUTURE,
  },
};

export const DEFAULT_WIDTH_CARDS = {
  FULL_SCREEN: '1500px',
  CARD2X3: '300px',
  CARD4X3: '500px',
  CHANNEL_CARD_IMG: '150px',
  SEARCH_CARD_IMG: '150px',
  CARD_SCHEDULE_IMG: '200px',
  CARD_LIVE: '300px',
};

export const EPG_FILTERS = {
  SPORTS: 'EPG_SPORTS',
};

export const SPORTS_TYPES = [
  RESOURCE_TYPES_ORBIS.EPG_COMPETITIONS,
  RESOURCE_TYPES_ORBIS.EPG_TEAMCOMPETITIONS,
];

export const DEVICE_TYPES = {
  DEFAULT: 'DEFAULT',
  ROKU: 'ROKU',
};

export const DATA_LAYER = 'DATA_LAYER';

export const IS_PUBLIC_SITE = false;

export const IS_BRAZIL = import.meta.env.REACT_APP_BRAZIL === 'true';

export const ROUTES = {
  home: {
    path: '/home',
    validRouteParams: [
      'detailsSerie',
      'live',
      'main',
      'player',
      'preferences',
      'sports',
      'watch',
      'kids',
      'myList',
      'lander',
      'pdp',
    ],
  },
  errorPage: {
    path: '/404',
    validRouteParams: [],
  },
  hbsPlayer: {
    path: '/player/hbs/',
  },
};

export const HDCP_COMPLIANCE_ERROR_CODE = 4012;

/**
 * Default separator for video content details.
 */
export const DEFAULT_SEPARATOR = ' | ';

export const PIN_LOGIN_URLS = {
  main: '/activar-tv',
  mainPlusResult: '/activar-tv/:result',
  basic: '/activar',
};

export const PIN_LOGIN_URLS_BR = {
  main: '/ativar-tv',
  mainPlusResult: '/ativar-tv/:result',
  basic: '/ativar',
};

export const LOGOUT_URL = '/logout';
export const TERMS_AND_CONDITIONS_URL = 'terms-and-conditions-update';
export const TERMS_OF_SERVICE_URL = 'terms-of-service';

export const COUNTRY_CODES = {
  brazil: 'br',
  colombia: 'co',
  chile: 'cl',
  peru: 'pe',
  ecuador: 'ec',
  uruguay: 'uy',
  mexico: 'mx',
  argentina: 'ar',
};

export const GRADIENTS = {
  CARD_1X1: 'linear-gradient(to top, rgb(8, 26, 43), rgba(8, 26, 43, 0) 93%)',
  CARD16X9_PDP_INTERSTITIAL: 'linear-gradient(to bottom, rgba(8, 26, 43, 0), rgba(8, 26, 43, 0.22) 41%, #101010)',
  CARD_LIVE: 'linear-gradient(to top, rgb(8, 26, 43), rgba(8, 26, 43, 0) 25%)',
  PDP: 'linear-gradient(to right, #000000, rgba(0, 0, 0, 0.52) 40%, rgba(0, 0, 0, 0))',
  PUBLIC_DETAILS_SERIES: 'linear-gradient(rgba(8, 26, 43, 0), rgb(8, 26, 43) 72%, rgb(8, 26, 43))',
};
export const AEM_PLATFORM = 'web';

export const NPAW_PLATFORM = 'Web';
export const APP_NAME = import.meta.env.REACT_APP_APP_NAME.toUpperCase();
export const NPAW_APP_NAME = `${APP_NAME} ${NPAW_PLATFORM}`;
export const NPAW_APP_VERSION = `${NPAW_APP_NAME} - ${APP_VERSION}`;

export const CONTINUE_WATCHING_SLIDER_KEY = 'CONTINUEWATCHING';
export const KIDS_LIVE_NOW_SLIDER_ID = 'LiveSSLAKidsCanalesVariados';
export const ID_CONTENT_FEATURED_SLIDERS = 'dtv-content-featured-sliders';
export const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const TIME_ZONE_OFFSET = format(new Date(), 'xxx');
export const CLIENT_TYPE_DESKTOP_THINK_ANALYTICS = 15;

// INTERACTIONS WITH SEARCH
export const INTERACTION_PDP = 'Click to PDP';
export const INTERACTION_PLAY = 'Click to play';
export const INTERACTIONS_TO_REGISTER_IN_THE_DATALAYER_FOR_VOD = [
  RESOURCE_TYPES_ORBIS.MOVIE,
  RESOURCE_TYPES_ORBIS.VOD_MOVIES,
  RESOURCE_TYPES_ORBIS.SHOWS,
  RESOURCE_TYPES_ORBIS.SHOW,
];

export const INTERACTIONS_TO_REGISTER_IN_THE_DATALAYER_FOR_LIVE = [
  RESOURCE_TYPES_ORBIS.VOD_MOVIES,
  RESOURCE_TYPES_ORBIS.EPG_STATIONS,
  RESOURCE_TYPES_ORBIS.EPG_MOVIES,
  RESOURCE_TYPES_ORBIS.EPG_EVENTS,
  RESOURCE_TYPES_ORBIS.EPG_COMPETITIONS,
  RESOURCE_TYPES_ORBIS.EPG_TEAMCOMPETITIONS,
  RESOURCE_TYPES_ORBIS.EPISODES,
];

export const LANDERS_IDS = {
  HOME: 'inicio',
  KIDS: 'kids',
  LIVE: 'live',
  PRESEARCH: 'presearch',
  SPORTS: 'deportes',
  VOD: 'on_demand',
};

export const landerUris = {
  home: 'main',
  kids: 'kids',
  live: 'live',
  sports: 'sports',
  onDemand: 'watch',
  onDirecTv: 'ONDIRECTV',
  hbo: 'HBO',
  lionsgate: 'Lionsgate',
  universal: 'Universal',
  discovery: 'DISCOVERY',
  movies: 'movies',
  series: 'SERIES',
};

export const SECTIONS_URIS = {
  CONTINUE_WATCHING: 'SIGUEVIENDO',
  CONTINUE_WATCHING_LIVE: 'SIGUEVIENDOLIVE',
  MY_LIST: 'MILISTA',
  PRESEARCH: 'PRESEARCH',
  SPORTS_LIVE: 'UCTALiveRecommendedSportsEPG',
  SPORTS_UPCOMING: 'UCTAUpcomingSportsEPG',
};

export const EPG = {
  CURRENT_TIME_LINE_MARGIN: 140, // channel images width
  CURRENT_TIME_MARGIN: 53,
  LEFT_CLICK: 'LEFT_CLICK',
  MINUTES_TO_LOAD_INIT: 300,
  MINUTES_TO_LOAD_LEFT: 30,
  MINUTES_TO_LOAD_RIGHT: 180,
  MINUTES_TO_MOVE: 30,
  MINUTES_TO_PIXELS: 14,
  RIGHT_CLICK: 'RIGHT_CLICK',
  SCHEDULES_PER_REQUEST: 20,
  CHANNELS_TO_LOAD: epgChannelsToLoad || 10,
  SCHEDULES_FETCHING_DIRECTIONS: { UP: 'UP', DOWN: 'DOWN' },
  SPORTS_FILTER: isBrazil ? 'Esportes' : 'Deportes',
};

export const CAROUSEL_SOURCES = {
  SERIES_CONTENT: 'CarouselSeriesContent',
  DETAILS_SERIE: 'CarouselDetailsSerie',
  HOMETAB: 'CarouselHomeTab',
  WATCHLIST: 'CarouselWatchList',
  KIDS: 'CarouselKids',
  LIVE: 'CarouselLive',
  VOD: 'CarouselWatch',
  SPORTS: 'CarouselSports',
  SERIE: 'CarouselSerie',
  SEARCH: 'CarouselSearch',
};

export const GA_EVENT_CATEGORY_ERROR = 'error';
export const GA_APPLICATION_NAME_DESKTOP = 'desktop_web';
export const GA_APPLICATION_NAME_CHROMECAST = 'chromecast';

export const HEARTBEAT_ASSET_TYPES = {
  VOD: 'VOD',
  EPG: 'EPG',
};

export const PATHS = {
  HOME: '/home/main',
  KIDS: '/home/kids',
  MY_LIST: '/home/myList',
  SEARCH: 'search',
  SPORTS: '/home/sports',
  LIVE: '/home/live',
  WATCH: '/home/watch',
  CREATE_PROFILE: '/user/create/profile',
  PROFILE_MANAGEMENT: '/user/profile/management',
  USER_PROFILE: '/user/profile',
  EDIT_PROFILE: '/user/edit/profile',
  ERROR: '/user/page/error',
  CONTENT_BLOCK: '/user/profile/content/block',
};

export const CONTENT_TYPES = {
  VOD: 'VOD',
  LIVE: 'LIVE',
};

export const REGIONS = {
  BR: 'br',
  SSLA: 'ssla',
};

export const TERMS_OF_SERVICE_SUBPAGES = {
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  PRIVACY_POLICY: 'privacy-policy',
};

export const ORDER_OPTIONS = {
  A_Z: 'a-z',
  Z_A: 'z-a',
};

export const QUALIFIERS = {
  NEW: 'epgQualifierNew',
  LIVE: 'epgQualifierLive',
  REPEAT: 'epgQualifierRepeat',
};

// String to recognize if an action from an asset should redirect to a browse lander
export const CollectionText = {
  PAGE: 'PAGE',
  SPORT_SECTION: 'SPORT_SECTION',
  SPORT_PAGE: 'SPORT_PAGE',
};

export const SPORT_PAGE_SET = new Set([CollectionText.SPORT_PAGE, CollectionText.SPORT_SECTION]);

export const DEFAULT_BUFFER_PARAMS = {
  LIVE_VIDEO_BUFFER_BACKWARD_DURATION: 12,
  LIVE_VIDEO_BUFFER_FORWARD_DURATION: 12,
  VOD_VIDEO_BUFFER_BACKWARD_DURATION: 30,
  VOD_VIDEO_BUFFER_FORWARD_DURATION: 60,
};

export const CHROMECAST_CUSTOM_MESSAGE_TYPES = {
  ASSET_LOAD: 'assetLoad',
  ERROR: 'error_cast',
};

export const SEARCH_KEYWORD_THRESHOLD = 3;

// Lazy Loading paramaters, PAGE_SIZE, the amount of request made in one call
export const FIRST_PAGE = 1;
export const PAGE_SIZE = 5;

export const PLAYER_STATES = {
  PAUSED: 'paused',
  PLAY: 'play',
  PLAYING: 'playing',
};

export const PROVIDER_FR = 'forgerock';

export const USER_ACCOUNT_TYPES = {
  SSLA_OTT: 'SSLA-OTT',
  BR_OTT: 'BR-OTT',
  LATAM_OTT: 'LATAM-OTT',
  LATAM_DTH: 'LATAM-DTH',
  SSLA_DTH: 'SSLA-DTH',
  SKY_DTH: 'SKY-DTH',
  MSO_DTH: 'MSO-DTH',
};

export const CHROMECAST_PLAYER_STATE = {
  IDLE: 'IDLE',
  BUFFERING: 'BUFFERING',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
  ERROR: 'ERROR',
  SESSION_STARTED: 'SESSION_STARTED',
  SESSION_ENDED: 'SESSION_ENDED',
};

export const CHROMECAST_NAMESPACE = 'urn:x-cast:com.directvgo.cast.chromecast';

export const DEFAULT_ARE_YOU_STILL_WATCHING_PARAMS = {
  vod: 3,
  live: 4,
};

export const PDP_ASSET_TYPES = {
  movies: 'movies',
  live: 'live',
  series: 'series',
  vod: 'vod',
};

export const PUBLIC_PDP_ASSET_TYPES = {
  movieBR: 'filme',
  movieES: 'pelicula',
  movieEN: 'movie',
  live: 'live',
  series: 'serie',
  sportsES: 'deportes',
};

export const NOTIFICATION_PERMISSION = {
  DEFAULT: 'default',
  GRANTED: 'granted',
  DENIED: 'denied',
};

export const FORGE_ROCK_LOGIN_SCOPE = 'openid profile entitlements';
export const SERVICES_CLIENT_ID = 'web';
export const SERVICES_CLIENT_ID_PREPROD = 'web_preprod';

export const TA_CONTENT_SOURCE_ID_TYPES = {
  LIVE: 1,
  VOD: 2,
};

export const CHROMECAST_ERROR_TYPES = {
  BLACKOUT: 'BLACKOUT',
  CONCURRENCY: 'CONCURRENCY',
  CONCURRENCY_PROVIDER: 'CONCURRENCY_PROVIDER',
  ENTITLEMENTS: 'ENTITLEMENTS',
  DEFAULT_ERROR: 'DEFAULT_ERROR',
};

export const LAST_ACTIVITY_LOCAL_STORAGE_NAME = 'LastActivity';
export const NOTIFICATIONS_PERMISSION_LOCAL_STORAGE_NAME = 'notificationsPermission';

export const FR_RENEW_TOKEN_SCOPE = 'profile openid entitlements vrio device';

export const CHANNEL_LINE_HEIGHT_IN_PX = 127;

export const featuredCarouselTypes = [
  CAROUSEL_TYPES.HERO_NOW_NEXT,
  CAROUSEL_TYPES.HIGHLIGHTS,
  CAROUSEL_TYPES.LIVE_HERO_AUTOMATIC,
  CAROUSEL_TYPES.LIVE_HERO_EDITORIAL,
  CAROUSEL_TYPES.TA_HERO,
];

export const liveCarouselTypes = [
  CAROUSEL_TYPES.HERO_NOW_NEXT,
  CAROUSEL_TYPES.HIGHLIGHTS,
  CAROUSEL_TYPES.LIVE_HERO_AUTOMATIC,
  CAROUSEL_TYPES.LIVE_HERO_EDITORIAL,
  CAROUSEL_TYPES.RECENTLY_WATCHED,
  CAROUSEL_TYPES.TA_HERO,
  CAROUSEL_TYPES.NOW_NEXT,
  CAROUSEL_TYPES.TA_LIVE,
];

export const portraitCarouselTypes = [
  CAROUSEL_TYPES.MOST_WATCHED,
  CAROUSEL_TYPES.MY_LIST,
  CAROUSEL_TYPES.PORTRAIT,
  CAROUSEL_TYPES.SPORT_VOD_PORTRAIT_DYNAMIC,
  CAROUSEL_TYPES.TA_VOD,
];

export const colors = {
  1: 'avatar-bg-1',
  2: 'avatar-bg-2',
  3: 'avatar-bg-3',
  4: 'avatar-bg-4',
  5: 'avatar-bg-5',
};

export const CHAMPIONSHIP_NAME = 'QATAR';

export const PROFILES_STORAGE = {
  profiles: 'profiles',
  profile: 'profile',
};

export const CLIENT_ID_TBX = {
  azp_dtvgo: 'dtvgo',
};

export const userCountry = JSON.parse(localStorage.getItem('user'))?.country?.toLowerCase() ?? '';

export const ageRatingBR = {
  1: '0',
  2: '10',
  3: '12',
  4: '14',
  5: '16',
  6: '18',
};

export const ageRatingSSLA = {
  1: '0',
  2: '7',
  3: '10',
  4: '13',
  5: '14',
  6: '17',
  7: '18',
};

export const FOOTER_URIS = {
  faq: 'faq',
  privacy_policy: 'privacy_policy',
  terms_and_conditions: 'terms_and_conditions',
  personal_data: 'personal_data',
  regulation: 'regulation',
  speed_test: 'speed_test',
};

export const EPG_VALUES = {
  all: 'Todos',
  recents: 'Recents',
  mostWatched: 'Most Watched',
  kids: 'Kids',
};

const EPG_CONFIG_MAPPING = {
  [EPG_VALUES.all]: {
    title: 'epgFilters.title.all',
    value: undefined,
    status: true,
  },
  [EPG_VALUES.kids]: {
    title: 'epgFilters.title.kids',
  },
  [EPG_VALUES.recents]: {
    title: 'epgFilters.title.recents',
  },
  [EPG_VALUES.mostWatched]: {
    title: 'epgFilters.title.most_watched',
  },
};

export function getEpgFilters(channelList) {
  const categoryList = [
    EPG_VALUES.all,
    EPG_VALUES.recents,
    EPG_VALUES.mostWatched,
    ...new Set(channelList
      .flatMap(({ categories: channelCategories }) => channelCategories)),
  ];
  return categoryList.map((value) => ({
    value,
    ...EPG_CONFIG_MAPPING[value],
  }));
}

export const AppTheme = {
  SKYMAIS: 'skymais',
  DGO: 'dgo',
};

export const MODAL_GRAN_HERMANO_SHOWN_LIST = 'modalGranHermanoShownList';

export const ERROR_SCREEN_CODES = {
  MAX_CONCURRENT_STREAMS: 2,
};

export function getUpSellPayPerViewData() {
  const data = {
    startDate: PPV_START_DATE,
    endDate: PPV_END_DATE,
    countryList: PPV_COUNTRIES_CAMPAIGN,
    channelList: PPV_CHANNELS_CAMPAIGN,
  };
  return data;
}

export const getPLayButtonConfig = (config) => {
  const trueKey = Object.keys(config).find((key) => config[key]);
  const btnConfig = [
    {
      isThisType: (type) => type === 'isFutureEvent',
      btnClass: 'dtv-icon-channel',
      btnTitle: 'slider.tuneChannel',
    },
    {
      isThisType: (type) => type === 'isLiveEvent',
      btnClass: 'dtv-icon-play',
      btnTitle: 'slider.tuneChannel.live',
    },
    {
      isThisType: (type) => type === 'isLocked',
      btnClass: 'dtv-icon-lock',
      btnTitle: 'slider.tuneChannel.lock',
    },
    {
      isThisType: () => true,
      btnClass: 'dtv-icon-play',
      btnTitle: 'slider.watchNow',
    },
  ];
  return btnConfig.find(({ isThisType }) => isThisType(trueKey));
};
