/* Global api modifiers */
import '@/utils/datadog';
import '@dtvgo/font-faces';

/* External */
import React from 'react';
import { render } from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

/* Components */
import App from './app/App';

/* Styles */
import './index.scss';

/* Other */
import store, { history } from './state/configureStore';
import { GtmProvider } from './components/GtmContext';

render(
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <GtmProvider>
        <App />
      </GtmProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
