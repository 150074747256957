import { userConstants } from './userConstants';

export const gtmDimensions = {
  component_type: 'epg',
  epg_live: {
    page_view: {
      hash: 'p-037.001.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    watch: {
      hash: 'e-016.005.001.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_list_position',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    date_filter: {
      hash: 'e-016.005.045.000.000.140',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_list_position',
        'v_tab',
        'v_filter_epg',
      ],
    },
    category_filter: {
      hash: 'e-036.001.015.000.000.197',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_list_position',
        'v_tab',
        'v_filter_epg',
      ],
    },
    past: {
      hash: 'e-036.001.016.000.000.096',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_tab',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    future: {
      hash: 'e-036.001.016.000.000.097',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_tab',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_time_to_start',
      ],
    },
    live: {
      hash: 'e-036.001.016.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_tab',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
  },
  epg_sports: {
    page_view: {
      hash: 'p-038.001.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    watch: {
      hash: 'e-016.028.001.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_list_position',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    date_filter: {
      hash: 'e-016.028.045.000.000.140',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_list_position',
        'v_tab',
        'v_filter_epg',
      ],
    },
    past: {
      hash: 'e-036.001.016.000.000.096',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_tab',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
      ],
    },
    future: {
      hash: 'e-036.001.016.000.000.097',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_tab',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_time_to_start',
      ],
    },
    live: {
      hash: 'e-036.001.016.000.000.009',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_tab',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
  },
};
