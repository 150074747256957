/**
 * External
 */
import React, { Component } from 'react';
/**
 * Components
 */
import General from './General';
import ChangePassword from './changePassword/ChangePassword';

/**
 * Constants
 */
export const GENERAL = 'GENERAL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

class GeneralContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedComponent: GENERAL,
    };
    this.changeComponent = this.changeComponent.bind(this);
    this.getComponentToDisplay = this.getComponentToDisplay.bind(this);
  }

  getComponentToDisplay() {
    const { selectedComponent } = this.state;
    switch (selectedComponent) {
      case GENERAL:
        return <General changeComponent={this.changeComponent} />;
      case CHANGE_PASSWORD:
        return <ChangePassword changeComponent={this.changeComponent} />;
      default:
        return <General changeComponent={this.changeComponent} />;
    }
  }

  changeComponent(component) {
    this.setState({
      selectedComponent: component,
    });
  }

  render() {
    return <div>{this.getComponentToDisplay()}</div>;
  }
}

export default GeneralContainer;
