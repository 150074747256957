/**
 * External
 */
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import isNilL from 'lodash-es/isNil';

/**
 * Actions
 */
import { login as loginAction } from '../../state/user/actions';
import { showBarNotification as showBarNotificationAction } from '../../state/notifications/actions';

/**
 * Styles
 */
import './LoginPage.scss';

/**
 * Assets
 */
import logo from '../../assets/images/logo/dgo.png';

/**
 * Other
 */
import { validateEmail, STATUS_TYPES, RECAPTCHA_SITE_KEY } from '../../utils';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      show: props.show,
      buttonState: 'disabled',
      notification: false,
      recaptchaChecked: false,
      recaptchaToken: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleShowLoginMyDirectv = this.handleShowLoginMyDirectv.bind(this);
    this.toggleLogin = this.toggleLogin.bind(this);
    this.handleRecaptchaChange = this.handleRecaptchaChange.bind(this);
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/enterprise.js';
    script.async = true;
    script.defer = true;
    script.id = 'recaptcha-script';
    document.body.append(script);
    script.addEventListener('load', () => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.render('recaptcha-checkbox', {
          sitekey: RECAPTCHA_SITE_KEY,
          callback: this.handleRecaptchaChange,
          action: 'LOGIN',
        });
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user: { login: { status = '', error = {} } = {} } = {}, showBarNotification, show } = nextProps;
    if (status === STATUS_TYPES.FAILED_STATE && error.code && error.isUserFriendly) {
      this.setState({ notification: true });
      showBarNotification({
        show: true,
        messageId: 'login.emailOrPasswordNotCorrect',
        message: 'Your email or password is not correct.',
        containerId: 'messageLoginBar',
      });
    }
    if (!isNilL(show)) this.setState({ show });
  }

  componentWillUnmount() {
    const { showBarNotification } = this.props;
    showBarNotification({ show: false });
    const recaptchBadge = document.getElementsByClassName('recaptcha-checkbox');
    recaptchBadge?.[0]?.remove();
  }

  handleChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.updateButtonState();
      },
    );
  }

  handleRecaptchaChange(response) {
    this.setState({
      recaptchaChecked: !!response,
      recaptchaToken: response,
    }, () => {
      this.updateButtonState();
    });
  }

  handleShowLoginMyDirectv() {
    const { showBarNotification } = this.props;
    showBarNotification({ show: false });
  }

  handleLogin(event) {
    if (event) {
      event.preventDefault();
    }

    const {
      router: {
        location: { state = {} },
      },
      login,
      showBarNotification,
    } = this.props;
    const { username, password, recaptchaToken } = this.state;
    if (validateEmail(username)) {
      login(username, password, state.from, recaptchaToken);
    } else {
      showBarNotification({
        show: true,
        messageId: 'login.email.invalid',
        message: 'Please, enter a valid email.',
        containerId: 'messageLoginBar',
      });
    }
  }

  updateButtonState() {
    const { username, password, recaptchaChecked } = this.state;
    const buttonState = (username !== '' && password !== '' && recaptchaChecked) ? '' : 'disabled';
    this.setState({ buttonState });
  }

  toggleLogin() {
    const { showBarNotification, toggleLogin } = this.props;
    this.setState({
      username: '', password: '', notification: false, recaptchaChecked: false,
    });
    showBarNotification({ show: false });
    toggleLogin();
  }

  render() {
    const {
      intl: { formatMessage },
      user: { login = {} } = {},
    } = this.props;

    const {
      show,
      notification,
      username,
      password,
      buttonState,
    } = this.state;

    return login.status === STATUS_TYPES.SUCCEED_STATE ? (
      <Redirect to={{ pathname: '/home' }} />
    ) : (
      <div className={`dtv-login-container dtv-text-center ${show}`}>
        <div className="dtv-login-form-container">
          <div id="messageLoginBar" />
          <div className="dtv-login-form">
            <img src={logo} className="mb-4 dtv-logo" alt="Logo" />
            <span
              className={`dtv-login-close dtv-icon-close ${notification ? 'tp' : ''}`}
              onClick={this.toggleLogin}
            />
            <form onSubmit={(event) => this.handleLogin(event)}>
              <input
                type="text"
                name="username"
                className="form-control mb-3"
                placeholder={formatMessage({
                  id: 'login.email',
                  defaultMessage: 'Your email account',
                })}
                value={username}
                onChange={this.handleChange}
              />
              <input
                type="password"
                name="password"
                className="form-control mb-3"
                placeholder={formatMessage({
                  id: 'login.password',
                  defaultMessage: 'Password',
                })}
                value={password}
                onChange={this.handleChange}
              />
              <div id="recaptcha-checkbox" className="mb-3" />
              <button
                type="button"
                className={`dtv-login-btn mt-3 mb-1 ${buttonState}`}
                disabled={buttonState}
                onClick={this.handleLogin}
              >
                <FormattedMessage id="login.logIn" defaultMessage="LOGIN" />
              </button>
            </form>
            <br />
            <br />
            <br />
            <p className="mb-1">
              <FormattedMessage id="login.areYouDirectvGold" defaultMessage="Are you DIRECTV ORO?" />
            </p>
            <p>
              <FormattedMessage id="login.logInWithYourAccount" defaultMessage="Log in with your account " />
              <span className="dtv-login-highlighted-text">
                <FormattedMessage id="login.directv" defaultMessage="MyDIRECTV" />
              </span>
              <FormattedMessage id="login.account" defaultMessage=" account." />
            </p>
            <button
              type="button"
              className="dtv-login-btn dtv-login-btn-my-directv mt-3"
              onClick={this.handleShowLoginMyDirectv.bind(this, true)}
            >
              <FormattedMessage id="login.loginWith" defaultMessage="Login with " />
              <FormattedMessage id="login.directv" defaultMessage="MyDIRECTV" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ router, user }) => ({ router, user });

export default injectIntl(connect(mapStateToProps, {
  login: loginAction,
  showBarNotification: showBarNotificationAction,
})(LoginPage));
