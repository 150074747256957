/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-undef */

export const initFifaPlayer = async ({ fifaMatchId, fifaPlayerTarget }) => {
  const options = {
    externalId: fifaMatchId,
    target: fifaPlayerTarget,
    competition: import.meta.env.REACT_APP_HBS_COMPETITION_ID,
    season: import.meta.env.REACT_APP_HBS_SEASON,
    autoPlay: true,
    mute: false,
    lang: 'spa',
  };

  return OnRewindPlayer(options);
};
