/* External */
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

/* OTT-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';
import { Errors } from '@dtvgo/player-utils';
import { PlayerError } from '@dtvgo/player-common-utils';

/* Assets */
import logo from '@/assets/theme/svg/logo.svg';
import extraContent from '@/assets/theme/icons/extra-content.svg';
import playerErrorIcon from '@/assets/theme/svg/player-error.svg';

/* Components */
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/errors';

/* Styles */
import './ErrorScreen.scss';

/* Other */
import {
  goBackFromPlayer,
  formatContentForGtm,
  gtmErrorType,
  externalRedirect,
  FOOTER_URIS,
  UPSELL_FLAG,
  PPV_FLAG,
  USER_TYPES,
  BUSINESS_CAMPAIGN,
  GRAN_HERMANO_URL,
  PPV_CHANNELS_CAMPAIGN,
  PPV_BOCA_URL,
  PPV_START_DATE,
  PPV_END_DATE,
  ERROR_SCREEN_CODES as ERROR_CODE,
  buildExternalSelfCareURL,
  getSelfCareURL,
  getUpSellPayPerViewData,
  getUserDetailsToken,
  isUpsellMsoMessageActive,
} from '../../utils';
import { getImage } from '../../theme/images';

const icon = getImage('iconExtraContent') || extraContent;
const defaultActionText = 'errorPage.btn';
const defaultAction = ({
  history, epgRedirectionData, asset, gtmUserData,
}) => {
  const { hash, eventType, dimensions } = gtmDimensions.error.ok;
  const dataToSend = formatContentForGtm(asset);
  gtmEvent({
    hash,
    eventType,
    dimensions,
    userData: gtmUserData,
    data: dataToSend,
  });
  goBackFromPlayer({ history, epgRedirectionData });
};

const getUpsellLink = (user) => {
  if (BUSINESS_CAMPAIGN.has(user.account.msoProvider) && user.account.iso2Code === 'AR') {
    const prefilledToken = btoa(JSON.stringify({
      nombre: user.account.givenName,
      apellido: user.account.familyName,
      email: user.account.email,
    }));
    return `${GRAN_HERMANO_URL}?token=${encodeURIComponent(prefilledToken)}`;
  }
  if (BUSINESS_CAMPAIGN.has(user.account.msoProvider) && user.account.iso2Code !== 'AR') return getSelfCareURL(user);
  if (user.account.userType === USER_TYPES.OTT_D2C) return getSelfCareURL(user);
  if (user.account.userType === USER_TYPES.TVE) {
    return buildExternalSelfCareURL(user.account.iso2Code, false, true);
  }
  return '';
};

const getMsoUpsellLink = (user) => user.account.msoSelfcareUrl;

const triggerGtmManageSubscription = ({
  asset,
  code,
  type,
  tab,
  gtmProviderName,
  gtmCollectionName,
  gtmUserData,
}) => {
  const { hash, eventType, dimensions } = gtmDimensions.error.upsell.manage_subscription;
  const dataToSend = formatContentForGtm(
    asset,
    {
      code,
      type,
      tab,
      provider: gtmProviderName,
      collectionName: gtmCollectionName,
    },
  );
  gtmEvent({
    hash,
    eventType,
    dimensions,
    userData: gtmUserData,
    data: dataToSend,
  });
};

const upsellAction = ({
  user,
  ...rest
}) => {
  triggerGtmManageSubscription(rest);
  window.location.href = getUpsellLink(user);
};

const msoUpsellAction = ({
  user,
  ...rest
}) => {
  triggerGtmManageSubscription(rest);
  window.location.href = getMsoUpsellLink(user);
};

const ppvConfig = getUpSellPayPerViewData();
const isDateInRange = (start, end) => {
  const currentDateTime = new Date().toISOString();
  if (currentDateTime >= start && currentDateTime <= end) {
    return true;
  }
  return false;
};

const getErrorMessagesAndActions = () => {
  const { selfcareUrl } = getUserDetailsToken();
  return [
    {
      isThisError: (error) => error.code === Errors.ErrorCode.BLACKOUT,
      title: 'player.blackout.title',
      description: 'player.blackout.subtitle',
      errorCode: `${gtmErrorType.unexpected} Error`,
      errorType: gtmErrorType.unexpected,
    },
    {
      isThisError: (error) => error.code === Errors.ErrorCode.CONCURRENCY_PER_PROVIDER,
      title: 'player.error.providerLimitTitle',
      description: 'player.error.providerLimitDescription',
      errorCode: `${gtmErrorType.concurrency_provider} Error`,
      errorType: gtmErrorType.concurrency_provider,
      primaryActionText: 'player.redirectFaq',
      primaryAction: ({ user }) => {
        externalRedirect(
          FOOTER_URIS.faq,
          user.account.country,
          user.account.profile.customData.preferences.language.client,
          true,
        );
      },
      secondaryActionText: defaultActionText,
      secondaryAction: defaultAction,
    },
    {
      isThisError: (error) => error.code === Errors.ErrorCode.CONCURRENCY_PER_CHANNEL,
      title: 'player.error.channelLimitTitle',
      description: 'player.error.channelLimitDescription',
      errorCode: `${gtmErrorType.concurrency_channel} Error`,
      errorType: gtmErrorType.concurrency_channel,
    },
    {
      isThisError: (error) => error.code === Errors.ErrorCode.CONCURRENCY_PER_USER,
      title: {
        id: 'player.error.deviceLimitTitle',
        getValues: ({ error }) => ({
          csmLimit: error.data.response.data.csm || ERROR_CODE.MAX_CONCURRENT_STREAMS,
        }),
      },
      description: 'player.error.deviceLimitDescription',
      errorCode: `${gtmErrorType.concurrency_devices} Error`,
      errorType: gtmErrorType.concurrency_devices,
    },
    {
      isThisError: (error) => (
        error.data.originalError.code === PlayerError.Code.DRM_RESTRICTED_OUTPUT
      ),
      title: 'player.error.unplugScreen',
      preTitle: 'common.attention',
      description: 'player.error.hdcp',
      errorCode: `${gtmErrorType.hdcp} Error`,
      errorType: gtmErrorType.hdcp,
      primaryActionText: 'player.error.unpluggedScreen',
      primaryAction: () => window.location.reload(),
      secondaryActionText: defaultActionText,
      secondaryAction: defaultAction,
    },
    {
      isThisError: (error) => error.code === Errors.ErrorCode.REFRESH_TOKEN_REVOKED,
      title: 'player.errorRefreshTokenRevoked.title',
      description: 'player.errorRefreshTokenRevoked.description',
      errorCode: `${gtmErrorType.unexpected} Error`,
      errorType: gtmErrorType.unexpected,
      primaryActionText: 'errorPage.btn',
      imageSrc: playerErrorIcon,
    },
    {
      isThisError: (error, { msoProvider, iso2Code }, channelId) => (
        error.code === Errors.ErrorCode.NOT_IN_PACKAGE
        && PPV_CHANNELS_CAMPAIGN.has(channelId)
        && BUSINESS_CAMPAIGN.has(msoProvider)
        && ppvConfig.countryList.has(iso2Code.toLowerCase())
        && PPV_FLAG
      ),
      imageSrc: icon,
      title: 'player.errorPackageContentCNIP.userGH_AR.title',
      description: 'player.errorPackageContentCNIP.userGH_AR.description',
      errorCode: gtmErrorType.isPackageContentErrorCNIP,
      errorType: gtmErrorType.cinp,
      primaryActionText: 'player.errorPackageContentCNIP.userGH_AR.button',
      primaryAction: upsellAction,
      secondaryActionText: defaultActionText,
      secondaryAction: defaultAction,
      redirect: PPV_BOCA_URL,
    },
    {
      isThisError: (error, { msoProvider, iso2Code }) => (
        error.code === Errors.ErrorCode.NOT_IN_PACKAGE
        && BUSINESS_CAMPAIGN.has(msoProvider)
        && iso2Code === 'AR'
        && UPSELL_FLAG
      ),
      imageSrc: icon,
      title: 'player.errorPackageContentCNIP.userGH_AR.title',
      description: 'player.errorPackageContentCNIP.userGH_AR.description',
      errorCode: gtmErrorType.isPackageContentErrorCNIP,
      errorType: gtmErrorType.cinp,
      primaryActionText: 'player.errorPackageContentCNIP.userGH_AR.button',
      primaryAction: upsellAction,
      secondaryActionText: defaultActionText,
      secondaryAction: defaultAction,
    },
    {
      isThisError: (error, { msoProvider, iso2Code }) => (
        error.code === Errors.ErrorCode.NOT_IN_PACKAGE
        && BUSINESS_CAMPAIGN.has(msoProvider)
        && iso2Code !== 'AR'
        && UPSELL_FLAG
      ),
      imageSrc: icon,
      title: 'player.errorPackageContentCNIP.userGH_SSLA.title',
      description: 'player.errorPackageContentCNIP.userGH_SSLA.description',
      errorCode: gtmErrorType.isPackageContentErrorCNIP,
      errorType: gtmErrorType.cinp,
      primaryActionText: 'player.errorPackageContentCNIP.userGH_SSLA.button',
      primaryAction: upsellAction,
      secondaryActionText: 'common.not.now',
      secondaryAction: defaultAction,
    },
    {
      isThisError: (error, { userType }) => error.code === Errors.ErrorCode.NOT_IN_PACKAGE
        && userType === USER_TYPES.OTT_B2B2C && UPSELL_FLAG && isUpsellMsoMessageActive,
      imageSrc: icon,
      title: 'player.errorPackageContentCNIP.userMSO.title',
      errorCode: gtmErrorType.isPackageContentErrorCNIP,
      errorType: gtmErrorType.cinp,
      ...(selfcareUrl ? {
        primaryActionText: 'player.errorPackageContentCNIP.userMSO.button.label',
        primaryAction: msoUpsellAction,
        secondaryActionText: 'common.not.now',
        secondaryAction: defaultAction,
      } : {
        primaryAction: defaultAction,
        primaryActionText: 'player.errorPackageContentCNIP.userMSO.okButton.label',
      }),
    },
    {
      isThisError: (error, { userType, iso2Code }) => error.code === Errors.ErrorCode.NOT_IN_PACKAGE
        && userType === USER_TYPES.OTT_D2C && iso2Code === 'AR' && UPSELL_FLAG,
      imageSrc: icon,
      title: 'player.errorPackageContentCNIP.userOTT_AR.title',
      description: 'player.errorPackageContentCNIP.userOTT_AR.description',
      errorCode: gtmErrorType.isPackageContentErrorCNIP,
      errorType: gtmErrorType.cinp,
      primaryActionText: 'player.errorPackageContentCNIP.userOTT_AR.button',
      primaryAction: upsellAction,
      secondaryActionText: 'common.not.now',
      secondaryAction: defaultAction,
    },
    {
      isThisError: (error, { userType, iso2Code }) => error.code === Errors.ErrorCode.NOT_IN_PACKAGE
        && userType === USER_TYPES.OTT_D2C && iso2Code !== 'AR' && UPSELL_FLAG,
      imageSrc: icon,
      title: 'player.errorPackageContentCNIP.userOTT.title',
      description: 'player.errorPackageContentCNIP.userOTT.description',
      errorCode: gtmErrorType.isPackageContentErrorCNIP,
      errorType: gtmErrorType.cinp,
      primaryActionText: 'player.errorPackageContentCNIP.userOTT.button',
      primaryAction: upsellAction,
      secondaryActionText: 'common.not.now',
      secondaryAction: defaultAction,
    },
    {
      isThisError: (error, { userType }) => error.code === Errors.ErrorCode.NOT_IN_PACKAGE
        && userType === USER_TYPES.TVE && UPSELL_FLAG,
      imageSrc: icon,
      title: 'player.errorPackageContentCNIP.userDTH.title',
      description: 'player.errorPackageContentCNIP.userDTH.description',
      errorCode: gtmErrorType.isPackageContentErrorCNIP,
      errorType: gtmErrorType.cinp,
      primaryActionText: 'player.errorPackageContentCNIP.userDTH.button',
      primaryAction: upsellAction,
      secondaryActionText: 'common.not.now',
      secondaryAction: defaultAction,
    },
    {
      isThisError: (error) => error.code === Errors.ErrorCode.NOT_IN_PACKAGE,
      title: {
        id: 'common.error.entitlements.title',
        getValues: ({ asset, isLive }) => ({
          assetTitle: isLive
            ? asset?.live?.channelName
            : asset?.episode?.showName || asset?.title,
        }),
      },
      description: 'player.errorEntitlements.description',
      errorCode: `${gtmErrorType.package_content} Error`,
      errorType: gtmErrorType.package_content,
      primaryActionText: 'player.redirectSelfCare',
      primaryAction: ({ history, user }) => history.push(getSelfCareURL(user)),
      secondaryActionText: 'player.errorEntitlements.goBack',
      secondaryAction: defaultAction,
    },
    {
      isThisError: () => true,
      title: 'player.error.title',
      preTitle: 'common.attention',
      description: 'player.error.description',
      errorCode: `${gtmErrorType.unexpected} Error`,
      errorType: gtmErrorType.unexpected,
    },
  ];
};

function ErrorScreen({ isLive, asset, error }) {
  const epgRedirectionData = useSelector((state) => state.epgRedirectionData) || {};
  const user = useSelector((state) => state.user || {});

  const history = useHistory();
  const { channelId } = useParams();
  const gtmRef = useRef(false);
  const tab = history.location.state?.tab;

  const { gtmUserData, gtmCollectionName, gtmProviderName } = useGtmContext();

  const logoFlag = getImage('logo') || logo;

  const {
    preTitle,
    title,
    description,
    imageSrc,
    primaryActionText,
    primaryAction,
    secondaryActionText,
    secondaryAction,
    errorCode,
    errorType,
    redirect,
  } = useMemo(
    () => getErrorMessagesAndActions()
      .find(({ isThisError }) => isThisError(error, user.account, channelId)),
    [error, user.account, channelId],
  );

  useEffect(() => {
    if (!gtmRef.current) {
      gtmRef.current = true;
      const setDimensionList = error.code === Errors.ErrorCode.NOT_IN_PACKAGE
        ? gtmDimensions.error.upsell.page_view : gtmDimensions.error.page_view;
      const { hash, eventType, dimensions } = setDimensionList;
      const dataToSend = formatContentForGtm(
        asset,
        {
          errorCode,
          errorType,
          tab,
          provider: gtmProviderName,
          collectionName: gtmCollectionName,
        },
      );
      gtmEvent({
        hash,
        eventType,
        dimensions,
        userData: gtmUserData,
        data: dataToSend,
      });
    }
  }, [
    asset,
    gtmCollectionName,
    gtmProviderName,
    gtmUserData,
    tab,
    error.code,
    errorCode,
    errorType,
  ]);

  const actionArgument = {
    user,
    asset,
    code: errorCode,
    type: errorType,
    tab,
    gtmProviderName,
    gtmCollectionName,
    gtmUserData,
    history,
    epgRedirectionData,
  };

  if (redirect && isDateInRange(PPV_START_DATE, PPV_END_DATE)) {
    window.location = redirect;
    return '';
  }

  return (
    <div className="dtv-player-error-page">
      <div className="dtv-error-content dtv-text-center">
        <img
          className="dtv-blackout-error-logo"
          src={imageSrc || logoFlag}
          alt="Logo"
        />

        {preTitle && (
          <p className="dtv-error-pretitle">
            <FormattedMessage id={preTitle} />
          </p>
        )}

        <p className="dtv-error-title">
          <FormattedMessage
            id={typeof title === 'string' ? title : title.id}
            values={title.getValues?.({ asset, isLive, error })}
          />
        </p>
        {
          description && (
            <p className="dtv-error-description">
              <FormattedMessage id={description} />
            </p>
          )
        }

        <ul className="dtv-error-content--btn_group">
          <li>
            <button
              type="button"
              className="dtv-btn-primary dtv-btn-error-screen"
              onClick={() => (primaryAction || defaultAction)(actionArgument)}
            >
              <FormattedMessage id={primaryActionText || defaultActionText} />
            </button>
          </li>

          {secondaryAction && (
            <li>
              <button
                type="button"
                className="dtv-btn-secondary dtv-btn-error-screen"
                onClick={() => secondaryAction(actionArgument)}
              >
                <FormattedMessage id={secondaryActionText} />
              </button>
            </li>
          )}
        </ul>

      </div>
    </div>
  );
}

ErrorScreen.propTypes = {
  isLive: PropTypes.bool,
  asset: PropTypes.shape({}),
  error: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

ErrorScreen.defaultProps = {
  isLive: false,
  asset: undefined,
};

export default ErrorScreen;
