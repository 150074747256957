/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import './DockButton.scss';

/**
 * Component DockButton
 * @description Component to display buttons on dock to preserve original styles
 */
function DockButton({
  controlText, iconClassName, type, className, onClick,
}) {
  return (
    <button
      /* eslint-disable-next-line react/button-has-type
        -- type have the PropType restricted to allowed values */
      type={type || 'button'}
      className={`dtv-dock-control dtv-dock-button ${className}`}
      title={controlText}
      onClick={onClick}
    >
      {iconClassName && <span className={iconClassName} aria-hidden="true" />}
      <span className="dtv-dock-control-text" aria-live="polite">
        {controlText}
      </span>
    </button>
  );
}

DockButton.propTypes = {
  controlText: PropTypes.string,
  iconClassName: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

DockButton.defaultProps = {
  controlText: '',
  iconClassName: null,
  type: 'button',
  className: '',
};

export default DockButton;
