/* External */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';

/* Ott-common */
import { Errors, Npaw } from '@dtvgo/player-utils';
import { LiveInfoProvider, useHandlePlayerError } from '@dtvgo/player-utils-react';
import { useFlag } from '@dtvgo/react-utils';

/* Components */
import VrioBasePlayer from './VrioBasePlayer';
import PlayerUI from '../PlayerUI';
import NpawMonitoringContainer from '../NpawMonitoring.container';
import PlayerGtmTrack from '../PlayerGtmTrack';
import ErrorScreen from '../errorScreen/ErrorScreen';

/* Other */
import { api } from '../../state/configureStore';
import { PLAYER_SCHEDULES_EVENT_TYPE } from '../../utils/env';
import { NPAW_PLATFORM } from '../../utils';
import { useLoadOnChromecastConnect, useParentalControlError } from '../utils/hooks';
import { parentalControlRedirect } from '../utils';

const useLazySchedules = PLAYER_SCHEDULES_EVENT_TYPE
  ? api.useLazyGetSchedulesByEventTypeQuery
  : api.useLazyGetSchedulesQuery;

function VrioLivePlayer({
  match: {
    params: { channelId: currentChannelId },
  },
}) {
  const { channel } = api.useGetChannelsQuery(
    { assetToken: false },
    {
      selectFromResult: ({ data, ...result }) => ({
        channel: data?.channels?.find(({ channelId }) => channelId === currentChannelId),
        ...result,
      }),
    },
  );

  const [
    fetchSchedules,
    { schedules },
  ] = useLazySchedules({
    selectFromResult: ({ data, isFetching }) => ({
      isFetching,
      schedules: data?.[0]?.schedules.map((schedule) => ({
        ...schedule,
        live: {
          ...schedule.live,
          channelLogo: channel?.images,
        },
      })),
    }),
  });

  const [currentScheduleIndex, setCurrentScheduleIndex] = useState(0);
  const [showPlayButton, { enable: displayPlayButton, disable: hidePlayButton }] = useFlag(false);
  const [readyToPlay, { enable: handleReadyToPlay }] = useFlag(false);
  const [authorizerResponse, setAuthorizerResponse] = useState({});
  const {
    error, reportableError, handleError, handleReportableError,
  } = useHandlePlayerError();
  const npawError = useMemo(
    () => Npaw.errorParser(NPAW_PLATFORM, reportableError),
    [reportableError],
  );

  const currentSchedule = schedules?.[currentScheduleIndex];
  const nextSchedule = schedules?.[currentScheduleIndex + 1];

  const updateSchedules = useCallback(async (channelId) => {
    await fetchSchedules(
      PLAYER_SCHEDULES_EVENT_TYPE ? {
        channelId,
        eventType: 'now-next',
        pageSize: 2,
        assetToken: false,
      } : {
        channelId: [channelId],
        pageSize: 3,
        startTime: new Date(),
        assetToken: false,
      },
    );

    setCurrentScheduleIndex(0);
  }, [fetchSchedules]);

  const handleProgress = useCallback((progress) => {
    if (
      currentSchedule?.live?.endTime
        && Date.now() + progress >= new Date(currentSchedule?.live?.endTime)
    ) {
      setCurrentScheduleIndex((prevScheduleIndex) => prevScheduleIndex + 1);
    }
  }, [currentSchedule?.live?.endTime]);

  const gtmRef = useRef();

  useEffect(() => {
    updateSchedules(currentChannelId);
  }, [currentChannelId, updateSchedules]);

  useEffect(() => {
    if (currentSchedule?.live?.blackout) {
      handleReportableError(Errors.getBlackoutError(currentSchedule));
    }
  }, [currentSchedule, handleReportableError]);

  useParentalControlError(currentSchedule, handleReportableError);
  useLoadOnChromecastConnect(currentChannelId, true);

  if (error) {
    if (error.category === Errors.ErrorCategory.PARENTAL) {
      parentalControlRedirect(currentSchedule);
      return null;
    }
    return (
      <ErrorScreen isLive asset={currentSchedule} error={error} />
    );
  }

  return (
    <VrioBasePlayer
      isLive
      playbackId={currentChannelId}
      asset={currentSchedule}
      onReadyToPlay={handleReadyToPlay}
      onProgress={handleProgress}
      onPlay={hidePlayButton}
      onAutoplayFailed={displayPlayButton}
      onError={handleError}
      onAuthorizerResponseReceived={setAuthorizerResponse}
      onAuthorizerError={handleReportableError}
    >
      <NpawMonitoringContainer
        isLive
        asset={currentSchedule}
        channelId={authorizerResponse.channelId}
        provider={authorizerResponse.provider}
        error={npawError}
      />
      <LiveInfoProvider schedule={currentSchedule?.live}>
        {readyToPlay && <PlayerGtmTrack isLive asset={currentSchedule} ref={gtmRef} />}
        <PlayerUI
          isLive
          asset={currentSchedule}
          readyToPlay={readyToPlay}
          nextAsset={nextSchedule}
          displayAreYouStillWatching
          showPlayButton={showPlayButton}
          gtmInteraction={gtmRef.current?.gtmInteraction}
        />
      </LiveInfoProvider>
    </VrioBasePlayer>
  );
}

VrioLivePlayer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      channelId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default VrioLivePlayer;
