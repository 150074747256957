/* External */
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

/* Ott-common */
import Button from '@dtvgo/button';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Other */
import ContentBlock from '@/assets/theme/icons/content-block.svg';
import IconBack from '../../assets/icons/iconBack.svg';

/* Componentes */
import NavBarProfile from '../../navBarProfile/NavBarProfile';
import BackButton from '../../components/backButton/BackButton';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/profile';

/* Utils */
import {
  PATHS,
  getGtmEventConfig,
  formatContentForGtm,
  userGtmEventHandler,
  gtmErrorType,
} from '../../utils';

/* Styles */
import './ProfileContentBlock.scss';
import { getImage } from '../../theme/images';

function ProfileContentBlock({
  intl: { formatMessage },
  history,
}) {
  const location = useLocation();
  const redirectFrom = location.state?.redirectFrom || '';
  const lastContent = location.state?.lastContent || '';
  const assetBlocked = location.state?.asset;
  const [buttonText, setButtonText] = useState('');
  const [subTitleText, setSubTitleText] = useState('');

  const {
    gtmUserData,
    gtmPlaySource,
    gtmSectionName,
    gtmComponentType,
    gtmCarouselVerticalPosition,
  } = useGtmContext();

  useEffect(() => {
    if (redirectFrom === 'miniEPG') {
      setButtonText(formatMessage({ id: 'profiles.userProfile.profilesErrorPage.backChannel', defaultMessage: 'Volver al canal anterior' }));
      setSubTitleText(formatMessage({ id: 'profiles.userProfile.profilesErrorPage.parental.subtitle.miniEPG', defaultMessage: 'Volver al canal anterior' }));
    } else if (redirectFrom === 'liveContent') {
      setButtonText(formatMessage({ id: 'profiles.userProfile.profilesErrorPage.showProgramation', defaultMessage: 'Mostrar programación' }));
      setSubTitleText(formatMessage({ id: 'profiles.userProfile.profilesErrorPage.parental.subtitle.live', defaultMessage: 'Puedes elegir otro contenido para ver en este perfil.' }));
    } else {
      setButtonText(formatMessage({ id: 'profiles.userProfile.profilesErrorPage.buttonOk', defaultMessage: 'OK entendí' }));
    }
  }, [formatMessage, redirectFrom]);

  useEffect(() => {
    try {
      const { hash, dimensions, eventType } = getGtmEventConfig(
        assetBlocked.type,
        gtmDimensions,
        'profile',
        'page_view',
      );

      const dataToSend = formatContentForGtm(
        assetBlocked,
        {
          playSource: gtmPlaySource,
          sectionName: gtmSectionName,
          componentType: gtmComponentType,
          verticalPositionCarousel: gtmCarouselVerticalPosition,
          errorCode: gtmErrorType.parental_block_content,
          errorType: gtmErrorType.parental_block_content,
        },
      );

      gtmEvent({
        hash,
        eventType,
        dimensions,
        userData: gtmUserData,
        data: dataToSend,
      });
    } catch (error) {
      console.error(error);
    }
  }, [
    assetBlocked,
    gtmUserData,
    gtmPlaySource,
    gtmSectionName,
    gtmComponentType,
    gtmCarouselVerticalPosition,
  ]);

  function sendHome() {
    userGtmEventHandler(gtmDimensions.error.back, gtmUserData);
    history.push(PATHS.HOME);
  }

  function sendLive() {
    history.push(PATHS.LIVE);
  }

  function sendBack() {
    history.go(-3);
  }

  function handleClick() {
    userGtmEventHandler(gtmDimensions.error.ok, gtmUserData);
    if (redirectFrom === 'miniEPG') {
      history.push(lastContent, {
        redirectFrom: 'parentalBlock',
      });
    } else {
      sendBack();
    }
  }

  return (
    <div className="dtv-web-content-block">
      <NavBarProfile />
      <div className="dtv-web-content-block--container">
        <div className="dtv-web-content-block--container-arrow-back">
          <BackButton
            backIcon={<img src={IconBack} alt="arrow-back" />}
            onClick={() => sendHome()}
          />
        </div>
        <img src={getImage('iconContentBlock') || ContentBlock} alt="dtv Profile Error" className="dtv-web-content-block--profile_error" />

        <h1 className="dtv-web-content-block--container-title">
          {formatMessage({ id: 'profiles.userProfile.profilesErrorPage.parental.title.1', defaultMessage: 'Este contenido está bloqueado por el Control' })}
          <br />
          {formatMessage({ id: 'profiles.userProfile.profilesErrorPage.parental.title.2', defaultMessage: 'parental' })}
        </h1>
        <p className="dtv-web-content-block--container-subtitle">
          {subTitleText || formatMessage({ id: 'profiles.userProfile.profilesErrorPage.parental.subtitle.default', defaultMessage: 'Para verlo, debes cambiar de perfil a uno sin restricciones.' })}
        </p>
        <div className="dtv-web-content-block--buttonAlignment">
          <Button
            secondBlue
            bordered
            className="dtv-btn-primary"
            onClick={() => handleClick()}
          >
            {buttonText || `${formatMessage({ id: 'profiles.userProfile.profilesErrorPage.buttonOk', defaultMessage: 'OK entendí' })}`}
          </Button>
          {redirectFrom === 'miniEPG' ? (
            <Button
              secondBlue
              bordered
              className="dtv-btn-secondary"
              onClick={() => sendLive()}
            >
              {formatMessage({ id: 'profiles.userProfile.profilesErrorPage.showProgramation', defaultMessage: 'Mostrar programación' })}
            </Button>
          ) : ('')}
        </div>
      </div>
    </div>
  );
}

ProfileContentBlock.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    go: PropTypes.func,
  }),
};

ProfileContentBlock.defaultProps = {
  intl: {
    formatMessage: () => {},
  },
  history: {
    push: PropTypes.func,
    go: PropTypes.func,
  },
};

export default injectIntl(ProfileContentBlock);
