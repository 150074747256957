/**
 * External
 */
import { connect } from 'react-redux';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Route, Switch, NavLink } from 'react-router-dom';
import lastL from 'lodash-es/last';

/**
 * Components
 */
import GeneralContainer from './general/GeneralContainer';

/**
 * Styles
 */
import './Preferences.scss';

function Preferences({ notificationHeight = 0, ...props }) {
  const option = lastL(props.history.location.pathname.split('/'));
  const topPreferences = notificationHeight === 0 ? 200 : 200 + notificationHeight;

  return (
    <div className="dtv-preferences-container">
      <p className="dtv-preferences-title dtv-text-left">
        <FormattedMessage id="myProfile.title" defaultMessage="Configuración" />
      </p>
      <div className="dtv-preferences-content">
        <div className="dtv-options dtv-text-left" style={{ top: `${topPreferences}px` }}>
          <NavLink to="/home/preferences" className={`${option === 'preferences'}`}>
            <FormattedMessage id="myProfile.option.general" defaultMessage="General" />
          </NavLink>
        </div>
        <div className="dtv-preferences-content-body dtv-text-left mb-5">
          <Switch>
            <Route exact component={GeneralContainer} path="/home/preferences" />
          </Switch>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ notifications }) {
  const notificationHeight = notifications.get('notificationHeight');
  return {
    notificationHeight,
  };
}

export default connect(mapStateToProps, {})(injectIntl(Preferences));
