import { userConstants } from './userConstants';

export const gtmDimensions = {
  component_type: 'miniEpg',
  page_view: {
    hash: 'p-001.076.000.000.000.000',
    eventType: 'pages',
    dimensions: [
      ...userConstants.constans,
      'v_content_name',
      'v_content_type',
      'v_content_id',
      'v_genre',
      'v_channel_name',
      'v_list_position',
      'v_transmission_type',
      'v_content_age_rating',
      'v_age_restricted_content',
    ],
  },
  miniEpg_series: {
    watch: {
      hash: 'e-001.076.001.000.000.110',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_list_position',
        'v_tab',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
  },
  miniEpg_live: {
    watch: {
      hash: 'e-001.076.001.000.000.110',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_list_position',
        'v_tab',
        'v_transmission_type',
        'v_channel_id',
        'v_content_player_type',
        'v_episode_name',
        'v_episode_number',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
  },
  miniEpg_vod: {
    watch: {
      hash: 'e-001.076.001.000.000.110',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_channel_name',
        'v_season',
        'v_list_position',
        'v_tab',
        'v_transmission_type',
        'v_channel_id',
        'v_content_player_type',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
  },
};
