/**
 * External
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/**
 * Components
 */
import SeriesDetails from '../components/seriesDetails/SeriesDetails';

/**
 * Styles
 */
import './SeriePage.scss';

/**
 * Other
 */
import { history } from '../state/configureStore';

/**
 * Page with details of a series
 */
class SeriePage extends Component {
  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
  }

  /**
   * @description React lifecycle method used to get serie age data
   * @method componentDidMount
   */
  componentDidMount() {
    const { getSeriePage, match: { params: { serieId = '' } = {} } = {} } = this.props;

    if (getSeriePage) getSeriePage(serieId, '*');
  }

  /**
   * @description React lifecycle method used to clear serie page data
   * @method componentWillUnmount
   */
  componentWillUnmount() {
    const { resetSeriePage } = this.props;

    if (resetSeriePage) resetSeriePage();
  }

  /**
   * @description Private function to prevent bad history movement
   * @method goBack
   */
  goBack() {
    const { pathname } = this.props;
    if (pathname !== '') history.push(pathname);
    else history.push('/home');
  }

  render() {
    const { serieResults, serieStatus } = this.props;

    return (
      <div className="dtv-serie-page">
        <span className="dtv-icon-arrow-back" onClick={this.goBack} />
        <SeriesDetails
          serieStatus={serieStatus}
          serieResults={serieResults}
          onErrorAction={this.goBack}
          showLoader
        />
      </div>
    );
  }
}

SeriePage.propTypes = {
  pathname: PropTypes.string,
  serieStatus: PropTypes.string,
  serieResults: PropTypes.shape(),
};

SeriePage.defaultProps = {
  pathname: '',
  serieStatus: '',
  serieResults: {},
};

const mapStateToProps = ({ trackHistory: { pathname = '' } }) => ({ pathname });

export default connect(mapStateToProps)(SeriePage);
