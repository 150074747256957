/* External */
import React, { useEffect, useState, useRef } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

/* Ott-common */
import Toast from '@dtvgo/toast';
import CardProfile from '@dtvgo/card-profile';
import CardNewProfile from '@dtvgo/card-new-profile';
import Loading from '@dtvgo/loading';

/* Components */
import NavBarProfile from '../../navBarProfile/NavBarProfile';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/user';

/* Utils */
import {
  checkRedirectProfile,
  colors,
  PATHS,
  PROFILES_STORAGE,
  userGtmEventHandler,
} from '../../utils';

/* Other */
import { history } from '../../state/configureStore';
import { backendService } from '../../services';

/* Styles */
import './UserProfile.scss';

function analyticsCreateUser(gtmUserData) {
  userGtmEventHandler(gtmDimensions.multiple_profile.profiles.create_profile, gtmUserData);
}
function analyticsUserManagement(gtmUserData) {
  userGtmEventHandler(gtmDimensions.multiple_profile.profiles.manage_profile, gtmUserData);
}

function UserProfile({
  intl: { formatMessage },
}) {
  const [show, setShow] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [profiles, setProfiles] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);

  const gtmRef = useRef({
    pageview: false,
    create: false,
    edit: false,
    delete: false,
  });
  const { gtmUserData, setGtmUserData } = useGtmContext();

  function handleClick(profile, index) {
    const profileSelected = { ...profile, color: index };
    localStorage.setItem(PROFILES_STORAGE.profile, JSON.stringify(profileSelected));

    const gtmUserDataWithProfileSelected = {
      ...gtmUserData,
      v_profile_custom_age: profileSelected?.ageRating || null,
      v_admin_profile: profileSelected?.isMainProfile?.toString() || null,
      v_profile_id: profileSelected?.id || null,
      v_profile_unrated_content: profileSelected?.isNotRatedContentAllowed?.toString() || null,
    };

    userGtmEventHandler(
      gtmDimensions.multiple_profile.profiles.profile_to_watch,
      gtmUserDataWithProfileSelected,
    );

    setGtmUserData(gtmUserDataWithProfileSelected);
    history.push(PATHS.HOME);
  }

  useEffect(() => {
    setLoading(true);
    backendService.customer.v1.getProfiles().then((res) => {
      setProfiles(res.data);
      const checkProfile = checkRedirectProfile(res.data);
      localStorage.setItem(PROFILES_STORAGE.profiles, JSON.stringify(res.data));
      if (checkProfile) {
        setRedirect(checkProfile);
      }
      setLoading(false);
      return null;
    }).catch((error) => {
      history.push(PATHS?.ERROR, {
        source: 'MIDDLEWARE',
        location: 'LOGIN',
        type: 'UNEXPECTED',
        externCode: error?.response?.status,
      });
    });
  }, []);

  useEffect(() => {
    if (profiles && location.state?.create && !gtmRef.current.create) {
      gtmRef.current.create = true;
      successToast();
      setSuccessText(formatMessage({ id: 'profiles.userProfile.createProfile.success', defaultMessage: '¡Creaste tu perfil correctamente!' }));
      if (profiles?.length === 1) {
        userGtmEventHandler(
          gtmDimensions.first_access.first_profile_creation.success.page_view,
          gtmUserData,
          {
            content: {
              changeParentalControl: 'true',
            },
          },
        );
      }
      if (profiles?.length > 1) {
        userGtmEventHandler(
          gtmDimensions.multiple_profile.create_profile.success.page_view,
          gtmUserData,
          {
            content: {
              changeParentalControl: 'true',
            },
          },
        );
      }
    }

    if (location.state?.edit && !gtmRef.current.edit) {
      gtmRef.current.edit = true;
      successToast();
      setSuccessText(formatMessage({ id: 'profiles.userProfile.editProfile.success', defaultMessage: '¡Editaste tu perfil!' }));
      userGtmEventHandler(
        gtmDimensions.multiple_profile.edit_profile.success.page_view,
        gtmUserData,
        {
          content: {
            changeParentalControl: 'true',
          },
        },
      );
    }

    if (location.state?.delete && !gtmRef.current.delete) {
      gtmRef.current.delete = true;
      successToast();
      setSuccessText(formatMessage({ id: 'profiles.userProfile.deleteProfile.success', defaultMessage: '¡Eliminaste un perfil!' }));

      userGtmEventHandler(
        gtmDimensions.multiple_profile.delete_profile.success.page_view,
        gtmUserData,
        {
          content: {
            changeParentalControl: 'true',
          },
        },
      );
    }

    if (profiles && gtmUserData && !gtmRef.current.pageview) {
      gtmRef.current.pageview = true;
      userGtmEventHandler(
        gtmDimensions.multiple_profile.profiles.page_view,
        gtmUserData,
      );
    }
  }, [
    dispatch,
    location,
    formatMessage,
    profiles,
    gtmUserData,
  ]);

  function successToast() {
    setShow(true);
    setTimeout(() => setShow(false), 3000);
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (loading) {
    return (
      <div className="dtv-web-user-profile">
        <Loading className="dev-web-user-profile__loading" />
      </div>
    );
  }

  return (
    <div className="dtv-web-user-profile">
      <Toast show={show} text={successText} type="success" />
      <NavBarProfile />
      <div className="dtv-web-user-profile__main">
        <div className="dtv-web-user-profile__management">
          <h2 className="dtv-web-user-profile__title">{formatMessage({ id: 'profiles.userProfile.whoIsWatching.text', defaultMessage: '¿Quién está viendo?' })}</h2>
          <div className="dtv-web-user-profile__card">
            {profiles?.map((profile, index) => (
              <div
                className="dtv-web-user-profile__card-tabindex"
                key={profile.id}
              >
                <CardProfile
                  className="dtv-web-user-profile__card-logo"
                  key={profile.id}
                  name={profile.name.slice(0, 20)}
                  color={colors[index + 1]}
                  onClick={() => { handleClick(profile, index); }}
                />
              </div>
            ))}

            { profiles?.length < 5
              ? (
                <Link
                  to={{ pathname: PATHS.CREATE_PROFILE, length: profiles.length }}
                  onClick={() => analyticsCreateUser(gtmUserData)}
                >
                  <CardNewProfile text={formatMessage({ id: 'profiles.userProfile.createProfile.text', defaultMessage: 'Crear perfil' })} className="dtv-web-user-profile--add" />
                </Link>
              )
              : '' }
          </div>
          <Link
            to={PATHS.PROFILE_MANAGEMENT}
            onClick={() => analyticsUserManagement(gtmUserData)}
          >
            <button className="dtv-btn-secondary dtv-web-user-profile__button" type="submit">
              {formatMessage({ id: 'profiles.userProfile.managerProfile.text', defaultMessage: 'Editar perfiles' })}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

UserProfile.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

UserProfile.defaultProps = {
  intl: {
    formatMessage: () => {},
  },
};

export default injectIntl(UserProfile);
