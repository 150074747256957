// External
import React from 'react';
import PropTypes from 'prop-types';

// Components
import AgeRating from '../../../components/ageRating/AgeRating';

// Styles
import './DockAgeRating.scss';

function DockAgeRating({ rating = '', ratingDescription = '', ratingWarning = [] }) {
  if (rating) {
    return (
      <div className="dtv-dock-age-rating">
        <AgeRating rating={rating} />
        <div>
          <p className="dtv-dock-age-rating__rating-description">{ratingDescription}</p>
          <p className="dtv-dock-age-rating__rating-warning">{ratingWarning?.join(', ')}</p>
        </div>
      </div>
    );
  }

  return null;
}

DockAgeRating.propTypes = {
  rating: PropTypes.string.isRequired,
  ratingDescription: PropTypes.string.isRequired,
  ratingWarning: PropTypes.shape([]).isRequired,
};

export default DockAgeRating;
