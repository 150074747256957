/* External */
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Button from '@dtvgo/button';
import { hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LOCATIONS_ERROR } from '@dtvgo/code-error';

/* Other */
import ContentBlock from '@/assets/theme/icons/content-block.svg';
import { APP_VERSION, LOGOUT_URL, getErrorDetails } from '../utils';

/* Styles */
import '@dtvgo/title/dist/main.css';
import '@dtvgo/code-error/dist/CodeError.scss';
import './AuthError.scss';
import { getImage } from '../theme/images';

function Error({ intl: { formatMessage } }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const code = new URLSearchParams(location.search).get('error');
  const details = new URLSearchParams(location.search).get('details');
  const status = new URLSearchParams(location.search).get('status');
  const errorLocation = new URLSearchParams(location.search).get('errorLocation') || LOCATIONS_ERROR.BACKGROUND_SERVICE;

  const { errorMessage, errorCodeWithDetails } = getErrorDetails(
    errorLocation,
    code,
    status,
    details,
  );

  const onClick = () => {
    history.push(LOGOUT_URL, { sendToLogin: true });
  };

  useEffect(() => {
    dispatch(hideLoading());
  }, [dispatch]);

  return (
    <div className="auth-error-page">
      <div>
        <img src={getImage('iconContentBlock') || ContentBlock} alt="dtv Auth Error" className="auth-error-page--image" />
      </div>
      <h1>{formatMessage({ id: 'auth.errors.title' })}</h1>
      <p className="auth-error-page--subtitle">{formatMessage({ id: errorMessage })}</p>
      <p className="auth-error-page--code">
        Código:
        {' '}
        {errorCodeWithDetails}
      </p>
      <div className="auth-error-page--button">
        <Button
          secondBlue
          bordered
          className="dtv-btn-primary"
          onClick={onClick}
        >
          {formatMessage({ id: 'auth.errors.buttonOk' })}
        </Button>
      </div>
      <div className="auth-error-page--info">
        <div>
          {/* <p>MV010000114200000000000</p> */}
          <p>{import.meta.env.REACT_APP_DATA_DOG_APPLICATION_ID}</p>
          <p>{new Date().toISOString()}</p>
          <p>{APP_VERSION}</p>
        </div>
      </div>
    </div>
  );
}

Error.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(Error);
