/* External */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

/* Ott-common */
import ShakaPlayerCommon from '@dtvgo/shaka-player-react';
import { removePeriodsWithoutAudio } from '@dtvgo/player-utils';

/* Utils */
import {
  PLAYER_REQUEST_BACKOFF_FACTOR,
  PLAYER_REQUEST_BASE_DELAY,
  PLAYER_REQUEST_CONNECTION_TIMEOUT,
  PLAYER_REQUEST_FUZZ_FACTOR,
  PLAYER_REQUEST_MAX_ATTEMPTS,
  PLAYER_REQUEST_STALL_TIMEOUT,
  PLAYER_REQUEST_TIMEOUT,
  PLAYER_PRESENTATION_DELAY,
  IGNORE_TEXT_STREAM_FAILURES,
} from '../utils';

/* Styles */
import './ShakaPlayer.scss';

/** @type {shaka.extern.RetryParameters} */
const REQUEST_CONFIG = {
  timeout: PLAYER_REQUEST_TIMEOUT,
  stallTimeout: PLAYER_REQUEST_STALL_TIMEOUT,
  connectionTimeout: PLAYER_REQUEST_CONNECTION_TIMEOUT,
  maxAttempts: PLAYER_REQUEST_MAX_ATTEMPTS,
  baseDelay: PLAYER_REQUEST_BASE_DELAY,
  backoffFactor: PLAYER_REQUEST_BACKOFF_FACTOR,
  fuzzFactor: PLAYER_REQUEST_FUZZ_FACTOR,
};

/** @type {shaka.extern.PlayerConfiguration} */
const shakaConfig = (isLiveContent) => ({
  streaming: {
    autoLowLatencyMode: true,
    retryParameters: REQUEST_CONFIG,
    ignoreTextStreamFailures: IGNORE_TEXT_STREAM_FAILURES,
  },
  manifest: {
    retryParameters: REQUEST_CONFIG,
    defaultPresentationDelay: PLAYER_PRESENTATION_DELAY,
    dash: {
      manifestPreprocessor: removePeriodsWithoutAudio,
      ignoreSuggestedPresentationDelay: !!PLAYER_PRESENTATION_DELAY,
    },
    // Workaround for https://github.com/shaka-project/shaka-player/issues/5482
    // It happens with Paramount+ content that only uses HLS manifest (See jira VP-956)
    // Other content use DASH
    // Remove when Shaka fix it
    // Rethink if we need to use HLS manifest for non Paramount+ content
    hls: {
      sequenceMode: false,
      ignoreManifestTimestampsInSegmentsMode: !isLiveContent,
    },
  },
  drm: {
    retryParameters: REQUEST_CONFIG,
  },
});

const ShakaPlayer = forwardRef(({
  className,
  isLive,
  source,
  keySystemsConfig,
  bufferConfig,
  isCatchUpActive,
  lowLatency,
  recovery,
  npawAdapterOptions,
  onAutoplayFailed,
  onBuffering,
  onBufferingEnded,
  onDurationChange,
  onEnded,
  onFailedLicense,
  onFullScreenMode,
  onHDCPError,
  onNonPlayableSources,
  onPause,
  onPlay,
  onPlaying,
  onProgress,
  onReadyToPlay,
  onSeeked,
  onSeeking,
  onTracksEvent,
  onUnrecoverableError,
  onVolumeChanged,
  onSourceLoaded,
  shouldPlay,
  startOffset,
  readyToBuild,
  onRequest,
  onResponseReceived,
  children,
}, ref) => (
  <ShakaPlayerCommon
    className={className}
    isLive={isLive}
    source={source}
    keySystemsConfig={keySystemsConfig}
    startOffset={startOffset}
    shouldPlay={shouldPlay}
    readyToBuild={readyToBuild}
    config={shakaConfig(isLive)}
    bufferConfig={bufferConfig}
    lowLatency={lowLatency}
    isCatchUpActive={isCatchUpActive}
    recovery={recovery}
    npawAdapterOptions={npawAdapterOptions}
    onSourceLoaded={onSourceLoaded}
    onReadyToPlay={onReadyToPlay}
    onPlay={onPlay}
    onPlaying={onPlaying}
    onPause={onPause}
    onProgress={onProgress}
    onDurationChange={onDurationChange}
    onBuffering={onBuffering}
    onBufferingEnded={onBufferingEnded}
    onSeeking={onSeeking}
    onSeeked={onSeeked}
    onTracksEvent={onTracksEvent}
    onFullScreenMode={onFullScreenMode}
    onVolumeChanged={onVolumeChanged}
    onEnded={onEnded}
    onRequest={onRequest}
    onResponseReceived={onResponseReceived}
    onAutoplayFailed={onAutoplayFailed}
    onFailedLicense={onFailedLicense}
    onHDCPError={onHDCPError}
    onNonPlayableSources={onNonPlayableSources}
    onUnrecoverableError={onUnrecoverableError}
    ref={ref}
  >
    {children}
  </ShakaPlayerCommon>
));

ShakaPlayer.propTypes = {
  className: PropTypes.string,
  isLive: PropTypes.bool,
  bufferConfig: PropTypes.shape({
    forward: PropTypes.number,
    behind: PropTypes.number,
  }),
  keySystemsConfig: PropTypes.shape({
    widevine: PropTypes.shape(),
    playready: PropTypes.shape(),
  }),
  lowLatency: PropTypes.shape({
    targetLatency: PropTypes.number,
    catchup: PropTypes.shape({
      playbackRate: PropTypes.number,
      playbackRateThreshold: PropTypes.number,
      seekThreshold: PropTypes.number,
    }),
    fallback: PropTypes.shape({
      playbackRate: PropTypes.number,
      playbackRateThreshold: PropTypes.number,
      seekThreshold: PropTypes.number,
    }),
  }),
  recovery: PropTypes.shape({
    asWarning: PropTypes.shape({ errorCodes: PropTypes.instanceOf(Set) }),
    reload: PropTypes.shape({
      errorCodes: PropTypes.instanceOf(Set),
      shouldReload: PropTypes.func,
      backoffOptions: PropTypes.shape({
        maxAttempts: PropTypes.number,
        baseDelay: PropTypes.number,
        backoffFactor: PropTypes.number,
        fuzzFactor: PropTypes.number,
        resetTime: PropTypes.number,
      }),
    }),
  }),
  npawAdapterOptions: PropTypes.shape({}),
  isCatchUpActive: PropTypes.bool,
  onAutoplayFailed: PropTypes.func,
  onBuffering: PropTypes.func,
  onBufferingEnded: PropTypes.func,
  onDurationChange: PropTypes.func,
  onEnded: PropTypes.func,
  onFailedLicense: PropTypes.func,
  onFullScreenMode: PropTypes.func,
  onHDCPError: PropTypes.func,
  onNonPlayableSources: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onPlaying: PropTypes.func,
  onProgress: PropTypes.func,
  onReadyToPlay: PropTypes.func,
  onSeeked: PropTypes.func,
  onSeeking: PropTypes.func,
  onTracksEvent: PropTypes.func,
  onUnrecoverableError: PropTypes.func,
  onVolumeChanged: PropTypes.func,
  onSourceLoaded: PropTypes.func,
  shouldPlay: PropTypes.bool,
  source: PropTypes.shape({
    dash: PropTypes.string,
    hls: PropTypes.string,
    isLowLatency: PropTypes.bool,
  }),
  startOffset: PropTypes.number,
  readyToBuild: PropTypes.bool,
  onRequest: PropTypes.func,
  onResponseReceived: PropTypes.func,
  children: PropTypes.node,
};

const noop = () => {};
ShakaPlayer.defaultProps = {
  className: '',
  isLive: false,
  bufferConfig: undefined,
  lowLatency: undefined,
  isCatchUpActive: undefined,
  keySystemsConfig: undefined,
  recovery: undefined,
  npawAdapterOptions: undefined,
  onAutoplayFailed: noop,
  onBuffering: noop,
  onBufferingEnded: noop,
  onDurationChange: noop,
  onEnded: noop,
  onFailedLicense: noop,
  onFullScreenMode: noop,
  onHDCPError: noop,
  onNonPlayableSources: noop,
  onPause: noop,
  onPlay: noop,
  onPlaying: noop,
  onProgress: noop,
  onReadyToPlay: noop,
  onSeeked: noop,
  onSeeking: noop,
  onTracksEvent: noop,
  onUnrecoverableError: noop,
  onVolumeChanged: noop,
  onSourceLoaded: noop,
  shouldPlay: false,
  source: null,
  startOffset: undefined,
  readyToBuild: true,
  onRequest: noop,
  onResponseReceived: noop,
  children: null,
};

export default ShakaPlayer;
