/* External */
import React from 'react';
import { injectIntl } from 'react-intl';
import Loading from '@dtvgo/loading';

/* Styles */
import './EpgLoader.scss';

function EpgLoader({ intl: { formatMessage } }) {
  return (
    <div className="dtv-epg-loader">
      <Loading />
      <span className="dtv-epg-loader__text">
        {formatMessage({
          id: 'epg.loadingChannels',
          defaultMessage: 'Loading more channels',
        })}
      </span>
    </div>
  );
}

export default injectIntl(EpgLoader);
