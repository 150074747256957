/* External */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import getL from 'lodash-es/get';
import { connect } from 'react-redux';

/* Ott-common */
import Toast from '@dtvgo/toast';
import Loading from '@dtvgo/loading';

/* Components */
import Lander from './landers/Lander';
import SeeAllPage from './seeAllPage/SeeAllPage';
import CollectionPage from './CollectionPage';
import Preferences from '../userMenu/settings/Preferences';
import Footer from '../components/footer/Footer';
import MenuBar from '../components/menuBar/MenuBar';
import SeriePage from '../seriePage/SeriePage';
import Pixel from '../components/pixel/pixel';
import Pdp from '../pdp/Pdp';
import MyList from './myList/MyList';
import Epg from '../epg/Epg';
import HomeModal from './homeModal/HomeModal';

/* Actions */
import {
  setNotificationHeight as setNotificationHeightAction,
  showBarNotification as showBarNotificationAction,
} from '../state/notifications/actions';
import {
  showModal as showModalAction,
  hideModal as hideModalAction,
} from '../state/modal/actions';

/* Styles */
import './Home.scss';

/* Other */
import {
  BROWSER,
  getBrowser,
  getSelfCareURL,
  getSelfCareLinkAction,
  getURLWithParams,
  isFrontPorchUser,
  isValidRoute,
  showMyListFlag,
  LANDERS_IDS,
  PATHS,
  ROUTES,
  validateDeviceToShowPixel,
  STATIC_URLS,
  EPG,
  landerUris,
  USER_ACCOUNT_TYPES,
  PROFILES_STORAGE,
  featureFlagParentalControl,
} from '../utils';

/* Constants */
const MODAL_LANDERS = { ALL: 'all', SPORTS: 'sports' };

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      browserNotification: false,
      countryNotification: false,
      frontPorchNotification: false,
      granHermanoNotification: false,
      showPixel: false,
      noNotification: false,
      isPushNotificationModalOpen: false,
      loadingSKY: false,
    };

    this.showModalInCurrentLander = this.showModalInCurrentLander.bind(this);
    this.hidePushNotificationModal = this.hidePushNotificationModal.bind(this);
  }

  componentDidMount() {
    const {
      deviceType,
      isFirstLoginWithPlan,
      isTVE,
      history,
      match: { params: { section = 'main' } = {} } = {},
      browserNotification,
      countryNotification,
      frontPorchNotification,
      granHermanoNotification,
      noNotification,
      location,
    } = this.props;
    const {
      home: { validRouteParams: homeValidRouteParams },
      errorPage,
    } = ROUTES;
    if (section && !isValidRoute(section, homeValidRouteParams)) {
      history.replace(errorPage.path);
    }
    const showToast = true;
    const TOAST_SHOW_TIME_MS = 5000;

    window.addEventListener('resize', this.setNotificationHeight.bind(this));

    if (!isTVE && isFirstLoginWithPlan) {
      this.setState({
        showPixel: validateDeviceToShowPixel(deviceType),
      });
    }

    activateNotificationDiv();

    const isShowingSomeNotification = frontPorchNotification
      || countryNotification
      || browserNotification
      || granHermanoNotification
      || noNotification;
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!isShowingSomeNotification) {
      this.showMainNotification();
    }

    if (featureFlagParentalControl() && showToast) {
      this.UpdateToastText();
      this.setState({ showToast: location.state?.toastParentalBlock });
      setTimeout(() => {
        this.setState({ showToast: false });
      }, TOAST_SHOW_TIME_MS);
    }

    if (!browserNotification && getBrowser() === BROWSER.SAFARI) {
      this.showBrowserNotification();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      barNotification: { show },
      match: { params: { section = 'main' } = {} } = {},
    } = this.props;
    const {
      barNotification: { messageId: prevMessageId },
      match: { params: { section: prevSection = '' } = {} } = {},
    } = prevProps;
    const {
      browserNotification,
      countryNotification,
      frontPorchNotification,
      granHermanoNotification,
      noNotification,
      isPushNotificationModalOpen: isModalVisible,
    } = this.state;

    if (!show && browserNotification && countryNotification && frontPorchNotification) {
      if (isFrontPorchUser()) {
        this.setState({
          countryNotification: false,
          browserNotification: false,
        });
        this.showFrontPorchNotification();
      }
      if (prevMessageId === 'countriesScope.contentRestricted') {
        this.setState({
          countryNotification: false,
          frontPorchNotification: false,
        });

        this.showBrowserNotification();
      }

      if (prevMessageId === 'browser.downloadChromeOrFirefox') {
        this.setState({
          browserNotification: false,
          frontPorchNotification: false,
        });
      }
    }

    this.setNotificationHeight();

    const sectionHasChanged = section !== prevSection;
    if (
      isModalVisible && sectionHasChanged && !this.showModalInCurrentLander()
    ) this.hidePushNotificationModal();
    const isShowingSomeNotification = frontPorchNotification
      || countryNotification
      || browserNotification
      || granHermanoNotification
      || noNotification;
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!isShowingSomeNotification) {
      this.showMainNotification();
    }
  }

  /**
   * @description Method that call the setNotificationHeight from the redux with the notification
   * and nav bar elements
   * @method setNotificationHeight
   */
  setNotificationHeight() {
    const {
      barNotification: { show },
      setNotificationHeight,
    } = this.props;
    const menuNavBarElement = document.getElementsByClassName('dtv-nav-bar-container').length > 0
      ? document.getElementsByClassName('dtv-nav-bar-container')
      : document.getElementsByClassName('dtv-nav-bar-mobile-container');
    const menuNavBarHeight = menuNavBarElement[0] ? menuNavBarElement[0].offsetHeight - 1 : 64;
    let notificationHeight = 0;

    if (show) {
      const barNotification = document.getElementsByClassName('dtv-bar-notification')[0];
      notificationHeight = barNotification && barNotification.offsetHeight - 1;
    }

    setNotificationHeight({
      notificationHeight,
      navBarHeight: menuNavBarHeight,
    });
  }

  /**
   * @method showModalInCurrentLander
   * @description Checks if notifications modal should be shown in current lander
   */
  showModalInCurrentLander() {
    const {
      modalConfiguration: {
        title: modalTitle, description: modaldescription, show: modalLanderToShow,
      } = {},
      match: { params: { section = '' } = {} } = {},
    } = this.props;
    // If AEM has no curated data, ask for notifications in any lander
    if (
      modalTitle === undefined && modaldescription === undefined && modalLanderToShow === undefined
    ) return true;

    return (
      !!validateModalParams(modalTitle, modaldescription, modalLanderToShow)
      && modalLanderToShow.length > 0
      && (modalLanderToShow === MODAL_LANDERS.ALL || modalLanderToShow === section)
    );
  }

  hidePushNotificationModal() {
    const { hideModal } = this.props;
    hideModal();
  }

  /**
   * @description Show main notification component.
   * @method showMainNotification
   */
  showMainNotification() {
    const { allowedCountry, user: { account: { businessUnit = '' } = {} } = {} } = this.props;
    const currentBrowser = getBrowser();

    if (this.showSearch) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    if (isFrontPorchUser()) {
      this.showFrontPorchNotification();
    } else if (
      !allowedCountry
      && businessUnit !== USER_ACCOUNT_TYPES.B2B2C
      && businessUnit !== USER_ACCOUNT_TYPES.SKY_B2B2C
    ) {
      this.showCountryNotification();
    } else if (currentBrowser !== BROWSER.CHROME && currentBrowser !== BROWSER.FIREFOX) {
      this.showBrowserNotification();
    } else {
      this.setState({ noNotification: true });
    }
  }

  /**
   * @description Method that shows a bar notification when the user don't have any entitlements.
   * @method showFrontPorchNotification
   */
  showFrontPorchNotification() {
    const { showBarNotification, user } = this.props;

    this.setState({
      frontPorchNotification: true,
    });
    const selfCareUrl = getSelfCareURL(user, {});
    const selfCareLinkAction = getSelfCareLinkAction(this.props);
    const actions = [
      {
        props: {
          className: 'dtv-notification-link dtv-btn-secondary',
          href: selfCareUrl,
          onClick: selfCareLinkAction,
          target: '_self',
        },
        textId: 'frontPorch.buttonText',
        text: 'ADMINISTRAR MI SUSCRIPCIÓN',
        type: 'a',
      },
    ];

    showBarNotification({
      show: true,
      titleId: 'frontPorch.title',
      title: 'Estás viviendo una experiencia gratuita de DIRECTV GO',
      messageId: 'frontPorch.description',
      message: 'Necesitas una suscripción activa para ver nuestro contenido completo.',
      type: 'info',
      actions,
      close: false,
    });
  }

  /**
   * @description Method that shows a bar notification informing that Safari is not supported.
   * @method showBrowserNotification
   */
  showBrowserNotification() {
    const { showBarNotification } = this.props;

    const actions = [
      {
        props: {
          className: 'dtv-notification-button',
          href: getURLWithParams(STATIC_URLS.BROWSER_DOWNLOAD.chrome),
          target: '_blank',
        },
        textId: 'browser.downloadChrome',
        text: 'Descargar Chrome',
        type: 'a',
      },
      {
        props: {
          className: 'dtv-notification-button',
          href: getURLWithParams(STATIC_URLS.BROWSER_DOWNLOAD.firefox),
          target: '_blank',
        },
        textId: 'browser.downloadFirefox',
        text: 'Descargar Firefox',
        type: 'a',
      },
    ];

    showBarNotification({
      show: true,
      titleId: 'browser.safariEdgeAndIEIsNotSupported',
      title: 'Para tener una experiencia óptima con DIRECTV GO te sugerimos que descargues Chrome o Firefox.',
      messageId: '',
      message: '',
      type: 'info',
      actions,
    });
  }

  /**
   * @description Method that shows a bar notification informing that the country is outside
   *              of the coverage region
   * @method showCountryNotification
   */
  showCountryNotification() {
    const { showBarNotification } = this.props;

    this.setState({
      countryNotification: true,
    });

    showBarNotification({
      show: true,
      titleId: 'countriesScope.outsideOfCoverageArea',
      title: 'Te encuentras fuera de la región de cobertura.',
      messageId: 'countriesScope.contentRestricted',
      message:
        'DIRECTV Go puede ser usado solo dentro de algunos países, por lo que no podrás ver contenido desde este país. Si quieres administrar tu suscripción, puedes hacerlo como siempre desde tu perfil.',
      containerId: 'messageLoginBar',
    });
  }

  UpdateToastText() {
    const { intl: { formatMessage } } = this.props;
    const newText = formatMessage({
      id: 'profiles.userProfile.profilesErrorPage.toast.title',
      defaultMessage: 'Contenido bloqueado por Control Parental',
    });
    const newSubText = formatMessage({
      id: 'profiles.userProfile.profilesErrorPage.toast.subtitle',
      defaultMessage: 'Pero, puedes ver otros contenidos en este perfil.',
    });

    this.setState({ toastText: newText });
    this.setState({ toastSubText: newSubText });
  }

  render() {
    const { showPixel } = this.state;
    const { notificationHeight, navBarHeight } = this.props;
    const paddingTop = notificationHeight + navBarHeight > 65
      ? notificationHeight + navBarHeight : 65;
    const initialPadding = `${paddingTop}px`;
    const { toastText } = this.state;
    const { toastSubText } = this.state;
    const { showToast } = this.state;
    const { loadingSKY } = this.state;

    const profileSelected = localStorage.getItem(PROFILES_STORAGE.profile);

    if (!profileSelected) {
      return <Redirect exact from="/home" to={PATHS.USER_PROFILE} />;
    }

    if (loadingSKY) {
      return (
        <div className="dtv-div-background__loader-screen">
          <Loading />
        </div>
      );
    }

    return (
      <>
        <Pixel showPixel={showPixel} url="CONFIRMATION" />
        <div className="dtv-div-background p-0">
          <div id="dtv-div-notifications" className="dtv-div-notifications" />
          <MenuBar />
          <HomeModal />
          <Toast show={showToast} text={toastText} subText={toastSubText} type="notification" />
          <div className="dtv-content" style={{ paddingTop: initialPadding }}>
            <Switch>
              <Redirect exact from="/home" to={PATHS.HOME} />
              <Route
                exact
                path={buildRoutePath('/home/lander/:pageId')}
                render={(props) => <Lander key={props.match.params.pageId} showLogo />}
              />
              <Route
                exact
                path={buildRoutePath(`/home/:landerUri(${landerUris.home})`)}
                render={() => (
                  <Lander key={LANDERS_IDS.HOME} pageId={LANDERS_IDS.HOME} />
                )}
              />
              <Route
                exact
                path={buildRoutePath(`/home/:landerUri(${landerUris.onDemand})`)}
                render={() => (
                  <Lander key={LANDERS_IDS.VOD} pageId={LANDERS_IDS.VOD} />
                )}
              />
              <Route
                exact
                path={buildRoutePath(`/home/:landerUri(${landerUris.sports})`)}
                render={() => (
                  <Lander key={LANDERS_IDS.SPORTS} pageId={LANDERS_IDS.SPORTS}>
                    <Epg filter={EPG.SPORTS_FILTER} isSports />
                  </Lander>
                )}
              />
              <Route
                exact
                path={buildRoutePath(`/home/:landerUri(${landerUris.kids})`)}
                render={() => (
                  <Lander key={LANDERS_IDS.KIDS} pageId={LANDERS_IDS.KIDS} />
                )}
              />
              <Route
                exact
                path={buildRoutePath(`/home/:landerUri(${landerUris.live})`)}
                render={() => (
                  <Lander key={LANDERS_IDS.LIVE} pageId={LANDERS_IDS.LIVE}>
                    <Epg />
                  </Lander>
                )}
              />
              <Route exact component={Pdp} path="/home/pdp/:assetType(live|movies|series|vod)/:assetId" />
              <Route component={Preferences} path={buildRoutePath('/home/preferences/:option?')} />
              <Route
                component={SeeAllPage}
                path={buildRoutePath('/home/:tab/carousels/:carouselId/:carouselTitle?/:carouselType?')}
              />
              <Route
                component={CollectionPage}
                path={buildRoutePath('/home/collection/:sourceCarouselType/:sourceCarouselId/:target')}
              />
              <Route exact component={SeriePage} path={buildRoutePath('/home/detailsSerie/:serieId')} />
              {showMyListFlag && (
                <Route exact component={MyList} path={buildRoutePath(PATHS.MY_LIST)} />
              )}
              <Redirect to="/404" />
            </Switch>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps({
  global, notifications, search, user, modal,
}) {
  const allowedCountry = getL(user, 'account.allowedCountry', false);
  const barNotification = notifications.get('barNotification');
  const notificationHeight = notifications.get('notificationHeight');
  const navBarHeight = notifications.get('navBarHeight');
  const isTVE = getL(user, 'account.isTVE', false);
  const isFirstLoginWithPlan = getL(user, 'account.isFirstLoginWithPlan', false);
  const deviceType = getL(user, 'account.profile.customData.deviceType', '');
  const currency = getL(user, 'account.currency', '');
  const monthlyPay = getL(user, 'account.monthlyPay', 0);
  const showSearch = getL(search, 'showSearch', false);
  const userUid = getL(user, 'account.uid', false);
  const configurationAEMStatus = getL(global, 'configurationAEM.status', '');
  return {
    allowedCountry,
    barNotification,
    notificationHeight,
    navBarHeight,
    isFirstLoginWithPlan,
    deviceType,
    isTVE,
    currency,
    monthlyPay,
    user,
    showSearch,
    userUid,
    configurationAEMStatus,
    modal,
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    showBarNotification: showBarNotificationAction,
    setNotificationHeight: setNotificationHeightAction,
    showModal: showModalAction,
    hideModal: hideModalAction,
  })(Home),
);

/**
 * @method validateModalParams
 * @description Validates modal params
 * @param {string} modalTitle
 * @param {string} modaldescription
 * @param {string} modalLanderToShow
 */
function validateModalParams(modalTitle, modaldescription, modalLanderToShow) {
  return (
    modalTitle
    && modaldescription
    && modalLanderToShow
    && Object.values(MODAL_LANDERS).includes(modalLanderToShow)
  );
}

/**
 * @description Method that builds a path array using a base path
 * @method buildRoutePath
 * @param basePath
 */
function buildRoutePath(basePath) {
  return [basePath, basePath.endsWith('/') ? `${basePath}${PATHS.SEARCH}` : `${basePath}/${PATHS.SEARCH}`];
}

/**
 * @description Activate the notification div
 * @method activateNotificationDiv
 */
function activateNotificationDiv() {
  const notificationDiv = document.getElementById('dtv-div-notifications');
  if (notificationDiv) notificationDiv.style.display = 'block';
}
