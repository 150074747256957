/* External */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/* Styles */
import './chromecastConnectionBarVolumeControl.scss';

function VolumeControl({
  intl: { formatMessage }, volumeLevel, setVolumeLevel, isMuted, muteOrUnmute,
}) {
  const isMutedOrVolume0 = isMuted || volumeLevel === 0;

  const handleChangeVolume = ({ target: { value: newVolume } }) => {
    setVolumeLevel(Number.parseFloat(newVolume));
  };

  return (
    <div className="dtv-chromecast-bar-player-volume">
      <button type="button" onClick={muteOrUnmute} title={formatMessage({ id: isMuted ? 'player.unMute' : 'player.mute' })}>
        <span className={isMutedOrVolume0 ? 'dtv-icon-volume-mute' : 'dtv-icon-volume'} />
      </button>
      <div className="dtv-chromecast-bar-player-volume-controls">
        <div className="dtv-chromecast-bar-player-volume-controls-container">
          <input
            className="dtv-chromecast-bar-player-volume-controls-range"
            min="0"
            max="1"
            onChange={handleChangeVolume}
            step="0.01"
            type="range"
            value={isMuted ? 0 : volumeLevel}
          />
        </div>
      </div>
    </div>
  );
}

VolumeControl.propTypes = {
  volumeLevel: PropTypes.number.isRequired,
  setVolumeLevel: PropTypes.func.isRequired,
  isMuted: PropTypes.bool.isRequired,
  muteOrUnmute: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(memo(VolumeControl));
