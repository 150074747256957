/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import HBSWidget from '../HBSWidget';

/* Styles */
import './SportHighlights.scss';

function SportHighlights({ match = {} }) {
  return (
    <div className="dtv-sport-highlights">
      <HBSWidget
        type="actions"
        props={{ matchId: match?.fifaMatchId }}
      />
    </div>
  );
}

SportHighlights.propTypes = {
  match: PropTypes.shape({
    fifaMatchId: PropTypes.string.isRequired,
  }).isRequired,
};

export default SportHighlights;
