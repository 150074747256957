/**
 * External
 */
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import throttleL from 'lodash-es/throttle';
import createSagaMiddleware from 'redux-saga';

/**
 * Actions
 */
import { configureStore } from '@reduxjs/toolkit';
import createApi from '@dtvgo/rtk-query-api';
import { ACTION_TYPES } from './user/actions';

/**
 * Other
 */
import { loadState, saveState, addStorageListener } from '../utils/localStorageState';
import { LOGOUT_URL } from '../utils/constants';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

import { backendService, authService, aemService } from '../services';

export const api = createApi(backendService, authService, aemService);
export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const preloadedState = loadState();

const store = configureStore({
  reducer: rootReducer(history, api),
  preloadedState,
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
      /* eslint-disable unicorn/prefer-spread
          -- Redux Toolkit suggest to use concat instead of spread operator */
      .concat(routerMiddleware(history))
      .concat(sagaMiddleware)
      .concat(api.middleware)
      /* eslint-enable unicorn/prefer-spread */
  ),
});

store.subscribe(
  throttleL(() => {
    const {
      user,
      interstitial,
      global,
    } = store.getState();

    saveState({
      global,
      interstitial,
      user,
    });
  }, 1000),
);

const dispatchStorageChange = (event) => {
  if (!event.key && !event.newValue) {
    store.dispatch({ type: ACTION_TYPES.RESET });
    history.push(LOGOUT_URL);
  }
};

addStorageListener(dispatchStorageChange);

sagaMiddleware.run(rootSaga);

export default store;
