/* External */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/* Components */
import DetailsFrame from '../../components/detailsFrame/NewDetailsFrame';
import GtmComponent from '../../components/GtmContext/GtmComponent';
import { gtmDimensions } from '../../components/GtmContext/dimensions/pdp';
import AgeRating from '../../components/ageRating/AgeRating';
import TabsPage from '../../components/TabsPage/TabsPage';
import MatchResultCard from '../../components/matchResultCard/MatchResultCard';
import DetailsTab from '../../components/seriesDetails/detailsTab/DetailsTab';
import MoreLikeThis from '../moreLikeThis/MoreLikeThis';

/* Other */
import {
  getDaySelectedText,
  getEpgShowTitle,
  joinArrayWithSeparator,
  getAssetNameWithQualifier,
  getBooleanFromEnvVariable,
  CHAMPIONSHIP_NAME,
  isMoreLikeThisActive,
} from '../../utils';
import { api } from '../../state/configureStore';

/* Constants */
const MORE_LIKE_THIS_TAB_KEY = 'moreLikeThis';
const DETAILS_TAB_KEY = 'details';

const getButtonTextId = ({
  currentTime,
  startTime,
  endTime,
  icon,
}) => {
  if (icon) return 'slider.tuneChannel.lock';

  const isLiveEvent = currentTime >= startTime
    && currentTime < endTime;

  return isLiveEvent ? 'slider.tuneChannel.live' : 'slider.tuneChannel';
};

function LiveContent({
  channelId,
  intl: { formatDate, formatMessage },
  isPublic,
  dataPublic,
}) {
  const history = useHistory();
  const { search } = useLocation();
  const [selectedTab, setSelectedTab] = useState(MORE_LIKE_THIS_TAB_KEY);
  const queryParams = new URLSearchParams(search);
  const startTimeParam = new Date(queryParams.get('startTime'));
  const {
    data: scheduleData = {},
    isLoading: isLoadingSchedules,
    isError: isAssetError,
  } = !isPublic ? api.useGetSchedulesQuery({
    channelId: [channelId],
    startTime: startTimeParam,
    endTime: null,
    pageSize: 1,
    assetToken: false,
  }) : dataPublic;

  const asset = !isPublic ? (scheduleData?.[0]?.schedules?.[0] || {})
    : (scheduleData?.channels?.[0]?.schedules?.[0] || {});

  const currentTime = new Date();
  const { live, rating, vrioAssetId } = asset || {};
  const {
    startTime, endTime, channelName, qualifiers,
  } = live || {};
  const liveEnded = new Date(endTime);
  let date = '';
  let tab;
  let widget;
  if (startTime) {
    const startDay = getDaySelectedText(startTime, formatMessage, formatDate);
    const startHour = format(new Date(startTime), 'HH:mm');
    date = `${startDay} ${startHour}`;
  }

  // Conditions to show Qatar information
  if (asset?.sport?.pageType) {
    const isPostPDPMatchResultActive = getBooleanFromEnvVariable('REACT_APP_IS_POST_PDP_MATCH_RESULT_ACTIVE');
    const isMatchOver = currentTime.getTime() > liveEnded.getTime();
    tab = <TabsPage className="dtv-collection-catalog__tabs-page" pageId={asset?.sport?.pageType} sportIds={asset?.sport?.sportIds} />;
    if (isMatchOver
      && isPostPDPMatchResultActive
      && asset.sport.championship === CHAMPIONSHIP_NAME) {
      widget = <MatchResultCard fifaMatchId={asset?.sport?.sportIds?.fifaMatchId} />;
    }
  }
  useEffect(() => {
    if (isAssetError) history.replace('/404');
  }, [history, isAssetError]);

  const pdpPlayButtonIcon = isPublic || asset.shouldDisplayLockIcon ? 'dtv-icon-lock' : 'dtv-icon-play';

  const tabs = {
    [MORE_LIKE_THIS_TAB_KEY]: formatMessage({
      id: 'live.moreLikeThis',
      defaultMessage: 'Contenidos Relacionados',
    }),
    [DETAILS_TAB_KEY]: formatMessage({
      id: 'live.details',
      defaultMessage: 'Detalles',
    }),
  };

  const hanleClickSeasonSelector = (tabKey) => {
    setSelectedTab(tabKey);
  };

  return (
    <>
      {asset?.description && (
        <GtmComponent
          assetToPlay={asset}
          config={{
            title: asset.episode.showName || asset.title,
            playSource: 'epg',
            contentPlayerType: 'channel',
            sectionName: 'epg',
          }}
          hash={gtmDimensions.pdp_live.page_view.hash}
          eventType={gtmDimensions.pdp_live.page_view.eventType}
          dimensions={gtmDimensions.pdp_live.page_view.dimensions}
        />
      )}
      <DetailsFrame
        assetId={asset?.vrioAssetId || asset?.id}
        assetToPlay={asset}
        description={asset?.description}
        fullHeight
        isLive
        isLoading={isLoadingSchedules}
        metadata={
          (rating || date || channelName) && (
            <>
              <AgeRating
                rating={rating}
              />
              <span>{joinArrayWithSeparator([date, channelName], ' • ')}</span>
            </>
          )
        }
        pdpPlayButtonIcon={pdpPlayButtonIcon}
        pdpPlayButtonText={!isPublic ? formatMessage({
          id: getButtonTextId({
            currentTime,
            startTime: startTimeParam,
            endTime: liveEnded,
            icon: asset.shouldDisplayLockIcon,
          }),
          defaultMessage: 'Sintonizar canal',
        }) : formatMessage({
          id: 'public.pdp.playButton',
          defaultMessage: 'Pruébalo gratis por {days} días',
        }, { days: 7 })}
        title={getAssetNameWithQualifier(
          qualifiers,
          getEpgShowTitle(asset, formatMessage),
          formatMessage,
        )}
        bannerBottomRight={widget}
        isPublic={isPublic}
      />
      {tab}
      {
        (isMoreLikeThisActive && !isPublic) ? (
          <>
            <div className="dtv-series-details-tab-selector">
              {Object.keys(tabs).map((tabKey) => {
                const tabText = tabs[tabKey];

                return (
                  <span
                    className={classNames({
                      active: tabKey === selectedTab,
                    })}
                    key={tabKey}
                    onClick={() => hanleClickSeasonSelector(tabKey)}
                  >
                    {tabText}
                  </span>
                );
              })}
            </div>

            {selectedTab === MORE_LIKE_THIS_TAB_KEY && vrioAssetId && (
              <MoreLikeThis contentId={vrioAssetId} />
            )}
            {selectedTab === DETAILS_TAB_KEY && (
              <DetailsTab asset={asset} />
            )}
          </>
        ) : (
          <DetailsTab asset={asset} />
        )
      }
    </>
  );
}

LiveContent.propTypes = {
  channelId: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  isPublic: PropTypes.bool,
  dataPublic: PropTypes.shape({}),
};

LiveContent.defaultProps = {
  isPublic: false,
  dataPublic: {},
};

export default injectIntl(LiveContent);
