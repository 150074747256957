/* External */
import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import {
  differenceInMinutes, isBefore, isWithinInterval, setSeconds,
} from 'date-fns';

/* Assets */
import genericCard2X3 from '@/assets/theme/images/2x3-GenericCard.png';
import genericCard4X3 from '@/assets/theme/images/4x3-GenericCard.png';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';

/* Components */
import ComboBoxFilter from '../../components/comboBoxFilter/ComboBoxFilter';
import SliderSkeleton from '../../components/sliderLoader/sliderSkeleton/SliderSkeleton';
import Card2X3 from '../../components/card2X3/Card2X3';
import Card4X3 from '../../components/card4X3/Card4X3';
import ScheduleCard from '../../components/ScheduleCard/ScheduleCard';

/* Styles */
import './SeeAllPage.scss';

/* Other */
import {
  compareStrings, goBack, ORDER_OPTIONS, CAROUSEL_TYPES, liveCarouselTypes,
} from '../../utils';
import { history, api } from '../../state/configureStore';
import useQuery from '../../utils/redirection';
import { getImage } from '../../theme/images';

const getTypeCarousel = (typeCarousel) => {
  if (typeCarousel) {
    if (Object.values(CAROUSEL_TYPES).includes(typeCarousel)) {
      for (const [key, value] of Object.entries(CAROUSEL_TYPES)) {
        if (value === typeCarousel) {
          return CAROUSEL_TYPES[key];
        }
      }
    } else {
      return CAROUSEL_TYPES.PORTRAIT;
    }
  }
  return CAROUSEL_TYPES.PORTRAIT;
};

const isLiveContent = (startTime, endTime) => {
  const currentDate = new Date();
  const start = setSeconds(new Date(startTime), 0);
  const end = setSeconds(new Date(endTime), 0);

  if (isBefore(start, end) && isWithinInterval(currentDate, { start, end })) {
    return !!(differenceInMinutes(end, currentDate) + 1);
  }
  return false;
};

function SeeAllPage({
  intl: { formatMessage },
  match: {
    params: {
      carouselId, carouselTitle, carouselType,
    },
  },
}) {
  const typeCarouselSeeAll = verifyTypeCarousel();
  const filters = useQuery();
  const { data: carousel, isFetching } = api.useGetCarouselQuery({
    sourceCarouselId: carouselId,
    source: 'Vrio',
    type: typeCarouselSeeAll,
    filters,
  });
  const isLiveCarousel = liveCarouselTypes.includes(typeCarouselSeeAll);
  const [assetsToShow, setAssetsToShow] = useState([]);
  const [orderOption, setOrderOption] = useState(ORDER_OPTIONS.A_Z);
  const sortAssets = useCallback(
    (option) => {
      if (typeCarouselSeeAll
            && (
              typeCarouselSeeAll === CAROUSEL_TYPES.LIVE_EDITORIAL
              || typeCarouselSeeAll === CAROUSEL_TYPES.NOW_NEXT
            )
      ) {
        const assets = carousel?.contents;
        const sortedAssets = [
          ...(assets || []),
        ].sort((a) => ((a === isLiveContent(a?.live?.startTime, a?.live?.endTime)) ? -1 : 1));
        setAssetsToShow(sortedAssets);
      } else {
        const assets = carousel?.contents;
        const sortedAssets = [
          ...(assets || []),
        ].sort((a, b) => compareStrings(getCardTitle(a), getCardTitle(b), option));
        setAssetsToShow(sortedAssets);
      }
    },
    [carousel, typeCarouselSeeAll],
  );

  const { data: { channels = [] } = {} } = api.useGetChannelsQuery(
    {},
    { skip: !isLiveCarousel },
  );

  function verifyTypeCarousel() {
    if (!carouselType) {
      return getTypeCarousel(carouselTitle);
    }
    return getTypeCarousel(carouselType);
  }

  useEffect(() => {
    if (isFetching) return;
    sortAssets(orderOption);
  }, [isFetching, sortAssets, orderOption]);

  /**
   * @description Maps an array of assets into an array of cards to render
   * @method getCards
   * @param {array} assets
   */
  const getCards = (assets) => assets.map((asset) => {
    if (typeCarouselSeeAll) {
      switch (typeCarouselSeeAll) {
        case CAROUSEL_TYPES.PORTRAIT:
          return (
            <Card2X3
              genericCard={getImage('2x3GenericCard') || genericCard2X3}
              infoCard={asset}
              key={asset.vrioAssetId || asset.id}
            />
          );
        case CAROUSEL_TYPES.LANDSCAPE:
          return (
            <Card4X3
              genericCard={getImage('4x3GenericCard') || genericCard4X3}
              infoCard={asset}
              isContinueWatching={false}
              key={asset.vrioAssetId || asset.id}
            />
          );
        case CAROUSEL_TYPES.LIVE_EDITORIAL:
        case CAROUSEL_TYPES.NOW_NEXT:
          return (
            <ScheduleCard
              channelName={
                channels?.find(({ channelId }) => channelId === asset.live?.channelId)?.title
              }
              infoCard={asset}
              key={asset.vrioAssetId || asset.id}
            />
          );
        default:
          return (
            <Card2X3
              genericCard={getImage('2x3GenericCard') || genericCard2X3}
              infoCard={asset}
              key={asset.vrioAssetId || asset.id}
            />
          );
      }
    } else {
      return (
        <Card2X3
          genericCard={getImage('2x3GenericCard') || genericCard2X3}
          infoCard={asset}
          key={asset.vrioAssetId || asset.id}
        />
      );
    }
  });

  function returnClassSeeAllPage(isSkeleton) {
    if (isSkeleton) {
      switch (typeCarouselSeeAll) {
        case CAROUSEL_TYPES.PORTRAIT:
          return 'card-2X3';
        case CAROUSEL_TYPES.LANDSCAPE:
        case CAROUSEL_TYPES.LIVE_EDITORIAL:
        case CAROUSEL_TYPES.NOW_NEXT:
          return 'card-4X3';
        default:
          return 'card-2X3';
      }
    } else {
      switch (typeCarouselSeeAll) {
        case CAROUSEL_TYPES.PORTRAIT:
          return 'dtv-see-all-page-cards-2x3';
        case CAROUSEL_TYPES.LANDSCAPE:
        case CAROUSEL_TYPES.LIVE_EDITORIAL:
        case CAROUSEL_TYPES.NOW_NEXT:
          return 'dtv-see-all-page-cards-channel';
        default:
          return 'dtv-see-all-page-cards-2x3';
      }
    }
  }

  const comboBoxFilterOptions = [
    {
      text: formatMessage({ id: 'genre.filter.second.alphabeticA', defaultMessage: 'A-Z' }),
      value: ORDER_OPTIONS.A_Z,
    },
    {
      text: formatMessage({ id: 'genre.filter.second.alphabeticD', defaultMessage: 'Z-A' }),
      value: ORDER_OPTIONS.Z_A,
    },
  ];

  return (
    <div className="dtv-see-all-page">
      <div className="dtv-see-all-page-header">
        <div className="dtv-see-all-page-header-title">
          <div onClick={() => goBack(history)} className="dtv-icon-arrow-back" />
          {isFetching ? (
            <div className="dtv-see-all-page-title-skt" />
          ) : (
            <h2>{carousel.title || carouselTitle}</h2>
          )}
        </div>
        <ComboBoxFilter
          defaultValue={comboBoxFilterOptions[0]}
          onChange={(text, value) => setOrderOption(value)}
          options={comboBoxFilterOptions}
          title={formatMessage({ id: 'genre.sortBy', defaultMessage: 'Ordenar por' })}
          width="200"
        />
      </div>
      {assetsToShow.length > 0 ? (
        <div className={`dtv-see-all-page-cards
          ${returnClassSeeAllPage()}`}
        >
          {getCards(assetsToShow)}
        </div>
      ) : (
        <SliderSkeleton classCard={`${returnClassSeeAllPage({ isSkeleton: true })}`} />
      )}
    </div>
  );
}

SeeAllPage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SeeAllPage);

/**
 * @description Returns the title of the card to order
 * @method getCardTitle
 * @param {object} asset
 */
function getCardTitle(asset) {
  switch (asset.type) {
    case assetTypes.episode:
      return asset.episode?.showName || '';
    default:
      return asset.title || '';
  }
}
