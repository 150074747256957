/* External */
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/* Components */
import LiveContent from './liveContent/LiveContent';
import SeriesDetails from '../components/seriesDetails/SeriesDetails';
import VodContent from './vodContent/VodContent';
import { useGtmContext } from '../components/GtmContext';

/* Styles */
import './Pdp.scss';

/* Other */
import { history } from '../state/configureStore';
import { isMoreLikeThisActive, PATHS, PDP_ASSET_TYPES } from '../utils';
import IconBack from '../assets/icons/iconBack.svg';

function Pdp({
  match: {
    params: { assetId, assetType },
  },
  intl: { formatMessage },
}) {
  const consecutivePdpCount = useSelector((state) => state.navigation.consecutivePdpCount);
  const containerRef = useRef();
  const {
    setGtmContent,
    setGtmPlaySource,
    setGtmSectionName,
    setGtmComponentType,
    setGtmCarouselVerticalPosition,
  } = useGtmContext();

  const isSecondPDP = useMemo(
    () => {
      if (!isMoreLikeThisActive) return false;
      return consecutivePdpCount >= 2;
    },
    [consecutivePdpCount],
  );

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => document.body.classList.remove('no-scroll');
  }, []);

  useEffect(() => {
    containerRef.current.scroll(0, 0);
  }, [assetId]);

  const handleClose = () => {
    setGtmContent(null);
    setGtmPlaySource(null);
    setGtmSectionName(null);
    setGtmComponentType(null);
    setGtmCarouselVerticalPosition(null);
    return consecutivePdpCount ? history.go(-consecutivePdpCount) : history.replace(PATHS.HOME);
  };

  const getContent = () => {
    switch (assetType) {
      case PDP_ASSET_TYPES.live:
        return <LiveContent key={assetId} channelId={assetId} resourceType={assetType} />;
      case PDP_ASSET_TYPES.series:
        return <SeriesDetails key={assetId} assetId={assetId} onErrorAction={handleClose} />;
      case PDP_ASSET_TYPES.movies:
      case PDP_ASSET_TYPES.vod:
        return <VodContent key={assetId} assetId={assetId} />;
      default:
        return null;
    }
  };

  const content = getContent();

  return (
    <div className="dtv-details" ref={containerRef}>
      <div className={`dtv-details-icons ${!isSecondPDP ? 'justify-end' : ''}`}>
        {isSecondPDP && (
          <button
            type="button"
            className="dtv-details-icons-back-button dtv-button with-icon"
            onClick={() => history.push(PATHS.HOME)}
          >
            <img src={IconBack} className="dtv-button-icon" alt="arrow-back" />
            <span className="dtv-button-text">
              {formatMessage({ id: 'pdp.homeButton', defaultMessage: 'Ir al inicio' })}
            </span>
          </button>
        )}

        {!isSecondPDP && (
          <span onClick={handleClose} className="dtv-details-icons-icon dtv-icon-close" />
        )}
      </div>
      <div className="dtv-details-content">{content}</div>
    </div>
  );
}

Pdp.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default injectIntl(Pdp);
