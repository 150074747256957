/**
 * External
 */
import mergeL from 'lodash-es/merge';
import setL from 'lodash-es/set';

/**
 * Actions
 */
import { ACTION_TYPES } from './actions';

/**
 * Other
 */
import { STATUS_TYPES } from '../../utils/constants';
import { clearState } from '../../utils/localStorageState';

const initialState = {
  login: {
    status: STATUS_TYPES.UNLOAD_STATE,
    error: {},
  },
  sessionToken: '',
  deviceId: '',
  account: {},
};

export default function user(state = initialState, { type, payload } = {}) {
  let newLoginValue = {};
  switch (type) {
    case ACTION_TYPES.REQUEST:
      newLoginValue = { ...state.login, status: STATUS_TYPES.INIT_STATE };
      return { ...state, login: newLoginValue };
    case ACTION_TYPES.SUCCESS:
      newLoginValue = { ...state.login, status: STATUS_TYPES.SUCCEED_STATE };
      return { ...state, login: newLoginValue };
    case ACTION_TYPES.FAILURE:
      newLoginValue = { ...state.login, status: STATUS_TYPES.FAILED_STATE, error: payload };
      return { ...state, login: newLoginValue };
    case ACTION_TYPES.SET_USER:
      return mergeL(state, payload);
    case ACTION_TYPES.UPDATE_USER: {
      setL(state, payload.property, payload.value);
      return { ...state };
    }
    case ACTION_TYPES.RESET:
      clearState();
      return initialState;
    default:
      return state;
  }
}
