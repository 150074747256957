/* External */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/* Ott-common */
import { Errors, PlaybackHeartbeatController } from '@dtvgo/player-utils';
import { TimeCounter } from '@dtvgo/utils';
import { useInitializer } from '@dtvgo/react-utils';

/* Other */
import {
  AEM_PLATFORM,
  DEFAULT_BUFFER_PARAMS,
  DEFAULT_ARE_YOU_STILL_WATCHING_PARAMS,
  PLAYER_HEARTBEAT_TIME_MINUTES,
  featureFlagParentalControl,
  contentRating,
  getProfileRating,
  getProfileContentNoClassificationAllowed,
  USE_STREAMING_V4,
  isLearnActionV4Active,
} from '../../utils';
import { api } from '../../state/configureStore';
import { backendService } from '../../services';
import { useCastActions, useIsCastConnected } from '../../components/chromecastConnectionBar';

const VOD_ARE_YOU_STILL_WATCHING_TIMEOUT_MINUTES = 2;

export function usePlaybackHeartbeatController() {
  const deviceId = useSelector((state) => state.user.deviceId);

  const streamedTimeCounter = useInitializer(() => new TimeCounter());
  const playbackHeartbeatController = useInitializer(() => new PlaybackHeartbeatController({
    backendService,
    heartbeatTimeInSeconds: PLAYER_HEARTBEAT_TIME_MINUTES * 60,
    deviceId,
    useStreamingV4: USE_STREAMING_V4,
    useStopV4: isLearnActionV4Active,
    getStreamedTime: () => streamedTimeCounter?.getElapsedSeconds(),
  }));

  return { streamedTimeCounter, playbackHeartbeatController };
}

export function useBufferConfig(isLive) {
  const { bufferConfig } = api.useGetAEMConfigurationQuery(AEM_PLATFORM, {
    selectFromResult: ({ data }) => {
      const backward = isLive
        ? data?.content?.live?.liveVideoBufferBackwardDuration
          || DEFAULT_BUFFER_PARAMS.LIVE_VIDEO_BUFFER_BACKWARD_DURATION
        : data?.content?.vod?.vodVideoBufferBackwardDuration
          || DEFAULT_BUFFER_PARAMS.VOD_VIDEO_BUFFER_BACKWARD_DURATION;

      const forward = isLive
        ? data?.content?.live?.liveVideoBufferForwardDuration
          || DEFAULT_BUFFER_PARAMS.LIVE_VIDEO_BUFFER_FORWARD_DURATION
        : data?.content?.vod?.vodVideoBufferForwardDuration
          || DEFAULT_BUFFER_PARAMS.VOD_VIDEO_BUFFER_FORWARD_DURATION;

      return {
        bufferConfig: { backward, forward },
      };
    },
  });

  return bufferConfig;
}

export function useAreYouStillWatchingTime(isLive) {
  const { liveTime } = api.useGetAEMConfigurationQuery(AEM_PLATFORM, {
    selectFromResult: ({ data } = {}) => ({ liveTime: data?.content?.live?.liveWatching }),
    skip: !isLive,
  }) || DEFAULT_ARE_YOU_STILL_WATCHING_PARAMS.live;

  return isLive
    ? liveTime * 60 * 60 * 1000
    : VOD_ARE_YOU_STILL_WATCHING_TIMEOUT_MINUTES * 60 * 1000;
}

export function useAreYouStillWatchingMinimumEpisodes() {
  const { minimumEpisodes } = api.useGetAEMConfigurationQuery(AEM_PLATFORM, {
    selectFromResult: ({ data } = {}) => ({ minimumEpisodes: data?.content?.vod?.vodWatching }),
  });
  return minimumEpisodes || DEFAULT_ARE_YOU_STILL_WATCHING_PARAMS.vod;
}

export function useParentalControlError(asset, onError) {
  useEffect(() => {
    if (!featureFlagParentalControl() || !asset) return;

    const profileRating = getProfileRating();
    const profileContentNoClassificationAllowed = getProfileContentNoClassificationAllowed();
    const rating = contentRating(asset?.rating);

    if (rating > profileRating || (
      rating === undefined && profileContentNoClassificationAllowed === false
    )) {
      onError(Errors.getParentalControlError(asset?.rating, profileRating, JSON.parse(localStorage.getItem('profile'))?.name));
    }
  }, [asset, onError]);
}

export function useLoadOnChromecastConnect(assetId, isLive = false) {
  const { playSource } = useSelector((state) => ({
    playSource: state.player.playSource,
  }));

  const isChromecastConnected = useIsCastConnected();
  const { loadMedia } = useCastActions();

  useEffect(() => {
    if (isChromecastConnected) {
      loadMedia({ assetId, isLive, playSource });
    }
  }, [assetId, isChromecastConnected, isLive, loadMedia, playSource]);
}
