import jsonSKY from '../../wonderwall-project-SKY.json';
import jsonDGO from '../../wonderwall-project-DGO.json';
import { APP_THEME, WONDERWALL_FEATURE_FLAG } from '../utils';

const root = document.documentElement;

if (WONDERWALL_FEATURE_FLAG) {
  const theme = APP_THEME;
  const json = theme === 'skymais' ? jsonSKY : jsonDGO; // DGO default
  const { typography } = json;

  Object.keys(typography).forEach((key, index) => {
    const font = typography[key];

    root.style.setProperty(`--font-family-${key}`, `"${font.fontFamily}"`);
    root.style.setProperty(`--font-weight-${key}`, font.fontWeight);
    root.style.setProperty(`--font-size-${key}`, `${font.fontSize}px`);
    root.style.setProperty(`--line-height-${key}`, `${font.lineHeight}px`);
    root.style.setProperty(`--letter-spacing-${key}`, `${font.spacing}%`);

    if (index === Object.keys(typography).length - 1) {
      document.body.classList.add('loaded');
    }
  });
}
