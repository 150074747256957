import { userConstants } from './userConstants';

export const gtmDimensions = {
  provider: {
    page_view: {
      hash: 'p-027.001.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_provider_name',
      ],
    },
  },
  content: {
    page_view: {
      hash: 'p-034.006.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_collection_name',
      ],
    },
  },
  collections: {
    page_view: {
      hash: 'p-028.001.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_play_source',
        'v_collection_name',
      ],
    },
  },
  main: {
    page_view: {
      hash: 'p-002.001.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
  },
  home: {
    page_view: {
      hash: 'p-002.001.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    watch: {
      hash: 'e-007.007.001.000.000.072',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
  live: {
    page_view: {
      hash: 'p-037.001.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    watch: {
      hash: 'e-007.007.001.000.000.020',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
  watch: {
    page_view: {
      hash: 'p-031.001.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    watch: {
      hash: 'e-007.007.001.000.000.037',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
  sports: {
    page_view: {
      hash: 'p-038.001.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    watch: {
      hash: 'e-007.007.001.000.000.038',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
  kids: {
    page_view: {
      hash: 'p-032.001.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    watch: {
      hash: 'e-007.007.001.000.000.039',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
  faq: {
    watch: {
      hash: 'e-006.007.001.000.000.088',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
  regulation: {
    watch: {
      hash: 'e-006.007.001.000.000.137',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
  speed_test: {
    watch: {
      hash: 'e-006.007.001.000.000.138',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
  privacy_policy: {
    watch: {
      hash: 'e-006.007.001.000.000.078',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
  terms_and_conditions: {
    watch: {
      hash: 'e-006.007.001.000.000.087',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
  personal_data: {
    watch: {
      hash: 'e-006.007.001.000.000.139',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
        'v_provider_name',
        'v_collection_name',
        'v_provider_name',
        'v_collection_name',
      ],
    },
  },
};
