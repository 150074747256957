/* External */
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';

/* Components */
import PreSearchCard from '../preSearchCard/PreSearchCard';

/* Store */
import { api } from '../../state/configureStore';

/* Utils */
import { LANDERS_IDS, getPageQueryByFeatureFlag } from '../../utils';

/* Styles */
import './PreSearch.scss';

const useGetPreSearchAssets = () => {
  const useGetPageQuery = getPageQueryByFeatureFlag(api);
  const {
    data: { elements: preSearchCarousels } = {},
    isLoading: isLoadingPage,
  } = useGetPageQuery({ id: LANDERS_IDS.PRESEARCH });
  const {
    id, source, type, filters,
  } = preSearchCarousels?.[0] || {};

  const {
    data: { collections } = {},
    isLoading: isLoadingCarousel,
  } = api.useGetCarouselQuery({
    source, sourceCarouselId: id, type, filters,
  });

  return {
    collections,
    carouselId: id,
    carouselType: type,
    isLoading: isLoadingPage || isLoadingCarousel,
  };
};

function PreSearch({ onIsLoadingChange, intl: { formatMessage } }) {
  const {
    collections, carouselId, carouselType, isLoading,
  } = useGetPreSearchAssets();

  /**
   * @description Executes onIsLoadingChange when isLoading value changes
   * @cleanUp Executes onIsLoadingChange with a value of false
   */
  useEffect(() => {
    onIsLoadingChange(isLoading);

    return () => onIsLoadingChange(false);
  }, [onIsLoadingChange, isLoading]);

  return isLoading ? null : (
    <div className="dtv-pre-search">
      <p className="dtv-pre-search-message">
        {collections?.length > 0
          ? formatMessage({ id: 'preSearch.results', defaultMessage: 'Descubre más opciones' }).toUpperCase()
          : formatMessage({ id: 'preSearch.noResults', defaultMessage: '¿Qué quieres ver?' })}
      </p>
      {collections?.length > 0 && (
        <div className="dtv-pre-search-cards-container">
          {collections.map((collection, index) => (
            <PreSearchCard
              collection={collection}
              carouselId={carouselId}
              carouselType={carouselType}
              key={collection.title}
              index={index}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default injectIntl(PreSearch);
