/**
 * External
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isValid, setSeconds } from 'date-fns';
import { injectIntl, FormattedMessage } from 'react-intl';

/**
 * Other
 */
import { addDurationMinutes, getDiffDuration } from '../../utils';

class TimeLeftText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finishedTime: false,
      timeLeftText: '',
    };
    this.calculateTimeLeft = this.calculateTimeLeft.bind(this);
  }

  componentDidMount() {
    this.currentTime(this.props);
  }

  componentWillUnmount() {
    clearInterval(this.intervalUpdateTimeLeftTet);
  }

  /**
   * @description Returns the time left formatted according with the internationalization labels for
   * each unit date
   * @method getFormattedMessage
   * @param {object} diffDuration
   * @param {boolean} isTextShort
   * @param {date} startTime
   */
  getFormattedMessage(diffDuration, isTextShort = false, startTime = null, andSeparator = null) {
    if (!diffDuration || diffDuration.days < 0 || diffDuration.hours < 0 || diffDuration.minutes < 0) return '';

    let optionsIds = {
      formattedId: 'live.startAtTimeLeft',
      defaultValue: 'Starts at {timeLeft}',
    };

    const keysFormattedTexts = diffDuration.days < 1 && diffDuration.minutes > 0 ? [
      {
        defaultValue: '{value}h',
        normal: 'common.hours',
        short: 'common.hoursShort',
        shortLeft: 'common.hoursShort',
        value: diffDuration.hours,
      },
      {
        defaultValue: ' y ',
        normal: 'common.and',
        short: 'common.and',
        shortLeft: 'common.and',
        value: andSeparator && diffDuration.hours,
      },
      {
        defaultValue: '{value}m',
        normal: 'common.minutesShortLeft',
        short: 'common.minutesShort',
        shortLeft: 'common.minutesShortLeft',
        value: diffDuration.minutes,
      },
    ] : [
      {
        defaultValue: '{value}d',
        normal: 'common.days',
        short: 'common.daysShort',
        shortLeft: 'common.daysShort',
        value: diffDuration.days,
      },
      {
        defaultValue: '{value}h',
        normal: 'common.hours',
        short: 'common.hoursShort',
        shortLeft: 'common.hoursShortLeft',
        value: diffDuration.hours,
      },
      {
        defaultValue: '{value}m',
        normal: 'common.minutes',
        short: 'common.minutesShort',
        shortLeft: 'common.minutesShortLeft',
        value: diffDuration.minutes,
      },
    ];

    const timeLeftText = keysFormattedTexts
      .map((keyFormattedText) => this.getTimeLeft(keyFormattedText, isTextShort, startTime))
      .join(' ');

    if (!isValid(startTime)) {
      optionsIds = {
        formattedId: 'live.timeLeft',
        defaultValue: '{timeLeft} left',
      };
    }

    return (
      <FormattedMessage
        id={optionsIds.formattedId}
        defaultMessage={optionsIds.defaultValue}
        values={{
          timeLeft: timeLeftText,
        }}
      />
    );
  }

  /**
   * @description Returns the formatted text for a specific unit date
   * @method getTimeLeft
   * @param {object} keyFormattedText
   * @param {boolean} isShort
   * @param {date} startTime
   */
  getTimeLeft(keyFormattedText, isShort, startTime) {
    const { intl: { formatMessage } } = this.props;
    const shortKeyMessage = startTime
      ? keyFormattedText.short
      : keyFormattedText.shortLeft;
    const keyMessage = isShort
      ? shortKeyMessage
      : keyFormattedText.normal;
    return keyFormattedText.value
      ? formatMessage(
        {
          id: keyMessage,
          defaultMessage: keyFormattedText.defaultValue,
        },
        {
          value: keyFormattedText.value,
        },
      )
      : null;
  }

  /**
   * @description Method that move the current time circle.
   * @method currentTime
   * @param props current props
   */
  currentTime = ({ startTime, endTime, isTextShort }) => {
    const newStartTime = setSeconds(new Date(startTime), 0);
    const newEndTime = setSeconds(new Date(endTime), 0);
    const { finishedTime } = this.state;
    if (!finishedTime) {
      this.calculateTimeLeft(isTextShort, newStartTime, newEndTime);
      this.intervalUpdateTimeLeftTet = setInterval(
        () => this.calculateTimeLeft(isTextShort, newStartTime, newEndTime),
        60_000,
      );
    }
  };

  /**
   * @description Method that change the state with the time left text according to the format
   * needed
   * @method calculateTimeLeft
   * @param {boolean} isTextShort
   * @param {date} startTime
   * @param {date} endTime
   */
  calculateTimeLeft(isTextShort, startTime, endTime) {
    const currentDate = new Date();
    const timeLeft = getDiffDuration(endTime, currentDate);
    const diffDuration = addDurationMinutes(timeLeft, 1);
    const finishedTime = diffDuration.days === 0
      && diffDuration.hours === 0
      && diffDuration.minutes === 0;
    const { andSeparator } = this.props;

    this.setState({
      timeLeftText: this.getFormattedMessage(diffDuration, isTextShort, startTime, andSeparator),
      finishedTime,
    });
  }

  render() {
    const { timeLeftText, finishedTime } = this.state;
    const { withSeparator } = this.state;
    if (finishedTime) {
      clearInterval(this.intervalUpdateTimeLeftTet);
    }
    return finishedTime ? (
      <span />
    ) : (
      <span>
        {withSeparator && <span className="dtv-separator"> | </span>}
        {timeLeftText}
      </span>
    );
  }
}

TimeLeftText.propTypes = {
  andSeparator: PropTypes.bool,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

TimeLeftText.defaultProps = {
  andSeparator: false,
};

export default injectIntl(TimeLeftText);
