/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import './SliderSkeleton.scss';

function SliderSkeleton({ classCard }) {
  const cards = [];

  for (let index = 0; index < 7; index += 1) {
    cards.push(
      <div key={index} className={`dtv-skeleton-slider-card ${classCard}`}>
        <div className="dtv-skeleton-slider-card-image" />
        <div className="dtv-skeleton-slider-card-first-line" />
        <div className="dtv-skeleton-slider-card-second-line" />
        <div className="dtv-skeleton-slider-card-third-line" />
      </div>,
    );
  }

  return (
    <div className="dtv-skeleton-swimlane">
      <div className="dtv-skeleton-slider-swimlane-title" />
      {cards}
    </div>
  );
}

SliderSkeleton.propTypes = {
  classCard: PropTypes.string,
};

SliderSkeleton.defaultProps = {
  classCard: '',
};

export default SliderSkeleton;
