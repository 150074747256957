/* External */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

function ErrorMessage({ title, message }) {
  return (
    <>
      <span className="dtv-chromecast-bar-info-data-title text-uppercase">{title}</span>
      <br />
      <span className="dtv-chromecast-bar-info-data-metadata">{message}</span>
    </>
  );
}

ErrorMessage.propTypes = {
  title: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
};

export default memo(ErrorMessage);
