/* External */
import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

/* Ott-common */
import Icon from '@dtvgo/icon';
import CardCreateProfile from '@dtvgo/card-create-profile';
import Input from '@dtvgo/input';
import { getInitials } from '@dtvgo/utils';

/* Components */
import NavBarProfile from '../../navBarProfile/NavBarProfile';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/user';
import ParentalMenu from '../../components/parentalMenu/ParentalMenu';

/* Other */
import { history } from '../../state/configureStore';
import { addProfile } from '../../state/profile/reducer';
import { backendService } from '../../services';

/* Utils */
import {
  ageRatingSelect,
  colors,
  featureFlagParentalControl,
  PATHS,
  PROFILES_STORAGE,
  userGtmEventHandler,
} from '../../utils';

/* Styles */
import './UserProfileCreate.scss';

function analyticsBackProfile(gtmUserData) {
  userGtmEventHandler(gtmDimensions.multiple_profile.create_profile.back, gtmUserData);
}
function analyticsCreateProfile(gtmUserData) {
  userGtmEventHandler(gtmDimensions.multiple_profile.create_profile.create_profile, gtmUserData);
}
function analyticsCreateFirstProfile(gtmUserData) {
  userGtmEventHandler(
    gtmDimensions.first_access.first_profile_creation.create_first_profile,
    gtmUserData,
  );
}

function UserProfileCreate({
  intl: { formatMessage },
}) {
  const [profiles] = useState(
    JSON.parse(localStorage.getItem(PROFILES_STORAGE.profiles)),
  );
  const newProfileName = `Perfil ${profiles.length + 1}`;
  const dispatch = useDispatch();
  const [name, setName] = useState(newProfileName || '');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const blockEmoji = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}|\p{Extended_Pictographic}/gu;
  const { gtmUserData } = useGtmContext();
  const [rating, setRating] = useState('18');
  const [activeStep, setActiveStep] = useState(6);
  const [contentWithoutClassification, setContentWithoutClassification] = useState(true);
  const featureFlagParental = featureFlagParentalControl();
  const logoLetter = useMemo(() => getInitials(name).slice(0, 2).join('').toUpperCase(), [name]);

  function sendHashAnalytics() {
    if (profiles.length === 0) {
      analyticsCreateFirstProfile(gtmUserData);
    } else {
      analyticsCreateProfile(gtmUserData);
    }
  }

  useEffect(() => {
    inputRef.current.focus();

    userGtmEventHandler(gtmDimensions.multiple_profile.create_profile.page_view, gtmUserData);
  }, [gtmUserData]);

  useEffect(() => {
    const ageRating = ageRatingSelect(activeStep);
    if (ageRating && ageRating !== '') {
      setRating(ageRating);
    }
  }, [activeStep]);

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (profiles.length === 0) {
      setRating('18');
      setContentWithoutClassification(true);
    }

    const newProfile = {
      name,
      avatar: '',
      ageRating: rating,
      isNotRatedContentAllowed: contentWithoutClassification,
    };

    backendService.customer.v1.createProfile(newProfile).then((res) => {
      setLoading(false);
      const allProfiles = res.data.profiles;
      dispatch(addProfile(allProfiles));
      sendHashAnalytics();
      return history.push(PATHS?.USER_PROFILE, { create: true });
    }).catch((error) => {
      setLoading(false);
      history.push(PATHS?.ERROR, {
        source: 'MIDDLEWARE',
        location: 'PROFILE_CREATE',
        type: 'UNEXPECTED',
        externCode: error?.response?.status,
      });
    });
  };

  function handleChange(text) {
    setName(text.replace(/^\s+|\s+$/g, '').replace(blockEmoji, ''));
  }

  function validationButton() {
    return (
      !name
      || name.replace(/\s/g, '').length === 0
      || name.replace(blockEmoji, '').length === 0
      || loading
    );
  }

  return (
    <div className="dtv-web-user-profile-create">
      <NavBarProfile />
      <form className="dtv-web-user-profile-create__main" onSubmit={onSubmit}>
        <div className="dtv-web-user-profile-create__management">
          {profiles.length > 0 ? (
            <Link className="dtv-web-user-profile-create__icon-back" to={PATHS?.PROFILE_MANAGEMENT} onClick={() => analyticsBackProfile(gtmUserData)}><Icon name="arrow-back" /></Link>
          ) : ('')}
          <h2 className="dtv-web-user-profile-create__title">
            {
              formatMessage({
                id: 'profiles.userProfile.createProfile.text',
                defaultMessage: 'Crear perfil',
              })
            }
          </h2>
          {profiles.length === 0 ? (
            <div className="dtv-web-user-profile-create__subtitle">
              {formatMessage({ id: 'profiles.userProfile.createProfile.subtitle', defaultMessage: 'Este será el perfil principal. No se puede eliminar ni convertir en un perfil infantil.' })}
            </div>
          ) : ('')}

          <div className="dtv-web-user-profile-create__card">
            <CardCreateProfile
              isInputNotEmpty={logoLetter || ''}
              color={colors[profiles.length + 1]}
            />
            <Input
              labelContent={formatMessage({ id: 'profiles.userProfile.editProfile.inputLabel', defaultMessage: '¿Cómo te podemos llamar?' })}
              inputValue={newProfileName}
              isInputActive
              innerRef={inputRef}
              onInput={(event) => {
                handleChange(event.target.value);
              }}
              emptyInputText={formatMessage({ id: 'profiles.userProfile.editProfile.emptyInputText', defaultMessage: 'Necesitas ingresar un nombre o apodo' })}
            />
          </div>

          {featureFlagParental && (
            <ParentalMenu
              profilesAdmin={profiles.length === 0}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              contentWithoutClassification={contentWithoutClassification}
              setContentWithoutClassification={setContentWithoutClassification}
            />
          )}

          <button
            className={validationButton() ? 'dtv-btn-secondary dtv-web-user-profile-create__button' : 'dtv-btn-primary dtv-web-user-profile-create__button'}
            type="submit"
            disabled={validationButton()}
          >
            {
              formatMessage({
                id: 'profiles.userProfile.createProfile.text',
                defaultMessage: 'Crear perfil',
              })
            }
          </button>
        </div>
      </form>

    </div>
  );
}

UserProfileCreate.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

UserProfileCreate.defaultProps = {
  intl: {
    formatMessage: () => {},
  },
};

export default injectIntl(UserProfileCreate);
