/* External */
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import isEmptyL from 'lodash-es/isEmpty';

/* Ott-common */
import { getInitials } from '@dtvgo/utils';
import CardCreateProfile from '@dtvgo/card-create-profile';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Assets */
import logo from '@/assets/theme/svg/logo.svg';

/* Components */
import UserMenu from '../userMenu/UserMenu';
import InputSearch from './InputSearch';
import { useGtmContext } from '../components/GtmContext';
import { gtmDimensions } from '../components/GtmContext/dimensions/header-footer';

/* Styles */
import './NavBar.scss';

/* Other */
import {
  PATHS,
  PROFILES_STORAGE,
  colors,
  formatContentForGtm,
  getLanderNameByCaption,
} from '../utils';
import { getImage } from '../theme/images';

function NavBar({
  user = null,
  notificationHeight,
}) {
  const [menu, setMenu] = useState(false);
  const {
    gtmUserData,
    gtmCollectionName,
    gtmProviderName,
    setGtmCollectionName,
    setGtmProviderName,
  } = useGtmContext();
  const location = useLocation();

  const handleClick = (caption) => {
    const { hash, eventType, dimensions } = gtmDimensions[caption].watch;

    const dataTosend = formatContentForGtm(
      {},
      {
        tab: location.state?.tab || null,
        providerName: gtmProviderName,
        collectionName: gtmCollectionName,
      },
    );

    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataTosend,
    });

    setGtmCollectionName(null);
    setGtmProviderName(null);
  };

  const showUserMenu = () => {
    setMenu(!menu);
  };

  let captionLink;
  let userName = null;

  const profileSelected = JSON.parse(localStorage.getItem(PROFILES_STORAGE.profile)) ?? [];
  const profileName = profileSelected?.name ? profileSelected?.name : '';
  const profileColor = profileSelected?.color ? profileSelected?.color : 0;
  const logoLetter = useMemo(() => getInitials(profileName).slice(0, 2).join('').toUpperCase(), [profileName]);

  const menuLinks = [
    {
      caption: 'home',
      default: 'Inicio',
      url: PATHS.HOME,
    },
    {
      caption: 'live',
      default: 'En vivo',
      url: PATHS.LIVE,
    },
    {
      caption: 'watch',
      default: 'Catálogo',
      url: PATHS.WATCH,
    },
    {
      caption: 'sports',
      default: 'Deportes',
      url: PATHS.SPORTS,
    },
    {
      caption: 'kids',
      default: 'Niños',
      url: PATHS.KIDS,
    },
  ];
  if (!isEmptyL(user)) {
    userName = user.userName ? user.userName.toLowerCase() : user.profile.email;
  }

  return (
    <>
      <div className={`dtv-nav-bar-container ${!userName && 'dtv-public-menu'}`} style={{ top: notificationHeight }}>
        <div className="dtv-nav-bar-content">
          <div className="dtv-nav-bar-content-left">
            <Link to="/home/" className="dtv-logo">
              <img src={getImage('logo') || logo} alt="Logo" />
            </Link>
            <div className="dtv-navlinks">
              {menuLinks.map((menuLink) => {
                captionLink = <FormattedMessage id={`menu.${menuLink.caption}`} defaultMessage={menuLink.default} />;
                return (
                  <NavLink
                    to={{
                      pathname: menuLink.url,
                      state: { tab: getLanderNameByCaption(menuLink.caption) },
                    }}
                    key={menuLink.caption}
                    className="dtv-navlink-item"
                    onClick={() => handleClick(menuLink.caption)}
                  >
                    {captionLink}
                  </NavLink>
                );
              })}
            </div>
          </div>

          <div className="dtv-nav-bar-content-right">
            <InputSearch />
            <button id="lblUserName" type="button" onClick={() => showUserMenu()} className="dtv-resemble-a">
              <CardCreateProfile
                isInputNotEmpty={logoLetter || ''}
                color={colors[profileColor + 1]}
              />
            </button>
          </div>
        </div>
      </div>
      <UserMenu menu={menu} setMenu={setMenu} />
    </>
  );
}

function mapStateToProps({ user, notifications, router }) {
  const barNotification = notifications.get('barNotification');
  const notificationHeight = notifications.get('notificationHeight');
  const { pathname } = router.location;
  return {
    user: user.account,
    barNotification,
    notificationHeight,
    pathname,
  };
}

export default connect(mapStateToProps)(injectIntl(NavBar));
