/* External */
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';

/* Components */
import LoginPage from './loginPage/LoginPage';

/* Assets */
import logo from '../assets/images/logo/dgo.png';

/* Styles */
import './Landing.scss';

/* Other */
import { isUserLoggedIn, PATHS } from '../utils';

function Landing({ loginOpened }) {
  const [showLoginButton, setShowLoginButton] = useState(loginOpened);

  const toggleLogin = useCallback(() => {
    setShowLoginButton((currentShowLoginButton) => !currentShowLoginButton);
  }, []);

  const handleLogIn = useCallback(
    (e) => {
      e.preventDefault();
      toggleLogin();
    },
    [toggleLogin],
  );

  if (isUserLoggedIn()) {
    return <Redirect to={{ pathname: PATHS.HOME }} />;
  }

  if (!isUserLoggedIn()) {
    return (
      <div className="dtv-landing-container">
        <LoginPage show={showLoginButton} toggleLogin={toggleLogin} />
        <div className="dtv-landing-left">
          <img src={logo} className="dtv-img-logo" alt="Logo" />
          <div className="dtv-landing-left-text">
            <p>
              <FormattedMessage id="landing.firstLine" defaultMessage="WELCOME TO THE TV" />
            </p>
            <p>
              <FormattedMessage id="landing.secondLine" defaultMessage="THAT IS MUCH THAN TV" />
            </p>
            <p className="sub-title">
              <FormattedMessage id="landing.thirdLine" defaultMessage="Take it wherever you want with DIRECTV GO" />
            </p>
          </div>
          <p className="legal-info">
            <FormattedMessage
              id="landing.legalInfo"
              defaultMessage="It is recommended to have an optimal Internet connection of 4MB. © 2018 AT & T Intellectual Property. DIRECTV, the DIRECTV logo, and all other DIRECTV brands contained herein are trademarks of AT & T Intellectual Property and / or affiliated companies of AT & T."
            />
          </p>
        </div>
        <div className="dtv-landing-right">
          <button type="button" className="dtv-landing-btn-log-in" onClick={handleLogIn}>
            <FormattedMessage id="landing.logIn" defaultMessage="LOG IN" />
          </button>
        </div>
      </div>
    );
  }
}

export default Landing;
