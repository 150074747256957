/**
 * External
 */
import React from 'react';
import isNilL from 'lodash-es/isNil';
import {
  differenceInMinutes, format, isBefore, isWithinInterval, setSeconds,
} from 'date-fns';
import { FormattedMessage } from 'react-intl';

/**
 * Components
 */
import TimeLeftText from '../timeLeftText/TimeLeftText';
import LiveProgressBar from '../liveProgressBar/LiveProgressBar';
import AssetToPlay from '../assetToPlay/AssetToPlay';

/**
 * Assets
 */
import genericCardLive from '../../assets/images/GenericCardLive.svg';
import liveIcon from '../../assets/icons/live-icon.png';

/**
 * Styles
 */
import './ChannelCard.scss';

/**
 * Other
 */
import { joinArrayWithSeparator, useImage } from '../../utils';
import { getImage } from '../../theme/images';

function ChannelCard({
  infoCard: {
    id, title, images, resourceType, startTime, endTime, playSource, channelId,
  } = {},
}) {
  let timeLeft = null;
  let startTimeFormatted = null;
  let endTimeFormatted = null;
  if (startTime && endTime) {
    const start = setSeconds(new Date(startTime), 0);
    const end = setSeconds(new Date(endTime), 0);
    const currentDate = new Date();
    if (isBefore(start, end) && isWithinInterval(currentDate, { start, end })) {
      timeLeft = differenceInMinutes(end, currentDate) + 1;
    }
    startTimeFormatted = format(start, 'HH:mm');
    endTimeFormatted = format(end, 'HH:mm a');
  }

  const genericCardLiveFlag = getImage('genericCardLive') || genericCardLive;
  const { imageRef, imageStyle } = useImage(images, genericCardLiveFlag, null, '4:3', false);
  const isVisibleTimeLeft = !isNilL(timeLeft) && timeLeft !== 0;

  return (
    <AssetToPlay
      className="dtv-card-channel"
      asset={{
        assetId: id,
        resourceType,
        playSource,
        channelId,
      }}
      isLive
    >
      <div className="dtv-card-channel-logo">
        <div className="dtv-card-channel-logo-image" style={imageStyle} ref={imageRef} />
        <span className="dtv-icon-play" />
      </div>
      <div className="dtv-card-channel-info dtv-text-left">
        <p className="dtv-card-channel-info-first-line">
          <img src={getImage('iconLive') || liveIcon} alt="Live" />
          <span className="first-line ml-2">
            <FormattedMessage id="VODCard.live" defaultMessage="Live" />
          </span>
        </p>
        <p className="dtv-card-channel-info-second-line">{title}</p>
        <p className="dtv-card-channel-info-third-line">
          {joinArrayWithSeparator([startTimeFormatted, endTimeFormatted], '-')}
          {' '}
          {isVisibleTimeLeft && <TimeLeftText isTextShort withSeparator endTime={endTime} />}
        </p>
        {timeLeft && <LiveProgressBar {...{ typeCard: 1, startTime, endTime }} width="240" initialPosition="245" />}
      </div>
    </AssetToPlay>
  );
}

export default ChannelCard;
