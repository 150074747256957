/* Ott-common */
import { createBackendService, ClientType } from '@dtvgo/backend-service';
import axios from 'axios';

/* Other */
import { APP_VERSION, SERVICES_CLIENT_ID, SERVICES_CLIENT_ID_PREPROD } from '../utils/constants';
import { getAuthTokensData } from '../utils/localStorageState';
import { isBrazil, ENVIRONMENT, APP_THEME } from '../utils/env';
import { authInterceptor } from './auth';
import { getUserLanguageFromEnvironment } from '../utils';

export const AppEnvironment = {
  local: 'dev',
  dev: 'dev',
  rt: 'alpha',
  preprod: 'beta',
  prod: 'prd',
  production: 'prd',
};

const backendService = createBackendService({
  clientType: ClientType.PC,
  getAccessTokenData: getAuthTokensData,
  getIsBrazil: () => isBrazil,
  host: import.meta.env.REACT_APP_BACKEND_SERVICE_HOST,
  clientId: document.location.origin.includes('preprod.directvgo') ? SERVICES_CLIENT_ID_PREPROD : SERVICES_CLIENT_ID,
  appVersion: APP_VERSION,
  profileToken: () => JSON.parse(localStorage.getItem('profile'))?.profileToken,
  environment: ENVIRONMENT,
  appName: APP_THEME,
  timeout: 60_000,
  authInterceptor,
  language: getUserLanguageFromEnvironment(),
});

const axiosPublicInstance = axios.create({
  baseURL: `${import.meta.env.REACT_APP_BACKEND_SERVICE_HOST}/publiccontents/v1`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'x-client-id': 'api-automation',
    'x-client-version': '1.0',
    'x-environment': AppEnvironment[ENVIRONMENT],
    'x-app': APP_THEME,
  },
});

export const createBackendServicePublic = {
  get: async (path, language = 'es', region = '') => {
    const retornoGet = await axiosPublicInstance.get(`${path}${path?.includes('?') ? '&' : '?'}language=${language}${region !== '' ? `&region=${region}` : ''}`)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return error;
      });

    return retornoGet;
  },
};

export default backendService;
