/* External */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profiles: [] || null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    addProfile(state, { payload }) {
      return { ...state, profiles: payload };
    },
    editProfile(state, { payload }) {
      return { ...state, profiles: payload };
    },
    deleteProfile(state, { payload }) {
      return { ...state, profiles: payload };
    },
    setProfiles(state, { payload }) {
      return { ...state, profiles: payload?.data || payload };
    },
  },
});

export const {
  addProfile,
  editProfile,
  deleteProfile,
  setProfiles,
} = profileSlice.actions;

export default profileSlice.reducer;
