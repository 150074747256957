/**
 * External
 */
import React, { Component } from 'react';
import isNilL from 'lodash-es/isNil';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import './ComboBoxFilter.scss';

class ComboBox extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      open: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onClickItem = this.onClickItem.bind(this);
  }

  /**
   * @description React life cycle method
   *             Save the value to show in ComboBox in the state
   * @method componentDidMount
   */
  componentDidMount() {
    const { defaultValue, options } = this.props;
    const defaultOption = defaultValue || { ...options[0] };
    this.setState(defaultOption);
  }

  /**
   * @description React life cycle method
   *              Update the value to display in ComboBox
   * @method componentWillReceiveProps
   */
  UNSAFE_componentWillReceiveProps() {
    const { selectedValue } = this.props;
    if (selectedValue) this.setState(selectedValue);
  }

  /**
   * @description Method that handles clicks on the ComboBox
   * @method handleClick
   */
  handleClick() {
    this.setState((prevState) => ({
      open: !prevState.open,
    }), () => {
      const { open } = this.state;
      if (open) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    });
  }

  /**
   * @description Method that detects clicks outside of the ComboBox and closes it
   * @method handleClickOutside
   */
  handleClickOutside(event) {
    if (this.ref && !this.ref.contains(event.target)) {
      this.setState({
        open: false,
      });
    }
  }

  /**
   * @description Method that receives the click on an item in the ComboBox
   * @method onClickItem
   * @param {*} text
   * @param {*} value
   */
  onClickItem(text, value, index) {
    this.setState(({ open }) => ({
      text,
      open: !open,
    }), () => {
      const { onChange } = this.props;
      onChange(text, value, index);
    });
  }

  render() {
    const {
      options, title, width, className, selectedValue,
    } = this.props;
    const { open, text } = this.state;

    return (
      <div
        className={`dtv-combobox ${className}`}
        ref={(node) => {
          this.ref = node;
        }}
        style={{ width: `${width}px` }}
      >
        <div onClick={this.handleClick} className={`dtv-combobox-text ${open ? 'open' : ''}`}>
          <div>
            <div className="dtv-combobox-value">
              {!isNilL(title) ? (
                <div className="dtv-title-content">
                  <span className="dtv-title">{title}</span>
                  <br />
                  <span className="dtv-text">{text}</span>
                </div>
              ) : (
                text
              )}
            </div>
          </div>
          <div className="dtv-combobox-arrow">
            <span className={`${!open ? 'dtv-icon-arrow-down' : 'dtv-icon-arrow-up'}`} />
          </div>
        </div>
        {!isNilL(options) && (
          <div className={`${open ? 'visible' : 'invisible'} dtv-combobox-options ${!isNilL(title) && 'text-right'}`}>
            <ul>
              {options.map(({ text: optionText, value }, index) => {
                const isSelected = selectedValue?.value?.toString() === value?.toString();

                return (
                  <li
                    className={`dtv-combobox-value dtv-combobox-value-list ${isSelected ? 'dtv-combobox-value--selected' : ''}`}
                    key={value}
                    onClick={() => this.onClickItem(optionText, value, index)}
                  >
                    {optionText}
                    { isSelected && (<span className="dtv-icon-verified" />)}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

ComboBox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.node,
    value: PropTypes.string,
  })).isRequired,
  selectedValue: PropTypes.shape({
    text: PropTypes.node,
    value: PropTypes.string,
  }),
  title: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  onChange: PropTypes.func,
};

ComboBox.defaultProps = {
  selectedValue: null,
  title: '',
  width: '208',
  className: '',
  onChange: () => {},
};

export default ComboBox;
