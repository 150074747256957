import React from 'react';
import PropTypes from 'prop-types';

import './CircleItem.scss';

function CircleItem({
  entityId = '',
  children = null,
  handleClick = () => {},
  isItemSelected = false,
}) {
  const onSelectItem = () => {
    handleClick(entityId);
  };

  return (
    <button
      className={`dtv-circle-item ${isItemSelected && 'dtv-circle-item--highlighted'}`}
      onClick={onSelectItem}
      type="button"
    >
      {children}
    </button>
  );
}

CircleItem.propTypes = {
  entityId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
};

export default CircleItem;
