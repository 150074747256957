import { userConstants } from './userConstants';

export const gtmDimensions = {
  component_type: 'player',
  player_series: {
    page_view: {
      hash: 'p-001.002.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_component_type',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    pause: {
      hash: 'e-001.002.001.000.000.017',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_component_type',
        'v_change_progress_percentual',
        'v_episode_number',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
      ],
    },
    play: {
      hash: 'e-001.002.001.000.000.027',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_component_type',
        'v_episode_number',
        'v_episode_name',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    language: {
      hash: 'e-001.002.001.000.000.021',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_episode_number',
        'v_episode_name',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    full_screen: {
      hash: 'e-001.003.001.000.000.026',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_episode_number',
        'v_episode_name',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    close: {
      hash: 'e-001.005.001.000.000.008',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_episode_number',
        'v_episode_name',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_finish_progress_percentual',
      ],
    },
    timeline: {
      hash: 'e-001.003.001.000.000.019',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_episode_number',
        'v_episode_name',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    change_audio: {
      hash: 'e-001.004.004.000.000.030',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_language',
        'v_video_subtitle',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_vertical_position_carousel',
        'v_video_duration',
      ],
    },
    change_subtitle: {
      hash: 'e-001.004.004.000.000.030',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_language',
        'v_video_subtitle',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_vertical_position_carousel',
        'v_video_duration',
      ],
    },
    backward: {
      hash: 'e-001.003.001.000.000.016',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_channel_id',
        'v_channel_name',
        'v_episode_number',
        'v_content_player_type',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_vertical_position_carousel',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
  },
  player_live: {
    page_view: {
      hash: 'p-001.002.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_component_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    pause: {
      hash: 'e-001.002.001.000.000.017',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    play: {
      hash: 'e-001.002.001.000.000.027',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    language: {
      hash: 'e-001.002.001.000.000.021',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_video_language',
        'v_video_subtitle',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    full_screen: {
      hash: 'e-001.003.001.000.000.026',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    more_channels: {
      hash: 'e-001.005.001.000.000.169',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    close: {
      hash: 'e-001.005.001.000.000.008',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_channel_id',
        'v_channel_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_finish_progress_percentual',
        'v_finish_progress_bar',
      ],
    },
    timeline: {
      hash: 'e-001.003.001.000.000.019',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    ao_vivo: {
      hash: 'e-001.005.001.000.000.020',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    change_audio: {
      hash: 'e-001.004.004.000.000.030',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_video_language',
        'v_video_subtitle',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_vertical_position_carousel',
        'v_video_duration',
      ],
    },
    change_subtitle: {
      hash: 'e-001.004.004.000.000.030',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_video_language',
        'v_video_subtitle',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_vertical_position_carousel',
        'v_video_duration',
      ],
    },
    backward: {
      hash: 'e-001.003.001.000.000.016',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_channel_id',
        'v_channel_name',
        'v_episode_number',
        'v_content_player_type',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_vertical_position_carousel',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
  },
  player_vod: {
    page_view: {
      hash: 'p-001.002.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_video_subtitle',
        'v_video_language',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_component_type',
        'v_content_player_type',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
    pause: {
      hash: 'e-001.002.001.000.000.017',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    play: {
      hash: 'e-001.002.001.000.000.027',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    language: {
      hash: 'e-001.002.001.000.000.021',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    full_screen: {
      hash: 'e-001.003.001.000.000.026',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    close: {
      hash: 'e-001.005.001.000.000.008',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_content_player_type',
        'v_finish_progress_bar',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_finish_progress_percentual',
      ],
    },
    timeline: {
      hash: 'e-001.003.001.000.000.019',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_content_player_type',
        'v_change_progress',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
    change_audio: {
      hash: 'e-001.004.004.000.000.030',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_language',
        'v_video_subtitle',
        'v_transmission_type',
        'v_channel_id',
        'v_content_player_type',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_vertical_position_carousel',
        'v_video_duration',
      ],
    },
    change_subtitle: {
      hash: 'e-001.004.005.000.000.030',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_language',
        'v_video_subtitle',
        'v_transmission_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_vertical_position_carousel',
        'v_video_duration',
      ],
    },
    backward: {
      hash: 'e-001.003.001.000.000.016',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_subtitle',
        'v_video_language',
        'v_transmission_type',
        'v_channel_id',
        'v_channel_name',
        'v_episode_number',
        'v_content_player_type',
        'v_change_progress',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_vertical_position_carousel',
        'v_video_duration',
        'v_change_progress_percentual',
      ],
    },
  },
};
