/* External */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Components */
import { useGtmContext } from '../GtmContext';

/* Utils */
import {
  getLanderLogoSrc,
  getCollectionRedirectUrl,
  useImage,
  getWebAction,
  CollectionText,
} from '../../utils';
import { history } from '../../state/configureStore';

/* Styles */
import './Card1X1.scss';

/**
 * 1X1 Card. Used for browse landers and collections
 */
function Card1X1({
  collection, genericCard, gradient = '', containerCarouselId, containerCarouselType,
}) {
  const {
    setGtmPlaySource,
  } = useGtmContext();
  const [showText, setShowText] = useState(false);
  const action = getWebAction(collection);
  const isSportPage = action?.text === CollectionText.SPORT_PAGE;
  const { imageRef, imageStyle } = useImage(collection?.cardImages, genericCard, isSportPage ? '' : gradient, '1:1', false);

  if (!collection) return null;

  const handleOnClick = () => {
    const redirectUrl = getCollectionRedirectUrl(
      collection,
      containerCarouselId,
      containerCarouselType,
    );

    setGtmPlaySource(collection.playSource);

    if (redirectUrl) history.push(redirectUrl);
  };

  const landerLogoSrc = getLanderLogoSrc(collection);

  const cardContent = landerLogoSrc && !showText ? (
    <img className="dtv-card-1X1-image-children" src={landerLogoSrc} alt={collection.title} onError={() => setShowText(true)} />
  ) : (
    <h3 className="dtv-card-1X1-text-children">{isSportPage ? '' : collection.title}</h3>
  );

  return (
    <div className="dtv-card-1X1" onClick={handleOnClick}>
      <div className="dtv-card-1X1-img" ref={imageRef} style={imageStyle}>
        {cardContent}
      </div>
    </div>
  );
}

Card1X1.propTypes = {
  genericCard: PropTypes.string,
  gradient: PropTypes.string,
  collection: PropTypes.shape({
    title: PropTypes.string,
    cardImages: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        ratio: PropTypes.string,
      }),
    ),
  }),
  containerCarouselId: PropTypes.string,
  containerCarouselType: PropTypes.string,
};

Card1X1.defaultProps = {
  genericCard: '',
  gradient: '',
  collection: {},
  containerCarouselId: '',
  containerCarouselType: '',
};

export default Card1X1;
