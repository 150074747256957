/* External */
import React, { useEffect, useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

/* Ott-common */
import Title from '@dtvgo/title';
import Button from '@dtvgo/button';
import CodeError from '@dtvgo/code-error';
import '@dtvgo/title/dist/main.css';
import '@dtvgo/code-error/dist/CodeError.scss';

/* Other */
import UserError from '@/assets/theme/icons/user-error.svg';
import IconBack from '../../assets/icons/iconBack.svg';
import { APP_VERSION, PATHS, PROFILES_STORAGE } from '../../utils/constants';
import { getImage } from '../../theme/images';

/* Componentes */
import NavBarProfile from '../../navBarProfile/NavBarProfile';
import BackButton from '../../components/backButton/BackButton';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/user';

/* Utils */
import {
  userGtmEventHandler,
} from '../../utils';

/* Styles */
import './ProfilesErrorPage.scss';

function GeneralErrors({
  intl: { formatMessage },
  history,
  onClick,
}) {
  const location = useLocation();
  const sourceError = location?.state.source;
  const platformError = 'WEB';
  const locationError = location?.state.location;
  const typeError = location?.state.type;
  const isExternCode = !!location?.state.externCode;
  const externCodeError = isExternCode ? location?.state.externCode : '';
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const todayFormat = today.toISOString();
  const [profiles] = useState(JSON.parse(localStorage.getItem(PROFILES_STORAGE.profiles)));
  const isOnline = window.navigator.onLine;

  const { gtmUserData } = useGtmContext();

  const gtmData = useMemo(() => ({
    errorCode: externCodeError.toString(),
    errorType: typeError.toString(),
  }), [externCodeError, typeError]);

  useEffect(() => {
    if (profiles?.length > 0) {
      userGtmEventHandler(
        gtmDimensions.multiple_profile.create_profile.error.page_view,
        gtmUserData,
        {
          ...gtmData,
          content: {
            changeParentalControl: 'false',
          },
        },
      );
    } else {
      userGtmEventHandler(
        gtmDimensions.first_access.first_profile_creation.error.page_view,
        gtmUserData,
        {
          ...gtmData,
          content: {
            changeParentalControl: 'false',
          },
        },
      );
    }
  }, [profiles, gtmUserData, gtmData]);

  useEffect(() => {
    if (isOnline) {
      switch (locationError) {
        case 'PROFILE_CREATE': {
          setErrorTitle(
            formatMessage({ id: 'profiles.userProfile.profilesErrorPage.title.create', defaultMessage: 'El perfil no fue creado' }),
          );
          setErrorMessage(
            formatMessage({ id: 'profiles.userProfile.profilesErrorPage.description.create', defaultMessage: 'Estamos revisando lo que sucedió, para que pueda crear este perfil.' }),
          );
          break;
        }
        case 'PROFILE_DELETE': {
          setErrorTitle(
            formatMessage({ id: 'profiles.userProfile.profilesErrorPage.title.delete', defaultMessage: 'Este perfil no fue eliminado' }),
          );
          setErrorMessage(
            formatMessage({ id: 'profiles.userProfile.profilesErrorPage.description.delete', defaultMessage: 'Estamos revisando lo que sucedió, para que pueda eliminar este perfil.' }),
          );
          break;
        }
        case 'PROFILE_EDIT': {
          setErrorTitle(
            formatMessage({ id: 'profiles.userProfile.profilesErrorPage.title.edit', defaultMessage: 'El perfil no fue editado' }),
          );
          setErrorMessage(
            formatMessage({ id: 'profiles.userProfile.profilesErrorPage.description.edit', defaultMessage: 'Estamos revisando lo que sucedió, para que pueda editar este perfil.' }),
          );
          break;
        }
        default: {
          break;
        }
      }
    } else {
      setErrorTitle(
        formatMessage({ id: 'profiles.userProfile.profilesErrorPage.title.internet', defaultMessage: '¡Revisa tu internet!' }),
      );
      setErrorMessage(
        formatMessage({ id: 'profiles.userProfile.profilesErrorPage.description.internet', defaultMessage: 'Asegúrate que el WiFi o el cable de red esten conectados para continuar.' }),
      );
    }
  }, [formatMessage, isOnline, locationError]);

  const handleClick = () => {
    if (profiles?.length > 0) {
      userGtmEventHandler(
        gtmDimensions.multiple_profile.create_profile.error.ok,
        gtmUserData,
        gtmData,
      );
    } else {
      userGtmEventHandler(
        gtmDimensions.first_access.first_profile_creation.error.try_Again,
        gtmUserData,
        gtmData,
      );
    }
    if (onClick) {
      onClick();
    } else if (history) {
      history.goBack();
    }
  };

  function sendHashGoBack() {
    if (profiles?.length > 0) {
      userGtmEventHandler(
        gtmDimensions.multiple_profile.create_profile.error.back,
        gtmUserData,
        gtmData,
      );
    } else {
      userGtmEventHandler(
        gtmDimensions.first_access.first_profile_creation.error.back,
        gtmUserData,
        gtmData,
      );
    }
    history.push(PATHS.USER_PROFILE);
  }

  return (
    <div className="dtv-web-error-page">
      <NavBarProfile />
      <div className="dtv-web-error-page--container">
        <div className="dtv-web-error-page--container-arrow-back">
          <BackButton
            backIcon={<img src={IconBack} alt="arrow-back" />}
            onClick={() => sendHashGoBack()}
          />
        </div>
        <img src={getImage('iconUserError') || UserError} alt="dtv Profile Error" className="dtv-web-error-page--profile_error" />
        <Title
          title={errorTitle || formatMessage({ id: 'profiles.userProfile.profilesErrorPage.title', defaultMessage: 'Ups! Algo salió mal' })}
          description={errorMessage || formatMessage({ id: 'profiles.userProfile.profilesErrorPage.description.default', defaultMessage: 'Estamos revisando lo que sucedió, espera unos minutos e inténtalo de nuevo.' })}
          descriptionExists
        />
        <div className="dtv-web-error-page--moreDetails">
          <CodeError
            text={`${formatMessage({ id: 'common.code', defaultMessage: 'Código' })}:`}
            sourceError={sourceError}
            platformError={platformError}
            locationError={locationError}
            typeError={typeError}
            isExternCode={isExternCode}
            externCode={externCodeError}
          />
        </div>
        <div className="dtv-web-error-page--buttonAlignment">
          <Button
            secondBlue
            bordered
            className="dtv-btn-primary"
            onClick={() => handleClick()}
          >
            {profiles?.length > 0 ? (
              formatMessage({ id: 'profiles.userProfile.profilesErrorPage.buttonOk', defaultMessage: 'OK entendí' })
            ) : (
              formatMessage({ id: 'profiles.userProfile.profilesErrorPage.buttonTry', defaultMessage: 'Inténtalo de nuevo' })
            )}
          </Button>
        </div>
        <div className="dtv-web-error-page--info">
          <p>MV010000114200000000000</p>
          <p>{import.meta.env.REACT_APP_DATA_DOG_APPLICATION_ID}</p>
          {sourceError === 'MIDDLEWARE' ? (<p>MIDDLEWARE</p>) : ''}
          <p>{todayFormat}</p>
          <p>{APP_VERSION}</p>
        </div>
      </div>
    </div>
  );
}

GeneralErrors.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
  onClick: PropTypes.func,
};

GeneralErrors.defaultProps = {
  intl: {
    formatMessage: () => {},
  },
  history: {
    push: PropTypes.func,
    goBack: PropTypes.func,
  },
  onClick: undefined,
};

export default injectIntl(GeneralErrors);
