/**
 * External
 */
import React, { Component } from 'react';
import { differenceInMinutes, setSeconds } from 'date-fns';

/**
 * Components
 */
import './LiveProgressBar.scss';

class LiveProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finishedTime: false,
    };
  }

  componentDidMount() {
    this.currentTime(this.props);
  }

  componentDidUpdate() {
    this.currentTime(this.props);
  }

  componentWillUnmount() {
    clearInterval(this.intervalMoveCircle);
  }

  /**
   * @description Method that move the current time circle.
   * @method currentTime
   * @param props current props
   */
  currentTime = ({
    endTime,
    typeCard,
    startTime,
    width = 300,
    onHandleFinalized,
  }) => {
    const newStartTime = setSeconds(new Date(startTime), 0);
    const newEndTime = setSeconds(new Date(endTime), 0);
    const durationTime = differenceInMinutes(newEndTime, newStartTime);
    const iteration = width / differenceInMinutes(new Date(endTime), newStartTime);
    const elapsedTime = differenceInMinutes(new Date(), newStartTime);
    const { circle, shadow, state: { finishedTime } } = this;

    if (iteration !== Number.POSITIVE_INFINITY && typeCard === 1 && !finishedTime) {
      circle.style.display = 'inherit';
      const percentage = `${((elapsedTime * 100) / durationTime).toFixed(0)}%`;
      circle.style.left = percentage;
      shadow.style.width = percentage;
      this.intervalMoveCircle = setInterval(() => {
        const internalElapsedTime = differenceInMinutes(new Date(), newStartTime);
        const internalPercentage = `${((internalElapsedTime * 100) / durationTime).toFixed(0)}%`;
        circle.style.left = internalPercentage;
        shadow.style.width = internalPercentage;
        if (durationTime === internalElapsedTime) {
          this.setState({ finishedTime: true });
          if (onHandleFinalized) onHandleFinalized();
        }
      }, 1000);
    }
  };

  render() {
    const { className = '' } = this.props;
    const { finishedTime } = this.state;

    if (finishedTime) {
      clearInterval(this.intervalMoveCircle);
    }

    return finishedTime ? (
      null
    ) : (
      <div className={`dtv-progress-container ${className}`}>
        <div className="dtv-progress-circle" ref={(div) => { this.circle = div; }} />
        <div className="dtv-progress" />
        <div className="dtv-progress-shadow" ref={(div) => { this.shadow = div; }} />
      </div>
    );
  }
}

export default LiveProgressBar;
