/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getL from 'lodash-es/get';
import isEmptyL from 'lodash-es/isEmpty';
import indexOfL from 'lodash-es/indexOf';

/**
 * Actions
 */
import { updatePlaySource as updatePlaySourceAction } from '../../state/player/actions';
import { setHistoryPathName as setHistoryPathNameAction } from '../../state/history/actions';
import { setSearchInteraction as setSearchInteractionAction } from '../../state/search/searchSlice';

/**
 * Other
 */
import {
  INTERACTION_PLAY,
  INTERACTION_PDP,
  INTERACTIONS_TO_REGISTER_IN_THE_DATALAYER_FOR_LIVE,
  INTERACTIONS_TO_REGISTER_IN_THE_DATALAYER_FOR_VOD,
  redirectToPdp,
  redirectToPlayer,
} from '../../utils';
import { history } from '../../state/configureStore';
import { useCastActions, useIsCastConnected } from '../chromecastConnectionBar';

function AssetToPlay({
  allowPlay,
  asset: {
    assetId, channelId = '', playSource = '', startTime = '', type,
    shouldDisplayLockIcon = false,
  },
  children,
  className,
  fromEpg,
  fromEpgFilterId,
  fromEpgRedirectionIndex,
  isLive,
  isSpan,
  isUserLoggedIn,
  onClick,
  path,
  redirectToDetailsFlag,
  replace,
  searchTerm,
  setHistoryPathName,
  setSearchInteraction,
  showSearch,
  style,
  updatePlaySource,
}) {
  const isChromecastConnected = useIsCastConnected();
  const { loadMedia } = useCastActions();

  const handleClick = (e) => {
    const avoidPlay = onClick ? onClick(e) || false : false;
    if (isUserLoggedIn && allowPlay && !avoidPlay) {
      const interactionWithSearch = showSearch
          && (indexOfL(INTERACTIONS_TO_REGISTER_IN_THE_DATALAYER_FOR_LIVE, type) !== -1
            || indexOfL(INTERACTIONS_TO_REGISTER_IN_THE_DATALAYER_FOR_VOD, type) !== -1);
      if (redirectToDetailsFlag || shouldDisplayLockIcon) {
        if (interactionWithSearch) {
          setSearchInteraction({ searchTerm, assetId, action: INTERACTION_PDP });
        }
        redirectToPdp({
          assetId: isLive ? channelId : assetId,
          type,
          startTime,
          history,
          isLive,
        });
      } else {
        if (interactionWithSearch) {
          setSearchInteraction({ searchTerm, assetId, action: INTERACTION_PLAY });
        }
        updatePlaySource(playSource);
        if (isChromecastConnected) {
          loadMedia({
            assetId: isLive ? channelId : assetId,
            isLive,
            playSource,
          });
        } else {
          if (path) setHistoryPathName({ pathname: path });
          const { redirect } = redirectToPlayer({
            fromEpg,
            fromEpgFilterId,
            fromEpgRedirectionIndex,
            history,
            assetId,
            replace,
            type,
            channelId,
            isLive,
          });
          if (redirect) redirect();
        }
      }
    }
  };

  const Tag = isSpan ? 'span' : 'div';
  return (
    <Tag
      style={style}
      className={className}
      onClick={handleClick}
    >
      {children}
    </Tag>
  );
}

AssetToPlay.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.string,
    type: PropTypes.string.isRequired,
    channelId: PropTypes.string,
    shouldDisplayLockIcon: PropTypes.bool,
  }),
  style: PropTypes.shape(),
  className: PropTypes.string,
  isSpan: PropTypes.bool,
  allowPlay: PropTypes.bool,
  redirectToDetailsFlag: PropTypes.bool,
  onClick: PropTypes.func,
  fromEpgFilterId: PropTypes.number,
  fromEpgRedirectionIndex: PropTypes.number,
  fromEpg: PropTypes.bool,
  isLive: PropTypes.bool,
  replace: PropTypes.bool,
};

AssetToPlay.defaultProps = {
  asset: {},
  style: {},
  className: '',
  isSpan: false,
  allowPlay: true,
  redirectToDetailsFlag: false,
  onClick: () => {},
  fromEpgFilterId: null,
  fromEpgRedirectionIndex: null,
  fromEpg: false,
  isLive: false,
  replace: false,
};

function mapStateToProps({
  search: { searchTerm, showSearch } = {},
  user,
}) {
  const account = getL(user, 'account', {});
  let isUserLoggedIn = false;

  if (!isEmptyL(account)) {
    isUserLoggedIn = account.isTVE || (!account.isTVE && account.uid);
  }

  return {
    isUserLoggedIn,
    searchTerm,
    showSearch,
  };
}

export default connect(mapStateToProps, {
  setHistoryPathName: setHistoryPathNameAction,
  updatePlaySource: updatePlaySourceAction,
  setSearchInteraction: setSearchInteractionAction,
})(AssetToPlay);
