/* External */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/* Utils */
import { IS_BRAZIL } from '../../utils';

/* Utils */
import { stepsBrazil } from './StepsBR';
import { stepsSSLA } from './StepsSSLA';

/* Style */
import './StepClassification.scss';

function StepClassification(props) {
  const {
    activeStep, setActiveStep,
  } = props;
  const [changeActiveStep, setChangeActiveStep] = useState(activeStep);
  const [width, setWidth] = useState();
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (IS_BRAZIL) {
      setSteps(stepsBrazil);
    } else {
      setSteps(stepsSSLA);
    }
  }, []);

  useEffect(() => {
    const totalSteps = steps.length;
    setWidth(`${(100 / (totalSteps - 1)) * (changeActiveStep - 1)}%`);
  }, [changeActiveStep, steps.length]);

  function changeClassification(step) {
    setChangeActiveStep(step);
    setActiveStep(step);
  }

  return (
    <div className="step-classification">
      <div className="step-classification__container">
        <span className="step-classification__container-line" style={{ width }} />
        {steps.map(({ step, label }) => (
          <button type="button" key={step} onClick={() => changeClassification(step)} className="step-classification__container-wrapper">
            <div className={`step-classification__container-background ${changeActiveStep >= step ? 'active' : ''}`}>
              {step <= changeActiveStep && <div className="step-classification__container-circle" /> }
            </div>
            <div className="step-classification__container-label-container">
              <div className="step-classification__container-label">{label}</div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

StepClassification.propTypes = {
  activeStep: PropTypes.string,
  setActiveStep: PropTypes.func,
};

StepClassification.defaultProps = {
  activeStep: '',
  setActiveStep: undefined,
};

export default StepClassification;
