/* External */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  differenceInMinutes, isBefore, isWithinInterval, setSeconds,
} from 'date-fns';
import classNames from 'classnames';
import { useRouteMatch } from 'react-router';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';

/* Assets */
import genericCardLive from '@/assets/theme/images/GenericCardLive.svg';

/* Components */
import AssetToPlay from '../assetToPlay/AssetToPlay';
import LiveProgressBar from '../liveProgressBar/LiveProgressBar';
import AgeRating from '../ageRating/AgeRating';
import LiveStationLine from '../liveStationLine/LiveStationLine';
import LockIcon, { CARD_TYPES } from '../lockIcon/LockIcon';
import { useGtmContext } from '../GtmContext';

/* Styles */
import './ScheduleCard.scss';

/* Other */
import {
  formatSeasonAndEpisode,
  getAssetNameWithQualifier,
  GRADIENTS,
  joinArrayWithSeparator,
  redirectToPdp,
  useImage,
  lockIconSelector,
} from '../../utils';
import { history } from '../../state/configureStore';
import { gtmWatchCarouselEventHandler } from '../GtmContext/dimensions/carousels/eventHandlers';
import { getImage } from '../../theme/images';

function ScheduleCard({
  channelName: channelNameFromProps,
  infoCard: asset,
  infoCard: {
    episode,
    genres,
    images = [],
    live,
    playSource,
    rating,
    shortTitle,
    title,
    type,
    vrioAssetId,
    shouldDisplayLockIcon,
  },
  carouselInfo,
  intl: { formatMessage },
}) {
  const {
    setGtmPlaySource,
    setGtmSectionName,
    setGtmComponentType,
    setGtmCarouselVerticalPosition,
    gtmUserData,
  } = useGtmContext();
  const { params: locationParams } = useRouteMatch();
  const genericCardLiveFlag = getImage('genericCardLive') || genericCardLive;
  const { imageRef, imageStyle } = useImage(images, genericCardLiveFlag, GRADIENTS.CARD_LIVE, '16:9', false, title);
  const {
    blackout,
    channelId: currentChannelId,
    channelName: channelNameFromAsset,
    endTime,
    qualifiers = [],
    startTime,
  } = live || {};
  const { number: episodeNumber, seasonNumber, showName } = episode || {};
  const textUnavailable = formatMessage({ id: 'common.unavailable', defaultMessage: 'No disponible' });
  const channelName = channelNameFromAsset || channelNameFromProps;

  const { shouldDisplayLockIcon: displayLockIcon, lockIconType } = lockIconSelector({
    shouldDisplayLockIcon,
    assetRating: rating,
  });

  const isLiveContent = () => {
    const currentDate = new Date();
    const start = setSeconds(new Date(startTime), 0);
    const end = setSeconds(new Date(endTime), 0);

    if (isBefore(start, end) && isWithinInterval(currentDate, { start, end })) {
      return !!(differenceInMinutes(end, currentDate) + 1);
    }
    return false;
  };

  const getProgressBar = () => (isLiveContent() && !blackout ? (
    <LiveProgressBar typeCard={1} startTime={startTime} endTime={endTime} className="dtv-card-live-progress" />
  ) : null);

  const getImageSchedule = () => (
    <div
      ref={imageRef}
      className={classNames('dtv-card-live-img', { 'dtv-card-live-blackout-img': blackout })}
      style={imageStyle}
    >
      {displayLockIcon && (
        <LockIcon cardType={CARD_TYPES.channelWithScheduleCard} iconType={lockIconType} />
      )}
      {getProgressBar()}
    </div>
  );

  const getTitle = () => {
    const baseTitle = type === assetTypes.liveEpisode ? showName : title;
    const titleWithQualifier = getAssetNameWithQualifier(
      qualifiers,
      baseTitle || textUnavailable,
      formatMessage,
    );

    return titleWithQualifier ? <h3 className="dtv-card-live-info-title">{titleWithQualifier}</h3> : null;
  };

  const getSubtitle = () => {
    let subtitle = shortTitle;

    if (type === assetTypes.liveEpisode) {
      subtitle = joinArrayWithSeparator([
        formatSeasonAndEpisode(seasonNumber, episodeNumber, formatMessage),
        title,
      ]);
    }

    if (type === assetTypes.liveMovie) {
      const genresArray = genres && genres.length > 0 ? genres.map((genre) => (genre.title ? genre.title : genre || '')) : [];

      subtitle = joinArrayWithSeparator(genresArray, ', ');
    }

    return (
      <span className="dtv-card-live-info-subtitle">
        <AgeRating rating={rating} />
        {subtitle && <h4 className="dtv-card-live-info-subtitle-text">{subtitle}</h4>}
      </span>
    );
  };

  /**
   * @method handleClick
   * @description Handles card click when it doesn't redirect to player
   */
  const handleClick = () => {
    setGtmCarouselVerticalPosition(carouselInfo.carouselVerticalPosition);
    setGtmPlaySource(playSource);
    setGtmSectionName(carouselInfo.sectionTitle);
    setGtmComponentType(carouselInfo.type);
    if (!isLiveContent() && !blackout) {
      redirectToPdp({
        assetId: live?.channelId,
        type,
        startTime,
        history,
        isLive: !!startTime,
      });
    }

    gtmWatchCarouselEventHandler({
      asset,
      gtmUserData,
      locationParams,
      carouselInfo,
    });
  };

  return (
    <AssetToPlay
      className={classNames('dtv-card-live', { 'dtv-card-live-blackout': blackout })}
      onClick={handleClick}
      asset={{
        assetId: vrioAssetId,
        type,
        channelId: currentChannelId,
        playSource,
        shouldDisplayLockIcon,
        startTime,
      }}
      allowPlay={isLiveContent()}
      isLive
    >
      {getImageSchedule()}
      {blackout ? (
        <p className="dtv-card-live-blackout-text">
          {formatMessage({
            id: 'common.contentUnavailable',
            defaultMessage: 'Este contenido no se encuentra actualmente disponible en tu región',
          })}
        </p>
      ) : (
        <div className="dtv-card-live-info">
          <div>
            {getTitle()}
            {getSubtitle()}
          </div>

          <LiveStationLine {...
            {
              startTime, endTime, channelName,
            }
          }
          />
        </div>
      )}
    </AssetToPlay>
  );
}

ScheduleCard.propTypes = {
  infoCard: PropTypes.shape({
    episode: PropTypes.number,
    genres: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape({ title: PropTypes.string }),
      PropTypes.string,
    ])),
    id: PropTypes.string,
    live: PropTypes.shape({
      blackout: PropTypes.bool,
      endTime: PropTypes.string,
      channelId: PropTypes.string,
      channelName: PropTypes.string,
      qualifiers: PropTypes.arrayOf(PropTypes.string),
      startTime: PropTypes.string,
    }),
    title: PropTypes.string,
    playSource: PropTypes.string,
    rating: PropTypes.string,
    season: PropTypes.number,
    shortTitle: PropTypes.string,
    showName: PropTypes.string,
    shouldDisplayLockIcon: PropTypes.bool,
    images: PropTypes.shape(),
    type: PropTypes.string,
    vrioAssetId: PropTypes.string,
  }),
  intl: intlShape.isRequired,
  carouselInfo: PropTypes.shape(),
  channelName: PropTypes.string,
};

ScheduleCard.defaultProps = {
  infoCard: {
    episode: 0,
    genres: [],
    id: '',
    live: {
      blackout: false,
      channelId: '',
      channelName: '',
      endTime: '',
      qualifiers: [],
      startTime: '',
    },
    title: '',
    playSource: '',
    rating: '',
    season: 0,
    shortTitle: '',
    shouldDisplayLockIcon: false,
    type: '',
    vrioAssetId: '',
  },
  carouselInfo: {},
  channelName: '',
};

export default injectIntl(ScheduleCard);
