/* External */
import isEmptyL from 'lodash-es/isEmpty';
import jwtDecode from 'jwt-decode';

/* Other */
import {
  IS_BRAZIL,
  LANGUAGES,
  ageRatingBR,
  ageRatingSSLA,
  userCountry,
} from './constants';
import { getAuthTokensData } from './localStorageState';

import { PARENTAL_COUNTRIES } from './env';

const GOOGLE_IN_APP_BILLING = 'googleInAppBilling';
const ROKU_PAYMENT_USER = 'roku';

export const getUserLanguages = (user = {}) => {
  let {
    account: { profile: { customData: { preferences: { language } = {} } = {} } = {} } = {},
  } = user;
  if (isEmptyL(language)) {
    language = {
      client: LANGUAGES.SPANISH_LANGUAGE,
      audio: null,
      subtitles: null,
    };
  } else {
    const { client } = language;

    if (!client) {
      language = {
        ...language,
        client: LANGUAGES.SPANISH_LANGUAGE,
      };
    }
  }

  return language;
};

/**
 * @method getUserLanguageFromEnvironment
 * @description Returns the language for the environment. Spanish or portuguese
 */
export const getUserLanguageFromEnvironment = () => (
  IS_BRAZIL ? LANGUAGES.PORTUGUESE_LANGUAGE : LANGUAGES.SPANISH_LANGUAGE
);

export const getUserLanguageFromEnvironmentPublicPdp = () => (
  IS_BRAZIL ? LANGUAGES.PORTUGUESE_LANGUAGE : LANGUAGES.SPANISH_LANGUAGE
);

/**
 * @method isUserLoggedIn
 * @description Method that validate if the user object has values of a user active.
 *
 */
export const isUserLoggedIn = () => {
  const { idToken } = getAuthTokensData();

  return !!idToken;
};

export const isRokuPayUser = (user) => user.account.msoProvider === ROKU_PAYMENT_USER;
export const isGoogleInAppBillingUser = ({ msoProvider }) => msoProvider === GOOGLE_IN_APP_BILLING;

export const checkRedirectProfile = (profiles) => {
  // const profileSelected = localStorage.getItem(PROFILES_STORAGE.profile);
  if (profiles?.length === 0) {
    return { pathname: '/user/onboarding' };
  }

  // if (profiles?.length !== 0 && profileSelected) {
  //   return { pathname: '/home/main' };
  // }

  return false;
};

export const isFrontPorchUser = () => {
  const { idToken } = getAuthTokensData();
  const { channels } = idToken ? jwtDecode(idToken) : {};

  return channels.length === 0;
};

export const getUserDetailsToken = () => {
  const { idToken } = getAuthTokensData();
  const {
    sub,
    channels,
    regions,
    iso2Code,
    familyName,
    givenName,
    name,
    msoProvider,
    businessUnit,
    selfcareUrl,
  } = idToken ? jwtDecode(idToken) : {};

  return {
    sub,
    channels,
    regions,
    iso2Code,
    familyName,
    givenName,
    name,
    msoProvider,
    businessUnit,
    selfcareUrl,
  };
};

export const featureFlagParentalControl = () => {
  const featureFlag = PARENTAL_COUNTRIES.has(userCountry);
  return featureFlag;
};

export const ageRatingSelect = (activeStep) => {
  if (featureFlagParentalControl()) {
    if (IS_BRAZIL) {
      return ageRatingBR[activeStep];
    }
    return ageRatingSSLA[activeStep];
  }
  return '18';
};
