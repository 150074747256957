/* External */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

function PlayPauseButton({ intl: { formatMessage }, isPlaying, playOrPause }) {
  return (
    <button
      className="dtv-chromecast-bar-player-play-or-pause"
      type="button"
      title={formatMessage({ id: isPlaying ? 'player.pause' : 'player.play' })}
      onClick={playOrPause}
    >
      <span className={isPlaying ? 'dtv-icon-pause' : 'dtv-icon-play'} />
    </button>
  );
}

PlayPauseButton.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isPlaying: PropTypes.bool.isRequired,
  playOrPause: PropTypes.func.isRequired,
};

export default injectIntl(memo(PlayPauseButton));
