/* External */
import {
  useState, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import cloneDeepL from 'lodash-es/cloneDeep';
import unionByL from 'lodash-es/unionBy';

/* Other */
import {
  EPG,
} from '../utils';
import { api } from '../state/configureStore';

export const useGetSchedules = ({
  schedulesPerRequest,
  channelsList,
  setChannelsList,
}) => {
  const dispatch = useDispatch();
  const [mostWatchedChannelsWithSchedules, setMostWatchedChannelsWithSchedules] = useState([]);

  const getMoreSchedules = useCallback(
    async ({
      channels, start, end, click, reset,
    }) => {
      const channelsToReturn = cloneDeepL(channels);
      const channelIds = channels
        .filter(({ schedules }) => schedules)
        .map(({ channelId }) => channelId);

      try {
        const { data: fetchedChannels = [] } = await dispatch(
          api.endpoints.getSchedules.initiate({
            channelId: channelIds,
            startTime: start,
            endTime: end,
            pageSize: channelIds.length * schedulesPerRequest,
            assetToken: false,
          }),
        );

        fetchedChannels.forEach(
          ({
            channelId: fetchedChannelId,
            schedules: fetchedSchedules,
          }) => {
            const channel = channelsToReturn.find(
              ({ channelId }) => channelId === fetchedChannelId,
            );
            if (reset) {
              channel.schedules = fetchedSchedules;
            } else {
              channel.schedules = click === EPG.RIGHT_CLICK
                ? unionByL(channel.schedules, fetchedSchedules, 'live.startTime')
                : unionByL(fetchedSchedules, channel.schedules, 'live.startTime');
            }
          },
        );
      } catch (error) {
        console.error('Epg-fetchSchedules', error);
      }
      return channelsToReturn;
    },
    [dispatch, schedulesPerRequest],
  );

  const fetchSchedules = useCallback(
    async ({
      start, end, click, reset,
    }) => {
      if (mostWatchedChannelsWithSchedules.length > 0) {
        const newMostWatchedChannelsWithSchedules = await getMoreSchedules({
          channels: mostWatchedChannelsWithSchedules,
          start,
          end,
          click,
          reset,
        });

        setMostWatchedChannelsWithSchedules(newMostWatchedChannelsWithSchedules);
      }

      const newChannelsWithSchedules = await getMoreSchedules({
        channels: channelsList,
        start,
        end,
        click,
        reset,
      });

      setChannelsList(newChannelsWithSchedules);
    },
    [
      mostWatchedChannelsWithSchedules,
      getMoreSchedules,
      channelsList,
      setChannelsList,
    ],
  );

  return {
    fetchSchedules,
  };
};
