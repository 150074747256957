/**
 * External
 */
import window from 'global/window';

function init() {
  const store = {
    setItem(id, val) {
      store[id] = String(val);
      return store[id];
    },
    getItem(id) {
      return Object.prototype.hasOwnProperty.call(store, id) ? String(store[id]) : undefined;
    },
    removeItem(id) {
      return delete store[id];
    },
    clear() {
      init();
    },
  };

  window.localStorage = store;
}

function isSupported() {
  try {
    return 'localStorage' in window && window.localStorage !== null;
  } catch {
    return false;
  }
}

(function localStoragePolyfill() {
  if (!isSupported()) {
    init();
  }
}());
