/**
 * External
 */
import {
  addDays, format, isAfter, isBefore, isSameDay, isWithinInterval, setSeconds, subDays,
} from 'date-fns';
import capitalizeL from 'lodash-es/capitalize';

/**
 * Other
 */
import { CARD_TYPES, LIVE_PROGRAM_TYPES } from './constants';
import { getDiffDuration } from './dates';

/**
 * @description Method to go get the fromEpgFilterId param from history.
 * @method getEpgFilterIdFromHistory
 */
export function getEpgFilterIdFromHistory(history) {
  const { state } = history.location;
  let filterId = null;

  if (state && state.fromEpgFilterId) filterId = state.fromEpgFilterId;

  return filterId;
}

/** @description Given an array of schedules, returns the one that's live now.
 * @param {object} schedules
 * @param {date} fromDatetime
 * @returns schedule currently live (if it exists)
 */
export function getLiveNow(schedules, fromDatetime = new Date()) {
  return (
    schedules
    && schedules.findIndex((schedule) => {
      const { startTime, endTime } = schedule;
      const currentDate = new Date(fromDatetime);
      const formattedStartTime = setSeconds(new Date(startTime), 0);
      const formattedEndTime = setSeconds(new Date(endTime), 0);
      if (
        isBefore(formattedStartTime, formattedEndTime)
        && isWithinInterval(currentDate, { start: formattedStartTime, end: formattedEndTime })
      ) {
        return true;
      }
      return false;
    })
  );
}

/**
 * @method getCardType
 * @param {date} startTime
 * @param {date} endTime
 */
export function getCardType(startTime, endTime, currentTime) {
  if (isBefore(endTime, currentTime)) {
    return CARD_TYPES.PROGRAM_PAST;
  }
  if (
    isBefore(startTime, endTime)
    && isWithinInterval(currentTime, { start: startTime, end: endTime })
  ) {
    return CARD_TYPES.PROGRAM_PRESENT;
  }
  return CARD_TYPES.PROGRAM_FUTURE;
}

/**
 * @see DGD-45226 Live upcoming
 * @method formatLiveStartsAt
 * @param {date} start
 * @param {function} formatMessage - intl injected
 * @param {function} formatDate - intl injected
 * @returns {string} ~ "Comienza en 15 min"|"hoy 15:00"|"mañana 22:30"|"Sabado 28, 15:20"
 */
export function formatLiveStartsAt(start, formatMessage, formatDate) {
  const now = new Date();
  const timeDifference = getDiffDuration(start, now);
  const { days } = timeDifference;
  const { hours } = timeDifference;
  const { minutes } = timeDifference;
  let r = '';
  r = days === 0 && hours < 1 ? formatMessage(
    {
      id: 'live.startsAtLessThan1Hour',
      defaultMessage: 'Comienza en {minutes} min',
    },
    {
      minutes,
    },
  ) : `${getDaySelectedText(start, formatMessage, formatDate, { endDayName: ',' })} ${formatDate(start, {
    hour: 'numeric',
    minute: 'numeric',
  })}`;
  return r;
}

/**
 * @description Get label according with the present day and the start day
 * @method getDaySelectedText
 * @param {date} startTime
 * @param {function} formatMessage
 * @param {function} formatDate
 * @param {object} arg
 * @param {string} arg.endYesteday
 * @param {string} arg.endToday
 * @param {string} arg.endTomorrow
 * @param {string} arg.endDayName
 * @returns {string} ~ "Hoy"|"Ayer"|"Mañana"|"Sabado 15"
 */
export function getDaySelectedText(
  startTime,
  formatMessage,
  formatDate,
  {
    endYesteday = '', endToday = '', endTomorrow = '', endDayName = '',
  } = {},
) {
  const today = new Date();
  const yesterday = subDays(today, 1);
  const tomorrow = addDays(today, 1);
  const formattedStartTime = setSeconds(new Date(startTime), 0);
  const formatTime = { weekday: 'long' };
  let daySelectedText = '';

  if (isSameDay(today, formattedStartTime)) {
    daySelectedText = `${formatMessage({ id: 'common.today', defaultMessage: 'Today' })}${endYesteday}`;
  } else if (isSameDay(yesterday, formattedStartTime)) {
    daySelectedText = `${formatMessage({ id: 'common.yesterday', defaultMessage: 'Yesterday' })}${endToday}`;
  } else if (isSameDay(tomorrow, formattedStartTime)) {
    daySelectedText = `${formatMessage({ id: 'common.tomorrow', defaultMessage: 'Tomorrow' })}${endTomorrow}`;
  } else {
    daySelectedText = `${capitalizeL(formatDate(formattedStartTime, formatTime))} ${format(
      formattedStartTime,
      'd',
    )}${endDayName}`;
  }

  return daySelectedText;
}

/**
 * @description Method that checks if an asset is past, present or future
 * @param {date} startTime
 * @param {date} endTime
 * @method isPastPresentOrFuture
 */
export function isPastPresentOrFuture(startTime, endTime) {
  if (!startTime || !endTime) return false;

  const currentTime = new Date();
  const start = new Date(startTime);
  const end = new Date(endTime);

  if (isAfter(start, end)) {
    return false;
  }
  if (isAfter(currentTime, end)) {
    return LIVE_PROGRAM_TYPES.PAST;
  }
  if (isWithinInterval(currentTime, { start, end })) {
    return LIVE_PROGRAM_TYPES.PRESENT;
  }
  return LIVE_PROGRAM_TYPES.FUTURE;
}

export const EPG_CARD_TYPES = {
  PAST: 'past',
  LIVE: 'live',
  FUTURE: 'future',
};

/**
 * @method getEpgCardType
 * @param {Object} schedule
 */
export function getEpgCardType(start, end) {
  const currentTime = setSeconds(new Date(), 0);

  if (isBefore(end, currentTime)) {
    return EPG_CARD_TYPES.PAST;
  }
  if (isBefore(start, end) && isWithinInterval(currentTime, { start, end })) {
    return EPG_CARD_TYPES.LIVE;
  }
  return EPG_CARD_TYPES.FUTURE;
}

export const DEFAULT_MOST_WATCHED_CHANNELS_BATCH_SIZE = 5;
