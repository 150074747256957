/**
 * External
 */
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Components
 */
import { CHANGE_PASSWORD } from './GeneralContainer';

/**
 * Actions
 */
import { showToastNotification } from '../../../state/notifications/actions';

/**
 * Styles
 */
import './General.scss';

/**
 * Other
 */
import { AEM_PLATFORM, USER_ACCOUNT_TYPES, getPartnerChangePasswordURL } from '../../../utils';
import { api } from '../../../state/configureStore';

function General({ changeComponent }) {
  const {
    data: { partners: partnersConfig = [] } = {},
  } = api.useGetAEMConfigurationQuery(AEM_PLATFORM);
  const dispatch = useDispatch();
  const { msoProvider, businessUnit } = useSelector(({ user }) => user.account);
  const notifications = useSelector(({ notifications: notif }) => notif);
  const toastNotification = notifications.get('toastNotification');

  useEffect(() => () => {
    if (toastNotification) dispatch(showToastNotification(null));
  }, [dispatch, toastNotification]);

  /**
   * @method changePassword
   * @description Sets selectedComponent value to change password in GeneralContainer component
   */
  const changePassword = () => {
    if (
      businessUnit === USER_ACCOUNT_TYPES.B2B2C
      || businessUnit === USER_ACCOUNT_TYPES.SKY_B2B2C
    ) {
      const changePasswordUrl = getPartnerChangePasswordURL(msoProvider, partnersConfig);
      if (changePasswordUrl) {
        window.open(changePasswordUrl);
        return;
      }
    }

    changeComponent(CHANGE_PASSWORD);
  };

  return (
    <div className="dtv-general-container">
      <span name="general" className="dtv-preferences-content-body-title">
        <FormattedMessage id="myProfile.option.general" defaultMessage="General" />
      </span>
      <p className="mt-4">
        <FormattedMessage id="myProfile.general.password" defaultMessage="Password" />
      </p>
      <div className="dtv-section">
        <span className="dtv-span-psw">&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</span>
        <button type="button" onClick={changePassword} className="dtv-resemble-a">
          <FormattedMessage id="myProfile.general.changePassword" defaultMessage="Cambiar contraseña" />
        </button>
      </div>
      <hr className="dtv-hr" />
    </div>
  );
}

export default General;
