/* External */
import React from 'react';
import PropTypes from 'prop-types';
import { addMinutes, format, isBefore } from 'date-fns';
import cn from 'classnames';
import uniqueIdL from 'lodash-es/uniqueId';

/* Styles */
import './EpgSlider.scss';

const EpgSlider = React.forwardRef(
  (
    {
      className,
      currentTime,
      currentTimeDisplacement,
      endTime,
      hideCurrentTimeLeft,
      hideCurrentTimeRight,
      onCurrentTimeClick,
      onLeftClick,
      onRightClick,
      showCurrentTime,
      startTime,
    },
    ref,
  ) => {
    const getTimeSteps = (start, end) => {
      let step = start;
      const timeSteps = [];

      while (isBefore(step, end)) {
        timeSteps.push(step.getMinutes() === 0 || step.getMinutes() === 30 ? format(step, 'HH:mm') : '|');
        step = addMinutes(step, 10);
      }

      return timeSteps.map((timeStep) => (
        <span key={uniqueIdL('schedule-content-item')} className="dtv-epg-slider__schedule-content-item">
          {timeStep === '|' ? (
            <div className="dtv-epg-slider__schedule-content-item-pipe">
              {timeStep}
            </div>
          ) : (
            <div className="dtv-epg-slider__schedule-content-item-clock">
              {timeStep}
            </div>
          )}
        </span>
      ));
    };

    return (
      <div className={cn('dtv-epg-slider', className)}>
        <span className="dtv-icon-arrow-back dtv-epg-slider__arrow" onClick={onLeftClick} />
        <div className="dtv-epg-slider__schedule" ref={ref}>
          {showCurrentTime && (
            <div
              className={cn('dtv-epg-slider__schedule-current-time', {
                'dtv-epg-slider__schedule-current-time--left': hideCurrentTimeLeft,
                'dtv-epg-slider__schedule-current-time--right': hideCurrentTimeRight,
              })}
              style={hideCurrentTimeLeft || hideCurrentTimeRight ? {} : { left: `${currentTimeDisplacement}px` }}
              onClick={onCurrentTimeClick}
            >
              {format(currentTime, 'HH:mm')}
            </div>
          )}
          <div className="dtv-epg-slider__schedule-content">{getTimeSteps(startTime, endTime)}</div>
        </div>
        <span className="dtv-icon-arrow-foward dtv-epg-slider__arrow" onClick={onRightClick} />
      </div>
    );
  },
);

EpgSlider.propTypes = {
  className: PropTypes.string,
  currentTime: PropTypes.instanceOf(Date),
  currentTimeDisplacement: PropTypes.number,
  endTime: PropTypes.instanceOf(Date),
  hideCurrentTimeLeft: PropTypes.bool,
  hideCurrentTimeRight: PropTypes.bool,
  onCurrentTimeClick: PropTypes.func,
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  showCurrentTime: PropTypes.bool,
  startTime: PropTypes.instanceOf(Date),
};

EpgSlider.defaultProps = {
  className: '',
  currentTime: new Date(),
  currentTimeDisplacement: 0,
  endTime: new Date(),
  hideCurrentTimeLeft: false,
  hideCurrentTimeRight: false,
  onCurrentTimeClick() {},
  onLeftClick() {},
  onRightClick() {},
  showCurrentTime: false,
  startTime: new Date(),
};

export default EpgSlider;
