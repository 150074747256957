/**
 * External
 */
import React from 'react';
import classNames from 'classnames';

/**
 * Styles
 */
import './Blur.scss';

function Blur({ bypass, children, ...rest }) {
  const className = bypass
    ? 'dtv-blur'
    : classNames({
      'dtv-blur': true,
      'dtv-blur-in': rest.in,
      'dtv-blur-out': !rest.in,
    });

  return <div className={className}>{children}</div>;
}

export default Blur;
