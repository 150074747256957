/**
 * External
 */
import { combineReducers } from 'redux';

/* Actions */
import { ACTION_TYPES } from './actions';

function playSource(state = '', { type, payload } = {}) {
  switch (type) {
    case ACTION_TYPES.UPDATE_PLAY_SOURCE:
      return payload;
    default:
      return state;
  }
}

export default combineReducers({
  playSource,
});
