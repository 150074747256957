/* External */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { injectIntl } from 'react-intl';

/* Assets */
import soccerBallIcon from '../../assets/icons/soccer-ball.svg';

/* Styles */
import './FifaPlayerSideButton.scss';

/* Utils */
import { getBooleanFromEnvVariable, ROUTES } from '../../utils';

function FifaPlayerSideButton({
  currentSchedule = {},
  intl: { formatMessage },
}) {
  const isFifaPlayerActive = getBooleanFromEnvVariable('REACT_APP_ENABLE_FIFA_PLAYER');
  const history = useHistory();

  const channelId = currentSchedule?.live?.channelId || '';
  const startTime = currentSchedule?.live?.startTime || '';

  const handleClick = () => {
    history.push(`${ROUTES.hbsPlayer.path}${channelId}?startTime=${startTime}`);
  };

  if (!isFifaPlayerActive) return null;

  return (
    <div className="dtv-fifa-side-button">
      <img className="dtv-fifa-side-button__icon" src={soccerBallIcon} alt="soccer ball" />
      <section>
        <p className="dtv-fifa-side-button__text">
          {formatMessage(
            { id: 'fifa.player.button.desc' },
          )}
        </p>
        <button type="button" className="dtv-fifa-side-button__open-player-button" onClick={handleClick}>
          {formatMessage(
            { id: 'fifa.player.button.title' },
          )}
        </button>
      </section>
    </div>
  );
}

FifaPlayerSideButton.propTypes = {
  currentSchedule: PropTypes.shape({}).isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
};

export default injectIntl(FifaPlayerSideButton);
