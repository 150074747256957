import { gtmEvent } from '@dtvgo/gtm-event-report';
import { getUserTypeByBusinessUnit, getMSOProviderFormatted } from '@dtvgo/utils';

import jwtDecode from 'jwt-decode';
import {
  APP_NAME,
  AEM_PLATFORM,
  BUSINESS_CAMPAIGN,
  NEW_CLIENT,
} from './constants';

import { getUserLanguages } from './users';

import { isBlockedByParentalControl, AVAILABLE_BR_RATING } from './profiles';

export const gtmConstantData = (idToken) => {
  const { client: lang } = getUserLanguages();
  const localStorageProfile = JSON.parse(localStorage.getItem('profile'));

  const {
    customerId,
    customerStatus,
    msoProvider,
    vrioCustomerId,
    businessUnit,
    iso2Code,
  } = jwtDecode(idToken);

  const loginType = getUserTypeByBusinessUnit(businessUnit);
  const billingStatus = BUSINESS_CAMPAIGN.has(customerStatus) ? NEW_CLIENT : customerStatus;

  const mso = getMSOProviderFormatted({ msoProvider, businessUnit });

  const gtmConstantDimensions = {
    v_client_id: customerId,
    v_user_id: vrioCustomerId,
    v_billing_status: billingStatus.toLowerCase(),
    v_mso: mso?.toLowerCase().replace(/\s+/g, '-'),
    v_language: lang,
    v_app_name: `${APP_NAME.toLowerCase()}-${AEM_PLATFORM}`,
    v_login_type: loginType?.toLowerCase(),
    v_admin_profile: localStorageProfile?.isMainProfile?.toString() || null,
    v_profile_id: localStorageProfile?.id || null,
    v_profile_custom_age: localStorageProfile?.isMainProfile
      ? AVAILABLE_BR_RATING.eighteen : localStorageProfile?.ageRating || null,
    v_profile_unrated_content: localStorageProfile?.isMainProfile ? 'true' : localStorageProfile?.isNotRatedContentAllowed?.toString() || null,
    v_isocode: iso2Code,
  };

  return gtmConstantDimensions;
};

export const gtmSelectedProfileData = ({ adminProfileData, selectedProfileData }) => {
  const formattedProfile = {
    ...adminProfileData,
    v_profile_id: selectedProfileData.id,
    v_admin_profile: selectedProfileData.isMainProfile?.toString() || null,
    v_profile_unrated_content: selectedProfileData.isNotRatedContentAllowed?.toString() || null,
    v_profile_custom_age: selectedProfileData?.ageRating === '' ? AVAILABLE_BR_RATING.eighteen : selectedProfileData?.ageRating || null,
  };
  return formattedProfile;
};

export const gtmTransmissionType = {
  movie: 'MOVIE',
  live: 'LIVE',
  live_show: 'LIVE_SHOW',
  live_episode: 'LIVE_EPISODE',
  live_movie: 'LIVE_MOVIE',
  serie: 'EPISODE',
  episode: 'SERIE',
  sport: 'SPORT',
  live_sport: 'LIVE_SPORT',
};

export const gtmErrorType = {
  blackout: 'BLACKOUT',
  concurrency_channel: 'CONCURRENCY_CHANNELS',
  concurrency_devices: 'CONCURRENCY_DEVICES',
  concurrency_provider: 'CONCURRENCY_PROVIDER',
  hdcp: 'HDCP',
  entitlements: 'ENTITLEMENTS',
  package_content: 'PACKAGE_CONTENT',
  unexpected: 'UNEXPECTED',
  parental_block_content: 'PARENTAL_BLOCK_CONTENT',
  isPackageContentErrorCNIP: 'M7-1632',
  isRefreshTokenExpired: 'M7-1750',
  cinp: 'BUS: AUTHORIZATION NIP',
};

export const gtmComponentType = {
  live_epg: {
    playSource: 'livelander epg',
    sectionName: 'epg',
    componentType: 'epg',
  },
};

export const gtmContentLander = new Set([
  'live',
  'series',
  'movies',
]);

export const gtmPlayerFunctionList = new Set([
  'selectAudioTrack',
  'selectSubtitleTrack',
  'disableSubtitles',
  'goToLive',
  'rewind',
  'toggleFullScreen',
]);

export const getGtmEventConfig = (
  assetType,
  transmissionList,
  transmissionType,
  getGtmTransmissionEvent,
) => {
  try {
    let hash;
    let eventType;
    let dimensions;

    switch (assetType) {
      case gtmTransmissionType.movie:
      case gtmTransmissionType.sport:
        hash = transmissionList[`${transmissionType}_vod`][`${getGtmTransmissionEvent}`].hash;
        eventType = transmissionList[`${transmissionType}_vod`][`${getGtmTransmissionEvent}`].eventType;
        dimensions = transmissionList[`${transmissionType}_vod`][`${getGtmTransmissionEvent}`].dimensions;
        break;
      case gtmTransmissionType.live_episode:
      case gtmTransmissionType.live_show:
      case gtmTransmissionType.live_movie:
      case gtmTransmissionType.live_sport:
      case gtmTransmissionType.live:
        hash = transmissionList[`${transmissionType}_live`][`${getGtmTransmissionEvent}`].hash;
        eventType = transmissionList[`${transmissionType}_live`][`${getGtmTransmissionEvent}`].eventType;
        dimensions = transmissionList[`${transmissionType}_live`][`${getGtmTransmissionEvent}`].dimensions;
        break;
      case gtmTransmissionType.serie:
      case gtmTransmissionType.episode:
        hash = transmissionList[`${transmissionType}_series`][`${getGtmTransmissionEvent}`].hash;
        eventType = transmissionList[`${transmissionType}_series`][`${getGtmTransmissionEvent}`].eventType;
        dimensions = transmissionList[`${transmissionType}_series`][`${getGtmTransmissionEvent}`].dimensions;
        break;
      default:
        hash = '1';
        dimensions = [];
        break;
    }

    return { hash, dimensions, eventType };
  } catch (error) {
    console.warn(`An error occurred while getting GTM_TRANSMISSION_TYPE: ${error.message}`);
    return { hash: '', dimensions: [], eventType: '' };
  }
};

export const getSectionName = (element) => {
  if (element !== null) {
    const parent = element.closest('.dtv-carousel');
    return parent.querySelector('.dtv-carousel-title').textContent;
  }
  return null;
};

export const getComponentType = (element, type) => {
  if (element !== null) {
    const parent = element.closest('.dtv-carousel');
    return parent.querySelector('.dtv-carousel-title').dataset[`${type}`];
  }
  return null;
};

export const getEpgSectionName = (element) => {
  if (element !== null) {
    let prevSibling = element.closest('.dtv-epg__channels-lines').previousElementSibling;
    while (prevSibling != null && !prevSibling.classList.contains('dtv-epg-title')) {
      prevSibling = prevSibling.previousElementSibling;
    }
    return prevSibling.querySelector('.dtv-epg-title__text').textContent;
  }
  return null;
};

export const getLanderNameByCaption = (value) => {
  const lander = {
    main: 'homeLander',
    home: 'homeLander',
    kids: 'kidsLander',
    live: 'liveLander',
    sports: 'sportsLander',
    watch: 'onDemandLander',
    movies: 'moviesLander',
    series: 'SERIESLander',
  }[value?.toLowerCase()] || `${value}Lander`;
  return lander;
};

export const transmissionContentType = (value) => {
  const contentType = {
    movie: 'vod',
    show: 'vod',
    contents: 'vod',
    shows: 'vod',
    episode: 'vod',
    serie: 'vod',
    schedules: 'live',
    live: 'live',
    live_show: 'live',
    live_movie: 'live',
    live_episode: 'live',
    channel: 'live',
    channels: 'live',
  }[value?.toLowerCase()] || null;
  return contentType;
};

export const contentType = (value) => {
  const contentTypeValue = {
    movie: 'movie',
    schedules: 'show',
    live: 'show',
    live_show: 'show',
    show: 'show',
    live_episode: 'episode',
    live_sport: 'sport',
    live_movie: 'movie',
    episode: 'episode',
    sport: 'sport',
    serie: 'serie',
  }[value?.toLowerCase()] || null;
  return contentTypeValue;
};

export const playerType = (value, isLive, isSports) => {
  const playerTypeValue = {
    movie: 'movie',
    schedules: 'channel',
    live: 'channel',
    live_show: 'channel',
    live_episode: 'channel',
    live_sport: 'channel',
    live_movie: 'channel',
    show: 'serie',
    episode: 'serie',
    sport: 'serie',
    serie: 'serie',
  }[value?.toLowerCase()] || null;
  return (isLive || isSports) ? 'channel' : playerTypeValue;
};

export const getGtmPlayerEventByType = (eventName) => {
  const playerEventType = {
    selectAudioTrack: 'change_audio',
    selectSubtitleTrack: 'change_subtitle',
    disableSubtitles: 'change_subtitle',
    setProgress: 'timeline',
    goToLive: 'ao_vivo',
    toggleMute: 'language',
    setVolume: 'language',
    rewind: 'backward',
    toggleFullScreen: 'full_screen',
  }[eventName] || eventName;
  return playerEventType;
};

export const getEpgType = (filter) => (filter ? 'sports' : 'live');

export const getDiffInSeconds = (startTime, endTime) => Math.round((endTime - startTime) / 1000);

export const userGtmEventHandler = (path, gtmUserData, customData) => {
  gtmEvent({
    hash: path.hash,
    eventType: path.eventType,
    dimensions: path.dimensions,
    userData: gtmUserData,
    data: { ...customData } || {},
  });
};

/**
   * @method formatString
   * @description FormatString method normalize the string by decomposing any accented characters
   * into a base character, replace all hyphens ("-") in the string with spaces (" ") and replace
   * multiple spaces with a single space. Example: take “Teste - SSLA” and convert to “Teste SSLA”.
   * We use this to transform stings to the format that the analytics team expects
*/
export const formatString = (string) => {
  if (!string) return null;
  const formattedString = string?.trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .replace(/[^\dA-Za-z-]+/g, ' ')
    .replace(/-/g, ' ')
    .replace(/\s+/g, ' ')
    .replace(/-+/g, '-');
  return formattedString;
};

/**
   * @method formatContentForGtm
   * @description FormatContentForGtm method receives the asset object and the configuration and
   * transforms it into the format expected by the gtmEvent method that fires an event in GTM
   * @param {object} data - asset data to transform
   * @param {object} config - Custom configuration
*/
export const formatContentForGtm = (data = {}, config = {}) => {
  try {
    const {
      genre,
      genres,
      vod,
      duration,
      episode,
      title,
      playSource,
      type,
      vrioAssetId,
      live,
      shouldDisplayLockIcon,
      sport,
      sectionName,
      rating,
    } = data;

    const {
      title: configTitle,
      startProgressBar: configStartProgressBar,
      playSource: configPlaySource,
      type: configType,
      serieName: configSerieName,
      episodeName: configEpisodeName,
      collectionName: configCollectionName,
      contentPlayerType: configContentPlayerType,
      componentType: configComponentType,
      transmissionType: configTransmissionType,
      sectionName: configSectionName,
      filterPdp: configFilterPdp,
      subtitleTrack: configSubtitleTrack,
      searchTerms: configSearchTerms,
      audioTrack: configAudioTrack,
      text: configText,
      errorCode: configErrorCode,
      providerName: configProviderName,
      verticalScrollPercent: configVerticalScrollPercent,
      errorType: configErrorType,
      api: configApi,
      isKids: configIsKids,
      askSecurityCode: configAskSecurityCode,
      pageReferral: configPageReferral,
      groupId: configGroupId,
      matchId: configMatchId,
      teamId: configTeamId,
      playOff: configPlayOff,
      sectionId: configSectionId,
      planId: configPlanId,
      timestamp: configTimestamp,
      filterEpg: configFilterEpg,
      filterMiniEpg: configFilterMiniEpg,
      tab: configTab,
      cancelMotive: configCancelMotive,
      ratingServices: configRatingServices,
      couponActivated: configCouponActivated,
      seriePosition: configSeriePosition,
      timeOnePage: configTimeOnePage,
      timeToStart: configTimeToStart,
      progress: configProgress,
      listPosition: configListPosition,
      verticalPositionCarousel: configVerticalPositionCarousel,
      changeParentalControl: configChangeParentalControl,
    } = config;

    const incomeGenres = (genre)
      ? Object.values(genre).map((item) => item.name)
      : genres;

    const formattedGenres = incomeGenres?.toString().replace(/,/g, ', ');
    const isSerie = (type === 'LIVE_EPISODE' || type === 'EPISODE');

    const formattedContent = {
      content: {
        title: formatString(episode?.showName) || formatString(configTitle) || formatString(title),
        type: contentType(configType) || contentType(type),
        vrioAssetId,
        genre: formattedGenres,
        playSource: formatString(configPlaySource) || formatString(playSource),
        primaryGenre: incomeGenres && incomeGenres.length > 0 ? incomeGenres[0] : undefined,
        duration: vod?.duration || duration || 0,
        progress: configProgress || vod?.progress || 0,
        startProgressBar: configStartProgressBar || 0,
        episode: {
          seasonNumber: episode?.seasonNumber || null,
          episodeNumber: episode?.number || null,
          episodeName: isSerie
            ? formatString(configEpisodeName) || formatString(title) : null,
        },
        channelId: live?.channelId?.toLowerCase(),
        channelName: formatString(live?.channelName),
        contentLocked: shouldDisplayLockIcon,
        serieName: formatString(episode?.showName) || formatString(configSerieName),
        contentAgeRating: formatString(rating) || null,
        ageRestrictedContent: isBlockedByParentalControl(rating).toString() || null,
      },
      collectionName: formatString(configCollectionName),
      playerType: configContentPlayerType || playerType(type),
      componentType: configComponentType,
      transmissionType:
        transmissionContentType(configTransmissionType) || transmissionContentType(type),
      sectionName: formatString(configSectionName) || formatString(sectionName),
      isSports: type === 'SPORT' || type === 'LIVE_SPORT' || !(sport?.pageType == null),
      listPosition: configListPosition || episode?.number,
      filterPdp: configFilterPdp,
      subtitleTrack: configSubtitleTrack,
      searchTerms: formatString(configSearchTerms),
      audioTrack: configAudioTrack,
      text: configText,
      errorCode: configErrorCode,
      providerName: formatString(configProviderName),
      verticalScrollPercent: configVerticalScrollPercent,
      errorType: configErrorType,
      api: configApi,
      isKids: configIsKids,
      askSecurityCode: configAskSecurityCode,
      pageReferral: configPageReferral,
      groupId: configGroupId,
      matchId: configMatchId,
      teamId: configTeamId,
      playOff: configPlayOff,
      sectionId: formatString(configSectionId),
      planId: configPlanId,
      timestamp: configTimestamp,
      filterEpg: configFilterEpg,
      filterMiniEpg: configFilterMiniEpg,
      tab: configTab,
      cancelMotive: configCancelMotive,
      ratingServices: configRatingServices || rating?.toLowerCase(),
      couponActivated: configCouponActivated,
      seriePosition: configSeriePosition,
      timeOnePage: configTimeOnePage,
      timeToStart: configTimeToStart,
      verticalPositionCarousel: configVerticalPositionCarousel,
      changeParentalControl: configChangeParentalControl?.toString() || null,
    };
    return formattedContent;
  } catch (error) {
    console.error(`An error occurred while formatting content for GTM: ${error.message}`);
    return null;
  }
};
