/* External */
import React from 'react';

/* Components */
import ChannelLineSkeleton from './channelLineSkeleton/ChannelLineSkeleton';

/* Styles */
import './EpgSkeleton.scss';

function EpgSkeleton() {
  return (
    <div className="dtv-epg-skeleton">
      <ChannelLineSkeleton cardsToRender={5} />
      <ChannelLineSkeleton cardsToRender={4} />
      <ChannelLineSkeleton cardsToRender={3} />
      <ChannelLineSkeleton cardsToRender={2} />
      <ChannelLineSkeleton cardsToRender={1} />
    </div>
  );
}

export default EpgSkeleton;
