/* External */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/* Components */
import Squad from './squad/Squad';
import Teams from './teams/Teams';
import Statistics from './stats/Statistics';

function SportPlayers({ teams = [] }) {
  const [teamIdSelected, setTeamIdSelected] = useState('');

  const onSelectingTeam = (teamId) => {
    setTeamIdSelected(teamId);
  };

  useEffect(() => {
    const defaultSelectedTeamId = teams[0]?.fifaTeamId;
    onSelectingTeam(defaultSelectedTeamId);
  }, [teams]);

  if (teams?.length === 0) return null;

  return (
    <section>
      <Teams
        teams={teams}
        teamIdSelected={teamIdSelected}
        onSelectingTeam={onSelectingTeam}
      />
      <Statistics
        teamIdSelected={teamIdSelected}
      />
      <Squad
        teamIdSelected={teamIdSelected}
      />
    </section>
  );
}

SportPlayers.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SportPlayers;
