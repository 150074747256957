/* External */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

/* Ott-common */
import Icon from '@dtvgo/icon';
import CardNewProfile from '@dtvgo/card-new-profile';
import CardProfile from '@dtvgo/card-profile';
import Loading from '@dtvgo/loading';

/* Componentes */
import NavBarProfile from '../../navBarProfile/NavBarProfile';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/user';

/* Utils */
import {
  colors, PATHS, PROFILES_STORAGE, userGtmEventHandler,
} from '../../utils';

/* Other */
import { history } from '../../state/configureStore';
import { backendService } from '../../services';

/* Styles */
import './UserProfileManagement.scss';

function analyticsProfileToEdit(gtmUserData) {
  userGtmEventHandler(gtmDimensions.multiple_profile.edit_create.profile_to_edit, gtmUserData);
}
function analyticsCreateProfile(gtmUserData) {
  userGtmEventHandler(gtmDimensions.multiple_profile.edit_create.create_profile, gtmUserData);
}

function goBack(gtmUserData) {
  userGtmEventHandler(gtmDimensions.multiple_profile.edit_create.manage_profile, gtmUserData);
  history.push(PATHS?.USER_PROFILE);
}

function UserProfileManagement({
  intl: { formatMessage },
}) {
  const [profiles, setProfiles] = useState(
    JSON.parse(localStorage.getItem(PROFILES_STORAGE.profiles)),
  );
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { gtmUserData } = useGtmContext();

  useEffect(() => {
    if (location.state === 'userMenu') {
      setLoading(true);
      backendService.customer.v1.getProfiles().then((res) => {
        setProfiles(res.data);
        setLoading(false);
        return null;
      }).catch((error) => {
        history.push(PATHS?.ERROR, {
          source: 'MIDDLEWARE',
          location: 'LOGIN',
          type: 'UNEXPECTED',
          externCode: error?.response?.status,
        });
      });
    }
  }, [location]);

  useEffect(() => {
    userGtmEventHandler(gtmDimensions.multiple_profile.edit_create.page_view, gtmUserData);
  }, [gtmUserData]);

  function handleClickEdit(profile, index) {
    analyticsProfileToEdit(gtmUserData);
    history.push({
      pathname: `${PATHS?.EDIT_PROFILE}/${profile?.id}`,
      state: { index },
      username: profile?.name,
    });
  }

  if (loading) {
    return (
      <div className="dtv-web-profile-management">
        <Loading className="dtv-web-profile-management__loading" />
      </div>
    );
  }

  return (
    <div className="dtv-web-profile-management">
      <NavBarProfile />
      <div className="dtv-web-profile-management__main">
        <div className="dtv-web-profile-management__management">
          <h2 className="dtv-web-profile-management__title">
            {
              formatMessage({ id: 'profiles.userProfile.managerProfile.text', defaultMessage: 'Editar perfiles' })
            }
          </h2>

          <div className="dtv-web-profile-management__card">
            {profiles?.map((profile, index) => (
              <div key={profile?.id} className="dtv-web-profile-management__card-edit">
                <div>
                  <Icon className="dtv-web-profile-management__edit-logo" name="edit" />
                </div>
                <CardProfile
                  className="opacity-logo"
                  name={profile?.name.slice(0, 20)}
                  color={colors[index + 1]}
                  onClick={() => handleClickEdit(profile, index)}
                />
              </div>
            ))}

            { profiles?.length < 5
              ? (
                <div className="dtv-web-profile-management__card-add">
                  <Link
                    to={{ pathname: PATHS?.CREATE_PROFILE, length: profiles.length }}
                    onClick={() => analyticsCreateProfile(gtmUserData)}
                  >
                    <CardNewProfile text={formatMessage({ id: 'profiles.userProfile.managerProfile.createProfile', defaultMessage: 'Crear perfil' })} />
                  </Link>
                </div>
              )
              : '' }

          </div>

          <button className="dtv-btn-secondary dtv-web-profile-management__button" type="submit" onClick={() => goBack(gtmUserData)}>
            {
              formatMessage({ id: 'profiles.userProfile.managerProfile.backButton', defaultMessage: 'Volver' })
            }
          </button>
        </div>
      </div>

    </div>
  );
}

UserProfileManagement.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

UserProfileManagement.defaultProps = {
  intl: {
    formatMessage: () => {},
  },
};

export default injectIntl(UserProfileManagement);
