/* External */
import React, {
  useEffect, useState, useMemo, useRef,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

/* Ott-common */
import Icon from '@dtvgo/icon';
import Input from '@dtvgo/input';
import CardCreateProfile from '@dtvgo/card-create-profile';
import Loading from '@dtvgo/loading';
import { getInitials } from '@dtvgo/utils';

/* Assets */
import Warning from '@/assets/theme/svg/warning.svg';

/* Components */
import NavBarProfile from '../../navBarProfile/NavBarProfile';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/user';
import ParentalMenu from '../../components/parentalMenu/ParentalMenu';

/* Other */
import { editProfile, deleteProfile } from '../../state/profile/reducer';
import { history } from '../../state/configureStore';
import { showModal, hideModal } from '../../state/modal/actions';
import { backendService } from '../../services';
import { getImage } from '../../theme/images';

/* Utils */
import {
  ageRatingBR,
  ageRatingSelect,
  ageRatingSSLA,
  colors,
  featureFlagParentalControl,
  IS_BRAZIL,
  PATHS,
  PROFILES_STORAGE,
  userGtmEventHandler,
  gtmSelectedProfileData,
} from '../../utils';

/* Styles */
import './UserProfileEdit.scss';

function findAgeRating(value) {
  const entries = IS_BRAZIL ? Object.entries(ageRatingBR) : Object.entries(ageRatingSSLA);

  for (const [index, age] of entries) {
    if (age === value) {
      return index;
    }
  }
  return null;
}

function UserProfileEdit({
  intl: { formatMessage },
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const featureFlagParental = featureFlagParentalControl();
  const { state, username } = location;
  const [loading, setLoading] = useState(false);
  const profileStorage = JSON.parse(localStorage.getItem(PROFILES_STORAGE.profile)) ?? [];
  const blockEmoji = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}|\p{Extended_Pictographic}/gu;
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [name, setName] = useState(selectedProfile?.name || '');
  const [activeStep, setActiveStep] = useState('');
  const [contentWithoutClassification, setContentWithoutClassification] = useState();
  const [rating, setRating] = useState('18');
  const [isProfileNameEdited, setIsProfileNameEdited] = useState(false);
  const [isProfileConfigEdited, setIsProfileConfigEdited] = useState(false);
  const [selectedProfileGtmData, setSelectedProfileGtmData] = useState(false);
  const gtmRef = useRef(false);

  const { gtmUserData } = useGtmContext();

  const logoLetter = useMemo(() => getInitials(name).slice(0, 2).join('').toUpperCase(), [name]);

  const notNow = () => {
    userGtmEventHandler(
      gtmDimensions.multiple_profile.delete_profile.not_now,
      selectedProfileGtmData,
    );
    history.push(PATHS?.USER_PROFILE);
    dispatch(hideModal());
  };

  const closeModal = () => {
    userGtmEventHandler(
      gtmDimensions.multiple_profile.delete_profile.close,
      selectedProfileGtmData,
    );
    history.push(PATHS?.USER_PROFILE);
    dispatch(hideModal());
  };

  const submitModal = () => {
    backendService.customer.v1.deleteProfile(params?.id).then((res) => {
      const allProfiles = res.data.profiles;
      dispatch(deleteProfile(allProfiles));

      if (selectedProfile.id === profileStorage.id) {
        localStorage.setItem(PROFILES_STORAGE.profile, JSON.stringify({ name: '', color: 1 }));
      }

      userGtmEventHandler(
        gtmDimensions.multiple_profile.delete_profile.delete,
        selectedProfileGtmData,
        {
          content: {
            changeParentalControl: 'true',
          },
        },
      );

      return history.push(PATHS?.USER_PROFILE, { delete: true });
    }).catch((error) => {
      userGtmEventHandler(
        gtmDimensions.multiple_profile.delete_profile.error,
        selectedProfileGtmData,
        {
          content: {
            changeParentalControl: 'false',
          },
        },
      );

      history.push(PATHS?.ERROR, {
        source: 'MIDDLEWARE',
        location: 'PROFILE_DELETE',
        type: 'UNEXPECTED',
        externCode: error?.response?.status,
      });
    });

    dispatch(hideModal());
  };

  const handleClickDeleteProfile = () => {
    dispatch(
      showModal({
        titleImageSrc: getImage('warning') || Warning,
        title: formatMessage({ id: 'profiles.userProfile.deleteProfile.confirmation', defaultMessage: '¿Realmente quieres eliminar este perfil?' }),
        message: formatMessage({ id: 'profiles.userProfile.deleteProfile.message', defaultMessage: 'Tu lista de favoritos y todas las configuraciones guardadas, se perderán.' }),
        primaryText: formatMessage({ id: 'profiles.userProfile.deleteProfile.primaryText', defaultMessage: 'Eliminar perfil' }),
        primaryAction: () => { submitModal(); },
        secondaryText: formatMessage({ id: 'profiles.userProfile.deleteProfile.secondaryText', defaultMessage: 'Ahora no' }),
        secondaryAction: () => { notNow(); },
        onClose: () => { closeModal(); },
        onHide: () => { closeModal(); },
        className: 'dtv-modal-container--profile',
      }),
    );

    userGtmEventHandler(
      gtmDimensions.multiple_profile.edit_profile.delete,
      selectedProfileGtmData,
    );
    userGtmEventHandler(
      gtmDimensions.multiple_profile.delete_profile.page_view,
      selectedProfileGtmData,
    );
  };

  const onSubmitForm = (e) => {
    e?.preventDefault();
    if (name?.length > 0) {
      if (selectedProfile?.isMainProfile) {
        setRating('18');
        setContentWithoutClassification(true);
      }

      backendService.customer.v1.patchProfile(params?.id, {
        name,
        avatar: '',
        ageRating: rating,
        isNotRatedContentAllowed: contentWithoutClassification,
      }).then((res) => {
        const allProfiles = res.data.profiles;
        dispatch(editProfile(allProfiles));
        return history.push(PATHS?.USER_PROFILE, { edit: true });
      }).catch((error) => {
        history.push(PATHS?.ERROR, {
          source: 'MIDDLEWARE',
          location: 'PROFILE_EDIT',
          type: 'UNEXPECTED',
          externCode: error?.response?.status,
        });
      });
    } else {
      history.push(PATHS?.USER_PROFILE);
    }
  };

  const handleInputChange = (text) => {
    const newProfileName = text.replace(blockEmoji, '').trim();
    setName(newProfileName);

    if (newProfileName?.length > 0 && newProfileName !== selectedProfile?.name) {
      setIsProfileNameEdited(true);
    } else {
      setIsProfileNameEdited(false);
    }
  };

  useEffect(() => {
    const ageRating = ageRatingSelect(activeStep);
    if (ageRating && ageRating !== '') {
      setRating(ageRating);
    }
  }, [activeStep]);

  useEffect(() => {
    setLoading(true);
    backendService.customer.v1.getProfile(params.id).then((res) => {
      setSelectedProfile(res.data);
      setActiveStep(findAgeRating(res.data?.ageRating));
      setContentWithoutClassification(res.data?.isNotRatedContentAllowed === undefined
        ? true : res.data?.isNotRatedContentAllowed);
      setName(res.data.name);
      setLoading(false);
      return null;
    }).catch((error) => {
      history.push(PATHS?.ERROR, {
        source: 'MIDDLEWARE',
        location: 'LOGIN',
        type: 'UNEXPECTED',
        externCode: error?.response?.status,
      });
    });
  }, [params.id]);

  useEffect(() => {
    if (gtmUserData && selectedProfile && !gtmRef.current) {
      const formattedGtmData = gtmSelectedProfileData({
        adminProfileData: gtmUserData,
        selectedProfileData: selectedProfile,
      });
      setSelectedProfileGtmData(formattedGtmData);
      userGtmEventHandler(gtmDimensions.multiple_profile.edit_profile.page_view, formattedGtmData);
      gtmRef.current = true;
    }
  }, [gtmUserData, selectedProfile]);

  if (loading) {
    return (
      <div className="dtv-web-user-profile-edit">
        <Loading className="dev-web-user-profile-edit__loading" />
      </div>
    );
  }

  return (
    <div className="dtv-web-user-profile-edit">
      <NavBarProfile />
      <form className="dtv-web-user-profile-edit__main" onSubmit={(e) => onSubmitForm(e)}>
        <div className="dtv-web-user-profile-edit__management">
          <Link
            className="dtv-web-user-profile-edit__back-icon"
            to={PATHS?.PROFILE_MANAGEMENT}
            onClick={() => userGtmEventHandler(
              gtmDimensions.multiple_profile.edit_profile.back,
              selectedProfileGtmData,
            )}
          >
            <Icon name="arrow-back" />
          </Link>
          <h2 className="dtv-web-user-profile-edit__title">
            {
              formatMessage({ id: 'profiles.userProfile.editProfile.title', defaultMessage: 'Editar perfil' })
            }
          </h2>
          {
            selectedProfile?.isMainProfile
              ? <p className="dtv-web-user-profile-edit__subtitle">{formatMessage({ id: 'profiles.userProfile.editProfile.warningAdmin', defaultMessage: 'Este será el perfil principal. No se puede eliminar ni convertir en un perfil infantil.' })}</p>
              : ''
          }
          <div className="dtv-web-user-profile-edit__card">
            <CardCreateProfile
              isInputNotEmpty={logoLetter || ''}
              color={colors[state.index + 1]}
            />
            <Input
              labelContent={formatMessage({ id: 'profiles.userProfile.editProfile.inputLabel', defaultMessage: '¿Cómo te podemos llamar?' })}
              inputValue={selectedProfile?.name ?? username}
              isInputActive
              onInput={(event) => handleInputChange(event.target?.value)}
              emptyInputText={formatMessage({ id: 'profiles.userProfile.editProfile.emptyInputText', defaultMessage: 'Necesitas ingresar un nombre o apodo' })}
            />

            {featureFlagParental && (
              <ParentalMenu
                profilesAdmin={selectedProfile?.isMainProfile}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                contentWithoutClassification={contentWithoutClassification}
                setContentWithoutClassification={setContentWithoutClassification}
                setIsProfileConfigEdited={setIsProfileConfigEdited}
              />
            )}

          </div>
          <button
            className={`dtv-common-c-card-profile__button ${isProfileNameEdited || isProfileConfigEdited ? 'dtv-btn-primary' : 'dtv-btn-secondary'}`}
            type="submit"
            disabled={!(isProfileNameEdited || isProfileConfigEdited)}
            onClick={() => userGtmEventHandler(
              gtmDimensions.multiple_profile.edit_profile.ok,
              selectedProfileGtmData,
            )}
          >
            {formatMessage({ id: 'profiles.userProfile.editProfile.confirmButton', defaultMessage: 'Confirmar' })}
          </button>
          {!selectedProfile?.isMainProfile && (
            <div>
              <button
                type="button"
                className="dtv-btn-delete"
                onClick={() => handleClickDeleteProfile()}
              >
                <Icon className="dtv-common-c-card-profile__edit-logo" name="delete" />
                <span>
                  {formatMessage({ id: 'profiles.userProfile.deleteProfile.primaryText', defaultMessage: 'Eliminar perfil' })}
                </span>
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

UserProfileEdit.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

UserProfileEdit.defaultProps = {
  intl: {
    formatMessage: () => {},
  },
};

export default injectIntl(UserProfileEdit);
