/**
 * External
 */
import React from 'react';

/**
 * Styles
 */
import './inputCheckbox.scss';

function InputCheckbox({
  id,
  onChange,
  children,
  checked,
  className = '',
}) {
  return (
    <label htmlFor={id} className={`${className} dtv-input-checkbox`}>
      <span className="dtv-input-checkbox-text">{children}</span>
      <input id={id} checked={checked} type="checkbox" onChange={onChange} />
      <span className="checkmark" />
    </label>
  );
}

export default InputCheckbox;
