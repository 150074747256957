/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData } from 'react-intl';
import { connect } from 'react-redux';
import isNilL from 'lodash-es/isNil';
import findIndexL from 'lodash-es/findIndex';
import isEmptyL from 'lodash-es/isEmpty';
import getL from 'lodash-es/get';

/**
 * Actions
 */
import { updateUser as updateUserAction } from './state/user/actions';

/**
 * Other
 */
import {
  getUserLanguages, LANGUAGES, IS_BRAZIL, AppTheme,
} from './utils';

/**
 * Constants
 */
const allowLanguages = [
  LANGUAGES.SPANISH_LANGUAGE,
  LANGUAGES.PORTUGUESE_LANGUAGE,
  LANGUAGES.PORTUGUESE_SKYMAIS_LANGUAGE,
];

class IntlProviderWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: null,
      locale: LANGUAGES.SPANISH_LANGUAGE,
    };
    this.getFileLanguage = this.getFileLanguage.bind(this);
  }

  componentDidMount() {
    const { updateUser, user } = this.props;
    const userLanguages = getUserLanguages(user);

    let languageApp;
    if (!isEmptyL(user)) {
      languageApp = getL(userLanguages, 'client', LANGUAGES.SPANISH_LANGUAGE);
      updateUser('account.profile.customData.preferences.language', userLanguages);

      // Our translated strings
      this.getFileLanguage(languageApp, false);
    } else {
      this.getFileLanguage(navigator.language.toLowerCase().split(/[_-]+/)[0], true);
    }
  }

  componentDidUpdate({ user }) {
    if (isNilL(user)) {
      this.getFileLanguage(navigator.language.toLowerCase().split(/[_-]+/)[0], true);
    }
  }

  async getFileLanguage(language, updateReducer) {
    let languageApp = language;
    const appTheme = import.meta.env.REACT_APP_THEME;
    const { updateUser } = this.props;
    if (IS_BRAZIL) {
      languageApp = appTheme === AppTheme.SKYMAIS
        ? LANGUAGES.PORTUGUESE_SKYMAIS_LANGUAGE : LANGUAGES.PORTUGUESE_LANGUAGE;
    }
    // valida si el lenguaje es soportado por la app, si no coloca espaÃ±ol
    const result = findIndexL(allowLanguages, (item) => item === languageApp);
    if (result === -1) {
      languageApp = LANGUAGES.SPANISH_LANGUAGE;
    }

    const { messages, localeData, parentLocale } = await import(`./languages/${languageApp}.js`);
    addLocaleData(localeData);
    if (parentLocale) {
      addLocaleData({
        locale: languageApp,
        parentLocale,
      });
    }
    if (updateReducer) {
      const languages = {
        client: languageApp,
        audio: languageApp,
        subtitles: languageApp,
      };
      updateUser('account.profile.customData.preferences.language', languages);
    }
    this.setState({
      messages,
      locale: languageApp,
    });
  }

  render() {
    const { locale, messages } = this.state;
    const { children } = this.props;

    return (
      <IntlProvider locale={locale} messages={messages} key={locale}>
        {messages ? children : <div />}
      </IntlProvider>
    );
  }
}

IntlProviderWrapper.propTypes = {
  user: PropTypes.shape({}),
  updateUser: PropTypes.func.isRequired,
  children: PropTypes.node,
};

IntlProviderWrapper.defaultProps = {
  user: undefined,
  children: null,
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, { updateUser: updateUserAction })(IntlProviderWrapper);
