/* External */
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

/* Ott-common */
import { useFlag } from '@dtvgo/react-utils';
import { Errors, Npaw } from '@dtvgo/player-utils';
import { useHandlePlayerError } from '@dtvgo/player-utils-react';
import { assetTypes } from '@dtvgo/rtk-query-api';

/* Components */
import VrioBasePlayer from './VrioBasePlayer';
import NpawMonitoringContainer from '../NpawMonitoring.container';
import PlayerUI from '../PlayerUI';
import PlayerGtmTrack from '../PlayerGtmTrack';
import ErrorScreen from '../errorScreen/ErrorScreen';

/* Other */
import {
  PDP_ASSET_TYPES, goBackFromPlayer, PATHS, NPAW_PLATFORM,
} from '../../utils';
import { api, history } from '../../state/configureStore';
import { useLoadOnChromecastConnect, useParentalControlError } from '../utils/hooks';
import { parentalControlRedirect } from '../utils';

// TODO: Next Episode, See All Episodes, Are you still watching message
function VrioVodPlayer({
  match: {
    params: { assetId, assetType },
  },
}) {
  const isSerie = assetType.toUpperCase() === assetTypes.episode;
  const { data: asset } = api.useGetAssetDetailQuery({ assetId });
  const {
    episode, vmsId, vrioAssetId,
  } = asset || {};
  const { showId } = episode || {};
  const continueWatchingAssetId = isSerie ? showId : vrioAssetId;
  const continueWatchingAssetType = isSerie ? PDP_ASSET_TYPES.series : PDP_ASSET_TYPES.movies;

  const {
    data: continueWatchingData,
    isError: isContinueWatchingError,
    isSuccess: isContinueWatchingSuccess,
  } = api.useGetContinueWatchingDataQuery(
    {
      assetId: continueWatchingAssetId,
      assetType: continueWatchingAssetType,
      vmsId,
    },
    { skip: !asset },
  );

  const continueWatchingDataContents = continueWatchingData?.contents?.[0];
  let savedProgress;

  if (isContinueWatchingSuccess) {
    const serieProgress = vrioAssetId === continueWatchingDataContents?.vrioAssetId
      ? continueWatchingDataContents?.vod?.progress || 0
      : 0;
    savedProgress = (isSerie
      ? serieProgress
      : continueWatchingData?.progress) || 0;
  } else if (isContinueWatchingError) {
    savedProgress = 0;
  }

  const [showPlayButton, { enable: displayPlayButton, disable: hidePlayButton }] = useFlag(false);
  const [readyToPlay, { enable: handleReadyToPlay }] = useFlag(false);
  const [authorizerResponse, setAuthorizerResponse] = useState({});
  const {
    error, reportableError, handleError, handleReportableError,
  } = useHandlePlayerError();
  const npawError = useMemo(
    () => Npaw.errorParser(NPAW_PLATFORM, reportableError),
    [reportableError],
  );

  const gtmRef = useRef();

  useParentalControlError(asset, handleReportableError);
  useLoadOnChromecastConnect(assetId);

  if (error) {
    // TODO: This should be handled by ErrorScreen, too
    if (error.category === Errors.ErrorCategory.PARENTAL) {
      parentalControlRedirect(asset);
      return null;
    }
    return (
      <ErrorScreen asset={asset} error={error} />
    );
  }
  return (
    <VrioBasePlayer
      playbackId={assetId}
      savedProgress={savedProgress}
      asset={asset}
      onEnded={() => (isSerie ? goBackFromPlayer({ history }) : history.push(PATHS.HOME))}
      onReadyToPlay={handleReadyToPlay}
      onPlay={hidePlayButton}
      onAutoplayFailed={displayPlayButton}
      onError={handleError}
      onAuthorizerResponseReceived={setAuthorizerResponse}
      onAuthorizerError={handleReportableError}
    >
      {readyToPlay && <PlayerGtmTrack asset={asset} ref={gtmRef} />}
      <NpawMonitoringContainer
        asset={asset}
        channelId={authorizerResponse.channelId}
        provider={authorizerResponse.provider}
        startOffset={savedProgress}
        error={npawError}
      />
      <PlayerUI
        asset={asset}
        readyToPlay={readyToPlay}
        showPlayButton={showPlayButton}
        gtmInteraction={gtmRef.current?.gtmInteraction}
      />
    </VrioBasePlayer>
  );
}

VrioVodPlayer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      assetId: PropTypes.string.isRequired,
      assetType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default VrioVodPlayer;
