/**
 * External
 */
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  consecutivePdpCount: 0,
};

const pdpUrlRegex = /.*\/pdp\/.*/;
let lastVisitedUrl;

export default function reducer(state = initialState, { type, payload } = {}) {
  if (type !== LOCATION_CHANGE) return state;

  const { consecutivePdpCount } = state;
  const {
    location: { pathname },
    action,
  } = payload;
  let newState;

  if (lastVisitedUrl && pdpUrlRegex.test(lastVisitedUrl) && action === 'POP') {
    newState = {
      consecutivePdpCount: consecutivePdpCount - 1,
    };

    lastVisitedUrl = pathname;

    return newState;
  }

  lastVisitedUrl = pathname;

  let newConsecutivePdpCount = 0;

  if (pdpUrlRegex.test(pathname)) {
    newConsecutivePdpCount = consecutivePdpCount + 1;
  }

  newState = {
    consecutivePdpCount: newConsecutivePdpCount,
  };

  return newState;
}
