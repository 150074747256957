/* External */
import React from 'react';

/* Ott */
import Rating from '@dtvgo/rating';

/* Utils */
import { IS_BRAZIL, ageRatingSSLA } from '../../utils';

export const stepsSSLA = Object.entries(ageRatingSSLA).map(([step, rating]) => ({
  label: (
    <div className="step-classification__age-step">
      <Rating
        rating={rating === '0' ? 'T' : rating}
        brazil={IS_BRAZIL}
        className={`step-classification__age-rating step-classification__${rating === '0' ? 'T' : rating}`}
      />
    </div>),
  step: Number.parseInt(step, 10),
}));
