// External
import React from 'react';
import PropTypes from 'prop-types';

// ott-common
import Rating from '@dtvgo/rating';
import '@dtvgo/rating/dist/main.css';

// Styles
import './AgeRating.scss';

// Utils
import { IS_BRAZIL } from '../../utils';

/**
 * Returns an age rating tag
 */
function AgeRating({ rating = '' }) {
  if (!rating) {
    return null;
  }

  return (
    <Rating
      rating={rating}
      brazil={IS_BRAZIL}
      className="dtv-web-age-rating"
    />
  );
}

AgeRating.propTypes = {
  rating: PropTypes.string,
};

AgeRating.defaultProps = {
  rating: '',
};

export default AgeRating;
