export const userConstants = {
  constans: [
    'v_client_id',
    'v_user_id',
    'v_billing_status',
    'v_mso',
    'v_language',
    'v_admin_profile',
    'v_profile_id',
    'v_app_name',
    'v_login_type',
    'v_profile_custom_age',
    'v_profile_unrated_content',
    'v_isocode',
  ],
};
