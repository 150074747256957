/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

/**
 * Components
 */
import BackButton from '../backButton/BackButton';

/**
 * Styles
 */
import './LanderLogo.scss';

function getLanderLogoSrc(pageId) {
  return pageId ? `/content/dam/ott/web/stations/${pageId}.png` : '';
}

function LanderLogo({ pageId, isEpgOnViewport }) {
  return (
    <div className={ClassNames({ 'dtv-lander-epg-background': isEpgOnViewport }, 'dtv-lander-logo')}>
      <BackButton className="dtv-lander-logo-back-button" />
      <img src={getLanderLogoSrc(pageId)} alt="" />
    </div>
  );
}

LanderLogo.propTypes = {
  pageId: PropTypes.string.isRequired,
};

export default LanderLogo;
