/* External */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

function StopButton({ intl: { formatMessage }, stop }) {
  return (
    <button
      type="button"
      className="dtv-chromecast-bar-player-stop"
      title={formatMessage({ id: 'player.chromecast.stop', defaultMessage: 'Detener reprodución' })}
      onClick={stop}
    >
      <span className="dtv-icon-stop" />
    </button>
  );
}

StopButton.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  stop: PropTypes.func.isRequired,
};

export default injectIntl(memo(StopButton));
