/** External */
import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { useParams } from 'react-router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import cloneDeepL from 'lodash-es/cloneDeep';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/** Assets */
import genericCard4X3 from '@/assets/theme/images/4x3-GenericCard.png';

/** Components */
import Card4X3 from '../card4X3/Card4X3';
import SeasonSkeleton from '../seasonSkeleton/SeasonSkeleton';
import AgeRating from '../ageRating/AgeRating';

/** Styles */
import './SeasonSelector.scss';

/** Other */
import {
  formatSeasonAndEpisode,
  formatSeconds,
  joinArrayWithSeparator,
  getUserLanguageFromEnvironmentPublicPdp,
} from '../../utils';
import { api } from '../../state/configureStore';
import { createBackendServicePublic } from '../../services/backend';
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/pdp';
import { getImage } from '../../theme/images';

function SeasonSelector({
  assetId,
  continueWatchingEpisode,
  intl: { formatMessage },
  isLoading,
  playSource,
  seasons,
  isPublic,
  regionPublic,
}) {
  const { country } = useParams() || {};
  const [selectedSeasonNumber, setSelectedSeasonNumber] = useState();
  const [seasonDetailPublic, setSeasonDetailPublic] = useState({});
  const [seasonEpisodes, setSeasonEpisodes] = useState([]);
  const languageEnvironment = getUserLanguageFromEnvironmentPublicPdp(country);
  const {
    data: seasonDetail = {},
    error: errorGetSeasonDetail,
  } = !isPublic ? api.useGetSeasonDetailQuery(
    { assetId, seasonNumber: selectedSeasonNumber },
    { skip: !selectedSeasonNumber },
  ) : {};
  const {
    gtmUserData, gtmContent,
  } = useGtmContext();
  const clonedEpisodes = !errorGetSeasonDetail?.data ? cloneDeepL(seasonDetail?.episodes) : [];
  const episodes = clonedEpisodes?.sort(
    (a, b) => (a?.episode?.number || 0) - (b?.episode?.number || 0),
  );
  useEffect(() => {
    async function fetchDataSeasonPublic() {
      if (isPublic && selectedSeasonNumber) {
        const returnSeasonPublic = await createBackendServicePublic.get(`/asset/show/${assetId}/seasons/${selectedSeasonNumber}`, languageEnvironment, regionPublic);
        setSeasonDetailPublic({
          data: returnSeasonPublic?.data,
          isError: (!returnSeasonPublic.data && returnSeasonPublic?.code !== 200),
        });
      }
    }
    if (isPublic) fetchDataSeasonPublic();
  }, [isPublic, selectedSeasonNumber, assetId, languageEnvironment, regionPublic]);

  useEffect(() => {
    if (isPublic) {
      const clonedEpisodesPublic = isPublic ? cloneDeepL(seasonDetailPublic?.data?.episodes) : [];
      setSeasonEpisodes(clonedEpisodesPublic);
    }
  }, [seasonDetailPublic, isPublic]);

  const handleClickSeasonNumber = (seasonNumber) => {
    setSelectedSeasonNumber(seasonNumber);

    const sendToGtm = {
      ...gtmContent,
    };

    sendToGtm.content.episode.seasonNumber = seasonNumber;
    sendToGtm.listPosition = seasonNumber;

    gtmEvent({
      hash: gtmDimensions.pdp_series.season.hash,
      eventType: gtmDimensions.pdp_series.season.eventType,
      userData: gtmUserData,
      dimensions: gtmDimensions.pdp_series.season.dimensions,
      data: {
        ...sendToGtm,
      },
    });
  };

  useEffect(() => {
    if (selectedSeasonNumber) return;

    const seasonNumber = (continueWatchingEpisode && continueWatchingEpisode?.season)
      || (seasons[0] && seasons[0]?.seasonNumber);

    setSelectedSeasonNumber(seasonNumber);
  }, [continueWatchingEpisode, seasons, selectedSeasonNumber]);

  const listEpisode = !isPublic ? episodes : seasonEpisodes;

  return (
    <div className="dtv-season-selector">
      <div className="dtv-season-selector-header">
        {!!seasons[0]?.seasonNumber && (
          <>
            <h2 className="dtv-season-selector-header-title">
              {formatMessage({
                id: 'serie.season',
                defaultMessage: 'Temporada',
              })}
            </h2>

            <div className="dtv-season-selector-header-numbers">
              {seasons.map(({ id, seasonNumber }) => (
                <span
                  className={classNames({
                    active: seasonNumber === selectedSeasonNumber,
                  })}
                  key={id || seasonNumber}
                  onClick={() => handleClickSeasonNumber(seasonNumber)}
                >
                  {seasonNumber}
                </span>
              ))}
            </div>
          </>
        )}
      </div>

      {isLoading ? (
        <SeasonSkeleton />
      ) : (
        <div className="dtv-season-selector-episodes">
          {listEpisode
            && listEpisode.map((ep) => {
              const episode = ep;
              const {
                vrioAssetId: id,
                title,
                rating,
              } = episode || {};
              const {
                vod: {
                  duration,
                } = {},
                episode: {
                  number: episodeNumber,
                } = {},
              } = episode || {};

              const {
                duration: durationPublic,
                episodeNumber: episodeNumberPublic,
              } = episode || {};

              const formattedSeasonAndEpisode = formatSeasonAndEpisode(
                selectedSeasonNumber,
                isPublic ? episodeNumberPublic : episodeNumber,
                formatMessage,
              );
              const formattedDuration = duration && formatSeconds(duration);
              const formattedDurationPublic = durationPublic && formatSeconds(durationPublic);
              const firstLine = joinArrayWithSeparator([formattedSeasonAndEpisode, title], ' • ');

              const secondLine = (
                <>
                  <AgeRating
                    rating={rating}
                  />
                  <span>{!isPublic ? formattedDuration : formattedDurationPublic}</span>
                </>
              );

              return (
                <Card4X3
                  fireCallbackOnImage
                  firstLine={firstLine}
                  genericCard={getImage('4x3GenericCard') || genericCard4X3}
                  hideEllipsis
                  infoCard={{
                    ...episode,
                    playSource,
                    ...(isPublic && { shouldDisplayLockIcon: true }),
                  }}
                  key={id}
                  secondLine={secondLine}
                  showPlayIconOnHover
                  isPublic={isPublic}
                />
              );
            })}
        </div>
      )}
    </div>
  );
}

SeasonSelector.propTypes = {
  continueWatchingEpisode: PropTypes.shape({
    season: PropTypes.number,
  }),
  intl: intlShape.isRequired,
  isLoading: PropTypes.bool,
  playSource: PropTypes.string.isRequired,
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      episodes: PropTypes.arrayOf(PropTypes.shape()),
      seasonNumber: PropTypes.number,
    }),
  ),
  isPublic: PropTypes.bool,
  regionPublic: PropTypes.string,
};

SeasonSelector.defaultProps = {
  continueWatchingEpisode: {},
  isLoading: false,
  seasons: [],
  isPublic: false,
  regionPublic: '',
};

export default injectIntl(SeasonSelector);
