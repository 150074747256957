/**
 * External
 */
import React, { Fragment } from 'react';

/**
 * Components
 */
import FeatureContentSkeleton from './featureContentSkeleton/FeatureContentSkeleton';
import SliderSkeleton from './sliderSkeleton/SliderSkeleton';

function SliderLoader({ classCard, hideSwinlane, sliderSkeletonSize = 1 }) {
  const swimlanes = [];

  for (let index = 0; index < sliderSkeletonSize; index += 1) {
    swimlanes.push(<SliderSkeleton key={index} classCard={classCard} />);
  }

  return (
    <>
      <FeatureContentSkeleton />
      {!hideSwinlane && swimlanes}
    </>
  );
}

export default SliderLoader;
