import { ICON_TYPE } from '../components/lockIcon/LockIcon';
import { featureFlagParentalControl } from './users';

export const getProfileRating = () => (JSON.parse(localStorage.getItem('profile'))?.ageRating || 18);
export const getProfileContentNoClassificationAllowed = () => {
  const profile = JSON.parse(localStorage.getItem('profile'));
  if (profile && profile?.isNotRatedContentAllowed === false) {
    return false;
  }
  return true;
};

export const AVAILABLE_BR_RATING = {
  free: 'L',
  autoClassifiedFree: 'AL',
  ten: '10',
  autoClassifiedTen: 'A10',
  twelve: '12',
  autoClassifiedTwelve: 'A12',
  fourteen: '14',
  autoClassifiedFourteen: 'A14',
  sixteen: '16',
  autoClassifiedSixteen: 'A16',
  eighteen: '18',
  autoClassifiedEighteen: 'A18',
};

export const contentRating = (rating) => (rating === AVAILABLE_BR_RATING.free || rating === AVAILABLE_BR_RATING.autoClassifiedFree ? 0 : rating?.match(/\d+/g));

export const isBlockedByParentalControl = (assetRating) => (
  Number(contentRating(assetRating)) > Number(getProfileRating())
);

export const lockIconSelector = ({ shouldDisplayLockIcon, assetRating }) => {
  const unratedContentAllowed = getProfileContentNoClassificationAllowed();

  const isContentRated = Object.values(AVAILABLE_BR_RATING).includes(assetRating);

  if (!featureFlagParentalControl()) {
    return {
      shouldDisplayLockIcon,
      lockIconType: shouldDisplayLockIcon ? ICON_TYPE.lock : ICON_TYPE.play,
    };
  }

  if (
    !shouldDisplayLockIcon
    && !isContentRated
    && !unratedContentAllowed) {
    return {
      shouldDisplayLockIcon: true,
      lockIconType: ICON_TYPE.parental,
    };
  }

  if (
    !shouldDisplayLockIcon
    && assetRating?.length > 1
    && Number(contentRating(assetRating)) > Number(getProfileRating())
  ) {
    return {
      shouldDisplayLockIcon: true,
      lockIconType: ICON_TYPE.parental,
    };
  }

  return {
    shouldDisplayLockIcon,
    lockIconType: shouldDisplayLockIcon ? ICON_TYPE.lock : ICON_TYPE.play,
  };
};
