/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import HBSWidget from '../../HBSWidget';

/* Styles */
import './Squad.scss';

function Squad({ teamIdSelected = '' }) {
  if (teamIdSelected) {
    return (
      <HBSWidget
        type="squad"
        props={{
          teamId: teamIdSelected,
          hideHero: true,
          playerRoute: '',
          squadPlayersSubtitle: '',
        }}
        className="dtv-sport-player__squad"
      />
    );
  }

  return null;
}

Squad.propTypes = {
  teamIdSelected: PropTypes.string.isRequired,
};

export default Squad;
