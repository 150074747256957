export const ACTION_TYPES = {
  SHOW_TOAST_NOTIFICATION: 'notification/SHOW_TOAST_NOTIFICATION',
  SHOW_BAR_NOTIFICATION: 'notification/SHOW_BAR_NOTIFICATION',
  SET_HEIGHT_NOTIFICATION: 'notification/SET_HEIGHT_NOTIFICATION',
};

export const showToastNotification = (notification) => ({
  type: ACTION_TYPES.SHOW_TOAST_NOTIFICATION,
  payload: notification,
});

export const showBarNotification = ({
  show, type, titleId, title, messageId, message, containerId, actions, close,
}) => ({
  type: ACTION_TYPES.SHOW_BAR_NOTIFICATION,
  payload: {
    show, type, titleId, title, messageId, message, containerId, actions, close,
  },
});

export const setNotificationHeight = ({ notificationHeight, navBarHeight }) => ({
  type: ACTION_TYPES.SET_HEIGHT_NOTIFICATION,
  payload: { notificationHeight, navBarHeight },
});
