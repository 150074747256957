/**
 * External
 */
import React, { useCallback } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import getL from 'lodash-es/get';
import findIndexL from 'lodash-es/findIndex';
import jwtDecode from 'jwt-decode';

/**
 * Components
 */
import { clone } from 'lodash-es';
import Button from '../components/button/Button';

/**
 * Assets
 */
import logo from '../assets/images/logo/dgo.png';

/**
 * Actions
 */
import { updateUser } from '../state/user/actions';

/**
 * Styles
 */
import './TermsAndConditions.scss';

/**
 * Other
 */
import { api } from '../state/configureStore';
import { TERMS_OF_SERVICE_URL, TERMS_OF_SERVICE_SUBPAGES, AEM_PLATFORM } from '../utils';
import { getAuthTokensData } from '../utils/localStorageState';

function TermsAndConditions() {
  const dispatch = useDispatch();
  const fRTokenData = getAuthTokensData();
  const { iso2Code } = jwtDecode(fRTokenData?.idToken) || {};
  const { data: userCustomData = {}, isLoading } = api.useGetUserCustomDataQuery(iso2Code);
  const {
    data: { policies: dataTermsAndConditions = {} } = {},
  } = api.useGetAEMConfigurationQuery(AEM_PLATFORM);
  const [updateUserCustomData] = api.useUpdateUserCustomDataMutation();
  const userCustomDataLegal = userCustomData?.customData?.legal
    || userCustomData?.customDataPlainJSON?.legal
    || userCustomData?.legal
    || {};
  const acceptedPrivacyPolicy = clone(userCustomDataLegal?.acceptedPrivacyPolicy || []);
  const acceptedTermsOfService = clone(userCustomDataLegal?.acceptedTermsOfService || []);

  /**
   * @description Record that the user accepted the terms and conditions
   * @method handleAccept
   */
  const handleAccept = useCallback(async () => {
    const lastVersionForPrivacyPolicy = getL(dataTermsAndConditions, 'privacyPolicyItems[0].privacyVersion', '');
    const lastVersionForTermsAndConditions = getL(
      dataTermsAndConditions,
      'termsAndConditionsItems[0].termsVersion',
      '',
    );

    if (findIndexL(acceptedPrivacyPolicy, ['version', lastVersionForPrivacyPolicy]) === -1) {
      acceptedPrivacyPolicy.push({
        version: lastVersionForPrivacyPolicy,
        timeStamp: Date.now().toString(),
      });
    }

    if (findIndexL(acceptedTermsOfService, ['version', lastVersionForTermsAndConditions]) === -1) {
      acceptedTermsOfService.push({
        version: lastVersionForTermsAndConditions,
        timeStamp: Date.now().toString(),
      });
    }
    const newUsersCustomData = {
      customData: {
        legal: { acceptedTermsOfService, acceptedPrivacyPolicy },
      },
    };
    updateUserCustomData({ iso2Code, customData: newUsersCustomData });
    dispatch(updateUser('account.profile.customData', newUsersCustomData));
  }, [
    acceptedPrivacyPolicy,
    acceptedTermsOfService,
    updateUserCustomData,
    iso2Code,
    dataTermsAndConditions,
    dispatch,
  ]);

  const privacyPolicyVersion = getL(dataTermsAndConditions, 'privacyPolicyItems[0].privacyVersion', '');
  const termsAndConditionsVersion = getL(dataTermsAndConditions, 'termsAndConditionsItems[0].termsVersion', '');

  const showPrivacyPolicy = acceptedPrivacyPolicy.length === 0 || findIndexL(acceptedPrivacyPolicy, ['version', privacyPolicyVersion]) === -1;
  const showTermsOfService = acceptedTermsOfService.length === 0
    || findIndexL(acceptedTermsOfService, ['version', termsAndConditionsVersion]) === -1;

  return (
    <div className="dtv-terms-conditions">
      <div className="dtv-tc-logo">
        <img className="dtv-tc-image" src={logo} alt="Logo" />
      </div>
      <div className="dtv-tc-container">
        <div className="dtv-tc-content">
          <p className="dtv-tc-title">
            <FormattedMessage id="termsAndConditions.title" defaultMessage="Los términos del servicio han cambiado" />
          </p>
          <p className="dtv-tc-description">
            <FormattedMessage
              id="termsAndConditions.description"
              defaultMessage="Por favor, lee y acepta los acuerdos legales para continuar disfrutando de DIRECTV GO."
            />
          </p>
          <div className="dtv-tc-links">
            {showTermsOfService && (
              <p className="dtv-tc-link">
                <FormattedMessage id="termsAndConditions.read" defaultMessage="Leer " />
                <a
                  href={`/${TERMS_OF_SERVICE_URL}/${TERMS_OF_SERVICE_SUBPAGES.TERMS_AND_CONDITIONS}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="termsAndConditions.linkTo" defaultMessage="Términos y Condiciones" />
                </a>
              </p>
            )}
            {showPrivacyPolicy && (
              <p className="dtv-tc-link">
                <FormattedMessage id="termsAndConditions.readPrivacyPolicy" defaultMessage="Leer " />
                <a
                  href={`/${TERMS_OF_SERVICE_URL}/${TERMS_OF_SERVICE_SUBPAGES.PRIVACY_POLICY}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="termsAndConditions.linkToPrivacyPolicy"
                    defaultMessage="Política de Privacidad"
                  />
                </a>
              </p>
            )}
          </div>
          <p className="dtv-tc-legal">
            <FormattedMessage
              id="termsAndConditions.legal"
              defaultMessage='Haciendo clic en el siguiente botón estás aceptando los "Acuerdos Legales" del servicio de DIRECTV GO.'
            />
          </p>
          {isLoading ? (
            <div className="dtv-button dtv-tc-btn-loading" />
          ) : (
            <Button className="dtv-tc-accept" onClick={() => handleAccept()}>
              <FormattedMessage id="termsAndConditions.accept" defaultMessage="Yo acepto" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(TermsAndConditions);
