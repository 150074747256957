/* External */
import React, {
  useMemo,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

/* Ott-common */
import OttCarousel from '@dtvgo/carousel';
import CodeError from '@dtvgo/code-error';
import Title from '@dtvgo/title';
import Loading from '@dtvgo/loading';
import { assetTypes } from '@dtvgo/rtk-query-api';

/* Assets */
import genericCard2X3 from '@/assets/theme/images/2x3-GenericCard.png';

/* Components */
import Card2X3 from '../../components/card2X3/Card2X3';
import ScheduleCard from '../../components/ScheduleCard/ScheduleCard';

/* Styles */
import './MoreLikeThis.scss';

/* Other */
import { api } from '../../state/configureStore';
import RefreshIcon from '../../assets/icons/refresh-icon.svg';
import { formatMoreLikeThisEPGJson, moreLikeThisEpgTitle, moreLikeThisVodTitle } from '../../utils';

function MoreLikeThis({
  contentId,
  intl: { formatMessage },
}) {
  const { data: { channels = [] } = {} } = api.useGetChannelsQuery(
    { assetToken: false },
  );

  const sourceError = 'MIDDLEWARE';
  const platformError = 'WEB';
  const locationError = 'PDP';
  const typeError = 'UNEXPECTED';

  const {
    data: carouselLive,
    isFetching: isLoadingLive,
    isError: isErrorLive,
    refetch: refetchLive,
  } = api.useGetMoreLikeThisEpgQuery(
    {
      contentId,
      max: 6,
    },
  );

  const {
    data: carouselVOD,
    isFetching: isLoadingVOD,
    isError: isErrorVOD,
    refetch: refetchVOD,
  } = api.useGetMoreLikeThisVodQuery(
    {
      contentId,
      max: 8,
    },
  );

  const isLoading = useMemo(() => isLoadingLive && isLoadingVOD, [isLoadingLive, isLoadingVOD]);

  const showErrorScreen = useMemo(() => {
    const isEmptyVOD = carouselVOD?.contents?.length <= 0;
    const isEmptyLIVE = carouselLive?.contents?.length <= 0;
    if ((isErrorLive && isErrorVOD) || (isEmptyLIVE && isEmptyVOD)) return true;
    return false;
  }, [isErrorLive, isErrorVOD, carouselVOD, carouselLive]);

  const cardsLive = useMemo(() => {
    const cards = carouselLive?.contents?.map((cardAsset, index) => {
      const cardAssetId = cardAsset.vrioAssetId;
      const cardPositionNumber = index + 1;
      const formattedCardInfo = formatMoreLikeThisEPGJson(cardAsset);
      return (
        <ScheduleCard
          channelName={
            channels?.find(({
              channelId,
            }) => channelId === formattedCardInfo.live?.channelId)?.title
          }
          infoCard={formattedCardInfo}
          key={cardAssetId}
          carouselInfo={{
            sectionTitle: moreLikeThisEpgTitle,
            cardPositionNumber,
            carouselVerticalPosition: 1,
          }}
        />
      );
    });

    return carouselLive?.contents?.length > 0 ? cards : [];
  }, [carouselLive, channels]);

  const cardsVOD = useMemo(() => {
    const cards = carouselVOD?.contents?.map((cardAsset, index) => {
      const cardAssetId = cardAsset.vrioAssetId;
      const cardPositionNumber = index + 1;
      return (
        <Card2X3
          genericCard={genericCard2X3}
          infoCard={cardAsset}
          key={cardAssetId}
          carouselInfo={{
            sectionTitle: moreLikeThisVodTitle,
            cardPositionNumber,
            carouselVerticalPosition: 0,
          }}
          hideHorizontalDots={cardAsset.type === assetTypes.episode}
        />
      );
    });

    return carouselVOD?.contents?.length > 0 ? cards : [];
  }, [carouselVOD]);

  const handleClickRefresh = useCallback(() => {
    refetchLive();
    refetchVOD();
  }, [refetchVOD, refetchLive]);

  if (isLoading) {
    return (
      <div className="dtv-web-more-like-this__loader">
        <Loading />
      </div>
    );
  }

  return (
    <div className="dtv-web-more-like-this">
      {showErrorScreen && (
        <>
          <Title
            title={formatMessage({ id: 'profiles.userProfile.profilesErrorPage.title', defaultMessage: 'Ups! Algo salió mal' })}
          />
          <div className="dtv-web-more-like-this--moreDetails">
            <CodeError
              text={`${formatMessage({ id: 'common.code', defaultMessage: 'Código' })}:`}
              sourceError={sourceError}
              platformError={platformError}
              locationError={locationError}
              typeError={typeError}
            />
          </div>
          <div className="dtv-web-more-like-this--buttonAlignment">
            <button
              type="button"
              onClick={() => handleClickRefresh()}
              className="dtv-button dtv-btn-primary with-icon"
            >
              <img src={RefreshIcon} className="dtv-button-icon" alt="refresh-icon" />
              <span className="dtv-button-text">{formatMessage({ id: 'myList.r1emove', defaultMessage: 'Tentar de novo' })}</span>
            </button>
          </div>
        </>
      )}

      {!showErrorScreen && (
        (
          <>
            {cardsVOD && cardsVOD.length > 0 && (
              <div className="dtv-carousel">
                <div className="dtv-carousel-header">
                  <p className="dtv-carousel-title dtv-text-left" data-type="carousel-portrait">{moreLikeThisVodTitle}</p>
                </div>
                <OttCarousel
                  size={cardsVOD?.length}
                  slidesSpacing={4.5}
                  sliderSettings={{
                    lazyLoad: true,
                    showSides: true,
                    sideSize: 0.28,
                    slidesToScroll: 6,
                    slidesToShow: 6,
                  }}
                  infinitive={false}
                >
                  {cardsVOD}
                </OttCarousel>
              </div>
            )}

            {cardsLive && cardsLive.length > 0 && (
              <div className="dtv-carousel">
                <div className="dtv-carousel-header">
                  <p className="dtv-carousel-title dtv-text-left" data-type="live-editorial">{moreLikeThisEpgTitle}</p>
                </div>
                <OttCarousel
                  size={cardsLive?.length}
                  slidesSpacing={4.5}
                  sliderSettings={{
                    lazyLoad: true,
                    showSides: true,
                    sideSize: 0.2,
                    slidesToScroll: 4,
                    slidesToShow: 4,
                  }}
                  infinitive
                >
                  {cardsLive}
                </OttCarousel>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
}

MoreLikeThis.propTypes = {
  contentId: PropTypes.string.isRequired,
};

export default injectIntl(MoreLikeThis);
