/**
 * External
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import isEmptyL from 'lodash-es/isEmpty';
import lastL from 'lodash-es/last';
import getL from 'lodash-es/get';

/* Assets */
import logo from '@/assets/theme/svg/logo.svg';

/**
 * Components
 */
import UserMenu from '../userMenu/UserMenu';

/**
 * Actions
 */
import { setShowSearch as setShowSearchAction } from '../state/search/searchSlice';

/* Other */
import { PATHS } from '../utils';
import { getImage } from '../theme/images';

/**
 * Styles
 */
import './NavBarMobile.scss';

class NavBarMobile extends Component {
  constructor() {
    super();
    this.state = {
      menu: false,
      userMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.showUserMenu = this.showUserMenu.bind(this);
  }

  /**
   * @description React lifecycle that listen the changes on the barNotification
   *              that allows remove the CSS top attribute
   * @method componentWillReceiveProps
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.barNotification.show) {
      this.setState({ topMenuByBarHeight: '0' });
    }
  }

  /**
   * @description Method that set the top of the menu according to the height of the notification.
   * @method setTopMenu
   */
  setTopMenu() {
    const {
      barNotification: { show },
    } = this.props;
    let topMenuByBarHeight;
    if (show) {
      setTimeout(() => {
        const barNotificationHeight = document.getElementsByClassName('dtv-bar-notification');
        if (barNotificationHeight.length > 0) {
          topMenuByBarHeight = barNotificationHeight[0].offsetHeight - 1;
          this.setState({ topMenuByBarHeight: `${topMenuByBarHeight}px` });
        }
      }, 200);
    }
  }

  /**
   * @description Method that shows and hides
   *              the menu.
   * @method showMenu
   */
  showMenu() {
    const { menu } = this.state;
    const { setShowSearch } = this.props;
    if (!menu) {
      document.addEventListener('click', this.showMenu, false);
    } else {
      document.removeEventListener('click', this.showMenu, false);
    }
    setShowSearch(false);
    this.setState((prevState) => ({ menu: !prevState.menu }));
  }

  /**
   * @description Method that shows and hides
   *              the user menu.
   * @method showUserMenu
   */
  showUserMenu() {
    const { userMenu } = this.state;
    const { setShowSearch } = this.props;
    if (!userMenu) {
      document.addEventListener('click', this.showUserMenu, false);
    } else {
      document.removeEventListener('click', this.showUserMenu, false);
    }
    setShowSearch(false);
    this.setState((prevState) => ({ userMenu: !prevState.userMenu }));
  }

  render() {
    this.setTopMenu();

    const {
      user,
      activeOption,
      navBarHeight = 0,
      notificationHeight = 0,
      setShowSearch,
    } = this.props;

    const { topMenuByBarHeight, menu, userMenu } = this.state;
    let userName = null;
    let captionLink = null;

    if (!isEmptyL(user)) {
      userName = user.userName ? user.userName.toLowerCase() : user.profile.email;
    }

    const menuLinksPrivate = [
      {
        caption: 'home',
        default: 'Home',
        url: PATHS.HOME,
      },
      {
        caption: 'live',
        default: 'Live',
        url: PATHS.LIVE,
      },
      {
        caption: 'watch',
        default: 'Watch',
        url: PATHS.WATCH,
      },
      {
        caption: 'sports',
        default: 'Sports',
        url: PATHS.SPORTS,
      },
      {
        caption: 'kids',
        default: 'Niños',
        url: PATHS.KIDS,
      },
    ];

    const typeMenu = userName ? 'menu' : 'menuPublic';

    return (
      <div className="dtv-nav-bar-mobile-container px-4" style={{ top: topMenuByBarHeight }}>
        <div className="dtv-nav-bar-mobile-left">
          <span className="dtv-icon-hamburguer-menu mr-2" onClick={this.showMenu} />
          <Link to="/home/">
            <img className="dtv-nav-bar-mobile-logo ml-2" src={getImage('logo') || logo} alt="Logo" />
          </Link>
        </div>
        <div className="dtv-nav-bar-mobile-right">
          {userName && <span onClick={() => setShowSearch(true)} className="dtv-icon-magnifying-glass" />}
          {userName && <span className="dtv-icon-user-profile ml-3" onClick={this.showUserMenu} />}
          {!userName && (
            <a href="/login">
              <button type="button" className="dtv-btn-login">
                <FormattedMessage id="menu.logIn" defaultMessage="Log In" />
              </button>
            </a>
          )}
        </div>
        <div
          className={`dtv-nav-bar-mobile-menu dtv-text-left ${menu}`}
          style={{ top: `${notificationHeight + navBarHeight}px` }}
        >
          {menuLinksPrivate.map((menuLink) => {
            captionLink = <FormattedMessage id={`${typeMenu}.${menuLink.caption}`} defaultMessage={menuLink.default} />;
            return (
              <NavLink
                key={menuLink.caption}
                to={menuLink.url}
                activeClassName="active"
                className={`pl-5 mt-3 py-2 ${activeOption === menuLink.caption && 'active'}`}
              >
                {captionLink}
              </NavLink>
            );
          })}
        </div>
        <UserMenu menu={userMenu} />
      </div>
    );
  }
}

function mapStateToProps({ user, notifications, router }) {
  const activeOption = lastL(getL(router, 'location.pathname').split('/'));
  const barNotification = notifications.get('barNotification');
  const notificationHeight = notifications.get('notificationHeight');
  const navBarHeight = notifications.get('navBarHeight');
  return {
    user: user.account,
    barNotification,
    notificationHeight,
    navBarHeight,
    activeOption,
  };
}

export default connect(mapStateToProps, {
  setShowSearch: setShowSearchAction,
})(injectIntl(NavBarMobile));
