/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/* Utils */
import { history } from '../../../state/configureStore';

/* Assets */
import closeWhiteIcon from '../../../assets/icons/close-white-icon.svg';

/* Styles */
import './CloseFifaPlayerButton.scss';

const handleClick = () => {
  if (history) {
    history.goBack();
  }
};
function CloseFifaPlayerButton({
  showCloseButton,
  className = '',
}) {
  return (
    <button
      type="button"
      className={
        `dtv-close-fifa-player-button ${className}
        ${showCloseButton
          ? 'dtv-close-fifa-player-button--fade-in'
          : 'dtv-close-fifa-player-button--fade-out'
        }`
      }
      onClick={handleClick}
    >
      <div className="dtv-close-fifa-player-button__close-icon">
        <img src={closeWhiteIcon} alt="close icon" />
      </div>
    </button>
  );
}

CloseFifaPlayerButton.propTypes = {
  className: PropTypes.string.isRequired,
  showCloseButton: PropTypes.bool.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
};

export default injectIntl(CloseFifaPlayerButton);
