/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */
import './LockIcon.scss';

export const CARD_TYPES = {
  card4X3: 'card-4x3',
  card2X3: 'card-2x3',
  channelWithScheduleCard: 'channel-with-schedule-card',
  default: '',
};

export const ICON_TYPE = {
  lock: 'lock',
  parental: 'parental',
  play: 'play',
};

function LockIcon({ cardType = CARD_TYPES.default, className = '', iconType }) {
  return (
    <div className={`dtv-card-actions-lock ${cardType} ${className}`}>
      <div className="dtv-card-actions-lock-container">
        <span className={`dtv-icon-${iconType}`} />
      </div>
    </div>
  );
}
LockIcon.propTypes = {
  cardType: PropTypes.string,
  className: PropTypes.string,
  iconType: PropTypes.string,
};

LockIcon.defaultProps = {
  cardType: '',
  className: '',
  iconType: '',
};
export default LockIcon;
