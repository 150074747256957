/**
 * External
 */
import React from 'react';

/**
 * Assets
 */
import loading from '@/assets/theme/svg/loading.svg';

/**
 * Styles
 */
import './FeatureContentSkeleton.scss';

/* Others */
import { getImage } from '../../../theme/images';

function FeatureContentSkeleton() {
  return (
    <div className="dtv-skeleton-feature">
      <div className="dtv-skeleton-feature-left">
        <div className="dtv-skeleton-feature-left-first-line" />
        <div className="dtv-skeleton-feature-left-second-line" />
        <div className="dtv-skeleton-feature-left-third-line" />
        <div className="dtv-skeleton-feature-left-fourth-line" />
      </div>
      <img className="dtv-skeleton-feature-loading" src={getImage('loading') || loading} alt="Loading" />
      <div className="dtv-skeleton-feature-btn" />
    </div>
  );
}

export default FeatureContentSkeleton;
