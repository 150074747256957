/* External */
import {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
/* Components */
import { useGtmContext } from './index';
/* Other */
import {
  formatContentForGtm,
} from '../../utils';

const GtmComponent = forwardRef(({
  assetToPlay,
  config,
  hash,
  eventType,
  dimensions,
  noGtmState,
  onlyInteractions,
}, ref) => {
  const tagsMountRef = useRef(false);
  const {
    gtmContent,
    setGtmContent,
    gtmUserData,
    gtmEvent,
    gtmSectionName,
    gtmComponentType,
    gtmPlaySource,
    gtmCarouselVerticalPosition,
  } = useGtmContext();
  const formatConfig = useCallback((
    configToFormat,
  ) => ({
    ...config,
    ...configToFormat,
    sectionName: gtmSectionName,
    componentType: gtmComponentType,
    playSource: gtmPlaySource,
    verticalPositionCarousel: gtmCarouselVerticalPosition || 0,
  }), [config, gtmSectionName, gtmComponentType, gtmPlaySource, gtmCarouselVerticalPosition]);

  useImperativeHandle(ref, () => ({
    gtmInteraction(asset, eventHash, event, eventDimensions, configFromInteraction) {
      const formattedConfig = formatConfig(configFromInteraction);
      const dataToSend = formatContentForGtm(asset || assetToPlay, formattedConfig);
      const dataLayer = {
        hash: eventHash,
        eventType: event,
        userData: gtmUserData,
        dimensions: eventDimensions,
        data: {
          ...dataToSend,
        },
      };
      gtmEvent(dataLayer);
    },
  }), [formatConfig, assetToPlay, gtmUserData, gtmEvent]);

  useEffect(() => {
    if (!tagsMountRef.current && !onlyInteractions) {
      const formattedConfig = formatConfig(config);
      const dataToSend = formatContentForGtm(assetToPlay, formattedConfig);
      const dataLayer = {
        hash,
        eventType,
        userData: gtmUserData,
        dimensions,
        data: {
          ...dataToSend,
        },
      };
      gtmEvent(dataLayer);

      if (noGtmState) {
        setGtmContent(null);
      }

      if (!gtmContent && !noGtmState) {
        setGtmContent(dataToSend);
      }
      tagsMountRef.current = true;
    }
  }, [
    assetToPlay,
    config,
    dimensions,
    eventType,
    formatConfig,
    gtmContent,
    gtmEvent,
    gtmUserData,
    hash,
    noGtmState,
    onlyInteractions,
    setGtmContent,
  ]);
  return null;
});

export default GtmComponent;

GtmComponent.propTypes = {
  assetToPlay: PropTypes.shape({}).isRequired,
  config: PropTypes.shape({}),
  hash: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  dimensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  noGtmState: PropTypes.bool,
  onlyInteractions: PropTypes.bool,
};

GtmComponent.defaultProps = {
  config: {},
  noGtmState: false,
  onlyInteractions: false,
};
