/**
 * External
 */
import { combineReducers } from 'redux';

/**
 * Actions
 */
import {
  ACTION_TYPES_ADD, ACTION_TYPES_REMOVE, ACTION_TYPES_REMOVE_MULTIPLE,
} from './actions';

/**
 * Other
 */
import { STATUS_TYPES } from '../../utils/constants';
import { createBaseReducer } from '../../utils/factories';

// My list

const myListInitialState = { status: STATUS_TYPES.UNLOAD_STATE, assets: [] };

function myList(state = myListInitialState, { type } = {}) {
  switch (type) {
    default:
      return state;
  }
}

// Add to myList

const addInitialState = {
  asset: {},
  error: {},
};

const addState = createBaseReducer(ACTION_TYPES_ADD, 'asset', addInitialState);

// Remove from myList

const removeInitialState = addInitialState;

const removeState = createBaseReducer(ACTION_TYPES_REMOVE, 'asset', removeInitialState);

// Remove multiple assets from myList

const removeMultipleInitialState = {
  assets: [],
  error: null,
};

const removeMultipleState = createBaseReducer(ACTION_TYPES_REMOVE_MULTIPLE, 'assets', removeMultipleInitialState);

export default combineReducers({
  myList,
  addState,
  removeState,
  removeMultipleState,
});
