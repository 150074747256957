/* External */
import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    showSearch: false,
    searchTerm: '',
    interaction: null,
    searchResultsLength: 0,
  },
  reducers: {
    setShowSearch: (state, action) => {
      const newState = state;
      newState.showSearch = action.payload;
    },
    updateSearchTerm: (state, action) => {
      const newState = state;
      newState.searchTerm = action.payload;
    },
    setSearchInteraction: (state, action) => {
      const newState = state;
      newState.interaction = action.payload;
    },
    setSearchResultsLength: (state, action) => {
      const newState = state;
      newState.searchResultsLength = action.payload;
    },
  },
});

export const {
  setShowSearch, updateSearchTerm, setSearchInteraction, setSearchResultsLength,
} = searchSlice.actions;

export default searchSlice.reducer;
