/**
 * External
 */
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

/**
 * Components
 */
import Button from '../../../../components/button/Button';
import InputCheckbox from '../../../../components/inputCheckbox/inputCheckbox';
import InputPassword from '../../../../components/inputPassword/inputPassword';

/**
 * Actions
 */
import {
  showBarNotification as showBarNotificationAction,
  showToastNotification as showToastNotificationAction,
} from '../../../../state/notifications/actions';
import { changePassword as changePasswordAction } from '../../../../state/userMenu/settings/general/changePassword/actions';

/**
 * Styles
 */
import './ChangePassword.scss';

/**
 * Other
 */
import { isValidString, STATUS_TYPES } from '../../../../utils';
import { GENERAL } from '../GeneralContainer';

/**
 * Constants
 */
const INVALID_CURRENT_PASSWORD_ERROR_CODE = '403';
const PASSWORD_LIMITS = {
  MINIMUM: 6,
  MAXIMUM: 64,
};

/**
 * Form to change password
 */
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      buttonDisabled: true,
      checkCloseSession: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleCheckCloseSession = this.handleCheckCloseSession.bind(this);
  }

  componentDidUpdate() {
    const {
      changePassword: { error: { response: { data: { code = '' } = {} } = {} } = {}, status = '' } = {},
      showToastNotification,
      changeComponent,
    } = this.props;
    if (status === STATUS_TYPES.SUCCEED_STATE) {
      changeComponent(GENERAL);
      showToastNotification({
        type: 'success',
        time: 5000,
        messageId: 'myProfile.changePassword.passwordHasBeenUpdated',
        message: 'Contraseña cambiada.',
      });
    } else if (status === STATUS_TYPES.FAILED_STATE) {
      this.showErrorNotification(code);
    }
  }

  /**
   * @description React lifecycle method. Used to hide notifications if they were showed.
   * @method componentWillUnmount
   */
  componentWillUnmount() {
    const { showBarNotification } = this.props;
    showBarNotification({
      show: false,
    });
  }

  /**
   * @description Method that updates checkCloseSession value in component's state
   * when the user clicks on the checkbox
   * @method handleCheckCloseSession
   */
  handleCheckCloseSession() {
    this.setState(({ checkCloseSession }) => ({
      checkCloseSession: !checkCloseSession,
    }));
  }

  /**
   * @description Method that handles input change and sets buttonDisabled value in component's
   * state
   * @method handleChange
   * @param {string} field
   * @param {event} event
   */
  handleChange(field, event) {
    this.setState(
      {
        [field]: event.target.value,
      },
      () => {
        const { currentPassword, newPassword, confirmPassword } = this.state;
        const validationButtonDisabled = !(
          isValidString(currentPassword)
          && isValidString(newPassword)
          && isValidString(confirmPassword)
        );
        this.setState({
          buttonDisabled: validationButtonDisabled,
        });
      },
    );
  }

  /**
   * @description Method that handles cancel click
   * @method handleCancel
   */
  handleCancel() {
    const { changeComponent, showBarNotification } = this.props;
    showBarNotification({
      show: false,
    });
    changeComponent(GENERAL);
  }

  /**
   * @description Method that validates if new password and confirm password
   * @param {event} event
   */
  handleChangePassword(event) {
    const {
      changePasswordAction: changePassword,
      showBarNotification,
      userName,
      iso2Code,
      email,
    } = this.props;
    const { currentPassword, newPassword, confirmPassword } = this.state;
    if (event) {
      event.preventDefault();
    }
    showBarNotification({
      show: false,
    });
    if (
      newPassword.length < PASSWORD_LIMITS.MINIMUM
      || newPassword.length > PASSWORD_LIMITS.MAXIMUM
    ) {
      showBarNotification({
        show: true,
        messageId: 'common.wrongNewPassword',
        message:
          'La contraseña que ingresaste no cumple los requerimientos. Recuerda que debe tener mínimo 6 caracteres y máximo 64.',
      });
    } else if (newPassword !== confirmPassword) {
      showBarNotification({
        show: true,
        messageId: 'common.passwordsMustMatch',
        message: 'Las nuevas contraseñas ingresadas no coinciden.',
      });
    } else {
      changePassword(userName || email, iso2Code, currentPassword, newPassword);
    }
  }

  /**
   * @description Method that shows the bar notification with an error message depending of the code
   * @method showErrorNotification
   * @param {string} code
   */
  showErrorNotification(code) {
    const { showBarNotification } = this.props;
    let messageParams = {
      messageId: 'myProfile.changePassword.failed',
      message: 'No hemos podido cambiar tu contraseña.',
    };

    if (code === INVALID_CURRENT_PASSWORD_ERROR_CODE) {
      messageParams = {
        messageId: 'myProfile.changePassword.currentPasswordIsNotValid',
        message: 'La contraseña ingresada no es correcta.',
      };
    }

    showBarNotification({ show: true, ...messageParams });
  }

  render() {
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      checkCloseSession,
      buttonDisabled,
    } = this.state;
    return (
      <div className="dtv-change-password-container">
        <form>
          <span className="dtv-title mb-3">
            <FormattedMessage
              id="myProfile.changePassword.title"
              defaultMessage="Necesitas cambiar tu contraseña de DIRECTV GO"
            />
          </span>
          <div className="dtv-input">
            <FormattedMessage id="myProfile.changePassword.password" defaultMessage="Contraseña actual" />
            <InputPassword
              name="txtPassword"
              onChange={(event) => this.handleChange('currentPassword', event)}
              value={currentPassword}
            />
          </div>
          <div className="dtv-input">
            <FormattedMessage
              className="dtv-label"
              id="myProfile.changePassword.newPassword"
              defaultMessage="Nueva contraseña"
            />
            <InputPassword
              name="txtNewPassword"
              onChange={(event) => this.handleChange('newPassword', event)}
              value={newPassword}
            />
            <p className="dtv-help-text">
              <FormattedMessage
                id="common.passwordRules"
                defaultMessage="Tu contraseña debe tener al menos 6 caracteres."
              />
            </p>
          </div>
          <div className="dtv-input">
            <FormattedMessage
              id="myProfile.changePassword.confirmPassword"
              defaultMessage="Confirma tu nueva contraseña"
            />
            <InputPassword
              name="txtConfirmPassword"
              onChange={(event) => this.handleChange('confirmPassword', event)}
              value={confirmPassword}
            />
          </div>
          <InputCheckbox
            checked={checkCloseSession}
            onChange={this.handleCheckCloseSession}
            className="dtv-checkbox d-none"
          >
            <FormattedMessage
              id="myProfile.changePassword.closeSession"
              defaultMessage="Cierra sesión en todos los dispositivos y crea una nueva contraseña."
            />
          </InputCheckbox>
          <div className="dtv-div-btns">
            <Button
              primary
              onClick={this.handleChangePassword}
              disabled={buttonDisabled}
              className={`${buttonDisabled ? 'disabled' : ''}`}
            >
              <FormattedMessage id="myProfile.changePassword.save" defaultMessage="Guardar" />
            </Button>
            <div onClick={() => this.handleCancel()} className="dtv-link-cancel">
              <FormattedMessage id="myProfile.changePassword.cancel" defaultMessage="Cancelar" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps({ changePassword, user }) {
  return {
    changePassword,
    userName: user.account.userName,
    iso2Code: user.account.profile.iso2country,
    email: user.account.profile.email,
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    changePasswordAction,
    showBarNotification: showBarNotificationAction,
    showToastNotification: showToastNotificationAction,
  })(ChangePassword),
);
