/**
 * Other
 */
import { STATUS_TYPES } from './constants';

export function createBaseReducer(ACTION_TYPES, name, initialStateValues) {
  const initialState = { status: STATUS_TYPES.UNLOAD_STATE, ...initialStateValues };
  return function baseReducer(state = initialState, { type, payload } = {}) {
    switch (type) {
      case ACTION_TYPES.REQUEST:
        return { ...state, status: STATUS_TYPES.INIT_STATE };
      case ACTION_TYPES.SUCCESS:
        return {
          ...state,
          status: STATUS_TYPES.SUCCEED_STATE,
          [name]: payload,
          error: initialState.error || null,
        };
      case ACTION_TYPES.FAILURE:
        return {
          ...state,
          status: STATUS_TYPES.FAILED_STATE,
          error: payload,
          [name]: initialState[name] || null,
        };
      case ACTION_TYPES.RESET:
        return initialState;
      default:
        return state;
    }
  };
}
