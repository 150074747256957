/**
 * External
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Styles
 */
import './AreYouStillWatchingMessage.scss';

/**
 * Other
 */
import { goBack } from '../../utils';
import { history } from '../../state/configureStore';

function AreYouStillWatchingMessage({ title, continueWatching }) {
  return (
    <div className="dtv-are-you-still-watching-message">
      <div className="dtv-are-you-still-watching-message-overlay" />
      <div className="dtv-are-you-still-watching-message-text">
        <h1>
          <FormattedMessage
            id={title ? 'player.areYouStillWatching.title' : 'player.areYouStillWatching.areYouThere'}
            values={{ title }}
          />
        </h1>
        <button type="button" className="dtv-back-btn" onClick={continueWatching}>
          <FormattedMessage id="player.areYouStillWatching.continue" />
        </button>
        <button type="button" className="dtv-back-flat-btn" onClick={() => goBack(history)}>
          <FormattedMessage id="player.areYouStillWatching.exit" />
        </button>
      </div>
    </div>
  );
}

export default AreYouStillWatchingMessage;
