/* External */
import React from 'react';

/* Ott */
import Rating from '@dtvgo/rating';

/* Utils */
import { IS_BRAZIL, ageRatingBR } from '../../utils';

export const stepsBrazil = Object.entries(ageRatingBR).map(([step, rating]) => ({
  label: (
    <div className="step-classification__age-step">
      <Rating
        rating={rating === '0' ? 'L' : rating}
        brazil={IS_BRAZIL}
        className="step-classification__age-rating"
      />
      <Rating
        rating={rating === '0' ? 'AL' : `A${rating}`}
        brazil={IS_BRAZIL}
        className="step-classification__age-rating"
      />
    </div>
  ),
  step: Number.parseInt(step, 10),
}));
