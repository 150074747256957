/* Ott-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Utils */
import {
  formatContentForGtm,
  getCarouselLander,
  playerType,
  getGtmEventConfig,
} from '../../../../utils';
import { gtmDimensions } from './carousels';

export const gtmWatchCarouselEventHandler = ({
  asset, gtmUserData, locationParams, carouselInfo,
}) => {
  try {
    const { landerUri, pageId } = locationParams;
    const {
      cardPositionNumber,
      sourceCarouselId,
      sectionTitle,
      carouselVerticalPosition,
      type,
    } = carouselInfo;
    const { hash, eventType, dimensions } = getGtmEventConfig(
      asset.type,
      gtmDimensions,
      gtmDimensions.component_type,
      'watch',
    );
    const dataToSend = formatContentForGtm(
      { ...asset },
      {
        tab: getCarouselLander({ landerUri: landerUri || pageId }).toLowerCase(),
        listPosition: cardPositionNumber,
        componentType: type,
        contentPlayerType: playerType(asset.type),
        sectionId: sourceCarouselId,
        sectionName: sectionTitle,
        verticalPositionCarousel: carouselVerticalPosition,
        providerName: pageId,
      },
    );
    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataToSend,
    });
  } catch (error) {
    console.error(`An error occurred on gtmWatchCarouselEventHandler: ${error.message}`);
  }
};

export const gtmSeeMoreCarouselEventHandler = ({
  playSource, gtmUserData, locationParams, carouselInfo,
}) => {
  try {
    const { landerUri, pageId } = locationParams;
    const { hash, eventType, dimensions } = gtmDimensions.seeMore;
    const {
      cardPositionNumber,
      sectionTitle,
      carouselVerticalPosition,
      type,
    } = carouselInfo;

    const dataToSend = formatContentForGtm(
      { playSource },
      {
        tab: getCarouselLander({ landerUri: landerUri || pageId }).toLowerCase(),
        listPosition: cardPositionNumber,
        componentType: type,
        sectionName: sectionTitle,
        verticalPosition: carouselVerticalPosition,
      },
    );
    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataToSend,
    });
  } catch (error) {
    console.error(`An error occurred on gtmSeeMoreCarouselEventHandler: ${error.message}`);
  }
};
