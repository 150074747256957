/**
 * External
 */
import React from 'react';

/**
 * Styles
 */
import './SkeletonBox.scss';

function SkeletonBox() {
  return <div className="dtv-box-loader" />;
}

export default SkeletonBox;
