/* eslint "react/require-default-props": [
  "error", { "functions": "defaultArguments", "forbidDefaultForRequired": true }
] */
/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Ott-common */
import PlayOffsOtt from '@dtvgo/plays-off';
import '@dtvgo/plays-off/dist/main.css';

/* Styles */
import './PlayOffs.scss';

/** Other */
import { IS_BRAZIL } from '../../utils';

function PlayOffs({
  standing = null,
}) {
  if (!standing) return null;
  return (
    <PlayOffsOtt
      standing={standing}
      className={`dtv-common-c-plays-off-web ${!IS_BRAZIL ? 'dtv-group-standing--color-hispam' : ''}`}
      currentStageDarkBackground={!IS_BRAZIL}
    />
  );
}

const playOffTeamShape = PropTypes.shape({
  team: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        ratio: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        type: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
    optaTeamId: PropTypes.string,
    fifaTeamId: PropTypes.string,
    name: PropTypes.string,
  }),
  isWinner: PropTypes.bool,
  scores: PropTypes.shape({
    regularPoints: PropTypes.number,
    extraPoints: PropTypes.number,
  }),
});

PlayOffs.propTypes = {
  standing: PropTypes.shape({
    playoffStages: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        isCurrentStage: PropTypes.bool,
        optaStageId: PropTypes.string,
        fifaStageId: PropTypes.string,
        matches: PropTypes.arrayOf(
          PropTypes.shape({
            matchId: PropTypes.string,
            home: playOffTeamShape,
            away: playOffTeamShape,
          }),
        ),
      }),
    ),
  }),
};

export default PlayOffs;
