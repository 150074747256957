import { userConstants } from './userConstants';

export const gtmDimensions = {
  component_type: 'banners',
  upsell_banner: {
    manage_suscription: {
      hash: 'e-036.155.001.000.000.065',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_tab',
      ],
    },
  },
};
