import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function TrackListItem({
  track,
  text,
  active,
  onTrackSelect,
}) {
  const handleClick = useCallback((event) => {
    event.stopPropagation();
    onTrackSelect(track);
  }, [track, onTrackSelect]);

  return (
    <li
      className={classNames('dtv-tracks-menu__item', {
        'dtv-tracks-menu__item--selected': active,
      })}
    >
      <button type="button" onClick={handleClick}>{text}</button>
    </li>
  );
}

TrackListItem.propTypes = {
  track: PropTypes.shape({
    trackId: PropTypes.number.isRequired,
  }),
  text: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  onTrackSelect: PropTypes.func.isRequired,
};

TrackListItem.defaultProps = {
  track: undefined,
};

export default memo(TrackListItem);
