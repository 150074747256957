/**
 * External
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Actions
 */
import { hideModal as hideModalAction } from '../../state/modal/actions';

/**
 * Styles
 */
import './Modal.scss';

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

function Modal({
  show,
  titleImageSrc,
  title,
  message,
  primaryText,
  primaryAction,
  secondaryText,
  secondaryAction,
  hideModal,
  onClose,
  className,
}) {
  useEffect(() => {
    if (show) {
      window.addEventListener('scroll', scrollToTop);
    } else {
      window.removeEventListener('scroll', scrollToTop);
    }
  }, [show]);

  if (show) {
    return (
      <div className={`dtv-modal-container ${className}`}>
        <div className="dtv-modal">
          <span className="dtv-icon-close" onClick={onClose || hideModal} />
          {titleImageSrc && <img src={titleImageSrc} alt="" />}
          {title && <h2 className="dtv-modal-title">{title}</h2>}
          {message && <p className="dtv-modal-paragraph">{message}</p>}
          {primaryAction && primaryText && (
            <button type="submit" className="dtv-btn-primary" onClick={primaryAction}>
              {primaryText}
            </button>
          )}
          {secondaryAction && secondaryText && (
            <button type="submit" className="dtv-btn-secondary" onClick={secondaryAction}>
              {secondaryText}
            </button>
          )}
        </div>
      </div>
    );
  }

  return null;
}

Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  primaryText: PropTypes.string,
  primaryAction: PropTypes.func,
  secondaryText: PropTypes.string,
  secondaryAction: PropTypes.func,
  onClose: PropTypes.func,
  hideModal: PropTypes.func,
  className: PropTypes.string,
};

Modal.defaultProps = {
  show: false,
  title: '',
  message: '',
  primaryText: '',
  primaryAction: () => {},
  secondaryText: '',
  secondaryAction: () => {},
  onClose: () => {},
  hideModal: () => {},
  className: '',
};

const mapStateToProps = ({ modal }) => modal;

export default connect(mapStateToProps, { hideModal: hideModalAction })(Modal);
