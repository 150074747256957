/* External */
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

/**
 * Assets
 */
import loading from '@/assets/theme/svg/loading.svg';

/* Components */
import Blur from './blur/Blur';
import SearchResults from './searchResults/SearchResults';
import ErrorMessageButton from '../components/errorMessageButton/ErrorMessageButton';
import PreSearch from './preSearch/PreSearch';

/* Actions */
import { setShowSearch, updateSearchTerm } from '../state/search/searchSlice';

/* Styles */
import './SearchOverlay.scss';

/* Other */
import { goBackFromSearch, SEARCH_KEYWORD_THRESHOLD } from '../utils';
import { api, history } from '../state/configureStore';
import { getImage } from '../theme/images';

function SearchOverlay({
  intl: { formatMessage },
  notificationHeight,
  navBarHeight,
  searchTerm,
}) {
  const dispatch = useDispatch();

  const {
    data: { contents: searchResults = [] } = {},
    isError: isSearchError,
    isSuccess: isSearchSuccess,
    isFetching: isSearchFetching,
  } = api.useSearchAssetsQuery({
    query: searchTerm,
  }, {
    skip: searchTerm.length < SEARCH_KEYWORD_THRESHOLD,
  });

  const showPreSearch = searchTerm.length < SEARCH_KEYWORD_THRESHOLD
    || (!isSearchError && !isSearchSuccess && searchResults.length === 0);
  const [isPreSearchLoading, setIsPreSearchLoading] = useState(false);

  /**
   * @cleanUp Resets searchTerm and showSearch
   */
  useEffect(
    () => () => {
      dispatch(updateSearchTerm(''));
      dispatch(setShowSearch(false));
    },
    [dispatch],
  );

  const searchResult = isSearchError ? (
    <ErrorMessageButton
      titleFirstLine={formatMessage({ id: 'common.ups', defaultMessage: '¡UPS!' })}
      titleSecondLine={formatMessage({ id: 'search.error', defaultMessage: 'Algo salió mal con la búsqueda' })}
      subtitle={formatMessage({
        id: 'common.pleaseTryAgainLater',
        defaultMessage: 'Por favor, inténtalo más tarde.',
      })}
      buttonText={formatMessage({ id: 'errorPage.btn', defaultMessage: 'Volver a DIRECTV GO' })}
      buttonAction={() => goBackFromSearch(history)}
    />
  ) : (
    <SearchResults results={searchResults} />
  );

  return (
    <div
      className={cn('dtv-search-overlay', {
        'dtv-search-overlay-centered': showPreSearch || isSearchError,
      })}
      style={{
        paddingTop: notificationHeight + navBarHeight,
      }}
    >
      {(isSearchFetching || isPreSearchLoading) && (
        <img className="dtv-search-overlay-loading" src={getImage('loading') || loading} alt="Loading" />
      )}
      <Blur in={isSearchFetching}>
        {showPreSearch ? (
          <PreSearch onIsLoadingChange={setIsPreSearchLoading} />
        ) : searchResult}
      </Blur>
    </div>
  );
}

SearchOverlay.propTypes = {
  notificationHeight: PropTypes.number,
  navBarHeight: PropTypes.number,
  searchTerm: PropTypes.string,
};

SearchOverlay.defaultProps = {
  notificationHeight: 0,
  navBarHeight: 0,
  searchTerm: '',
};

const mapStateToProps = ({ notifications, search: { searchTerm } }) => {
  const notificationHeight = notifications.get('notificationHeight');
  const navBarHeight = notifications.get('navBarHeight');

  return {
    notificationHeight,
    navBarHeight,
    searchTerm,
  };
};

export default compose(connect(mapStateToProps), injectIntl)(SearchOverlay);
