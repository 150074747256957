/* External */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import qs from 'qs';

/* Ott-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Components */
import { useGtmContext } from '../components/GtmContext';
import { gtmDimensions } from '../components/GtmContext/dimensions/search';

/* Actions */
import { setShowSearch, updateSearchTerm } from '../state/search/searchSlice';

/* Styles */
import './InputSearch.scss';

/* Other */
import {
  goBackFromSearch, PATHS, SEARCH_KEYWORD_THRESHOLD, formatContentForGtm,
} from '../utils';

function InputSearch() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search: queryString, pathname } = history.location;
  const showSearch = useSelector((state) => state.search.showSearch);
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef();

  const {
    gtmUserData,
    gtmCollectionName,
    gtmProviderName,
  } = useGtmContext();

  /**
   * @description Starts a timeout that updates searchTerm and url
   * @cleanUp Clears the timeout
   */
  useEffect(() => {
    if (!showSearch) return () => {};
    const updateSearchTermTimeout = setTimeout(() => {
      dispatch(updateSearchTerm(searchTerm));
      if (searchTerm.length < SEARCH_KEYWORD_THRESHOLD) {
        history.replace({ search: null });
      } else {
        history.replace({ search: qs.stringify({ q: searchTerm }, { format: 'RFC1738' }) });
      }
    }, 100);

    return () => clearTimeout(updateSearchTermTimeout);
  }, [dispatch, history, searchTerm, showSearch]);

  /**
   * @description Recovers searchTerm and sets focus after the first render, clears searchTerm on
   * route changes
   */
  useEffect(() => {
    if (pathname.split('/').includes(PATHS.SEARCH)) {
      const query = qs.parse(queryString, { ignoreQueryPrefix: true }).q;
      if (query) setSearchTerm(query);
      inputRef.current.focus();

      if (query?.length > 3) {
        const sendToGtm = formatContentForGtm({}, { searchTerms: query });
        gtmEvent({
          hash: gtmDimensions.pre_search.hash,
          eventType: gtmDimensions.pre_search.eventType,
          userData: gtmUserData,
          dimensions: gtmDimensions.pre_search.dimensions,
          data: sendToGtm,
        });
      }
    } else {
      setSearchTerm('');
    }
  }, [dispatch, pathname, queryString, gtmUserData]);

  const handleFocus = () => {
    if (!showSearch) {
      if (!pathname.split('/').includes(PATHS.SEARCH)) history.push(`${pathname}/${PATHS.SEARCH}`);
      dispatch(setShowSearch(true));
      const { location } = history;
      const dataTosend = formatContentForGtm(
        {},
        {
          tab: location.state?.tab || null,
          providerName: gtmProviderName,
          collectionName: gtmCollectionName,
        },
      );
      gtmEvent({
        hash: gtmDimensions.search_bar.hash,
        eventType: gtmDimensions.search_bar.eventType,
        userData: gtmUserData,
        dimensions: gtmDimensions.search_bar.dimensions,
        data: dataTosend,
      });
    }
  };

  const handleClose = () => {
    if (searchTerm?.length > 3) {
      const sendToGtm = formatContentForGtm({}, { searchTerms: searchTerm });
      gtmEvent({
        hash: gtmDimensions.clear.hash,
        eventType: gtmDimensions.clear.eventType,
        userData: gtmUserData,
        dimensions: gtmDimensions.clear.dimensions,
        data: sendToGtm,
      });
    }
    setSearchTerm('');
    goBackFromSearch(history);
  };

  return (
    <div className="dtv-input-search">
      <span className="dtv-icon-magnifying-glass" />
      <input
        type="text"
        name="search"
        value={searchTerm || ''}
        onChange={({ target: { value } = {} }) => setSearchTerm(value)}
        onFocus={handleFocus}
        autoComplete="off"
        ref={inputRef}
      />
      {showSearch && (
        <button type="button" className="dtv-input-search-button dtv-input-search-close" onClick={handleClose}>
          <span className="dtv-icon-close" />
        </button>
      )}
    </div>
  );
}

export default InputSearch;
