/* External */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  getUnixTime, isBefore, isWithinInterval, setSeconds,
} from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Components */
import AssetToPlay from '../assetToPlay/AssetToPlay';
import LiveProgressBar from '../liveProgressBar/LiveProgressBar';
import LiveStationLine from '../liveStationLine/LiveStationLine';
import LockIcon from '../lockIcon/LockIcon';
import AgeRating from '../ageRating/AgeRating';
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/search';

/* Styles */
import { backendService } from '../../services';

/* Actions */
import { setSearchInteraction } from '../../state/search/searchSlice';

/* Styles */
import './SearchCard.scss';

/* Other */
import {
  buildEpisodeSecondaryDataString,
  formatSeconds,
  getCardTitle,
  INTERACTION_PDP,
  joinArrayWithSeparator,
  redirectToPdp,
  useImage,
  formatContentForGtm,
  getGtmEventConfig,
  playerType,
  lockIconSelector,
  isLearnActionV4Active,
} from '../../utils';
import { history } from '../../state/configureStore';

function SearchCard({
  asset, intl, genericCard, gradient, index,
}) {
  const dispatch = useDispatch();

  const { searchTerm } = useSelector((state) => state.search);
  const {
    contentSourceId,
    type,
    vrioAssetId,
    images,
    vod,
    live: {
      channelId, startTime, endTime, channelName,
    },
    episode = {},
    genres,
    vmsId,
    shouldDisplayLockIcon,
    rating = '',
  } = asset;
  const {
    showId, seasonNumber = 0, number: episodeNumber = 0, showName: episodeTitle = '',
  } = episode || {};
  const { duration } = vod || {};
  const { imageRef, imageStyle } = useImage(
    images,
    genericCard,
    gradient,
    '2:3',
  );
  const { formatMessage } = intl;

  const {
    gtmUserData,
    setGtmPlaySource,
    setGtmSectionName,
    setGtmComponentType,
  } = useGtmContext();

  const { shouldDisplayLockIcon: displayLockIcon, lockIconType } = lockIconSelector({
    shouldDisplayLockIcon,
    assetRating: rating,
  });

  /**
   * @method getBottomLine
   * @description Returns the last line of the card
   */
  const getBottomLine = () => {
    let text = '';

    switch (type) {
      case assetTypes.liveEpisode:
      case assetTypes.liveSerie:
      case assetTypes.liveShow:
      case assetTypes.liveSport:
      case assetTypes.liveMovie:
        return <LiveStationLine {...{ startTime, endTime, channelName }} />;
      case assetTypes.movie:
        text = formatMessage({ id: 'searchCard.infoMovie', defaultMessage: 'PELÍCULA' });
        break;
      case assetTypes.episode:
      case assetTypes.serie:
        text = formatMessage({ id: 'searchCard.infoSerie', defaultMessage: 'SERIES' });
        break;
      case assetTypes.show:
      case assetTypes.sport:
        text = formatMessage({ id: 'searchCard.infoEvent', defaultMessage: 'EVENTO' });
        break;
      default:
        break;
    }

    return <h4 className="dtv-search-card-live-info-bottom">{text}</h4>;
  };

  const isLiveAsset = contentSourceId === 1;
  const title = getCardTitle(asset) || '';

  /**
   * @method getSubtitle
   * @description Returns the subtitle of the card
   * @param {string} resultType
   */
  const getSubtitle = () => {
    switch (type) {
      case assetTypes.liveEpisode:
      case assetTypes.liveSerie:
        return buildEpisodeSecondaryDataString(
          seasonNumber,
          episodeNumber,
          episodeTitle,
          formatMessage,
        );
      case assetTypes.episode:
      case assetTypes.liveMovie:
      case assetTypes.liveShow:
      case assetTypes.liveSport:
      case assetTypes.movie:
      case assetTypes.serie:
      case assetTypes.show:
      case assetTypes.sport:
        return joinArrayWithSeparator(genres, ', ');
      default:
        return '';
    }
  };

  const subtitle = getSubtitle();
  const start = setSeconds(new Date(startTime), 0);
  const end = setSeconds(new Date(endTime), 0);
  const isCurrentlyLive = isBefore(start, end) && isWithinInterval(new Date(), { start, end });
  const allowPlay = isLiveAsset && isCurrentlyLive;
  const formattedDuration = isCurrentlyLive ? null : formatSeconds(duration).toLowerCase();

  const gtmEventHandler = (assetInfo) => {
    const { hash, eventType, dimensions } = getGtmEventConfig(
      assetInfo.type,
      gtmDimensions,
      gtmDimensions.component_type,
      'result',
    );
    const sendToGtm = formatContentForGtm(
      { ...assetInfo },
      {
        searchTerms: searchTerm,
        listPosition: index + 1,
        playSource: gtmDimensions.component_type,
        sectionName: gtmDimensions.component_type,
        componentType: gtmDimensions.component_type,
        contentPlayerType: playerType(assetInfo.type.toLowerCase()),
        transmissionType: assetInfo.type,
      },
    );
    gtmEvent({
      hash,
      eventType,
      userData: gtmUserData,
      dimensions,
      data: sendToGtm,
    });
    setGtmPlaySource(gtmDimensions.component_type);
    setGtmSectionName(gtmDimensions.component_type);
    setGtmComponentType(gtmDimensions.component_type);
  };

  /**
   * @method handleClick
   * @description Handles click when the card doesn't redirect to player
   */
  const handleClick = () => {
    gtmEventHandler(asset);

    if (assetTypes.episode !== type) {
      backendService.experience[isLearnActionV4Active ? 'v4' : 'v2'].searchResult({
        actionTime: getUnixTime(new Date()),
        contentSourceId,
        vmsId,
        vrioAssetId,
      });
    }

    if (!allowPlay) {
      const assetId = isLiveAsset ? channelId : vrioAssetId;
      dispatch(setSearchInteraction({ searchTerm, assetId, action: INTERACTION_PDP }));
      redirectToPdp({
        assetId, type, startTime, history, showId, isLive: isLiveAsset,
      });
    }
  };

  return (
    <AssetToPlay
      className="dtv-search-card-live"
      asset={{
        assetId: vrioAssetId,
        channelId,
        playSource: isLiveAsset ? 'Search-LIVE' : 'Search-VOD',
        type,
      }}
      allowPlay={allowPlay}
      isLive={isLiveAsset}
      onClick={() => handleClick()}
    >
      <div className="dtv-search-card-live-img" ref={imageRef} style={imageStyle}>
        {displayLockIcon && (
          <LockIcon iconType={lockIconType} />
        )}
      </div>
      <div className="dtv-search-card-live-info">
        <span>
          {title && <h3 className="dtv-search-card-live-info-title">{title}</h3>}
          <div className="dtv-search-card-live-info-rating-subtitle">
            <AgeRating rating={rating} />
            {subtitle && <h4 className="dtv-search-card-live-info-subtitle">{subtitle}</h4>}
          </div>
          {duration && <h4 className="dtv-search-card-live-info-duration">{formattedDuration}</h4>}
        </span>
        <span>
          {allowPlay && (
            <LiveProgressBar
              className="dtv-search-card-live-info-progress"
              endTime={endTime}
              startTime={startTime}
              typeCard={1}
            />
          )}
          {getBottomLine()}
        </span>
      </div>
    </AssetToPlay>
  );
}

SearchCard.propTypes = {
  asset: PropTypes.shape({
    vod: {
      duration: PropTypes.number,
    },
    live: {
      endTime: PropTypes.string,
      startTime: PropTypes.string,
      channelId: PropTypes.string,
      channelName: PropTypes.string,
    },
    episode: {
      number: PropTypes.number,
      seasonNumber: PropTypes.number,
    },
    genres: PropTypes.arrayOf(PropTypes.string),
    vrioAssetId: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    source: PropTypes.string,
    type: PropTypes.string,
    shouldDisplayLockIcon: PropTypes.bool,
  }),
  intl: intlShape.isRequired,
  genericCard: PropTypes.string,
  pictureId: PropTypes.string,
};

SearchCard.defaultProps = {
  asset: {
    vod: {
      duration: 0,
    },
    live: {
      endTime: '',
      startTime: '',
      channelId: '',
      channelName: '',
    },
    episode: {
      number: 0,
      seasonNumber: 0,
    },
    genres: [],
    vrioAssetId: '',
    labels: [],
    title: '',
    source: '',
    type: '',
    shouldDisplayLockIcon: false,
  },
  genericCard: '',
  pictureId: '',
};

export default injectIntl(SearchCard);
