/**
 * External
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getAuthTokensData } from '../../../../../utils/localStorageState';

/**
 * Actions
 */
import * as actions from './actions';

/**
 * Services
 */
import { changePasswordApi } from './services';

function* changePassword({ payload }) {
  yield put(showLoading());
  try {
    yield put(actions.userRequest());
    const { accessToken } = getAuthTokensData();
    const {
      userName, iso2Code, oldPassword, newPassword,
    } = payload;
    let responseData = {};
    responseData = yield call(
      changePasswordApi,
      userName,
      iso2Code,
      oldPassword,
      newPassword,
      accessToken,
    );
    yield put(actions.userSuccess(responseData));
    yield put(actions.userReset());
  } catch (error) {
    console.error(`changePasswordSaga-changePassword: ${error}`);
    yield put(actions.userFailure(error));
  } finally {
    yield put(hideLoading());
  }
}

export default function* root() {
  yield takeLatest(actions.ACTION_TYPES.CHANGE_PASSWORD, changePassword);
}
