import { datadogRum } from '@datadog/browser-rum';

import { APP_VERSION } from './constants';

datadogRum.init({
  applicationId: import.meta.env.REACT_APP_DATA_DOG_APPLICATION_ID,
  clientToken: import.meta.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'web-private',
  env: import.meta.env.REACT_APP_ENV_NAME,
  version: APP_VERSION,
  sessionSampleRate: Number.parseInt(import.meta.env.REACT_APP_DATA_DOG_SAMPLE_RATE, 10),
  sessionReplaySampleRate: Number.parseInt(import.meta.env.REACT_APP_DATA_DOG_SAMPLE_RATE, 10),
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingOrigins: [
    import.meta.env.REACT_APP_BACKEND_SERVICE_HOST,
    import.meta.env.REACT_APP_FORGEROCK_SERVICE_HOST,
    import.meta.env.REACT_APP_TOOLBOX_HOST,
    import.meta.env.REACT_APP_TOOLBOX_HOST_LOGIN_MSO,
  ],
});
