/* External */
import { createSlice } from '@reduxjs/toolkit';

const epgRedirectionDataSlice = createSlice({
  name: 'epgRedirectionData',
  initialState: {
    hasBeenRedirectedToEpgFromPlayer: false,
  },
  reducers: {
    updateEpgRedirectionData: (state, action) => {
      const newState = state;
      const {
        channelId,
        fromEpg,
        fromEpgFilterId,
        fromEpgRedirectionIndex,
        hasBeenRedirectedToEpgFromPlayer,
        isSports,
        redirectedByUer,
      } = action.payload;
      newState.channelId = channelId;
      newState.fromEpg = fromEpg;
      newState.fromEpgFilterId = fromEpgFilterId;
      newState.fromEpgRedirectionIndex = fromEpgRedirectionIndex;
      newState.hasBeenRedirectedToEpgFromPlayer = hasBeenRedirectedToEpgFromPlayer;
      newState.isSports = isSports;
      newState.redirectedByUer = redirectedByUer;
    },
  },
});

export const { updateEpgRedirectionData } = epgRedirectionDataSlice.actions;

export default epgRedirectionDataSlice.reducer;
