/**
 * External
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { injectIntl } from 'react-intl';

/**
 * Components
 */
import Toast from './Toast';

function CloseButton({ closeToast }) {
  return <div className="dtv-icon-close dtv-icon-close-notificacion" onClick={closeToast} />;
}

class ToastNotification extends Component {
  constructor(props) {
    super(props);
    this.toastId = null;
    this.showNotification = this.showNotification.bind(this);
  }

  /**
   * @method componentDidUpdate
   * @description React lifecycle method. Used to validate when to show notification
   * @param {object} prevProps
   */
  componentDidUpdate(prevProps) {
    const { notification: currentNotification } = this.props;
    const { notification: prevNotification } = prevProps;

    if (currentNotification !== prevNotification) {
      if (currentNotification) {
        this.showNotification(currentNotification);
      } else {
        toast.dismiss(this.toastId);
        this.toastId = null;
      }
    }
  }

  /**
   * @method showNotification
   * @description Shows toast notification
   * @param {object} notification
   */
  showNotification({
    messageId, message, type, title, time = 5000,
  }) {
    const {
      intl: { formatMessage },
    } = this.props;
    const messageToast = messageId
      ? formatMessage({ id: messageId, defaultMessage: message })
      : message;
    this.toastId = toast(<Toast message={messageToast} title={title} />, {
      hideProgressBar: true,
      autoClose: time,
      closeButton: true,
      className: `dtv-toast toast-content ${type}`,
    });
  }

  render() {
    return <ToastContainer closeButton={<CloseButton />} />;
  }
}

function mapStateToProps({ notifications }) {
  return { notification: notifications.get('toastNotification') };
}

export default injectIntl(connect(mapStateToProps)(ToastNotification));
