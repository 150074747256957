/* eslint "react/require-default-props": [
  "error", { "functions": "defaultArguments", "forbidDefaultForRequired": true }
] */
/* External */
import React, { useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { renderWidget } from './utils';

const defaultProps = {};
function HBSWidget({ className = '', type, props = defaultProps }) {
  const ref = useRef();

  useEffect(() => {
    renderWidget(ref.current, type, props);
  }, [props, type]);

  return <div className={className} ref={ref} />;
}

HBSWidget.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'matchbox',
    'standing',
    'topstats',
    'videos',
    'matchesplayed',
    'teamcard',
    'teamslist',
    'squad',
    'squadplayers',
    'statisticsH2H',
    'teampicker',
    'playercard',
    'playerprofile',
    'seasonstats',
    'championship',
    'watch',
    'teamboard',
    'teamboard-cards',
    'matchcenter',
    'match-hero',
    'match-lineups',
    'match-moments',
    'actions',
    'match-stats',
    'navigation',
  ]).isRequired,
  props: PropTypes.shape({}),
};

export default memo(HBSWidget);
