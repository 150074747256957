/* External */
import React from 'react';
import {
  differenceInMinutes, format, isBefore, isWithinInterval, setSeconds,
} from 'date-fns';
import { injectIntl } from 'react-intl';
import { useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';

/* Assets */
import genericCard2X3 from '@/assets/theme/images/2x3-GenericCard.png';

/* Components */
import LiveProgressBar from '../liveProgressBar/LiveProgressBar';
import AssetToPlay from '../assetToPlay/AssetToPlay';
import AgeRating from '../ageRating/AgeRating';
import LockIcon, { CARD_TYPES } from '../lockIcon/LockIcon';
import { useGtmContext } from '../GtmContext';

/* Styles */
import './CardChannelWithSchedule.scss';

/* Other */
import {
  getAssetTitle,
  getEpgEpisodeSubtitle,
  joinArrayWithSeparator,
  useImage,
  lockIconSelector,
} from '../../utils';
import { gtmWatchCarouselEventHandler } from '../GtmContext/dimensions/carousels/eventHandlers';
import { getImage } from '../../theme/images';

function CardChannelWithSchedule({ infoCard, intl: { formatMessage }, carouselInfo = {} }) {
  const {
    setGtmPlaySource,
    setGtmSectionName,
    setGtmComponentType,
    setGtmCarouselVerticalPosition,
    gtmUserData,
  } = useGtmContext();
  const { params: locationParams } = useRouteMatch();
  const genericCard2X3Flag = getImage('2x3GenericCard') || genericCard2X3;
  const { imageRef, imageStyle } = useImage(infoCard.images, genericCard2X3Flag, null, '', false);
  const schedule = infoCard?.schedules?.[0] || {};
  const { startTime, endTime } = schedule.live || {};
  let timeLeft = null;
  let startTimeFormatted = null;
  let endTimeFormatted = null;
  if (startTime && endTime) {
    const start = setSeconds(new Date(startTime), 0);
    const end = setSeconds(new Date(endTime), 0);
    const currentDate = new Date();
    if (isBefore(start, end) && isWithinInterval(currentDate, { start, end })) {
      timeLeft = differenceInMinutes(end, currentDate) + 1;
    }
    startTimeFormatted = format(start, 'HH:mm');
    endTimeFormatted = format(end, 'HH:mm');
  }

  /**
   * @description Returns description line
   * @param {string} text
   * @param {string} rating
   */
  const getDescriptionLine = (text, rating) => {
    if (!text && !rating) return '';

    return (
      <span className="dtv-card-channel-with-schedule-description">
        <AgeRating rating={rating} />
        <span className="dtv-card-channel-with-schedule-description-text">{text}</span>
      </span>
    );
  };

  const {
    shouldDisplayLockIcon,
    channelId,
    playSource,
  } = infoCard;

  const { shouldDisplayLockIcon: displayLockIcon, lockIconType } = lockIconSelector({
    shouldDisplayLockIcon,
    assetRating: schedule?.rating,
  });

  const handleClick = () => {
    try {
      setGtmCarouselVerticalPosition(carouselInfo.carouselVerticalPosition);
      setGtmPlaySource(playSource);
      setGtmSectionName(carouselInfo.sectionTitle);
      setGtmComponentType(carouselInfo.type);

      gtmWatchCarouselEventHandler({
        asset: schedule,
        gtmUserData,
        locationParams,
        carouselInfo,
      });
    } catch (error) {
      console.warn(`An error occurred: ${error.message}`);
    }
  };

  return (
    <AssetToPlay
      className="dtv-card-channel-with-schedule"
      asset={{
        assetId: schedule.vrioAssetId,
        type: schedule.type,
        channelId,
        playSource,
      }}
      isLive
      onClick={handleClick}
    >
      <div className="dtv-card-channel-with-schedule-info">
        <div className="dtv-card-channel-with-schedule-info-show">
          <span className="dtv-card-channel-with-schedule-title">
            {getAssetTitle(schedule)
              || formatMessage({ id: 'common.dataNotAvailable', defaultMessage: 'Información no disponible' })}
          </span>
          {getDescriptionLine(
            getEpgEpisodeSubtitle(schedule, formatMessage, true),
            schedule.rating,
          )}
        </div>
        <div className="dtv-card-channel-with-schedule-logo">
          {displayLockIcon
            && <LockIcon cardType={CARD_TYPES.channelWithScheduleCard} iconType={lockIconType} />}
          <div className="dtv-card-channel-logo-image" style={imageStyle} ref={imageRef} />
        </div>
      </div>

      <div className="dtv-card-channel-with-schedule-progress">
        <span className="dtv-card-channel-with-schedule-progress-clock">{joinArrayWithSeparator([startTimeFormatted, endTimeFormatted], ' - ')}</span>
        {timeLeft && <LiveProgressBar {...{ typeCard: 1, startTime, endTime }} width="143" initialPosition="148" />}
      </div>
    </AssetToPlay>
  );
}

CardChannelWithSchedule.propTypes = {
  infoCard: PropTypes.shape({
    shouldDisplayLockIcon: PropTypes.bool,
  }),
};

CardChannelWithSchedule.defaultProps = {
  infoCard: {
    shouldDisplayLockIcon: false,
  },
};

export default injectIntl(CardChannelWithSchedule);
