/* External */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

/* Ott-common */
import { useAudioTracks, usePlayerTime, useSubtitleTracks } from '@dtvgo/player-context';
import { useLiveProgress } from '@dtvgo/player-utils-react';

/* Components */
import GtmComponent from '../components/GtmContext/GtmComponent';

/* Other */
import { contentType, getGtmEventConfig, playerType } from '../utils';
import { gtmDimensions } from '../components/GtmContext/dimensions/player';

const PlayerGtmTrack = forwardRef(({ isLive, asset }, ref) => {
  const { hash, dimensions, eventType } = getGtmEventConfig(
    asset.type,
    gtmDimensions,
    'player',
    'page_view',
  );
  const subtitleTracks = useSubtitleTracks();
  const audioTracks = useAudioTracks();
  const liveProgress = useLiveProgress();
  const playerTime = usePlayerTime();
  const progress = isLive ? liveProgress : playerTime;

  return (
    <GtmComponent
      assetToPlay={asset}
      config={{
        subtitleTrack: subtitleTracks?.find((item) => item.isSelected)?.language || 'no-subtitles',
        audioTrack: audioTracks?.find((item) => item.isSelected)?.lang,
        startProgressBar: progress,
        contentType: contentType(asset.type),
        progress,
        contentPlayerType: playerType(asset.type),
        transmissionType: asset.type,
      }}
      hash={hash}
      eventType={eventType}
      dimensions={dimensions}
      ref={ref}
      noGtmState
    />
  );
});

PlayerGtmTrack.propTypes = {
  isLive: PropTypes.bool,
  asset: PropTypes.shape({
    type: PropTypes.string,
  }),
};

PlayerGtmTrack.defaultProps = {
  isLive: false,
  asset: {},
};

export default PlayerGtmTrack;
