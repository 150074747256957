/**
 * External
 */
import { all, fork } from 'redux-saga/effects';

/**
 * Sagas
 */
import changePassword from './userMenu/settings/general/changePassword/sagas';
import user from './user/sagas';

export default function* rootSaga() {
  yield all([fork(changePassword)]);
  yield all([fork(user)]);
}
