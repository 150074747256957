/**
 * External
 */
import React, { Component } from 'react';
import isNilL from 'lodash-es/isNil';
import { history } from '../../state/configureStore';

/**
 * Styles
 */
import './LinkButton.scss';

class LinkButton extends Component {
  handleRedirect(e) {
    const { onClick, url } = this.props;
    if (!isNilL(onClick)) onClick(e);
    history.push(url);
  }

  render() {
    const {
      children, icon, width, className = '',
    } = this.props;
    return (
      <div
        style={{ width: `${width}px` }}
        onClick={(e) => this.handleRedirect(e)}
        className={`dtv-link-button ${!isNilL(icon) && 'with-icon'} ${className}`}
      >
        <span className="dtv-link-button-text">{children}</span>
        {!isNilL(icon) && <span className={`${icon} dtv-link-icon`} />}
      </div>
    );
  }
}

export default LinkButton;
