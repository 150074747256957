/* External */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, NavLink } from 'react-router-dom';
import getL from 'lodash-es/get';
import PropTypes from 'prop-types';

/* Ott-common */
import Icon from '@dtvgo/icon';
import CardProfile from '@dtvgo/card-profile';
import CardNewProfile from '@dtvgo/card-new-profile';

/* Assets */
import ExitApp from '@/assets/theme/icons/exit-app.svg';
import Settings from '../assets/icons/settings.svg';
import MyList from '../assets/icons/my-list.svg';
import Menu from '../assets/icons/menu.svg';

/* Components */
import { useGtmContext } from '../components/GtmContext';
import { gtmDimensions } from '../components/GtmContext/dimensions/user';

/* Actions */
import { showModal, hideModal } from '../state/modal/actions';

/* Styles */
import './UserMenu.scss';

/* Other */
import {
  getSelfCareURL,
  getSelfCareLinkAction,
  getUserLanguages,
  DEFAULT_VALUES,
  LOGOUT_URL,
  PATHS,
  colors,
  PROFILES_STORAGE,
  userGtmEventHandler,
  BUSINESS_CAMPAIGN,
} from '../utils';
import { getImage } from '../theme/images';

function UserMenu(props) {
  const {
    notificationHeight,
    user,
    menu,
    setMenu,
  } = props;
  const { account: { userType, msoProvider } } = user;
  const selfCareUrl = getSelfCareURL(user);
  const selfCareLinkAction = getSelfCareLinkAction(props);
  const isOttUser = userType.toLowerCase().includes('ott');

  const [profiles] = useState(JSON.parse(localStorage.getItem(PROFILES_STORAGE.profiles)));
  const profileSelected = JSON.parse(localStorage.getItem(PROFILES_STORAGE.profile));
  const isGranHermanoUser = BUSINESS_CAMPAIGN.has(msoProvider);

  const { gtmUserData, setGtmUserData } = useGtmContext();

  const handleClick = (e, profile, index) => {
    e.preventDefault();
    const newProfileSelected = { ...profile, color: index };
    localStorage.setItem(PROFILES_STORAGE.profile, JSON.stringify(newProfileSelected));

    const gtmUserDataWithProfileSelected = {
      ...gtmUserData,
      v_profile_custom_age: newProfileSelected?.ageRating || null,
      v_admin_profile: newProfileSelected?.isMainProfile?.toString() || null,
      v_profile_id: newProfileSelected?.id || null,
      v_profile_unrated_content: newProfileSelected?.isNotRatedContentAllowed?.toString() || null,
    };

    userGtmEventHandler(
      gtmDimensions.multiple_profile.profiles.profile_to_watch,
      gtmUserDataWithProfileSelected,
    );

    setGtmUserData(gtmUserDataWithProfileSelected);
    window.location.href = PATHS.HOME;
  };

  return (
    <div className="dtv-options-user-menu-container">
      <div
        className={`dtv-options-user-menu-container__feature-flag-profiles dtv-text-right ${menu}`}
        style={{ top: `${65 + notificationHeight}px` }}
      >
        <div className="dtv-options-user-menu-container__title">
          <FormattedMessage id="common.hello" defaultMessage="Hola," />
        </div>
        <div className="dtv-options-user-menu-container__title">
          {profileSelected.name.length > 15
            ? `${profileSelected.name.slice(0, 15)}...` : profileSelected.name}
        </div>
        <div className="dtv-options-user-menu-container__profiles">
          {profiles.map((profile, index) => (
            <a
              onClick={(e) => handleClick(e, profile, index)}
              href={PATHS.HOME}
              target="_self"
              className="dtv-options-user-menu-container__profile"
              rel="noopener noreferrer"
              key={profile.id}
            >
              <CardProfile
                className="dtv-web-user-profile__card-logo"
                key={profile.id}
                name={profile.name.slice(0, 20)}
                color={colors[index + 1]}
              />
              <div className="dtv-options-user-menu-container__name">
                {profile.name}
              </div>
            </a>
          ))}
        </div>
        <div>
          {profiles?.length < 5 ? (
            <Link
              to={{ pathname: PATHS.CREATE_PROFILE, length: profiles.length }}
              className="dtv-options-user-menu-container__create-profile"
              onClick={() => userGtmEventHandler(
                gtmDimensions.multiple_profile.settings.create_profile,
              )}
            >
              <CardNewProfile
                className="dtv-web-user-profile--add"
              />
              <div className="dtv-options-user-menu-container__name">
                <FormattedMessage id="profiles.userProfile.createProfile.text" defaultMessage="Crear perfil" />
              </div>
            </Link>
          ) : ('')}
        </div>
        <Link
          to={{ pathname: PATHS.PROFILE_MANAGEMENT, state: 'userMenu' }}
          className="menu-itens menu-link"
          onClick={() => userGtmEventHandler(
            gtmDimensions.multiple_profile.settings.manage_profile,
          )}
        >
          <div>
            <FormattedMessage id="profiles.userProfile.managerProfile.text" defaultMessage="Gestionar perfiles" />
          </div>
        </Link>
        <Link
          to={{ pathname: PATHS.MY_LIST }}
          className="menu-itens"
          onClick={() => setMenu(false)}
        >
          <div className="menu-item">
            <img src={MyList} alt="menu-my-list" />
            <div className="dtv-options-user-menu-container__name">
              <FormattedMessage id="myList" defaultMessage="Mi lista" />
            </div>
          </div>
          <Icon name="arrow-forward" />
        </Link>
        <a
          onClick={selfCareLinkAction}
          href={selfCareUrl}
          target="_self"
          className="menu-itens"
          rel="noopener noreferrer"
        >
          <div className="menu-item">
            <img src={Settings} alt="menu-settings" />
            <div className="dtv-options-user-menu-container__name">
              <FormattedMessage
                id={isGranHermanoUser ? 'menu.optionUser.account.preferences.granHermano' : 'menu.optionUser.account.preferences'}
                defaultMessage="Configuración y Cuenta"
              />
            </div>
          </div>
          <Icon name="arrow-forward" />
        </a>
        {
          isOttUser && (
            <a
              href="/home/preferences"
              className="menu-itens"
            >
              <div className="menu-item">
                <img src={Menu} alt="menu-settings" />
                <div className="dtv-options-user-menu-container__name">
                  <FormattedMessage id={isGranHermanoUser ? 'myProfile.general.changePassword' : 'menu.optionsUser.preferences'} defaultMessage="Preferencias" />
                </div>
              </div>
              <Icon name="arrow-forward" />
            </a>
          )
        }
        <NavLink to={LOGOUT_URL} className="mt-3 menu-link" activeClassName="active">
          <img src={getImage('iconExitApp') || ExitApp} alt="menu-exit-app" />
          <div className="dtv-options-user-menu-container__name menu-link">
            <FormattedMessage id="menu.optionsUser.logout" defaultMessage="Salir" />
          </div>
        </NavLink>
      </div>
    </div>
  );
}

function mapStateToProps({ user, notifications, global }) {
  const notificationHeight = notifications.get('notificationHeight');
  const navBarHeight = notifications.get('navBarHeight');
  const { client: language = DEFAULT_VALUES.LANGUAGE } = getUserLanguages(user);
  const partnersConfig = getL(global, 'configurationAEM.data.partners', []);
  return {
    language,
    navBarHeight,
    notificationHeight,
    partnersConfig,
    sessionToken: user.sessionToken,
    user,
  };
}

UserMenu.propTypes = {
  notificationHeight: PropTypes.number,
  user: PropTypes.shape({
    account: PropTypes.shape({
      userType: PropTypes.string,
      msoProvider: PropTypes.string,
      iso2Code: PropTypes.string,
    }),
  }),
  menu: PropTypes.bool,
  setMenu: PropTypes.func,
};

UserMenu.defaultProps = {
  notificationHeight: 0,
  user: {
    account: {
      userType: '',
      msoProvider: '',
      iso2Code: '',
    },
  },
  menu: false,
  setMenu: () => {},
};

export default injectIntl(connect(mapStateToProps, { showModal, hideModal })(UserMenu));
