/* External */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/* Other */
import { CHROMECAST_PLAYER_STATE as PLAYER_STATE, getAssetDescription } from '../../utils';

function AssetDescription({
  intl: { formatMessage }, isMediaLoaded, asset, playerState, castDeviceName,
}) {
  let assetDescription;
  let castStatusText;

  if (asset) {
    const assetTitle = asset.episode?.showName || asset?.title || '';
    const assetSubtitle = getAssetDescription(asset, formatMessage, true);

    assetDescription = `${assetTitle}${assetSubtitle ? ` | ${assetSubtitle}` : ''}`;
  }

  switch (playerState) {
    case PLAYER_STATE.BUFFERING:
    case PLAYER_STATE.LOADING:
      castStatusText = formatMessage({ id: 'player.chromecast.loadingIn', defaultMessage: 'Cargando' });
      break;
    case PLAYER_STATE.PAUSED:
      castStatusText = formatMessage({ id: 'player.chromecast.pausedIn', defaultMessage: 'Pausado' });
      break;
    case PLAYER_STATE.PLAYING:
      castStatusText = formatMessage({ id: 'player.chromecast.casting', defaultMessage: 'Casting' });
      break;
    default:
      castStatusText = '';
  }

  const title = isMediaLoaded
    ? castStatusText
    : formatMessage({
      id: 'player.chromecast.readyToCast',
      defaultMessage: 'Listo para reproducir en',
    });

  const description = isMediaLoaded
    ? assetDescription
    : formatMessage({
      id: 'player.chromecast.readyToCastDescription',
      defaultMessage: 'Reproduzca un título para comenzar el casting',
    });

  return (
    <>
      <span className="dtv-chromecast-bar-info-data-title">
        {title}
        {' '}
        {castDeviceName}
      </span>
      &nbsp;&nbsp;
      <span className="dtv-chromecast-bar-info-data-metadata">{description}</span>
    </>
  );
}

AssetDescription.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isMediaLoaded: PropTypes.bool.isRequired,
  asset: PropTypes.shape({
    episode: PropTypes.shape({
      showName: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  playerState: PropTypes.oneOf(Object.values(PLAYER_STATE)).isRequired,
  castDeviceName: PropTypes.string.isRequired,
};

AssetDescription.defaultProps = {
  asset: null,
};

export default injectIntl(memo(AssetDescription));
