/**
 * External
 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import getL from 'lodash-es/get';

/* OTT-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';

/**
 * Assets
 */
import cellPhone from '@/assets/theme/images/error-cell-phone.png';
import playStore from '../assets/images/play-store.png';
import appStore from '../assets/images/app-store.svg';

/**
 * Components
 */
import MenuBar from '../components/menuBar/MenuBar';
import Footer from '../components/footer/Footer';
import { useGtmContext } from '../components/GtmContext';
import { gtmDimensions } from '../components/GtmContext/dimensions/errors';

/**
 * Styles
 */
import './ErrorPage.scss';

/**
 * Other
 */
import {
  goBackFromErrorPage,
  DEFAULT_VALUES,
  STATUS_TYPES,
  STATIC_URLS,
  getURLWithParams,
  formatContentForGtm,
  IS_BRAZIL,
} from '../utils';
import { getImage } from '../theme/images';

function redirectPlayStore() {
  return IS_BRAZIL
    ? STATIC_URLS.APP_STORES_SKY.playStore
    : STATIC_URLS.APP_STORES_DGO.playStore;
}

function ErrorPage({ user: { login = {}, account = {} } = {}, intl }) {
  const country = getL(account, 'country', DEFAULT_VALUES.COUNTRY).toLowerCase();
  const isUserLoggedIn = login.status === STATUS_TYPES.SUCCEED_STATE;

  const {
    gtmUserData,
  } = useGtmContext();

  const handleClick = () => {
    const { hash, eventType, dimensions } = gtmDimensions.error.ok;
    const dataToSend = formatContentForGtm();
    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataToSend,
    });
    goBackFromErrorPage();
  };

  useEffect(() => {
    const titleDocument = intl?.formatMessage({ id: 'errorPage.titleDocument', defaultMessage: 'Page not found - DIRECTV GO.' });
    document.title = titleDocument || 'DIRECTV GO';

    return () => {
      document.title = 'DIRECTV GO';
    };
  }, [intl]);

  useEffect(() => {
    const { hash, eventType, dimensions } = gtmDimensions.error.page_view;
    const dataToSend = formatContentForGtm();
    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataToSend,
    });
  }, [gtmUserData]);

  function redirectItunes() {
    return IS_BRAZIL
      ? getURLWithParams(STATIC_URLS.APP_STORES_SKY.iTunes, { country })
      : getURLWithParams(STATIC_URLS.APP_STORES_DGO.iTunes, { country });
  }

  return (
    <div className="dtv-error">
      <MenuBar />
      <div className={`dtv-error-content ${isUserLoggedIn ? 'private' : 'public'}`}>
        <div className="dtv-error-content-info">
          <p className="dtv-error-content-info-title">
            <FormattedHTMLMessage id="errorPage.title-1" defaultMessage="This page is not available." />
            <br />
            <FormattedHTMLMessage id="errorPage.title-2" defaultMessage="¡Do not worry!" />
          </p>
          <p className="dtv-error-content-info-subtitle">
            <FormattedMessage
              id="errorPage.subtitle"
              defaultMessage="Keep enjoying your favorite shows on DIRECTV GO."
            />
          </p>
          <button type="button" onClick={() => handleClick()} className="dtv-error-content-info-btn">
            <FormattedMessage id="errorPage.btn" defaultMessage="BACK TO DIRECTV GO" />
          </button>
        </div>
        <div className="dtv-error-content-mobile">
          <img className="dtv-error-content-mobile-cell-phone" src={getImage('errorCellPhone') || cellPhone} alt="Phone" />
          <div className="dtv-error-content-mobile-stores">
            <p className="dtv-error-content-mobile-stores-text">
              <FormattedMessage
                id="errorPage.mobile"
                defaultMessage="Did you know that DIRECTV GO is available for your mobile device? Download it now!"
              />
            </p>
            <div className="dtv-error-content-mobile-stores-icons">
              <a
                className="dtv-error-content-mobile-stores-icons-link"
                href={redirectPlayStore()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="dtv-error-content-mobile-stores-icons-icon" src={getImage('playStore') || playStore} alt="Play store" />
              </a>
              <a
                className="dtv-error-content-mobile-stores-icons-link"
                href={redirectItunes()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="dtv-error-content-mobile-stores-icons-icon" src={getImage('appStore') || appStore} alt="App store" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer publicZone={!isUserLoggedIn} />
    </div>
  );
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(injectIntl(ErrorPage));
