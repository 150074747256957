export const ACTION_TYPES = {
  SHOW_MODAL: 'modal/SHOW_MODAL',
  HIDE_MODAL: 'modal/HIDE_MODAL',
};

export const showModal = (modal) => ({
  type: ACTION_TYPES.SHOW_MODAL,
  payload: modal,
});

export const hideModal = () => ({
  type: ACTION_TYPES.HIDE_MODAL,
});
