import { userConstants } from './userConstants';

export const gtmDimensions = {
  web_enter_code: {
    page_view: {
      hash: 'p-030.011.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    enter_validation_code: {
      hash: 'e-030.011.001.000.000.132',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
      ],
    },
  },
  web_code_validation_success: {
    page_view: {
      hash: 'p-030.011.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
  },
  web_code_validation_error: {
    page_view: {
      hash: 'p-041.023.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_error_code',
        'v_error_type',
      ],
    },
  },
};
