/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import HBSWidget from '../HBSWidget';

/* Styles */
import './SportTeamFormation.scss';

function SportTeamFormation({ match = {} }) {
  return (
    <HBSWidget
      className="team-formation-widget"
      type="match-lineups"
      props={{ matchId: match?.fifaMatchId, playerRoute: '' }}
    />
  );
}

SportTeamFormation.propTypes = {
  match: PropTypes.shape({
    fifaMatchId: PropTypes.string.isRequired,
    optaMatchId: PropTypes.string.isRequired,
  }).isRequired,
};

export default SportTeamFormation;
