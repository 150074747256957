import { LOCATIONS_ERROR } from '@dtvgo/code-error';
import { history } from '../state/configureStore';

export const ErrorCodes = {
  TOO_MUCH_TIME_AWAY: 'A7-{errorLocation}40',
  DEVICE_CONCURRENCY: 'A7-{errorLocation}21',
  DEVICE_LOGGED_OUT: 'A7-{errorLocation}25',
  DEVICE_TOKEN_EXPIRED: 'A7-{errorLocation}15',
  DEFAULT_LOGOUT_ERROR: 'A7-{errorLocation}16',
};

export const ErrorMessages = {
  [ErrorCodes.TOO_MUCH_TIME_AWAY]: 'auth.errors.generic',
  [ErrorCodes.DEVICE_CONCURRENCY]: 'auth.errors.concurrency',
  [ErrorCodes.DEVICE_LOGGED_OUT]: 'auth.errors.generic',
  [ErrorCodes.DEVICE_TOKEN_EXPIRED]: 'auth.errors.generic',
  [ErrorCodes.DEFAULT_LOGOUT_ERROR]: 'auth.errors.generic',
};

export const getErrorDetails = (errorLocation, code, status, details) => {
  const resolveErrorCode = (template) => template.replace('{errorLocation}', errorLocation);
  let errorCode = ErrorCodes.DEFAULT_LOGOUT_ERROR;

  if (code === ErrorCodes.TOO_MUCH_TIME_AWAY) {
    errorCode = ErrorCodes.TOO_MUCH_TIME_AWAY;
  } else if (status === '401') {
    if (details === 'TOKEN_ALREADY_LOGGED_OUT') {
      errorCode = ErrorCodes.DEVICE_LOGGED_OUT;
    } else if (code === 'DTV-007') {
      errorCode = ErrorCodes.DEVICE_CONCURRENCY;
    }
  }

  if (details === 'TOKEN_EXPIRED') {
    errorCode = ErrorCodes.DEVICE_TOKEN_EXPIRED;
  }

  const errorMessage = ErrorMessages[errorCode];
  let errorCodeWithDetails;

  if (errorCode === ErrorCodes.TOO_MUCH_TIME_AWAY) {
    errorCodeWithDetails = resolveErrorCode(errorCode);
  } else if (code) {
    errorCodeWithDetails = `${resolveErrorCode(errorCode)} (${code})`;
  } else if (status) {
    errorCodeWithDetails = `${resolveErrorCode(errorCode)} (${status})`;
  } else {
    errorCodeWithDetails = resolveErrorCode(errorCode);
  }

  return { errorMessage, errorCodeWithDetails };
};

const getErrorLocation = (requestError) => {
  const requestRoute = requestError?.config?.url;
  const currentRoute = window.location.pathname;

  if (requestRoute) {
    const requestMethod = requestError?.config?.method || '';
    if (requestRoute.includes('/search')) return LOCATIONS_ERROR.SEARCH;
    if (requestRoute.includes('/playback/token/authorizer')) return LOCATIONS_ERROR.PLAYER_BEFORE_VIDEO_START;
    if (requestRoute.includes('/epg')) return LOCATIONS_ERROR.EPG;
    if (requestRoute.includes('/morelikethis')) return LOCATIONS_ERROR.SEE_MORE_LANDER;
    if (
      requestMethod === 'DELETE'
      && requestRoute.includes('/customer/v1/profiles/')
    ) return LOCATIONS_ERROR.PROFILE_DELETE;
  }

  if (currentRoute.includes('/pdp')) return LOCATIONS_ERROR.PDP;
  if (currentRoute.includes('/lander')) return LOCATIONS_ERROR.LANDER;
  if (currentRoute.includes('/live')) return LOCATIONS_ERROR.LIVE_PAGE;
  if (currentRoute.includes('/watch')) return LOCATIONS_ERROR.CATALOG_PAGE;
  if (currentRoute.includes('/sports')) return LOCATIONS_ERROR.SPORTS_PAGE;
  if (currentRoute.includes('/kids')) return LOCATIONS_ERROR.KIDS_PAGE;
  if (currentRoute.includes('/preferences')) return LOCATIONS_ERROR.SETTINGS;
  if (currentRoute.includes('/lander')) return LOCATIONS_ERROR.LANDER;
  if (currentRoute.includes('/main')) return LOCATIONS_ERROR.HOME_PAGE;
  if (currentRoute.includes('/player/')) return LOCATIONS_ERROR.PLAYER_PLAYBACK;
  if (currentRoute.includes('/user/create/profile')) return LOCATIONS_ERROR.PROFILE_CREATE;
  if (currentRoute.includes('/user/profile/management')) return LOCATIONS_ERROR.PROFILE_EDIT;
  if (
    currentRoute.includes('/ativar-tv')
    || currentRoute.includes('/activar-tv')
  ) return LOCATIONS_ERROR.TV_ACTIVATION;
  return LOCATIONS_ERROR.BACKGROUND_SERVICE;
};

export const redirectToAuthErrorPage = (error) => {
  if (window.location.pathname.includes('error')) return;

  const { code, details } = error?.axiosError?.response?.data || {};
  const { status } = error?.axiosError?.response || {};
  const errorLocation = getErrorLocation(error?.requestError);

  history.push(
    `/user/error?
    ${code ? `error=${code}&` : ''}
    ${errorLocation ? `errorLocation=${errorLocation}&` : ''}
    ${status ? `status=${status}&` : ''}
    ${details ? `details=${details}` : ''}
  `.replace(/&$/, '').replace(/\s/g, ''),
  );
};
