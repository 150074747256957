import { userConstants } from './userConstants';

export const gtmDimensions = {
  component_type: 'error',
  error: {
    page_view: {
      hash: 'p-041.023.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_error_code',
        'v_section_name',
        'v_tab',
        'v_transmission_type',
        'v_provider_name',
        'v_collection_name',
        'v_error_type',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_finish_progress_percentual',
      ],
    },
    ok: {
      hash: 'e-041.023.001.000.000.154',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_error_code',
        'v_section_name',
        'v_tab',
        'v_transmission_type',
        'v_provider_name',
        'v_collection_name',
        'v_error_type',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_finish_progress_percentual',
      ],
    },
    upsell: {
      page_view: {
        hash: 'p-041.023.000.000.000.000',
        eventType: 'pages',
        dimensions: [
          ...userConstants.constans,
          'v_content_name',
          'v_content_type',
          'v_content_id',
          'v_genre',
          'v_play_source',
          'v_start_progress_bar',
          'v_channel_name',
          'v_serie_name',
          'v_season',
          'v_error_code',
          'v_section_name',
          'v_tab',
          'v_transmission_type',
          'v_provider_name',
          'v_collection_name',
          'v_error_type',
          'v_finish_progress_percentual',
        ],
      },
      manage_subscription: {
        hash: 'e-041.023.001.000.000.065',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
          'v_content_name',
          'v_content_type',
          'v_content_id',
          'v_genre',
          'v_play_source',
          'v_start_progress_bar',
          'v_channel_name',
          'v_serie_name',
          'v_season',
          'v_error_code',
          'v_section_name',
          'v_tab',
          'v_transmission_type',
          'v_provider_name',
          'v_collection_name',
          'v_error_type',
          'v_finish_progress_percentual',
        ],
      },
    },
  },
};
