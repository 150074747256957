/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Components
 */
import Button from '../button/Button';

/**
 * Styles
 */
import './ErrorMessageButton.scss';

function ErrorMessageButton({
  titleFirstLine, titleSecondLine, subtitle, buttonText, buttonAction,
}) {
  return (
    <div className="dtv-emb">
      {titleFirstLine && <h3 className="dtv-emb-title-first">{titleFirstLine}</h3>}
      {titleSecondLine && <h3 className="dtv-emb-title-second">{titleSecondLine}</h3>}
      {subtitle && <p className="dtv-emb-subtitle">{subtitle}</p>}
      <Button className="dtv-emb-btn" onClick={buttonAction}>
        {buttonText}
      </Button>
    </div>
  );
}

ErrorMessageButton.propTypes = {
  titleFirstLine: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleSecondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
};

ErrorMessageButton.defaultProps = {
  titleFirstLine: '',
  titleSecondLine: '',
  subtitle: '',
  buttonText: '',
  buttonAction: () => {},
};

export default ErrorMessageButton;
