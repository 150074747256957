/* External */
import { createSlice } from '@reduxjs/toolkit';

/* Other */
import { getLocalStorageLastActivity, setLocalStorageLastActivity } from '../../utils/misc';
import { STATUS_TYPES } from '../../utils/constants';
import lastActivityObserver from '../../utils/lastActivityObserver';

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    configurationAEM: {
      data: {
        content: {},
        policies: {},
        lastRequestDate: null,
        isReady: false,
      },
      status: STATUS_TYPES.UNLOAD_STATE,
    },
    lastActivity: null,
    closedInactivityMilliseconds: 0,
  },
  reducers: {
    setAEMConfiguration: (state, action) => {
      const newState = state;
      newState.configurationAEM = {
        data: { ...action.payload, lastRequestDate: new Date(), isReady: true },
        status: STATUS_TYPES.SUCCEED_STATE,
      };
    },
    updateLastActivity: (state, action) => {
      const newState = state;
      let isFirstActivity = false;
      let closedInactivityMilliseconds;
      let prevLastActivity = state.lastActivity;
      const time = action?.payload?.time;

      if (!time) return;

      if (!state.lastActivity) {
        prevLastActivity = getLocalStorageLastActivity();
        closedInactivityMilliseconds = prevLastActivity
          ? time - prevLastActivity
          : Number.POSITIVE_INFINITY;
        isFirstActivity = true;
      }

      lastActivityObserver.reportChange({
        newLastActivity: time,
        prevLastActivity,
        closedInactivityMilliseconds,
        isFirstActivity,
      });

      setLocalStorageLastActivity(time);
      newState.lastActivity = action.payload;
    },
    updateClosedInactivityMilliseconds: (state, action) => {
      const newState = state;
      newState.closedInactivityMilliseconds = action.payload;
    },
  },
});

export const { setAEMConfiguration } = globalSlice.actions;

export default globalSlice.reducer;
