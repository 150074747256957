/* eslint "react/require-default-props": [
  "error", { "functions": "defaultArguments", "forbidDefaultForRequired": true }
] */
/* External */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

/* Ott-common */
import { joinClassNames } from '@dtvgo/classnames';
import Tabs from '@dtvgo/tabs';
import '@dtvgo/tabs/dist/main.css';

/* Components */
import CarouselList from '../CarouselList';

/* Store */
import { api } from '../../state/configureStore';

/* Utils */
import { ClassNames, getPageQueryByFeatureFlag } from '../../utils';

/* Styles */
import './TabsPage.scss';

const TabsPageClassNames = ClassNames.component('tabs-page');

function TabsPage({ className = '', pageId, sportIds = {} }) {
  const useGetPageQuery = getPageQueryByFeatureFlag(api);
  const {
    data: { elements: pageElements } = {},
    isLoading,
  } = useGetPageQuery({
    id: pageId,
    sportIds,
  });

  const tabsObject = useMemo(() => pageElements?.reduce((result, element) => {
    if (!element.tab) return result;
    const { id, title } = element.tab;
    if (!result[id]) {
      return {
        ...result,
        [id]: { id, title, pageElements: [element] },
      };
    }
    result[id].pageElements.push(element);
    return result;
  }, {}), [pageElements]);

  const tabsArray = useMemo(() => Object.values(tabsObject || {}), [tabsObject]);

  const tabsContent = useMemo(() => (
    tabsArray.map(({ id, title, pageElements: tabCarousels }) => (
      <Tabs.Tab key={id} id={id} title={title}>
        <CarouselList key={id} carousels={tabCarousels} />
      </Tabs.Tab>
    ))
  ), [tabsArray]);

  if (isLoading) return null;

  return (
    <Tabs
      className={joinClassNames(TabsPageClassNames.block(), className)}
      initialActiveId={tabsArray?.[0]?.id}
    >
      <Tabs.TabList className={TabsPageClassNames.element('tab-list')}>
        {tabsContent}
      </Tabs.TabList>
      <Tabs.ActiveTabContent />
    </Tabs>
  );
}

TabsPage.propTypes = {
  className: PropTypes.string,
  pageId: PropTypes.string.isRequired,
  sportIds: PropTypes.objectOf(PropTypes.string),
};

export default memo(TabsPage);
