/**
 * @class LastActivityObserver
 * @classdesc This class is an implentation of observer pattern
 * in order to get aware of last activity changes
 */
class LastActivityObserver {
  constructor() {
    this.listeners = [];
    this.firstLastActivityChangeEvent = null;
  }

  /**
   * An object that represents a Last activity change event
   * @typedef {Object} LastActivityChangeEvent
   * @property {Date} newLastActivity - The new last activity value
   * @property {Date} prevLastActivity - The previous last activity value
   * @property {number} closedInactivityMilliseconds - The milliseconds that passed between last
   * activity in a previous browser session and the first activity in the current session
   * @property {boolean} isFirstActivity - A boolean value that indicates if this change event is
   * the first in the current session i.e. this is the first activity within this session
   */

  /**
    * A listener for LastActivityChangeEvent
    * @callback LastActivityChangeListener
    * @param {LastActivityChangeEvent} lastActivityChangeEvent - The last activity change event
    * @param {boolean} isFirstActivityDelayed - A boolean value that indicates if listener was
    * called due the first last activity change event in this session was already dispatched, and
    * the event passed to this listener is that event.
    */

  /**
   * @method addListener
   * @description Method to add a listener for changes on last activity value.
   * @param {LastActivityChangeListener} listener A function that will be executed when a change on
   * last activity is detected.
   */
  addListener(listener) {
    if (listener && !this.listeners.includes(listener)) {
      this.listeners.push(listener);
      if (this.firstLastActivityChangeEvent) {
        listener(this.firstLastActivityChangeEvent, true);
      }
    }
  }

  /**
   * @method removeListener
   * @description Method to remove a listener for changes on last activity value.
   * @param {Function} listener The listener to be removed
   */
  removeListener(listener) {
    if (listener) {
      const index = this.listeners.indexOf(listener);
      if (index >= 0) {
        this.listeners.splice(index, 1);
      }
    }
  }

  /**
   * @method reportChange
   * @description A method to tell the observer that the last activity change event happened.
   * It calls all listeners registered for the event.
   * @param {LastActivityChangeEvent} lastActivityChangeEvent - The last activity change event
   */
  reportChange(lastActivityChangeEvent) {
    if (!this.firstLastActivityChangeEvent && lastActivityChangeEvent.isFirstActivity) {
      this.firstLastActivityChangeEvent = lastActivityChangeEvent;
    }
    this.listeners.forEach((listener) => {
      listener(lastActivityChangeEvent, false);
    });
  }
}

const lastActivityObserver = new LastActivityObserver();
export default lastActivityObserver;
