import React from 'react';
import PropTypes from 'prop-types';

import { usePlayerActions, usePlayerDuration, usePlayerTime } from '@dtvgo/player-context';

import ProgressBar from './ProgressBar';

export default function VodProgressBar({ className }) {
  return (
    <ProgressBar
      className={className}
      progress={usePlayerTime()}
      duration={usePlayerDuration()}
      onSeek={usePlayerActions().seek}
    />
  );
}

VodProgressBar.propTypes = {
  className: PropTypes.string,
};

VodProgressBar.defaultProps = {
  className: '',
};
