/* External */
import jwtDecode from 'jwt-decode';

export function setUserData(data) {
  const { id_token: idToken } = data;

  const userData = jwtDecode(idToken);
  const businessUnitLower = userData?.businessUnit?.toLowerCase();

  const type = getUserType(businessUnitLower);
  const tve = determineTve(businessUnitLower);
  const userObj = createUserObj(userData, type, tve);

  localStorage.setItem('user', JSON.stringify(userObj));
}

function getUserType(businessUnitLower) {
  if (businessUnitLower.includes('br')) {
    return 'ott';
  }
  return businessUnitLower.includes('ssla') ? businessUnitLower : 'tve';
}

function determineTve(businessUnitLower) {
  return !businessUnitLower.includes('br') && !businessUnitLower.includes('ssla');
}

function createUserObj(userData, type, tve) {
  return {
    allowedCountry: true,
    country: userData.iso2Code,
    isTVE: tve,
    businessUnit: userData.businessUnit,
    iso2Code: userData.iso2Code,
    profile: {
      iso2country: userData.iso2Code,
      email: userData.sub,
    },
    userName: userData.sub,
    uid: userData.customerId,
    userType: type,
  };
}
