export const ACTION_TYPES = {
  LOGIN: 'user/LOGIN',
  REQUEST: 'user/LOGIN_REQUEST',
  SUCCESS: 'user/LOGIN_SUCCESS',
  FAILURE: 'user/LOGIN_FAILURE',
  RESET: 'user/RESET_USER',
  SET_USER: 'user/SET_USER',
  UPDATE_USER: 'user/UPDATE_USER',
};

export const login = (userName, password, redirectTo, tokenRecaptcha) => ({
  type: ACTION_TYPES.LOGIN,
  payload: {
    userName, password, redirectTo, tokenRecaptcha,
  },
});

export const loginRequest = () => ({ type: ACTION_TYPES.REQUEST });

export const loginSuccess = () => ({
  type: ACTION_TYPES.SUCCESS,
});

export const loginFailure = (error) => ({
  type: ACTION_TYPES.FAILURE,
  payload: { ...error },
});

export const resetUser = () => ({
  type: ACTION_TYPES.RESET,
});

export const setUser = (user) => ({
  type: ACTION_TYPES.SET_USER,
  payload: { ...user },
});

export const updateUser = (property, value) => ({
  type: ACTION_TYPES.UPDATE_USER,
  payload: { property, value },
});
