/* External */
import React, { useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { api } from '../state/configureStore';

/* Other */
import {
  shouldRedirectToAEM,
  isUserLoggedIn,
  AEM_PLATFORM,
} from '../utils';
import { checkToken } from '../services/auth';

function PrivateRoute({
  component, exact, path, from,
}) {
  const {
    data: { country },
  } = api.useGetAEMConfigurationQuery(AEM_PLATFORM, {
    selectFromResult: ({ data }) => ({
      data: {
        country: data?.userCountry,
      },
    }),
  });
  const { pathname, search } = useSelector((state) => state.router.location);
  const isRefreshing = useRef(false);
  const redirect = shouldRedirectToAEM(country);

  useEffect(() => {
    if (!isUserLoggedIn() && redirect) {
      redirect(`${pathname}${search || ''}`);
    }
  }, [pathname, redirect, search]);

  useEffect(() => {
    const performCheckToken = async () => {
      if (!isUserLoggedIn || isRefreshing.current) return;

      isRefreshing.current = true;
      try {
        await checkToken();
      } finally {
        isRefreshing.current = false;
      }
    };

    performCheckToken();
  }, [pathname]);

  return isUserLoggedIn() && <Route from={from} exact={exact} path={path} component={component} />;
}

export default PrivateRoute;
