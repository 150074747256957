/* External */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import debounceL from 'lodash-es/debounce';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';
import {
  PlayerOverlay, AudioSubtitleButton, CastButton, ConditionalVisible, ControlBar, LiveButton,
  PlayPauseButton, RemainingTime, RewindButton, ViewModeButton, VolumeButton,
} from '@dtvgo/web-player-ui';
import { useIsDelayed, useLiveProgress } from '@dtvgo/player-utils-react';
import { Tracks } from '@dtvgo/player-utils';
import { useGetSync } from '@dtvgo/react-utils';
import {
  PlayerState, PlayerViewMode, isPlayerLoaded, useAudioTracks, useBuffering, usePlayerActions,
  usePlayerDuration, usePlayerState, usePlayerTime, usePlayerViewMode, usePlayerVolume,
  useSeeking, useSubtitleTracks,
} from '@dtvgo/player-context';

/* Components */
import LoaderScreen from './loaderScreen/LoaderScreen';
import PlayerDock from './dock/PlayerDock';
import AreYouStillWatchingMessage from './areYouStillWatchingMessage/AreYouStillWatchingMessage';
import FifaPlayerSideButton from '../components/fifaPlayerSideButton/FifaPlayerSideButton';
import FifaPlayerNotification from '../components/fifaPlayerNotification/FifaPlayerNotification';
import MiniEPG from './miniEPG/MiniEPG';
import { LiveProgressBar, VodProgressBar } from './ProgressBar';

/* Other */
import { IS_BRAZIL, getGtmEventConfig } from '../utils';
import { useAreYouStillWatchingTime } from './utils/hooks';
import { gtmDimensions } from '../components/GtmContext/dimensions/player';

/* Styles */
import './PlayerUI.scss';

const INACTIVITY_TIME_TO_HIDE_CONTROLS_MS = 4000;
const SECONDS_TO_REWIND = 30;

function PlayerUI({
  isLive,
  asset,
  nextAsset,
  readyToPlay,
  displayAreYouStillWatching,
  showPlayButton,
  gtmInteraction,
  onUserInteraction,
  intl: { formatMessage },
}) {
  const [isUserActive, setIsUserActive] = useState(true);
  const [
    isAreYouStillWatchingActive,
    setIsAreYouStillWatchingActive,
  ] = useState(false);
  const [isMiniEpgOpen, setIsMiniEpgOpen] = useState(false);

  const state = usePlayerState();
  const { isSeeking } = useSeeking();
  const isBuffering = useBuffering();
  const playerTime = usePlayerTime();
  const duration = usePlayerDuration();
  const viewMode = usePlayerViewMode();
  const audioTracks = useAudioTracks();
  const subtitleTracks = useSubtitleTracks();
  const volume = usePlayerVolume();

  const orderByIso = IS_BRAZIL ? Tracks.TrackLanguageOrder.BR : Tracks.TrackLanguageOrder.SSLA;
  const sortAudioTracks = useMemo(() => Tracks.sortAudioTracks(orderByIso), [orderByIso]);
  const sortTextTracks = useMemo(() => Tracks.sortTextTracks(orderByIso), [orderByIso]);

  const sortedAudioTracks = useMemo(() => (
    sortAudioTracks(Tracks.addIndexToUnknownTracks(Tracks.parseAudioTracks(audioTracks)))
  ), [audioTracks, sortAudioTracks]);

  const sortedSubtitleTracks = useMemo(() => (
    sortTextTracks(Tracks.addIndexToUnknownTracks(Tracks.parseTextTracks(subtitleTracks)))
  ), [sortTextTracks, subtitleTracks]);

  const playerActions = usePlayerActions();

  const isPaused = !isPlayerLoaded()
    || state === PlayerState.PAUSED
    || state === PlayerState.LOADED;

  const liveProgress = useLiveProgress();
  const isDelayed = useIsDelayed();

  const progress = isLive ? liveProgress : playerTime;

  const isPlaying = state === PlayerState.PLAYING;
  const getIsPlaying = useGetSync(isPlaying);
  const prevIsSeeking = useRef(false);
  const isLoading = !readyToPlay || !state || !isPlayerLoaded(state);
  const shouldReportSeekedEvent = useRef(false);

  const handleGtmEvent = useCallback((type) => {
    const { hash, dimensions, eventType } = getGtmEventConfig(
      asset.type,
      gtmDimensions,
      'player',
      type,
    );
    gtmInteraction(asset, hash, eventType, dimensions);
  }, [asset, gtmInteraction]);

  const togglePlayPause = useCallback(() => {
    shouldReportSeekedEvent.current = true;
    playerActions.togglePlayPause();
    handleGtmEvent(getIsPlaying() ? 'pause' : 'play');
  }, [getIsPlaying, handleGtmEvent, playerActions]);

  const rewind30 = useCallback(() => {
    shouldReportSeekedEvent.current = false;
    playerActions.rewind(SECONDS_TO_REWIND);
    handleGtmEvent('backward');
  }, [handleGtmEvent, playerActions]);

  const toggleMute = useCallback(() => {
    shouldReportSeekedEvent.current = true;
    playerActions.toggleMute();
  }, [playerActions]);

  const setVolume = useCallback((newVolume) => {
    shouldReportSeekedEvent.current = true;
    playerActions.setVolume(newVolume);
  }, [playerActions]);

  const selectAudioTrack = useCallback((track) => {
    shouldReportSeekedEvent.current = true;
    playerActions.selectAudioTrack(track);
    handleGtmEvent('change_audio');
  }, [handleGtmEvent, playerActions]);

  const selectSubtitleTrack = useCallback((track) => {
    shouldReportSeekedEvent.current = true;
    playerActions.selectSubtitleTrack(track);
    handleGtmEvent('change_subtitle');
  }, [handleGtmEvent, playerActions]);

  const disableSubtitles = useCallback(() => {
    shouldReportSeekedEvent.current = true;
    playerActions.disableSubtitles();
    handleGtmEvent('change_subtitle');
  }, [handleGtmEvent, playerActions]);

  const goToLive = useCallback(() => {
    shouldReportSeekedEvent.current = false;
    playerActions.goToLive();
    handleGtmEvent('ao_vivo');
  }, [handleGtmEvent, playerActions]);

  const toggleFullScreen = useCallback(() => {
    shouldReportSeekedEvent.current = true;
    playerActions.toggleFullScreen();
    handleGtmEvent('full_screen');
  }, [handleGtmEvent, playerActions]);

  const scheduleSetUserInactive = useMemo(() => debounceL(
    () => setIsUserActive(false),
    INACTIVITY_TIME_TO_HIDE_CONTROLS_MS,
  ), []);

  const areYouStillWatchingTime = useAreYouStillWatchingTime(isLive);
  const getDisplayAreYouStillWatching = useGetSync(displayAreYouStillWatching);

  const scheduleDisplayAreYouStillWatching = useMemo(() => debounceL(
    () => {
      playerActions.pause();
      setIsAreYouStillWatchingActive(true);
    },
    areYouStillWatchingTime,
  ), [areYouStillWatchingTime, playerActions]);

  const setUserActive = useCallback(() => {
    setIsUserActive(true);
    if (getIsPlaying()) {
      scheduleSetUserInactive();
    } else {
      scheduleSetUserInactive.cancel();
    }
    if (getDisplayAreYouStillWatching()) {
      scheduleDisplayAreYouStillWatching();
    } else {
      scheduleDisplayAreYouStillWatching.cancel();
    }
    onUserInteraction();
  }, [
    getIsPlaying,
    getDisplayAreYouStillWatching,
    onUserInteraction,
    scheduleSetUserInactive,
    scheduleDisplayAreYouStillWatching,
  ]);

  const continueWatching = useCallback(() => {
    setIsAreYouStillWatchingActive(false);
    playerActions.play();
  }, [playerActions]);

  const openMiniEPG = useCallback(() => {
    setIsMiniEpgOpen(true);
  }, []);

  const closeMiniEPG = useCallback(() => {
    setIsMiniEpgOpen(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setUserActive();
  }, [setUserActive]);

  const handleMouseMove = useCallback(() => {
    setUserActive();
  }, [setUserActive]);

  const getLanguageLabel = useCallback((track) => {
    const defaultMessage = formatMessage({ id: 'common.unavailable', defaultMessage: 'Not Available' });

    if (track.isUnknow) {
      return formatMessage(
        {
          id: track.isText
            ? 'languages.undefinedSubtitleWithCounter'
            : 'languages.undefinedAudioWithCounter',
          defaultMessage,
        },
        { number: track.unknownIndex },
      );
    }

    const label = formatMessage({ id: `languages.${track.iso || track.lang}`, defaultMessage });

    if (track.isClosedCaption) return `${label} [CC]`;

    return label;
  }, [formatMessage]);

  /* When seeked is completed report event to GTM */
  useEffect(() => {
    if (!isSeeking && prevIsSeeking.current) {
      if (shouldReportSeekedEvent.current) handleGtmEvent('timeline');
      else shouldReportSeekedEvent.current = true;
    }
    prevIsSeeking.current = isSeeking;
  }, [isSeeking, handleGtmEvent]);

  /* When playing state change schedule user inactive or cancel it */
  useEffect(() => {
    if (isPlaying) {
      scheduleSetUserInactive();
    } else {
      scheduleSetUserInactive.cancel();
    }
  }, [isPlaying, scheduleSetUserInactive]);

  /* When displayAreYouStillWatching change schedule display or cancel it */
  useEffect(() => {
    if (displayAreYouStillWatching) {
      scheduleDisplayAreYouStillWatching();
    } else {
      scheduleDisplayAreYouStillWatching.cancel();
    }
  }, [displayAreYouStillWatching, scheduleDisplayAreYouStillWatching]);

  /* Prevent exitting the page if the player is playing */
  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (getIsPlaying()) {
        e.preventDefault();
      }
      return null;
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [getIsPlaying]);

  /* Cancel active debounced functions when unmounted (or the debounce function change) */
  useEffect(() => () => {
    scheduleSetUserInactive.cancel();
    scheduleDisplayAreYouStillWatching.cancel();
  }, [scheduleDisplayAreYouStillWatching, scheduleSetUserInactive]);

  if (isLoading) return <LoaderScreen isLive={isLive} asset={asset} />;

  const ProgressBar = isLive ? LiveProgressBar : VodProgressBar;

  const uiElements = isMiniEpgOpen ? (
    <MiniEPG
      asset={asset}
      progress={progress}
      close={closeMiniEPG}
    />
  ) : (
    <>
      <ConditionalVisible visible={isUserActive}>
        <PlayerDock
          isLive={isLive}
          asset={asset}
          nextAsset={nextAsset}
          isDelayed={isDelayed}
          gtmInteraction={gtmInteraction}
          openMiniEPG={openMiniEPG}
        />
      </ConditionalVisible>
      <PlayerOverlay
        isUserActive={isUserActive}
        showIconPlayButton={showPlayButton}
        handleToggleFullScreen={toggleFullScreen}
        handleTogglePlayPause={togglePlayPause}
        isBuffering={isBuffering}
        tooltip={formatMessage({ id: 'common.continue', defaultMessage: 'Continuar viendo' })}
      />
      <ControlBar className="dtv-web-c-player-ui__control-bar" display={isUserActive}>
        <PlayPauseButton
          handleTogglePlayPause={togglePlayPause}
          isPlaying={!isPaused}
          tooltipPause={formatMessage({ id: 'player.pause' })}
          tooltipPlay={formatMessage({ id: 'player.play' })}
        />
        {!isLive && (
          <RewindButton
            handleRewind={rewind30}
            showButton={!isLive}
            tooltip={formatMessage({ id: 'player.rewind30' })}
          />
        )}
        <ProgressBar className="dtv-web-c-player-ui__progress-bar" />
        {isLive ? (
          <LiveButton
            isDelayed={isDelayed}
            goToLive={goToLive}
            onLiveText={formatMessage({ id: 'player.live', defaultMessage: 'En vivo' })}
          />
        ) : (
          <RemainingTime
            progress={progress < duration ? progress : duration}
            duration={duration}
          />
        )}
        <VolumeButton
          isMuted={volume === 0}
          handleToggleMute={toggleMute}
          setVolume={setVolume}
          volume={volume}
          tooltipMute={formatMessage({ id: 'player.mute' })}
          tooltipUnMute={formatMessage({ id: 'player.unMute' })}
        />
        <AudioSubtitleButton
          audioTracks={sortedAudioTracks}
          onSelectAudio={selectAudioTrack}
          subtitleTracks={sortedSubtitleTracks}
          tooltip={formatMessage({ id: 'player.subtitles' })}
          subtitlesTitle={formatMessage({ id: 'player.subtitles' })}
          audioTitle={formatMessage({ id: 'player.languages' })}
          onSelectSubtitle={selectSubtitleTrack}
          onDisableSubtitles={disableSubtitles}
          getLanguageLabel={getLanguageLabel}
        />
        <CastButton />
        <ViewModeButton
          isFullScreenMode={viewMode === PlayerViewMode.FULL_SCREEN}
          handleToggleFullScreen={toggleFullScreen}
          enterFullscreenTooltip={formatMessage({ id: 'player.fullscreen.enter' })}
          exitFullscreenTooltip={formatMessage({ id: 'player.fullscreen.exit' })}
        />
        {asset?.sport?.sportIds?.fifaMatchId && (
          <>
            {readyToPlay && <FifaPlayerNotification currentSchedule={asset} />}
            <FifaPlayerSideButton currentSchedule={asset} />
          </>
        )}
      </ControlBar>
    </>
  );

  return (
    <div className={classnames('dtv-web-c-player-ui')} onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove}>
      {isAreYouStillWatchingActive ? (
        <AreYouStillWatchingMessage
          title={!isLive && asset?.type === assetTypes.episode && asset?.episode?.showName}
          continueWatching={continueWatching}
        />
      ) : uiElements}
    </div>
  );
}

PlayerUI.propTypes = {
  isLive: PropTypes.bool,
  asset: PropTypes.shape({
    type: PropTypes.string,
    episode: PropTypes.shape({
      showName: PropTypes.string,
    }),
    sport: PropTypes.shape({
      sportIds: PropTypes.shape({
        fifaMatchId: PropTypes.string,
      }),
    }),
  }),
  nextAsset: PropTypes.shape({}),
  readyToPlay: PropTypes.bool,
  displayAreYouStillWatching: PropTypes.bool,
  showPlayButton: PropTypes.bool,
  gtmInteraction: PropTypes.func,
  onUserInteraction: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

PlayerUI.defaultProps = {
  isLive: false,
  asset: {},
  nextAsset: {},
  readyToPlay: false,
  displayAreYouStillWatching: false,
  showPlayButton: false,
  gtmInteraction: () => {},
  onUserInteraction: () => {},
};

export default injectIntl(PlayerUI);
