/**
 * External
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import document from 'global/document';
import uniqueIdL from 'lodash-es/uniqueId';

/**
 * ACtions
 */
import { showBarNotification as showBarNotificationAction } from '../../../state/notifications/actions';

/**
 * Styles
 */
import './BarNotification.scss';

const DEFAULT_CONTAINER = 'dtv-div-notifications';
const TYPE_ERROR = 'error';

function NotificationComponent({
  notification: {
    titleId, title, messageId, message, type = TYPE_ERROR, actions, close = true,
  },
  showBarNotification,
}) {
  const textClass = actions ? 'dtv-notification-text-left' : 'dtv-notification-text-center';

  return (
    <div className={`dtv-bar-notification ${type}`}>
      <div className={textClass}>
        {titleId && (
          <span className="dtv-notification-title">
            {titleId ? <FormattedMessage id={titleId} defaultMessage={title} /> : title}
          </span>
        )}
        {titleId && <br />}
        <span className="dtv-notification-message">
          {messageId ? <FormattedMessage id={messageId} defaultMessage={message} /> : message}
        </span>
      </div>
      {actions && (
        <div className="dtv-notification-actions">
          {actions.map((action, idx) => {
            const Tag = action.type;
            return (
              /* eslint-disable-next-line react/jsx-props-no-spreading
                -- The props could be different since the action is defined in multiple ways */
              <Tag {...action.props} key={action.textId || action.text || idx}>
                {action.textId
                  ? <FormattedMessage id={action.textId} defaultMessage={action.text} />
                  : action.text}
              </Tag>
            );
          })}
        </div>
      )}
      {close && <span className="dtv-icon-close" onClick={() => showBarNotification({ show: false })} />}
    </div>
  );
}

const BarNotification = ({ notification, showBarNotification }) => {
  const { show, containerId = DEFAULT_CONTAINER } = notification;
  const barContainer = document.getElementById(containerId)
  || document.getElementById(DEFAULT_CONTAINER);
  return show && barContainer
    ? ReactDOM.createPortal(
      <NotificationComponent
        key={uniqueIdL('notificationComponent')}
        notification={notification}
        showBarNotification={showBarNotification}
      />,
      barContainer,
    )
    : '';
};

function mapStateToProps({ notifications }) {
  return { notification: notifications.get('barNotification') };
}

export default connect(mapStateToProps, {
  showBarNotification: showBarNotificationAction,
})(BarNotification);
