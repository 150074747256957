/* External */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

/* Ott-common */
import Icon from '@dtvgo/icon';

/* Style */
import './ButtonScroll.scss';

function ButtonScroll({ buttons }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const buttonWidth = 120;
  const containerWidth = 350;
  const dragStartXRef = useRef(0);
  const scrollStep = buttonWidth * 2;
  const isAtEnd = scrollPosition >= (buttonWidth * (buttons.length - 1)) - containerWidth;

  const handleLeftClick = () => {
    setScrollPosition(Math.max(0, scrollPosition - scrollStep));
  };

  const handleRightClick = () => {
    const maxScroll = Math.max(0, buttonWidth * buttons.length - containerWidth);
    setScrollPosition(Math.min(maxScroll, scrollPosition + scrollStep));
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    dragStartXRef.current = event.clientX - scrollPosition;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const newScrollPosition = event.clientX - dragStartXRef.current;
    setScrollPosition(
      Math.max(0, Math.min(newScrollPosition, buttonWidth * buttons.length - containerWidth)),
    );
  };

  return (
    <div className="button-container" onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
      {scrollPosition !== 0 && (
        <div className="left-arrow">
          <button type="button" className="arrow left-arrow-button" onClick={handleLeftClick} disabled={scrollPosition === 0}>
            <Icon name="arrow-back" />
          </button>
        </div>
      )}
      <div className="buttons-wrapper" onMouseDown={handleMouseDown}>
        <div className="buttons" style={{ transform: `translateX(-${scrollPosition}px)`, transition: isDragging ? 'none' : 'transform 0.3s ease' }}>
          {buttons}
        </div>
      </div>
      {!isAtEnd && (
        <div className="right-arrow">
          <button type="button" className="arrow right-arrow-button" onClick={handleRightClick} disabled={scrollPosition >= buttonWidth * (buttons.length - 1)}>
            <Icon name="arrow-forward" />
          </button>

        </div>
      )}

    </div>
  );
}

ButtonScroll.propTypes = {
  buttons: PropTypes.shape(),
};

ButtonScroll.defaultProps = {
  buttons: {},
};

export default ButtonScroll;
