import jsonSKY from '../../wonderwall-project-SKY.json';
import jsonDGO from '../../wonderwall-project-DGO.json';
import { APP_THEME, WONDERWALL_FEATURE_FLAG } from '../utils';

export function getImage(imageName) {
  if (WONDERWALL_FEATURE_FLAG) {
    const theme = APP_THEME;
    const json = theme === 'skymais' ? jsonSKY : jsonDGO; // DGO default
    return json.images[imageName];
  }
  return null;
}

// Images that are directly on SCSS
function setSCSSVariablesFromJSON() {
  if (WONDERWALL_FEATURE_FLAG) {
    const theme = APP_THEME;
    const json = theme === 'skymais' ? jsonSKY : jsonDGO; // DGO default
    const root = document.documentElement;

    // list of images that are imported directly on SCSS
    const desiredImages = [
      'iconLive',
      'tvCode8',
      'bgDesktop',
      'bgMobile',
      'bgTablet',
      'errorBg',
      'homeBg',
    ];

    desiredImages.forEach((imageName) => {
      if (json.images[imageName]) {
        root.style.setProperty(`--${imageName}`, `url(${json.images[imageName]})`);
      }
    });
  }
}

setSCSSVariablesFromJSON();
