/* External */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Ott-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Components */
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/search';

/* Styles */
import './PreSearchCard.scss';

/* Other */
import { history } from '../../state/configureStore';
import { getCollectionRedirectUrl, getLanderLogoSrc, formatContentForGtm } from '../../utils';

function PreSearchCard({
  collection, carouselId, carouselType, index,
}) {
  const [showText, setShowText] = useState(false);

  const {
    gtmUserData,
  } = useGtmContext();

  const logoSrc = getLanderLogoSrc(collection);
  const redirectUrl = getCollectionRedirectUrl(
    collection,
    carouselId,
    carouselType,
  );
  const handleOnClick = () => {
    const sendToGtm = formatContentForGtm(
      {},
      {
        searchTerms: null,
        listPosition: index + 1,
        providerName: collection.title,
        collectionName: collection.title,
      },
    );
    gtmEvent({
      hash: gtmDimensions.pre_search.hash,
      eventType: gtmDimensions.pre_search.eventType,
      userData: gtmUserData,
      dimensions: gtmDimensions.pre_search.dimensions,
      data: sendToGtm,
    });
    history.push(redirectUrl);
  };

  return (
    <div className="dtv-pre-search-card" onClick={handleOnClick}>
      {logoSrc && !showText ? (
        <img className="dtv-pre-search-card-logo" src={logoSrc} alt={collection.title} onError={() => setShowText(true)} />
      ) : (
        <h3 className="dtv-pre-search-card-title">{collection.title}</h3>
      )}
    </div>
  );
}

PreSearchCard.propTypes = {
  collection: PropTypes.shape({
    title: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({
      target: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
    })),
  }).isRequired,
  carouselId: PropTypes.string,
  index: PropTypes.number,
};

PreSearchCard.defaultProps = {
  carouselId: '',
  index: 0,
};

export default PreSearchCard;
