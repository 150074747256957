/**
 * External
 */
import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import getL from 'lodash-es/get';

/* Ott-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';

/**
 * Assets
 */
import logo from '@/assets/theme/svg/logo.svg';

/* Components */
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/header-footer';

/**
 * Styles
 */
import './Footer.scss';

/**
 * Other
 */
import {
  COUNTRY_CODES,
  DEFAULT_VALUES,
  externalRedirect,
  getUserLanguages,
  IS_BRAZIL,
  PATHS,
  AEM_PLATFORM,
  formatContentForGtm,
  FOOTER_URIS,
} from '../../utils';
import { api } from '../../state/configureStore';
import { getImage } from '../../theme/images';

/**
 * @description Method that redirects to public home.
 * @method redirectToAEM
 */
const redirectToAEM = () => {
  window.location.replace('/');
};

function Footer({
  publicZone, language, country, intl: { formatMessage },
}) {
  const {
    gtmUserData, gtmCollectionName, gtmProviderName, setGtmCollectionName, setGtmProviderName,
  } = useGtmContext();
  const location = useLocation();
  const {
    data: AEMConfiguration,
    isLoading: isAEMConfigurationLoading,
    isError: isAEMConfigurationError,
    isSuccess: isAEMConfigurationSuccess,
  } = api.useGetAEMConfigurationQuery(AEM_PLATFORM);
  let userCountry; let
    legal;

  if (!isAEMConfigurationLoading && !isAEMConfigurationError) {
    ({ userCountry, redirects: { legal = '' } = {} } = AEMConfiguration);
  }

  /**
   * @method renderIconsSocialMedia
   * @description Rendering the icons of social media
   */
  const renderIconsSocialMedia = () => {
    if (isAEMConfigurationSuccess) {
      const {
        REACT_APP_SOCIAL_FACEBOOK_URL,
        REACT_APP_SOCIAL_TWITTER_URL,
        REACT_APP_SOCIAL_INSTAGRAM_URL,
        REACT_APP_SOCIAL_FACEBOOK_URL_MX,
        REACT_APP_SOCIAL_TWITTER_URL_MX,
        REACT_APP_SOCIAL_INSTAGRAM_URL_MX,
      } = import.meta.env;

      let links = {
        facebook: REACT_APP_SOCIAL_FACEBOOK_URL,
        twitter: REACT_APP_SOCIAL_TWITTER_URL,
        instagram: REACT_APP_SOCIAL_INSTAGRAM_URL,
      };

      if (userCountry === COUNTRY_CODES.mexico) {
        links = {
          facebook: REACT_APP_SOCIAL_FACEBOOK_URL_MX,
          twitter: REACT_APP_SOCIAL_TWITTER_URL_MX,
          instagram: REACT_APP_SOCIAL_INSTAGRAM_URL_MX,
        };
      }

      return (
        <div className="dtv-footer-second-section-social">
          <div className="dtv-footer-second-section-social-icons mt-2">
            <a href={links.facebook} target="_blank" rel="noopener noreferrer">
              <span className="dtv-icon-facebook mr-3" />
            </a>
            <a href={links.twitter} target="_blank" rel="noopener noreferrer">
              <span className="dtv-icon-twitter mr-3" />
            </a>
            <a href={links.instagram} target="_blank" rel="noopener noreferrer">
              <span className="dtv-icon-instagram mr-3" />
            </a>
          </div>
        </div>
      );
    }
    return null;
  };

  const handleFooterLinkClick = (id, path, region, lang, openOtherTab = true) => {
    const { hash, eventType, dimensions } = gtmDimensions[id].watch;

    const dataTosend = formatContentForGtm(
      {},
      {
        tab: location.state?.tab || null,
        providerName: gtmProviderName,
        collectionName: gtmCollectionName,
      },
    );

    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataTosend,
    });

    setGtmCollectionName(null);
    setGtmProviderName(null);

    externalRedirect(path, region, lang, openOtherTab);
  };

  return (
    <div className="dtv-footer-container">
      <div className="dtv-footer-content-logo-social">
        {!publicZone ? (
          <Link to={PATHS.HOME} className="dtv-footer-first-section-logo mb-4">
            <img src={getImage('logo') || logo} alt="Logo" />
          </Link>
        ) : (
          <span onClick={redirectToAEM} className="dtv-footer-first-section-logo mb-4">
            <img src={getImage('logo') || logo} alt="DIRECTV GO" />
          </span>
        )}
        {renderIconsSocialMedia()}
      </div>
      <div className="dtv-footer-first-section">
        <div className={IS_BRAZIL ? 'dtv-footer-first-section-links-br' : 'dtv-footer-first-section-links'}>
          <span
            onClick={
              () => handleFooterLinkClick(
                FOOTER_URIS.faq,
                import.meta.env.REACT_APP_AEM_FAQ_PATH,
                country,
                language,
                false,
              )
            }
            className="dtv-link"
          >
            <FormattedMessage id="footer.frequentQuestions" defaultMessage="Frequent Questions" />
          </span>
          <span
            id="dtv-footer-legales-link"
            target="_blank"
            onClick={() => handleFooterLinkClick(
              FOOTER_URIS.regulation,
              legal,
              country,
              language,
            )}
            className="dtv-link"
          >
            <FormattedMessage id="footer.legal" defaultMessage="Legales" />
          </span>
          <span
            target="_blank"
            onClick={() => handleFooterLinkClick(
              FOOTER_URIS.privacy_policy,
              formatMessage({
                id: 'url.privacyPolicy',
                defaultMessage: '/privacy-policy',
              }),
              country,
              language,
            )}
            className="dtv-link"
          >
            <FormattedMessage id="footer.privacyPolicy" defaultMessage="Privacy policy" />
          </span>
          <span
            target="_blank"
            onClick={() => handleFooterLinkClick(
              FOOTER_URIS.terms_and_conditions,
              formatMessage({
                id: 'url.termsAndConditions',
                defaultMessage: '/terms-and-conditions',
              }),
              country,
              language,
            )}
            className="dtv-link"
          >
            <FormattedMessage id="footer.termsAndConditions" defaultMessage=" Terms and conditions" />
          </span>
          {IS_BRAZIL && (
            <span
              target="_blank"
              onClick={
                () => handleFooterLinkClick(
                  FOOTER_URIS.personal_data,
                  import.meta.env.REACT_APP_PERSONAL_DATA_MANAGEMENT,
                  country,
                  language,
                )
              }
              className="dtv-link"
            >
              <FormattedMessage id="footer.managePersonalData" defaultMessage="Gerencie seus dados pessoais" />
            </span>
          )}
        </div>
      </div>
      <div className="dtv-footer-second-section">
        {renderIconsSocialMedia()}
        <div>
          <p>
            <FormattedMessage id="footer.allRightsReserved" defaultMessage="All rights reserved." />
          </p>
        </div>
        {IS_BRAZIL && (
          <div>
            <p>
              <FormattedMessage
                id="footer.cookiesPolicy"
                defaultMessage="Para prestar e melhorar nossos serviços, personalizar e otimizar sua experiência, enviar comunicações sobre o DIRECTV GO ou parceiros, e cumprir com obrigações legais e regulatórias, realizamos o tratamento de seus dados pessoais e fazemos uso de cookies. Ao acessar o nosso site, você declara que lê e compreende nossa Política de Privacidade e Política de Cookies."
              />
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps({ user }) {
  const { client: language = DEFAULT_VALUES.LANGUAGE } = getUserLanguages(user);
  const country = getL(user, 'account.country', DEFAULT_VALUES.COUNTRY);

  return {
    language,
    country,
  };
}

export default injectIntl(connect(mapStateToProps, {})(Footer));
