/* External */
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/* Assets */
import genericCard16X9 from '@/assets/theme/images/16x9-GenericCard.png';
import playIcon from '../../assets/icons/play-icon-circled.svg';

/* Components */
import CloseToastButton from './closeToastButton/CloseToastButton';

/* Styles */
import './FifaPlayerNotification.scss';
import { history } from '../../state/configureStore';

/* Utils */
import {
  useImage, GRADIENTS, getBooleanFromEnvVariable, ROUTES,
} from '../../utils';

/* Other */
import { getImage } from '../../theme/images';

const PLAYER_NOTIFICATION_TIMER_IN_MS = 15_000;

function FifaPlayerNotificationContent({
  currentSchedule = {},
  intl: { formatMessage },
}) {
  const genericCard16x9Flag = getImage('16x9GenericCard') || genericCard16X9;
  const { imageRef, imageStyle } = useImage(currentSchedule.images, genericCard16x9Flag, GRADIENTS.PDP, '16:9', false);

  const channelId = currentSchedule?.live?.channelId || '';
  const startTime = currentSchedule?.live?.startTime || '';

  const handleMatchPreviewOnClick = () => {
    history.push(`${ROUTES.hbsPlayer.path}${channelId}?startTime=${startTime}`);
  };

  const bodyText = formatMessage(
    { id: 'fifa.player.notification.desc' },
  );

  return (
    <div className="fifa-notification-content">
      <section className="fifa-notification-content__match-preview">
        <button className="fifa-notification-content__button" type="button" onClick={handleMatchPreviewOnClick}>
          <div className="fifa-notification-content__preview-image" ref={imageRef} style={imageStyle}>
            <img className="fifa-notification-content__play-icon" src={playIcon} alt="" />
          </div>
        </button>
      </section>
      <section className="fifa-notification-content__wording">
        <h3 className="fifa-notification-content__title">
          {formatMessage(
            { id: 'fifa.player.notification.title' },
          )}
        </h3>
        <p
          className="fifa-notification-content__paragraph"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: bodyText }}
        />
      </section>
    </div>
  );
}

function FifaPlayerNotification({
  currentSchedule = {},
  intl,
}) {
  const isFifaPlayerActive = getBooleanFromEnvVariable('REACT_APP_ENABLE_FIFA_PLAYER');

  useEffect(() => {
    if (isFifaPlayerActive) {
      toast(<FifaPlayerNotificationContent
        currentSchedule={currentSchedule}
        intl={intl}
      />, {
        className: 'fifa-notification-container',
        position: 'top-right',
        autoClose: PLAYER_NOTIFICATION_TIMER_IN_MS,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        limit: 1,
        closeButton: <CloseToastButton />,
      });
    }
    return () => toast.dismiss();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

FifaPlayerNotificationContent.propTypes = {
  currentSchedule: PropTypes.shape({}).isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
};

FifaPlayerNotification.propTypes = {
  currentSchedule: PropTypes.shape({}).isRequired,
  intl: PropTypes.shape({}).isRequired,
};

export default injectIntl(FifaPlayerNotification);
