/* External */
import { useEffect, useRef } from 'react';
import bowser from 'bowser';
import { es, pt } from 'date-fns/locale';

/* Ott-common */
import { createClassNames } from '@dtvgo/classnames';

/* Utils */
import { BROWSER, DEVICE_TYPES, LAST_ACTIVITY_LOCAL_STORAGE_NAME } from './constants';
import { isPagesV5Active, USE_LAYOUT_PAGES_V4 } from './env';

export const ClassNames = createClassNames('dtv-web');

/**
 * @method isValidString
 * @description Validates if a string is different from undefined, null and empty
 * @param {string} value
 */
export function isValidString(value) {
  if (value === undefined || value === null || value === '') {
    return false;
  }
  return true;
}

export function cutString(text, maxlength = 25) {
  if (!text) return '';

  return text.length > maxlength ? `${text.slice(0, Math.max(0, maxlength))}...` : text;
}

export function isMobile() {
  return {
    Android: () => navigator.userAgent.match(/android/i),
    iOS: () => navigator.userAgent.match(/iphone|ipad|ipod/i),
    any() {
      return this.Android() || this.iOS();
    },
  };
}

export function getBrowser() {
  // Firefox 1.0+
  const isFirefox = bowser.firefox;
  if (isFirefox) {
    return BROWSER.FIREFOX;
  }

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari = bowser.safari;
  if (isSafari) {
    return BROWSER.SAFARI;
  }

  // Internet Explorer 6-11
  const isIE = bowser.msie;
  if (isIE) {
    return BROWSER.IE;
  }

  // Edge 20+
  const isEdge = bowser.msedge;
  const isEdgeChromium = window.navigator.userAgent.includes('Edg/');
  if (isEdge || isEdgeChromium) {
    return BROWSER.EDGE;
  }

  // Chrome 1+
  const isChrome = bowser.chrome || bowser.chromium;
  if (isChrome) {
    return BROWSER.CHROME;
  }

  return BROWSER.UNKNOWN;
}
/** @description allow setting variables for nunance (chat session)
 *  @param {string} country
 */
export function setNuanceDataVariable(country = '') {
  const userStr = localStorage.getItem('user');
  const userObj = JSON.parse(userStr);

  if (userObj) {
    window.nuanceData = {
      USER_NAME: userObj.userName,
      USER_UUID: userObj.uid,
      LOCATION: country,
      AUTHENTICATION_FLAG: true,
      LANGUAGE: 'ES',
    };
  }
}

/**
 * Validate if the pixel will be shown on a specific device
 * @method validateDeviceToShowPixel
 * @param deviceType
 */
export function validateDeviceToShowPixel(deviceType) {
  return deviceType === DEVICE_TYPES.ROKU;
}

/**
 * Obtains the country code from URL on public pages
 */
export function getCountryCodeFromPath() {
  const basePath = window.location.pathname;

  const res = basePath.match(/^\/(.*?)\//gi);

  if (res && res.length > 0) {
    const countryCode = res[0].replace(/\//g, '');

    if (countryCode.length === 2) {
      return countryCode;
    }
  }

  return 'co';
}

/**
 * @description Method that add the schema code to head
 * @method addSchemaCode
 * @param {object} schema
 */
export function addSchemaCode(schema) {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');

  script.type = 'application/ld+json';
  script.textContent = JSON.stringify(schema);
  if (head) head.append(script);
}

/**
 * @description Method that remove the schema code from head
 * @method removeSchemaCode
 */
export function removeSchemaCode() {
  const scripts = document.getElementsByTagName('script');
  [...scripts].forEach((script) => {
    if (script.type.toLowerCase() === 'application/ld+json') {
      script.remove();
    }
  });
}

/**
 * @description Removes all but the host on the URL
 * @method getHostFromUrl
 * @param {String} url
 * @returns String
 */
export function getHostFromUrl(url = '') {
  let hostname = '';
  // find & remove protocol (http, ftp, etc.) and get hostname
  try {
    hostname = url.includes('//') ? url.split('/')[2] : url.split('/')[0];
    // find & remove port number
    [hostname] = hostname.split(':');
    // find & remove "?"
    [hostname] = hostname.split('?');
  } catch (error) {
    console.error(error);
  }
  return hostname;
}

/**
 * @description Get an object with the localization language
 * @param {string} code
 * @returns {Object}
 */
export function getLocale(code) {
  switch (code) {
    case 'en':
      return null;
    case 'pt':
      return { locale: pt };
    default:
      return { locale: es };
  }
}

/**
 * @method getBooleanValueFromString
 * @description Method to get boolean value from a string value.
 * @param {string} booleanStr value string of boolean.
 * @return {boolean}
 */
export function getBooleanValueFromString(booleanStr = '') {
  if (booleanStr) {
    return booleanStr.toLowerCase().trim() === 'true' || booleanStr.toLowerCase().trim() === '1';
  }
  return false;
}

/**
 * Converts an URLSearchParams instance to an object
 * @param {URLSearchParams} urlSearchParams - the URLSearchParams instance to be
 * converted to object
 */
export function searchParamsToObject(urlSearchParams) {
  const queryParams = {};
  urlSearchParams.forEach((value, key) => {
    const currentValue = queryParams[key];
    if (currentValue) {
      if (Array.isArray(currentValue)) {
        currentValue.push(value);
      } else {
        queryParams[key] = [currentValue, value];
      }
    } else {
      queryParams[key] = value;
    }
  });

  return queryParams;
}

/**
 * @function parseQueryParams
 * @description Takes a URL search part (the query) and returns an object that
 * represents that query. Some browsers are not supported.
 * Check https://caniuse.com/#search=URLSearchParams
 * @param {string} search The URL search part, including the ? symbol
 * @returns {object} An object with the same keys in ths search string and
 * the values are the values of the param. If a param appears many times in
 * the params the value will be an array with all those values.
 */
export function parseQueryParams(search) {
  let queryParams = {};

  if (URLSearchParams) {
    queryParams = searchParamsToObject(new URLSearchParams(search));
  }

  return queryParams;
}

/**
 * @method addPlaySourceToAssets
 * @description Method that adds the play source attribute to assets.
 * @param {array} assets Array with the assets to modify
 * @param {string} playSource The play source attribute value
 */
export function addPlaySourceToAssets(assets, playSource) {
  return assets.map((asset) => ({
    ...asset,
    playSource,
  }));
}

/**
 * @method getWebAssetAction
 * @description Method that returns the web action from an asset actions array
 * @param {array} actions Actions from an asset
 */
export function getWebAssetAction(actions) {
  return actions?.find((action) => action.type === 'web');
}

/**
 * @method getVwJs
 * @description calculates dynamically the viewport width like their function pair in SCSS get-vw
 */
export const getVwJs = (target) => (window ? (target / 12.8) * (window.innerWidth * 0.01) : target);

/**
 * @function setLocalStorageLastActivity
 * @description Sets the new value of last activity on local storage
 * @param {Date} time The Date time to set as last activity on local storage
 */
export function setLocalStorageLastActivity(time) {
  localStorage.setItem(LAST_ACTIVITY_LOCAL_STORAGE_NAME, time.toISOString());
}

/**
 * @function getLocalStorageLastActivity
 * @description Gets the current value of Last Activity from Local storage
 * @returns {Date} The date stored on local storage as last activity or null
 * if there is not a value
 */
export function getLocalStorageLastActivity() {
  const localStorageLastActivity = localStorage.getItem(LAST_ACTIVITY_LOCAL_STORAGE_NAME);
  return localStorageLastActivity ? new Date(localStorageLastActivity) : null;
}

export const usePrevious = (value) => {
  const ref = useRef();

  /**
   * @description Updates ref
   */
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export function hasOwnProperty(object, property) {
  return Object.hasOwn(object, property);
}

/**
 * Execute a function when it's detected that the system was suspended based on interval execution
 * time.
 *
 * @param {number} thresholdMs - The minimum suspended time in milliseconds to trigger
 * the callback. It's important to be at least 3 times the interval.
 * @param {(suspendedMs: number) => void} callback - The callback function to be executed when
 * system is detected as suspended. It receives the ellapsed time in milliseconds.
 * @param {number} [intervalMs=1000] - The interval in milliseconds to check for system suspension.
 * Default value is 1000.
 * @return {() => void} A function to stop the detection interval.
 */
export function onSuspendedForMoreThan(thresholdMs, callback, intervalMs = 1000) {
  /* When system is suspended, the execution of intervals are stopped. They're resumed when the
  system is resumed. If the ellapsed time between the last execution and the current execution
  is greater than a provided treshold then the system was suspended */

  let prev = Date.now();
  const interval = setInterval(() => {
    const now = Date.now();
    const suspendedMs = now - prev;
    if (suspendedMs >= thresholdMs) {
      try {
        callback(suspendedMs);
      } catch (error) {
        console.error('onSuspendedForMoreThan > error in callback', error);
      }
    }
    prev = now;
  }, intervalMs);

  return () => {
    clearInterval(interval);
  };
}

export function getPageQueryByFeatureFlag(api) {
  if (isPagesV5Active) {
    return api.useGetPageV5Query;
  }

  if (USE_LAYOUT_PAGES_V4) {
    return api.useGetPageV4Query;
  }

  return api.useGetPageQuery;
}

export function getCarouselQueryByFeatureFlag(api, isCheddar) {
  if (isPagesV5Active) {
    return isCheddar ? api.useGetCarouselGeneralQuery : api.useGetCarouselInsightQuery;
  }

  return api.useGetCarouselQuery;
}
