/**
 * External
 */
import React from 'react';

/**
 * Components
 */
import SkeletonBox from '../skeletonBox/SkeletonBox';

/**
 * Components
 */
import './SeasonSkeleton.scss';

/**
 * Constants
 */
const CARDS_NUMBER = 4;

function SeasonSkeleton() {
  const cards = [];

  for (let i = 0; i < CARDS_NUMBER; i += 1) {
    cards.push(
      <div className="dtv-season-skeleton-card" key={`dtv-season-skeleton-card-${i}`}>
        <SkeletonBox />
        <SkeletonBox />
        <SkeletonBox />
      </div>,
    );
  }

  return <div className="dtv-season-skeleton">{cards}</div>;
}

export default SeasonSkeleton;
