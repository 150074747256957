/* External */
import React from 'react';
import { useDispatch } from 'react-redux';

/* Components */
import ChannelImage from '../channelImage/ChannelImage';
import ChannelLine from '../channelLine/ChannelLine';
import { updatePlaySource } from '../../state/player/actions';

/* Styles */
import './EpgChannels.scss';

function EpgChannels({
  channelLinesOffset,
  channels,
  isSports,
  onEpgPositionRecovery,
  schedulesEnd,
  schedulesStart,
}) {
  const dispatch = useDispatch();
  const playSource = isSports ? updatePlaySource('SportsLander-EPG') : updatePlaySource('LiveLander-EPG');
  dispatch(playSource);

  return (
    <div className="dtv-epg__channels-lines">
      <div className="dtv-epg__channels-images">
        {channels.map(
          (channel) => channel.schedules && (
          <ChannelImage
            imageUrl={channel?.images?.[0]?.url}
            title={channel.title}
            key={`channel-image_${channel.channelId}`}
          />
          ),
        )}
      </div>
      <div className="dtv-epg__channels-lines-move" style={{ left: `${channelLinesOffset}px` }}>
        {channels.map(
          (channel, index) => channel.schedules && (
          <ChannelLine
            channelId={channel.channelId}
            epgIndex={index}
            isSports={isSports}
            key={`channel-line_${channel.channelId}`}
            onEpgPositionRecovery={onEpgPositionRecovery}
            schedules={channel.schedules}
            schedulesEnd={schedulesEnd}
            schedulesStart={schedulesStart}
          />
          ),
        )}
      </div>
    </div>
  );
}

export default EpgChannels;
