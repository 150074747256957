/* External */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import { format, setSeconds } from 'date-fns';
import isEmptyL from 'lodash-es/isEmpty';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Components */
import AssetToPlay from '../../../components/assetToPlay/AssetToPlay';
import Button from '../../../components/button/Button';
import TimeLeftText from '../../../components/timeLeftText/TimeLeftText';
import { useGtmContext } from '../../../components/GtmContext';
import { gtmDimensions } from '../../../components/GtmContext/dimensions/epg';
import AgeRating from '../../../components/ageRating/AgeRating';

/* Styles */
import './EpgCard.scss';

/* Other */
import {
  redirectToPlayer,
  buildEpisodeSecondaryDataString,
  EPG_CARD_TYPES,
  EPG,
  getAssetNameWithQualifier,
  getEpgCardType,
  redirectToPdp,
  gtmComponentType,
  formatContentForGtm,
  playerType,
  getDiffInSeconds,
} from '../../../utils';
import { history } from '../../../state/configureStore';
import { useIsCastConnected } from '../../../components/chromecastConnectionBar';

function EpgCard({
  channelId, className, epgIndex, intl: { formatMessage }, offset, schedule, isSports,
}) {
  const isChromecastConnected = useIsCastConnected();
  const {
    duration,
    episode: { number: episodeNumber, seasonNumber, showName } = {},
    live: {
      blackout, startTime, endTime, qualifiers,
    } = {},
    title,
    type,
    rating = '',
  } = schedule || {};
  const isScheduleEmpty = isEmptyL(schedule);
  const start = startTime ? setSeconds(new Date(startTime), 0) : null;
  const end = endTime ? setSeconds(new Date(endTime), 0) : null;
  const cardType = getEpgCardType(start, end);
  const marginRight = 5;
  const width = (duration / 60) * EPG.MINUTES_TO_PIXELS - marginRight - offset;
  const isEpisode = !isScheduleEmpty && type === assetTypes.liveEpisode;
  const subtitle = isEpisode ? buildEpisodeSecondaryDataString(seasonNumber, episodeNumber, title, formatMessage) : '';

  const {
    gtmUserData,
    setGtmPlaySource,
    setGtmSectionName,
    setGtmComponentType,
  } = useGtmContext();

  const handleClick = () => {
    try {
      setGtmPlaySource(gtmComponentType.live_epg.playSource);
      setGtmComponentType(gtmComponentType.live_epg.componentType);
      setGtmSectionName(gtmComponentType.live_epg.sectionName);

      const epgType = isSports ? 'epg_sports' : 'epg_live';

      const { hash, eventType, dimensions } = gtmDimensions[epgType][cardType];

      const currentTime = new Date();

      const dataToSend = formatContentForGtm(
        schedule,
        {
          listPosition: epgIndex + 1,
          contentPlayerType: playerType(schedule.type),
          progress: offset,
          timeToStart: getDiffInSeconds(currentTime, start),
          tab: isSports ? 'sportsLander' : 'liveLander',
        },
      );

      gtmEvent({
        hash,
        eventType,
        dimensions,
        userData: gtmUserData,
        data: dataToSend,
      });
    } catch (error) {
      console.warn(`An error occurred: ${error.message}`);
    }

    if (!isChromecastConnected) {
      if (blackout) return;

      if (cardType !== EPG_CARD_TYPES.LIVE && !isScheduleEmpty) {
        redirectToPdp({
          assetId: channelId,
          type,
          startTime,
          history,
          isLive: true,
        });
        return;
      }

      const { redirect } = redirectToPlayer({
        assetId: channelId,
        channelId,
        fromEpg: true,
        fromEpgFilterId: null,
        fromEpgOriginalChannelId: channelId,
        fromEpgRedirectionIndex: epgIndex,
        history,
        isLive: true,
        isSports,
        replace: false,
        type,
      });

      if (redirect) redirect();
    }
  };

  return (
    <AssetToPlay
      allowPlay={isChromecastConnected}
      asset={{ channelId, type }}
      className={cn(`dtv-epg-card dtv-epg-card--${cardType}`, className, {
        'dtv-epg-card--blackout': blackout,
        'dtv-epg-card--no-schedule': isScheduleEmpty,
      })}
      isLive
      onClick={handleClick}
      style={{ width: `${width}px`, marginRight: `${marginRight}px` }}
      fromEpg
    >
      <h3 className="dtv-epg-card__title">
        {getAssetNameWithQualifier(qualifiers, isEpisode ? showName : title, formatMessage)
          || formatMessage({
            id: 'common.dataNotAvailable',
            defaultMessage: 'Información no disponible',
          })}
      </h3>
      {isScheduleEmpty ? (
        <Button className="dtv-epg-card__tune-channel">
          {formatMessage({
            id: 'slider.tuneChannel',
            defaultMessage: 'Sintonizar canal',
          })}
        </Button>
      ) : (
        <>
          <h4 className="dtv-epg-card__subtitle" title={subtitle}>
            <AgeRating rating={rating} />
            {subtitle}
          </h4>
          <h4 className="dtv-epg-card__thirdLine">
            {cardType === EPG_CARD_TYPES.LIVE ? <TimeLeftText endTime={end} /> : ''}
          </h4>
          <h4 className="dtv-epg-card__schedule">
            {blackout
              ? formatMessage({
                id: 'common.contentUnavailable',
                defaultMessage: 'Este contenido no se encuentra actualmente disponible en tu región',
              })
              : (start && end && `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`) || ''}
          </h4>
        </>
      )}
    </AssetToPlay>
  );
}

EpgCard.propTypes = {
  className: PropTypes.string,
  offset: PropTypes.number,
  schedule: PropTypes.shape(),
};

EpgCard.defaultProps = {
  className: '',
  offset: 0,
  schedule: {},
};

export default injectIntl(EpgCard);
