/* External */
import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';

/* Assets */
import genericCard16X9 from '@/assets/theme/images/16x9-GenericCard.png';

/* Components */
import AddOrRemoveFromMyListButton from '../addOrRemoveFromMyListButton/AddOrRemoveFromMyListButton';
import LiveTag from '../liveTag/LiveTag';
import PdpPlayButton from '../pdpPlayButton/PdpPlayButton';
import SkeletonBox from '../skeletonBox/SkeletonBox';
import Button from '../button/Button';

/* Styles */
import './NewDetailsFrame.scss';

/* Other */
import { GRADIENTS, useImage, redirectToPublicPDP } from '../../utils';
import { history } from '../../state/configureStore';
import { getImage } from '../../theme/images';

function NewDetailsFrame({
  assetId,
  assetToPlay: {
    images, type, live, vrioAssetId, vod, rating,
  } = {},

  description,
  fullHeight,
  isContinueWatchingAsset,
  isLive,
  isLoading,
  metadata,
  pdpPlayButtonText,
  pdpPlayButtonIcon,
  progress,
  showMyListButton,
  subtitle,
  title,
  bannerBottomRight,
  isPublic,
}) {
  const genericCard16x9Flag = getImage('16x9GenericCard') || genericCard16X9;
  const { imageRef, imageStyle } = useImage(images, genericCard16x9Flag, GRADIENTS.PDP, (!isPublic ? '16:9' : 'BACKGROUND'), false);
  const assetToPlayId = vrioAssetId;
  const { channelId, startTime, endTime } = live || {};
  const { duration } = vod || {};
  const { country } = useParams() || {};
  return (
    <div
      className={classNames('dtv-new-details-frame', {
        'full-height': fullHeight,
        loading: isLoading,
      })}
      ref={imageRef}
      style={isLoading ? {} : imageStyle}
    >
      {isLoading ? (
        <>
          <SkeletonBox />
          <SkeletonBox />
        </>
      ) : (
        <>
          <div>
            <div className="dtv-new-details-frame-content">
              {isLive && startTime && endTime && (
                <LiveTag
                  classText="dtv-new-details-frame-content-live-tag-text"
                  endTime={endTime}
                  startTime={startTime}
                />
              )}

              {title && (
                <h1
                  className={classNames({
                    'dtv-new-details-frame-content-title': true,
                  })}
                >
                  {title}
                </h1>
              )}

              {subtitle && <h2 className="dtv-new-details-frame-content-subtitle">{subtitle}</h2>}
              {metadata && <span className="dtv-new-details-frame-content-metadata">{metadata}</span>}
              {description && <p className="dtv-new-details-frame-content-description">{description}</p>}
            </div>

            <div className="dtv-new-details-frame-actions">
              {assetToPlayId && !isPublic ? (
                <PdpPlayButton
                  assetProps={{
                    asset: {
                      assetId: assetToPlayId,
                      playSource: `PDP-${title}`,
                      type,
                      channelId,
                      rating,
                    },
                    isLive,
                  }}
                  duration={duration}
                  progress={isContinueWatchingAsset && progress}
                  text={pdpPlayButtonText}
                  icon={pdpPlayButtonIcon}
                />
              ) : (
                <Button
                  icon={pdpPlayButtonIcon}
                  className="DTVGo-public-button"
                  onClick={() => {
                    redirectToPublicPDP(country, history);
                  }}
                >
                  {pdpPlayButtonText}
                </Button>
              )}

              {showMyListButton && assetId && !isPublic && (
                <AddOrRemoveFromMyListButton
                  assetId={assetId}
                  type={type === assetTypes.episode ? assetTypes.serie : type}
                  assetToPlayId={assetToPlayId}
                />
              )}
            </div>
          </div>
          <div className="right-content">
            {bannerBottomRight}
          </div>
        </>
      )}
    </div>
  );
}

NewDetailsFrame.propTypes = {
  assetId: PropTypes.string,
  assetToPlay: PropTypes.shape({
    vrioAssetId: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    progress: PropTypes.number,
    type: PropTypes.string,
    channelId: PropTypes.string,
  }),
  bannerBottomRight: PropTypes.node,
  description: PropTypes.node,
  fullHeight: PropTypes.bool,
  isContinueWatchingAsset: PropTypes.bool,
  isLive: PropTypes.bool,
  isLoading: PropTypes.bool,
  metadata: PropTypes.node,
  pdpPlayButtonText: PropTypes.string,
  showMyListButton: PropTypes.bool,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  isPublic: PropTypes.bool,
};

NewDetailsFrame.defaultProps = {
  assetId: '',
  assetToPlay: {},
  bannerBottomRight: null,
  description: '',
  fullHeight: false,
  isContinueWatchingAsset: false,
  isLive: false,
  isLoading: false,
  metadata: '',
  pdpPlayButtonText: '',
  showMyListButton: false,
  subtitle: '',
  title: '',
  isPublic: false,
};

export default injectIntl(NewDetailsFrame);
