/* External */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/* Components */
import HBSWidget from '../HBSWidget';

/* Styles */
import './SportGameStatistics.scss';

function SportGameStatistics({ match = {}, intl: { formatMessage } }) {
  return (
    <div className="dvt-sport-game-statistics">
      <h3 className="dvt-sport-game-statistics__title">
        {formatMessage(
          { id: 'sport.game.statistics.title' },
        )}
      </h3>
      <HBSWidget
        className="game-statistics-widget"
        type="match-stats"
        props={{ matchId: match?.fifaMatchId }}
      />
    </div>
  );
}

SportGameStatistics.propTypes = {
  match: PropTypes.shape({
    fifaMatchId: PropTypes.string.isRequired,
    optaMatchId: PropTypes.string.isRequired,
  }).isRequired,

  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
};

export default injectIntl(SportGameStatistics);
