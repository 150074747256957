/* External */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  differenceInMinutes, differenceInSeconds, isAfter, isBefore,
} from 'date-fns';

/* Actions */
import { updateEpgRedirectionData } from '../../state/epgRedirectionData/epgRedirectionDataSlice';

/* Components */
import EpgCard from './epgCard/EpgCard';

/* Styles */
import './ChannelLine.scss';

/* Other */
import { EPG } from '../../utils';

function ChannelLine({
  channelId,
  epgIndex,
  isSports,
  onEpgPositionRecovery,
  schedules,
  schedulesEnd,
  schedulesStart,
}) {
  const dispatch = useDispatch();
  const {
    channelId: redirectionChannelId,
    hasBeenRedirectedToEpgFromPlayer,
  } = useSelector((state) => state.epgRedirectionData) || {};
  const channelLineRef = useRef(null);
  const lastScheduleIndex = schedules.length - 1;
  const schedulesWithGaps = schedules?.reduce((result, schedule, index) => {
    const lastScheduleEndTime = new Date(schedule.live?.endTime);
    const prevEndTime = index === 0 ? schedulesStart : new Date(schedules[index - 1].live?.endTime);
    const startTime = new Date(schedule.live?.startTime);

    if (index === lastScheduleIndex && isBefore(lastScheduleEndTime, schedulesEnd)) {
      result.push({
        ...schedule,
        duration: differenceInSeconds(schedulesEnd, lastScheduleEndTime),
        live: { endTime: schedulesEnd, startTime },
      });
    }

    if (isAfter(startTime, prevEndTime)) {
      result.push({
        ...schedule,
        duration: differenceInSeconds(startTime, prevEndTime),
        live: { endTime: startTime, startTime: prevEndTime },
      });
    }

    result.push(schedule);

    return result;
  }, []);

  const firstProgramStartTime = new Date(schedulesWithGaps?.[0]?.live?.startTime);
  const minutesOffset = differenceInMinutes(schedulesStart, firstProgramStartTime);
  const pixelsOffset = minutesOffset > 0 ? minutesOffset * EPG.MINUTES_TO_PIXELS : 0;

  /**
   * @description Executes the scroll on the initial render if needed.
   */
  useEffect(() => {
    const { current } = channelLineRef;

    if (!hasBeenRedirectedToEpgFromPlayer) return;
    if (channelId !== redirectionChannelId) return;

    current.scrollIntoView(true, {
      behavior: 'auto',
      block: 'center',
      inline: 'start',
    });
    window.scrollBy(0, -(current.clientHeight * 2));

    if (onEpgPositionRecovery) onEpgPositionRecovery();
    dispatch(updateEpgRedirectionData({ hasBeenRedirectedToEpgFromPlayer: false }));
  }, [
    dispatch,
    channelId,
    redirectionChannelId,
    hasBeenRedirectedToEpgFromPlayer,
    onEpgPositionRecovery,
  ]);

  return (
    <div className="dtv-channel-line" ref={channelLineRef}>
      {schedulesWithGaps?.length ? (
        schedulesWithGaps.map((schedule, i) => (
          <EpgCard
            channelId={channelId}
            className="dtv-channel-line__card"
            epgIndex={epgIndex}
            key={schedule.guideId}
            offset={i === 0 ? pixelsOffset : 0}
            schedule={schedule}
            isSports={isSports}
          />
        ))
      ) : (
        <EpgCard
          channelId={channelId}
          isSports={isSports}
          className="dtv-channel-line__card"
        />
      )}
    </div>
  );
}

ChannelLine.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape()),
  schedulesStart: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

ChannelLine.defaultProps = {
  schedules: [],
  schedulesStart: '',
};

export default ChannelLine;
