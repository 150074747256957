/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import './EpgFilter.scss';

function EpgFilter({
  className,
  title,
  status,
  onClick,
}) {
  return (
    <button
      className={`epg-filter ${status ? 'epg-filter--on' : 'epg-filter--off'} ${className}`}
      type="button"
      onClick={onClick}
    >
      {title}
    </button>
  );
}

EpgFilter.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.bool,
  onClick: PropTypes.func,
};

EpgFilter.defaultProps = {
  className: undefined,
  title: '',
  status: false,
  onClick: undefined,
};

export default EpgFilter;
