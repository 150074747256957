/**
 * External
 */
import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/**
 * Assets
 */
import logo from '@/assets/theme/svg/my-list-logo.svg';
import genericCard2X3 from '@/assets/theme/images/2x3-GenericCard.png';

/**
 * Components
 */
import Card2X3 from '../../components/card2X3/Card2X3';
import SliderSkeleton from '../../components/sliderLoader/sliderSkeleton/SliderSkeleton';
import ErrorMessageButton from '../../components/errorMessageButton/ErrorMessageButton';
import Button from '../../components/button/Button';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/myList';

/**
 * Actions
 */
import { showToastNotification } from '../../state/notifications/actions';

/**
 * Styles
 */
import './MyList.scss';

/**
 * Other
 */
import { STATUS_TYPES, PATHS, addPlaySourceToAssets } from '../../utils';
import { api, history } from '../../state/configureStore';
import { getImage } from '../../theme/images';

function MyList({ intl: { formatMessage } }) {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [removeStatus, setRemoveStatus] = useState(null);
  const { data, isLoading, isError } = api.useGetMyListQuery();
  const [removeFromMyList] = api.useRemoveFromMyListMutation();
  const { contents: assets = [] } = data || {};
  const { gtmUserData } = useGtmContext();
  /**
   * @method showNotifications
   * @description Shows toast notifications
   */
  const showNotifications = useCallback(() => {
    if (removeStatus === STATUS_TYPES.SUCCEED_STATE) {
      dispatch(
        showToastNotification({
          type: 'success',
          time: 5000,
          message: formatMessage({
            id: 'myList.update',
            defaultMessage: 'Tu Lista Go ha sido actualizada correctamente.',
          }),
        }),
      );

      const { hash, eventType, dimensions } = gtmDimensions.update;

      gtmEvent({
        hash,
        eventType,
        dimensions,
        userData: gtmUserData,
        data: {},
      });
    } else if (removeStatus === STATUS_TYPES.FAILED_STATE) {
      dispatch(
        showToastNotification({
          type: 'info',
          time: 5000,
          message: formatMessage({
            id: 'myList.updateWithErrors',
            defaultMessage:
                'Tu Lista GO ha sido actualizada. Por favor verifica que tu selección haya sido eliminada correctamente.',
          }),
        }),
      );
    }
  }, [dispatch, removeStatus, formatMessage, gtmUserData]);

  /**
   * @method resetComponentState
   * @description Resets component's state
   */
  const resetComponentState = () => {
    setRemoveStatus(null);
    setEditMode(false);
    setSelectedAssetIds([]);
  };

  useEffect(() => {
    if (removeStatus === STATUS_TYPES.SUCCEED_STATE || removeStatus === STATUS_TYPES.FAILED_STATE) {
      showNotifications();
      resetComponentState();
    }
  }, [removeStatus, showNotifications]);

  useEffect(() => {
    const { hash, eventType, dimensions } = gtmDimensions.page_view;

    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: {},
    });
  }, [gtmUserData]);

  const handleClickCancel = () => {
    resetComponentState();
    const { hash, eventType, dimensions } = gtmDimensions.cancel;

    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: {},
    });
  };

  /**
   * @method handleOnEditModeCardClick
   * @description Handles card click on edit mode, selecting and unselecting assets
   * @param {string} id
   */
  const handleOnEditModeCardClick = (id) => {
    setSelectedAssetIds((currentSelectedAssetIds) => (
      currentSelectedAssetIds.includes(id)
        ? currentSelectedAssetIds.filter((assetId) => assetId !== id)
        : [...currentSelectedAssetIds, id]
    ));
  };

  /**
   * @method getContent
   * @description Returns the content of the list
   * @param {array} assets
   */
  const getContent = () => {
    if (assets && assets.length > 0) {
      const assetsWithPlaySource = addPlaySourceToAssets(assets, 'MyList-Any');

      return (
        <div className="dtv-myList-content-cards">
          {assetsWithPlaySource.map((asset, index) => (
            <Card2X3
              genericCard={getImage('2x3GenericCard') || genericCard2X3}
              infoCard={asset}
              isInMyList
              key={asset.vrioAssetId}
              myListEditMode={editMode}
              myListEditModeModeSelected={selectedAssetIds.includes(asset.vrioAssetId)}
              onEditModeCardClick={handleOnEditModeCardClick}
              pictureId={asset.pictures ? asset.pictures['2x3'] : ''}
              carouselInfo={{ listPosition: index + 1 }}
            />
          ))}
        </div>
      );
    }

    if (isError) {
      return (
        <ErrorMessageButton
          titleFirstLine={formatMessage({ id: 'common.ups', defaultMessage: '¡UPS!' })}
          titleSecondLine={formatMessage({ id: 'myList.error', defaultMessage: 'No pudimos cargar Tu Lista GO' })}
          subtitle={formatMessage({
            id: 'common.pleaseTryAgainLater',
            defaultMessage: 'Por favor, inténtalo más tarde.',
          })}
          buttonText={formatMessage({ id: 'errorPage.btn', defaultMessage: 'Volver a DIRECTV GO' })}
          buttonAction={goToHome}
        />
      );
    }

    return (
      <div className="dtv-myList-content-empty">
        <img className="dtv-myList-content-empty-logo" src={getImage('myListLogo') || logo} alt="" />
        <h3 className="dtv-myList-content-empty-title">
          {formatMessage({
            id: 'myList.empty',
            defaultMessage: 'Tu Lista GO está vacía.',
          })}
        </h3>
        <h3 className="dtv-myList-content-empty-subtitle">
          {formatMessage({
            id: 'myList.addYourFavorites',
            defaultMessage: '¡Anímate y agrega tus favoritos!',
          })}
        </h3>
      </div>
    );
  };

  /**
   * @method handleRemoveClick
   * @description Handles remove button click
   */
  const handleRemoveClick = () => {
    Promise.all(
      selectedAssetIds.map((id) => {
        const { vmsId, type, vrioAssetId } = assets.find((asset) => asset.vrioAssetId === id) || {};
        return removeFromMyList({ id: type === assetTypes.movie ? vmsId : vrioAssetId, type });
      }),
    )
      .then((values) => {
        if (values.some((res) => !!res.error)) {
          setRemoveStatus(STATUS_TYPES.FAILED_STATE);
        } else {
          setRemoveStatus(STATUS_TYPES.SUCCEED_STATE);
          const { hash, eventType, dimensions } = gtmDimensions.delete_action;

          gtmEvent({
            hash,
            eventType,
            dimensions,
            userData: gtmUserData,
            data: {},
          });
        }
        return values;
      })
      .catch(() => {
        setRemoveStatus(STATUS_TYPES.FAILED_STATE);
      });
  };

  /**
   * @method toggleEditMode
   * @description Toggles editMode in component's state
   */
  const toggleEditMode = () => {
    setEditMode(!editMode);
    if (!editMode) {
      const { hash, eventType, dimensions } = gtmDimensions.edit;

      gtmEvent({
        hash,
        eventType,
        dimensions,
        userData: gtmUserData,
        data: {},
      });
    }
  };

  if (isLoading) {
    return <SliderSkeleton classCard="card-2X3" />;
  }

  return (
    <div className="dtv-myList">
      <div className="dtv-myList-header">
        <h2 className="dtv-myList-header-title">
          {formatMessage({
            id: 'myList',
            defaultMessage: 'Mi lista',
          })}
        </h2>
        <div className="dtv-myList-header-actions">
          {editMode ? (
            <>
              <Button
                className="dtv-myList-btn dtv-myList-btn-red"
                disabled={selectedAssetIds.length === 0}
                icon="dtv-icon-trash"
                onClick={handleRemoveClick}
              >
                {formatMessage({ id: 'myList.remove', defaultMessage: 'Remover' })}
              </Button>
              <Button className="dtv-myList-btn" icon="dtv-icon-close" onClick={() => handleClickCancel()}>
                {formatMessage({ id: 'myProfile.general.cancel', defaultMessage: 'Cancelar' })}
              </Button>
            </>
          ) : (
            <Button
              className="dtv-myList-btn"
              disabled={assets && assets.length === 0}
              icon="dtv-icon-edit-pencil"
              onClick={toggleEditMode}
            >
              {formatMessage({ id: 'myProfile.general.edit', defaultMessage: 'Editar' })}
            </Button>
          )}
        </div>
      </div>
      <div className="dtv-myList-content">{getContent()}</div>
    </div>
  );
}

export default injectIntl(MyList);

/**
 * @method goToHome
 * @description Redirects to home page
 */
function goToHome() {
  history.push(PATHS.HOME);
}
