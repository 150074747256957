/* External */
import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

/* Ott-common */
import InfiniteScrollContainer from '@dtvgo/infinite-scroll-container';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Actions */
import { hideLoading, showLoading } from 'react-redux-loading-bar';

/* Components */
import LanderLogo from '../../components/landerLogo/LanderLogo';
import Carousel from '../../components/carousel/Carousel';
import SliderLoader from '../../components/sliderLoader/SliderLoader';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/header-footer';

/* Other */
import {
  PAGE_SIZE,
  formatContentForGtm,
  getPageQueryByFeatureFlag,
  gtmContentLander,
} from '../../utils';
import { api } from '../../state/configureStore';

function Lander({ pageId: pageIdFromProps, showLogo, children }) {
  const { landerUri, pageId: pageIdFromRoute } = useParams();
  const pageId = pageIdFromRoute || pageIdFromProps;
  const useGetPageQuery = getPageQueryByFeatureFlag(api);
  const { data: { elements } = {}, isError: isPageError } = useGetPageQuery(
    { id: pageId },
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const successfulElementsCountRef = useRef(0);
  const resolveCarouselsFetchingPromiseRef = useRef();
  const [carouselsToFetchQuantity, setCarouselsToFetchQuantity] = useState(0);
  const [shownCarousels, setShownCarousels] = useState([]);
  const gtmRef = useRef(false);
  const { gtmUserData, setGtmProviderName } = useGtmContext();

  const fetchCarouselsBatch = useCallback(() => {
    dispatch(showLoading());

    const fetchingCarouselsPromise = new Promise((resolve) => {
      resolveCarouselsFetchingPromiseRef.current = resolve;
    });

    setCarouselsToFetchQuantity((prevQuantity) => prevQuantity + PAGE_SIZE);

    return fetchingCarouselsPromise;
  }, [dispatch]);

  useEffect(() => {
    fetchCarouselsBatch();
  }, [fetchCarouselsBatch]);

  useEffect(() => {
    if (isPageError) {
      dispatch(hideLoading());
      history.replace('/404');
    }
  }, [history, isPageError, dispatch]);

  const handleCarouselFetched = (assets) => {
    const handleFinishCarouselFetch = () => {
      setShownCarousels(elements.slice(0, carouselsToFetchQuantity).map(({ id }) => id));
      resolveCarouselsFetchingPromiseRef.current();
      dispatch(hideLoading());
    };

    if (carouselsToFetchQuantity >= elements.length) {
      handleFinishCarouselFetch();
    }

    if (!assets || assets.length <= 0) {
      setCarouselsToFetchQuantity((prevQuantity) => prevQuantity + 1);
      return;
    }

    successfulElementsCountRef.current += 1;

    if (
      successfulElementsCountRef.current < PAGE_SIZE
      && carouselsToFetchQuantity < elements.length
    ) return;

    successfulElementsCountRef.current = 0;

    handleFinishCarouselFetch();
  };

  useEffect(() => {
    let providerName = null;
    let landerData = landerUri ? gtmDimensions[landerUri] : null;

    if (pageIdFromRoute) {
      landerData = gtmContentLander.has(pageIdFromRoute)
        ? gtmDimensions.content : gtmDimensions.provider;

      providerName = gtmContentLander.has(pageIdFromRoute) ? null : pageIdFromRoute;
      setGtmProviderName(providerName);
    }

    if (!gtmRef.current && landerData) {
      gtmRef.current = true;

      const { hash, eventType, dimensions } = landerData.page_view;

      const dataTosend = formatContentForGtm(
        {},
        {
          providerName,
        },
      );

      gtmEvent({
        hash,
        eventType,
        dimensions,
        userData: gtmUserData,
        data: dataTosend,
      });
    }
  }, [landerUri, pageIdFromRoute, gtmUserData, setGtmProviderName]);

  return (
    <>
      {shownCarousels.length === 0 && <SliderLoader classCard="card-2X3" />}
      {showLogo && <LanderLogo pageId={pageId} />}

      {elements?.length && (
        <InfiniteScrollContainer
          onBottomReached={fetchCarouselsBatch}
          shouldObserveBottom={
            shownCarousels.length > 0
            && carouselsToFetchQuantity < elements.length
          }
        >
          {elements.slice(0, carouselsToFetchQuantity).map(({
            id, source, type, filters, recommendationType, isCheddar,
          }, index) => (
            <Carousel
              id={id}
              key={id}
              landerUri={landerUri || pageIdFromRoute}
              onFetched={handleCarouselFetched}
              shown={shownCarousels.includes(id)}
              source={source}
              type={type}
              filters={filters}
              carouselVerticalPosition={index + 1}
              recommendationType={recommendationType}
              isCheddar={isCheddar}
            />
          ))}
        </InfiniteScrollContainer>
      )}

      {children}
    </>
  );
}

Lander.propTypes = {
  pageId: PropTypes.string,
  showLogo: PropTypes.bool,
};

Lander.defaultProps = {
  pageId: '',
  showLogo: false,
};

export default Lander;
