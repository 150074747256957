/**
 * Actions
 */
import { ACTION_TYPES } from './actions';

const initialState = {
  show: false,
  title: '',
  message: '',
  primaryText: '',
  primaryAction: null,
  secondaryText: '',
  secondaryAction: null,
  onClose: null,
};

export default function modal(state = initialState, { type, payload = {} } = {}) {
  switch (type) {
    case ACTION_TYPES.SHOW_MODAL:
      return { ...payload, show: true };
    case ACTION_TYPES.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
}
