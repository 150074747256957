/* External */
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';

/* Ott-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Assets */
import genericCard2X3 from '@/assets/theme/images/2x3-GenericCard.png';

/* Components */
import SearchCard from '../../components/searchCard/SearchCard';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/search';

/* Actions */
import { setSearchResultsLength } from '../../state/search/searchSlice';

/* Styles */
import './SearchResults.scss';

/* Utils */
import { IMAGE_RESIZERS, getVrioImageUrl, formatContentForGtm } from '../../utils';
import { getImage } from '../../theme/images';

function SearchResults({ results, searchTerm, intl: { formatMessage } }) {
  const dispatch = useDispatch();
  const {
    gtmUserData,
  } = useGtmContext();

  /**
   * @description Updates search results length in rtk state to be available in DataLayerComponent
   */
  useEffect(() => {
    dispatch(setSearchResultsLength(results?.length || 0));
  }, [dispatch, results]);

  useEffect(() => {
    if (results?.length === 0) {
      const sendToGtm = formatContentForGtm(
        {},
        {
          searchTerms: searchTerm,
          playSource: 'search',
          sectionName: 'search',
          componentType: 'search',
        },
      );
      gtmEvent({
        hash: gtmDimensions.clear.hash,
        eventType: gtmDimensions.clear.eventType,
        userData: gtmUserData,
        dimensions: gtmDimensions.clear.dimensions,
        data: sendToGtm,
      });
    }
  }, [results, searchTerm, gtmUserData]);

  return (
    <div className="dtv-search-results">
      <h2 className="dtv-search-results-message">
        {results?.length > 0
          ? formatMessage(
            { id: 'search.resultsFound', defaultMessage: 'Mostrando resultados para: {searchTerm}' },
            { searchTerm },
          )
          : formatMessage(
            { id: 'search.resultsNotFound', defaultMessage: 'No se encuentran resultados para: {searchTerm}' },
            { searchTerm },
          )}
      </h2>
      <div className="dtv-search-results-cards">
        {results?.map((asset, index) => (
          <SearchCard
            asset={asset}
            genericCard={getImage('2x3GenericCard') || genericCard2X3}
            pictureId={getVrioImageUrl(asset?.images, '2:3', IMAGE_RESIZERS.CARD_2x3)}
            key={asset?.vrioAssetId}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = ({ search: { searchTerm } }) => ({ searchTerm });

export default compose(connect(mapStateToProps), injectIntl)(SearchResults);
