/* External */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';

/* Reducers */
import changePassword from './userMenu/settings/general/changePassword/reducers';
import mobileReducer from './mobile/reducers';
import modal from './modal/reducers';
import myList from './myList/reducers';
import navigation from './navigation/reducers';
import notifications from './notifications/reducers';
import player from './player/reducers';
import trackHistory from './history/reducers';
import user from './user/reducers';
import profileSlice from './profile/reducer';

/* Rtk */
import globalSlice from './global/globalSlice';
import epgRedirectionDataSlice from './epgRedirectionData/epgRedirectionDataSlice';
import searchSlice from './search/searchSlice';
import vrioHeartbeatSlice from './player/streamHeartbeat/vrioHeartbeatSlice';

export default function reducer(history, api) {
  return combineReducers({
    [api.reducerPath]: api.reducer,
    changePassword,
    epgRedirectionData: epgRedirectionDataSlice,
    global: globalSlice,
    loadingBar: loadingBarReducer,
    mobile: mobileReducer,
    modal,
    myList,
    navigation,
    notifications,
    player,
    router: connectRouter(history),
    search: searchSlice,
    trackHistory,
    user,
    vrioHeartbeat: vrioHeartbeatSlice,
    profile: profileSlice,
  });
}
