/**
 * Other
 */
import { getBooleanFromEnvVariable } from '../env';
import { IS_BRAZIL } from '../constants';

/**
 * Constants
 */
const BASE_AEM_PATH = '/bin/ott';
const CONTENT_AEM_PATH = '/content/dam/ott/web';
const IS_PROD_ENV = getBooleanFromEnvVariable('REACT_APP_IS_PRODUCTION_ENV');

export const GA = 'https://www.google-analytics.com/collect';

export const AEM = {
  chromecastConfig: `${BASE_AEM_PATH}/chromecast`,
  interstitial: `${BASE_AEM_PATH}/interstitial`,
  webConfig: `${BASE_AEM_PATH}/web`,
  stations: `${CONTENT_AEM_PATH}/stations`,
};

export const APP_STORES_DGO = {
  iTunes: 'https://itunes.apple.com/{country}/app/directv-go/id1349939060?ls=1&mt=8',
  playStore: 'https://play.google.com/store/apps/details?id=com.directv.dtvlatam',
};

export const APP_STORES_SKY = {
  iTunes: 'https://apps.apple.com/{country}/app/sky-tv-streaming-num-s%C3%B3-app/id6467130144',
  playStore: 'https://play.google.com/store/apps/details?id=br.com.skymais',
};

export const BROWSER_DOWNLOAD = {
  chrome: 'https://www.google.com/chrome/',
  firefox: 'https://www.mozilla.org',
};

export const MI_DIRECTV = {
  CL: {
    forgotPassword: 'https://www.directv.cl/midirectv/contrasena-olvidada',
    selfCare: 'https://www.directv.cl/midirectv/ingresar',
  },
  GENERIC: {
    forgotPassword: 'https://www.directv.com.{country}/midirectv/contrasena-olvidada',
    ottSelfCare: '/{country}/autoservicio/suscripcion',
    selfCare: '/selfcare/',
    tve: 'https://www.directv.com.{country}/midirectv/ingresar',
    tveBR: 'https://www.sky.com.br/minha-sky',
  },
};

export const USER = {
  logout: `${import.meta.env.REACT_APP_BACKEND_SERVICE_HOST}/customer/v1/accounts/logout`,
};

export const FORGE_ROCK = {
  pinLoginSSOToken: `${import.meta.env.REACT_APP_FORGEROCK_HOST}${
    IS_PROD_ENV ? '' : ':443'
  }/openam/json/realms/root/realms/${
    IS_BRAZIL ? 'br' : 'ssla'
  }/authenticate?authIndexType=module&authIndexValue=OIDCBearer`,
  registerPinLogin: `${import.meta.env.REACT_APP_FORGEROCK_HOST}:443/openam/oauth2/device/user`,
  tokenRefreshUrl: `${import.meta.env.REACT_APP_FORGEROCK_HOST}:443/openam/oauth2/realms/root/realms/${
    IS_BRAZIL ? 'br' : 'ssla'
  }/access_token`,
  patchUserUrl: `${import.meta.env.REACT_APP_FORGEROCK_HOST}${IS_PROD_ENV ? '' : ':443'}/openidm/endpoint/v2/patchUser`,
  logout: `${import.meta.env.REACT_APP_FORGEROCK_HOST}${IS_PROD_ENV ? '' : ':443'}/openam/json/realms/root/realms/${
    IS_BRAZIL ? 'br' : 'ssla'
  }/sessions/?_action=logout`,
  deviceLogin: `${import.meta.env.REACT_APP_FORGEROCK_HOST}/openam/oauth2/realms/root/realms/${
    IS_BRAZIL ? 'br' : 'ssla'
  }/device/user?nonce={deviceId}&locale=${IS_BRAZIL ? 'pt' : 'es'}`,
};

export const PIXEL = {
  confirmation:
    'https://p.ads.roku.com/v1/web/?meType=12&evType=CONFIRMATION&chID=259958&chName=DIRECTVLATAM&tStamp=CACHEBUSTER',
};
