import { userConstants } from './userConstants';

export const gtmDimensions = {
  component_type: 'user',
  first_access: {
    first_profile_creation: {
      page_view: {
        hash: 'p-042.060.000.000.000.000',
        eventType: 'pages',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      create_first_profile: {
        hash: 'e-042.060.001.000.000.157',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      success: {
        page_view: {
          hash: 'p-042.062.000.000.000.000',
          eventType: 'pages',
          dimensions: [
            ...userConstants.constans,
          ],
        },
      },
      error: {
        page_view: {
          hash: 'p-041.023.000.000.000.000',
          eventType: 'pages',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
            'v_change_parental_control',
          ],
        },
        back: {
          hash: 'e-041.023.001.000.000.008',
          eventType: 'interactions',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
          ],
        },
        continue: {
          hash: 'e-041.023.001.000.000.101',
          eventType: 'interactions',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
          ],
        },
        try_Again: {
          hash: 'e-041.023.001.000.000.117',
          eventType: 'interactions',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
          ],
        },
      },
      cold_start: {
        page_view: {
          hash: 'p-042.062.000.000.000.000',
          eventType: 'pages',
          dimensions: [
            ...userConstants.constans,
          ],
        },
        continue: {
          hash: 'e-042.062.012.000.000.008',
          eventType: 'interactions',
          dimensions: [
            ...userConstants.constans,
          ],
        },
      },
    },
  },
  multiple_profile: {
    settings: {
      profile_to_watch: {
        hash: 'e-043.007.001.000.000.161',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      create_profile: {
        hash: 'e-043.007.001.000.000.157',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      manage_profile: {
        hash: 'e-043.007.001.000.000.158',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
    },
    profiles: {
      page_view: {
        hash: 'p-042.001.000.000.000.000',
        eventType: 'pages',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      profile_to_watch: {
        hash: 'e-042.001.001.000.000.161',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      create_profile: {
        hash: 'e-042.001.001.000.000.157',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      manage_profile: {
        hash: 'e-042.001.001.000.000.158',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
    },
    edit_create: {
      page_view: {
        hash: 'p-042.056.000.000.000.000',
        eventType: 'pages',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      profile_to_edit: {
        hash: 'e-042.056.001.000.000.159',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      create_profile: {
        hash: 'e-042.001.001.000.000.157',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      manage_profile: {
        hash: 'e-042.001.001.000.000.158',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
    },
    create_profile: {
      page_view: {
        hash: 'p-042.057.000.000.000.000',
        eventType: 'pages',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      back: {
        hash: 'e-042.057.001.000.000.028',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      change: {
        hash: 'e-042.057.001.000.000.090',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      create_profile: {
        hash: 'e-042.057.001.000.000.157',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      error: {
        page_view: {
          hash: 'p-041.023.000.000.000.000',
          eventType: 'pages',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
            'v_change_parental_control',
          ],
        },
        back: {
          hash: 'e-041.023.001.000.000.028',
          eventType: 'interactions',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
          ],
        },
        ok: {
          hash: 'e-041.023.001.000.000.154',
          eventType: 'interactions',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
          ],
        },
      },
      success: {
        page_view: {
          hash: 'p-042.068.000.000.000.000',
          eventType: 'pages',
          dimensions: [
            ...userConstants.constans,
            'v_change_parental_control',
          ],
        },
      },
    },
    edit_profile: {
      page_view: {
        hash: 'p-042.063.000.000.000.000',
        eventType: 'pages',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      back: {
        hash: 'e-042.063.001.000.000.028',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      ok: {
        hash: 'e-042.063.001.000.000.154',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      change: {
        hash: 'e-042.063.001.000.000.090',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      delete: {
        hash: 'e-042.063.001.000.000.162',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      error: {
        page_view: {
          hash: 'p-041.023.000.000.000.000',
          eventType: 'pages',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
            'v_change_parental_control',
          ],
        },
        back: {
          hash: 'e-041.023.001.000.000.028',
          eventType: 'interactions',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
          ],
        },
        ok: {
          hash: 'e-041.023.001.000.000.154',
          eventType: 'interactions',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
          ],
        },
      },
      success: {
        page_view: {
          hash: 'p-042.065.000.000.000.000',
          eventType: 'pages',
          dimensions: [
            ...userConstants.constans,
            'v_change_parental_control',
          ],
        },
      },
    },
    delete_profile: {
      page_view: {
        hash: 'p-042.066.000.000.000.000',
        eventType: 'pages',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      close: {
        hash: 'e-042.066.001.000.000.008',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      delete: {
        hash: 'e-042.066.001.000.000.162',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      not_now: {
        hash: 'e-042.066.001.000.000.047',
        eventType: 'interactions',
        dimensions: [
          ...userConstants.constans,
        ],
      },
      error: {
        page_view: {
          hash: 'p-041.023.000.000.000.000',
          eventType: 'pages',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
            'v_change_parental_control',
          ],
        },
        back: {
          hash: 'e-041.023.001.000.000.028',
          eventType: 'interactions',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
          ],
        },
        ok: {
          hash: 'e-041.023.001.000.000.154',
          eventType: 'interactions',
          dimensions: [
            ...userConstants.constans,
            'v_error_code',
            'v_error_type',
          ],
        },
      },
      success: {
        page_view: {
          hash: 'p-042.067.000.000.000.000',
          eventType: 'pages',
          dimensions: [
            ...userConstants.constans,
            'v_change_parental_control',
          ],
        },
      },
    },
  },
};
