import React from 'react';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

/* Ott-common */
import Button from '@dtvgo/button';

/* Other */
import Profiles from '@/assets/theme/images/profiles.svg';
import { history } from '../../state/configureStore';
import { addProfile } from '../../state/profile/reducer';
import { backendService } from '../../services';
import { getImage } from '../../theme/images';

/* Componentes */
import NavBarProfile from '../../navBarProfile/NavBarProfile';
import { useGtmContext } from '../../components/GtmContext';
import { gtmDimensions } from '../../components/GtmContext/dimensions/user';

/* Utils */
import { PATHS, userGtmEventHandler } from '../../utils';

/* Styles */
import './Onboarding.scss';

function Onboarding({
  intl: { formatMessage },
}) {
  const dispatch = useDispatch();
  const isUserOtt = JSON.parse(localStorage.getItem('user')).userType;
  const userToken = localStorage.getItem('sessionToken');
  const userInfo = jwtDecode(userToken);
  const nameUser = userInfo.name ? userInfo.name.slice(0, 16) : JSON.parse(localStorage.getItem('user')).userName.slice(0, 16);

  const { gtmUserData } = useGtmContext();

  const handleClick = () => {
    if (isUserOtt === 'ott') {
      const newProfile = {
        name: nameUser,
        avatar: '',
        ageRating: '18',
        isNotRatedContentAllowed: true,
      };

      backendService.customer.v1.createProfile(newProfile).then((res) => {
        const allProfiles = res.data.profiles;
        dispatch(addProfile(allProfiles));

        userGtmEventHandler(
          gtmDimensions.first_access.first_profile_creation.create_first_profile,
          gtmUserData,
        );

        userGtmEventHandler(
          gtmDimensions.first_access.first_profile_creation.page_view,
          gtmUserData,
        );

        return history.push(PATHS?.USER_PROFILE, { create: true, first: true });
      }).catch((error) => {
        history.push(PATHS?.ERROR, {
          source: 'MIDDLEWARE',
          location: 'PROFILE_CREATE',
          type: 'UNEXPECTED',
          externCode: error?.response?.status,
        });
      });
    } else {
      history.push(PATHS?.CREATE_PROFILE);
    }
  };

  return (
    <div className="dtv-web-onboarding">
      <NavBarProfile />
      <div className="dtv-web-onboarding--centralized">
        <div>
          <img src={getImage('profiles') || Profiles} alt="dtv-profiles" className="dtv-web-onboarding--centralized-img" />
          <div className="dtv-web-onboarding--title">
            {isUserOtt !== 'ott' ? (
              <div className="dtv-web-onboarding--title-h1">
                {formatMessage({ id: 'profiles.onboarding.title.1', defaultMessage: '¡Ahora puedes crear tu propio perfil!' })}
              </div>
            ) : (
              <div className="dtv-web-onboarding--title-h1">
                {formatMessage({ id: 'profiles.onboarding.title.2.1', defaultMessage: '¡Hola, {nameUser}!' }, { nameUser })}
                <br />
                {formatMessage({ id: 'profiles.onboarding.title.2.2', defaultMessage: 'Ahora puedes tener tu propio perfil' })}
              </div>
            )}
            <p className="dtv-web-onboarding--title-subtitle">
              {formatMessage({ id: 'profiles.onboarding.subtitle.1.1', defaultMessage: 'Así disfrutarás tu contenido favorito a tu manera.' })}
              <br />
              {formatMessage({ id: 'profiles.onboarding.subtitle.1.2', defaultMessage: 'Otras personas también pueden crear perfiles en esta cuenta.' })}
            </p>
          </div>
          <Button
            type="submit"
            secondBlue
            className="dtv-btn-primary"
            bordered
            onClick={() => { handleClick(); }}
          >
            {formatMessage({ id: 'profiles.onboarding.button', defaultMessage: 'Continuar' })}
          </Button>
        </div>
      </div>
    </div>
  );
}

Onboarding.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

Onboarding.defaultProps = {
  intl: {
    formatMessage: () => {},
  },
};

export default injectIntl(Onboarding);
