/* External */
import { injectIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { format, isBefore } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/* Ott-common */
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Components */
import LiveProgressBar from '../../../components/liveProgressBar/LiveProgressBar';
import AgeRating from '../../../components/ageRating/AgeRating';
import { useGtmContext } from '../../../components/GtmContext';
import { gtmDimensions } from '../../../components/GtmContext/dimensions/miniEpg';

/* Actions */
import { updatePlaySource } from '../../../state/player/actions';

/* Other */
import {
  redirectToPlayer,
  getEpgShowTitle,
  getEpgEpisodeSubtitle,
  getAssetNameWithQualifier,
  formatContentForGtm,
  getGtmEventConfig,
  playerType,
  PATHS,
  contentRating,
  featureFlagParentalControl,
  getProfileRating,
  getProfileContentNoClassificationAllowed,
} from '../../../utils';
import { history } from '../../../state/configureStore';

/* Styles */
import './MiniChannelCard.scss';

function MiniChannelCard({
  channel: {
    channelId, images, schedules, title: channelName,
  },
  epgIndex,
  intl: { formatMessage },
  progress,
}) {
  const dispatch = useDispatch();
  const { channelId: currentChannelId } = useParams();
  const currentSchedule = schedules?.[0] || {};
  const {
    blackout, endTime, qualifiers, startTime,
  } = currentSchedule?.live || {};
  const channelLogo = images?.[0]?.url;
  const description = getEpgEpisodeSubtitle(currentSchedule, formatMessage);
  const isCurrentlyLive = isBefore(new Date(startTime), new Date());
  const { rating } = currentSchedule;
  const [currentURL, setCurrentURL] = useState('');
  const profileRating = getProfileRating();
  const profileContentNoClassificationAllowed = getProfileContentNoClassificationAllowed();

  useEffect(() => {
    const currentPath = window.location.href;
    const playerIndex = currentPath.indexOf('/player');

    if (playerIndex !== -1) {
      const desiredPath = currentPath.slice(Math.max(0, playerIndex));
      setCurrentURL(desiredPath);
    }
  }, []);

  const { gtmUserData } = useGtmContext();

  const handleClick = () => {
    const { hash, eventType, dimensions } = getGtmEventConfig(
      currentSchedule.type,
      gtmDimensions,
      gtmDimensions.component_type,
      'watch',
    );

    const dataToSend = formatContentForGtm(
      currentSchedule,
      {
        listPosition: epgIndex + 1,
        contentPlayerType: playerType(currentSchedule.type),
        progress,
        tab: 'livelander',
      },
    );

    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataToSend,
    });

    const { redirect } = redirectToPlayer({
      channelId,
      fromEpg: true,
      fromEpgRedirectionIndex: epgIndex,
      history,
      replace: true,
      isLive: true,
    });

    if (featureFlagParentalControl() && ((contentRating(rating) > profileRating)
          || (contentRating(rating) === undefined
          && profileContentNoClassificationAllowed === false))) {
      return history.push(PATHS?.CONTENT_BLOCK, {
        redirectFrom: 'miniEPG',
        lastContent: currentURL,
        asset: currentSchedule,
      });
    }

    dispatch(updatePlaySource('Player-LIVEMiniEPGRecent'));
    return redirect();
  };

  const startTimeFormatted = format(new Date(startTime), 'HH:mm');
  const endTimeFormatted = format(new Date(endTime), 'HH:mm');
  const blackoutClass = blackout ? 'blackout' : '';
  const title = isCurrentlyLive ? getAssetNameWithQualifier(
    qualifiers,
    getEpgShowTitle(currentSchedule, formatMessage),
    formatMessage,
  ) : formatMessage({ id: 'common.dataNotAvailable', defaultMessage: 'Información no disponible' });
  const finalTitle = (title && (title.length > 60 ? `${title.slice(0, 50).trim()}...` : title)) || '';
  const finalDescription = (isCurrentlyLive && description && (
    description.length > 35
      ? `${description.slice(0, 30).trim()}...`
      : description)
  ) || '';

  return (
    <div className={`dtv-minichannelcard ${blackoutClass}`} onClick={blackout ? undefined : handleClick}>
      <div className={`dtv-channel-container ${blackoutClass}`}>
        <div className="dtv-card-header">
          <img src={channelLogo} alt={channelName} />
          {!blackout && (
            <p className="dtv-card-header-time">
              {`${startTimeFormatted} - ${endTimeFormatted}`}
              {' '}
            </p>
          )}
        </div>
        <div className="dtv-content">
          <div className="dtv-content-text">
            <div className="dtv-top-content">
              <span className="dtv-title">{finalTitle}</span>
            </div>
            <div className="dtv-content__rating-and-description">
              <AgeRating rating={rating} />
              {description && <p className="dtv-description">{finalDescription}</p>}
            </div>
            {blackout && (
              <p className="dtv-blackout-text">
                <FormattedMessage
                  id="common.contentUnavailable"
                  defaultMessage="Este contenido no se encuentra actualmente disponible en tu región"
                />
              </p>
            )}
          </div>
          {channelId === currentChannelId && (
            <span className="dtv-live-tag">
              {formatMessage({
                id: 'player.currentChannel',
                defaultMessage: 'Estás viendo',
              })}
            </span>
          )}
          {!blackout && isCurrentlyLive && (
            <div className="dtv-mini-epg-channel-live-progress-bar-container">
              <LiveProgressBar
                typeCard={1}
                startTime={startTime}
                endTime={endTime}
                channelId={channelId}
                className="dtv-progressbar-miniepg"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(MiniChannelCard);
