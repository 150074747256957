// External
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

// Components
import AgeRating from '../../ageRating/AgeRating';

// Styles
import './DetailsTab.scss';

// Other
import { PUBLIC_PDP_ASSET_TYPES } from '../../../utils';

function DetailsTab({ asset, intl: { formatMessage } }) {
  const {
    title = '',
    description = '',
    genres = [],
    rating = '',
    ratingWarning = [],
    ratingDescription = '',
  } = asset;

  const isLiveContent = asset?.type?.includes(PUBLIC_PDP_ASSET_TYPES.live.toUpperCase());
  const isMovie = asset?.type === PUBLIC_PDP_ASSET_TYPES.movieEN.toUpperCase();

  let content;

  if (isLiveContent) {
    content = formatMessage({ id: 'live.details.about', defaultMessage: 'Acerca de este contenido en vivo' });
  } else if (isMovie) {
    content = formatMessage({ id: 'movie.details.about', defaultMessage: 'Acerca de esta película' });
  } else {
    content = formatMessage({ id: 'serie.details.about', defaultMessage: 'Acerca de esta serie' });
  }

  return (
    <div className="dtv-details-tab">

      <section className="dtv-details-tab__asset-info">
        <h5 className="dtv-details-tab__about">
          {content}
        </h5>
        <h1 className="dtv-details-tab__title">{title}</h1>
        <p className="dtv-details-tab__description">{description}</p>
      </section>

      <hr />

      <section className="dtv-details-tab__genre-rating">

        <section className="dtv-details-tab__labels-section">
          <h4 className="dtv-details-tab__genre-label">
            {formatMessage({ id: 'serie.details.genre', defaultMessage: 'Género' })}
            :
          </h4>

          {rating && (
            <h4 className="dtv-details-tab__rating-label">
              {formatMessage({ id: 'serie.details.rating', defaultMessage: 'Clasificación' })}
              :
            </h4>
          )}
        </section>

        <section className="dtv-details-tab__genre-rating-values">
          <ul className="dtv-details-tab__genre-list">
            {genres.map((genre) => <li>{genre}</li>)}
          </ul>
          <div className="dtv-details-tab__rating-info">
            <AgeRating rating={rating} />
            <div>
              <p className="dtv-details-tab__rating-description">{ratingDescription}</p>
              <p className="dtv-details-tab__rating-warning">{ratingWarning?.join(', ')}</p>
            </div>
          </div>
        </section>

      </section>
    </div>
  );
}

DetailsTab.propTypes = {
  asset: PropTypes.shape().isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(DetailsTab);
