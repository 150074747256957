/* Other */
import { history } from '../../state/configureStore';
import { PATHS } from '../../utils';

export function parentalControlRedirect(asset) {
  if (history.location?.state?.redirectFrom === 'parentalBlock') {
    history.push(PATHS?.LIVE, {
      toastParentalBlock: true,
      asset,
    });
  } else {
    history.push(PATHS?.CONTENT_BLOCK, {
      asset,
    });
  }
}
