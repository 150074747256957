/* External */
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmptyL from 'lodash-es/isEmpty';
import templateL from 'lodash-es/template';
import queryString from 'query-string';
import { useLocation } from 'react-router';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';

/* Actions */
import { hideModal as hideModalAction, showModal as showModalAction } from '../state/modal/actions';
import { updateEpgRedirectionData } from '../state/epgRedirectionData/epgRedirectionDataSlice';

/* Assets */
import rokuLogo from '../assets/images/roku.svg';
import googlePlayIcon from '../assets/icons/google-play-icon.svg';
/* Other */
import {
  DEFAULT_VALUES, PATHS, PDP_ASSET_TYPES, COUNTRY_CODES, IS_BRAZIL,
} from './constants';
import { isRokuPayUser, isGoogleInAppBillingUser } from './users';
import { STATIC_URLS, getURLWithParams } from './urls/index';
import { SELF_CARE } from './urls/apiConfig';
import store, { history as navHistory } from '../state/configureStore';
import { APP_THEME } from './env';
import { getImage } from '../theme/images';

const GOOGLE_PLAY_SUBSCRIPTIONS_LINK = 'https://play.google.com/store/account/subscriptions';
const ROKU_SELFCARE_URL = 'https://my.roku.com';

function getLoginPage(country) {
  const linkLogin = import.meta.env.REACT_APP_AEM_LOGIN_PATH;
  const isLocal = !window.location.origin.includes('skymais') && !window.location.origin.includes('directvgo');

  if (!isLocal && country !== COUNTRY_CODES.brazil) {
    return `/${country}${linkLogin}-${country}`;
  }

  return `${linkLogin}`;
}

function getLoginUrl(currentUrl, country = DEFAULT_VALUES.COUNTRY) {
  if (!currentUrl) return (`${getLoginPage(country)}`);
  if (/^\/ac?tivar-tv/.test(currentUrl)) return currentUrl.replace(/(ac?tivar)-tv/, '$1');
  return `${getLoginPage(country)}?redirectTo=${encodeURIComponent(currentUrl)}`;
}

export function shouldRedirectToAEM(country) {
  if (import.meta.env.REACT_APP_AEM_LOGIN === 'true') {
    return (redirectAfterLoginURL) => {
      window.location.replace(getLoginUrl(redirectAfterLoginURL, country));
    };
  }
  return false;
}

export const redirectToPlayer = ({
  assetId,
  channelId,
  fromEpg,
  fromEpgFilterId,
  fromEpgOriginalChannelId,
  fromEpgRedirectionIndex,
  history,
  isLive,
  isSports,
  replace,
  type,
}) => {
  let url = '/player';

  if (isLive) {
    url = `${url}/live/${channelId}`;
  } else {
    let playerAssetType = 'vod';

    if (type === assetTypes.episode) playerAssetType = 'episode';
    if (type === assetTypes.movie) playerAssetType = 'movie';

    url = `${url}/${playerAssetType}/${assetId}`;
  }

  const epgRedirectionData = {
    channelId: fromEpgOriginalChannelId || channelId,
    fromEpg,
    fromEpgFilterId,
    fromEpgRedirectionIndex,
    hasBeenRedirectedToEpgFromPlayer: false,
    isSports,
    redirectedByUser: true,
  };

  store.dispatch(updateEpgRedirectionData(epgRedirectionData));

  const redirect = () => {
    window.wasInternalRedirectedToPlayer = true;
    if (replace) {
      history.replace(url);
    } else {
      history.push(url);
    }
  };

  return { url, redirect };
};

/**
 * @description Method to go back.
 * @method goBack
 */
export function goBack(history) {
  if (history.length > 1) {
    history.goBack();
  } else {
    history.push('/');
  }
}

/**
 * @description Method to go back from the player.
 * @method goBackFromPlayer
 */
export async function goBackFromPlayer({ history, epgRedirectionData }) {
  const { fromEpg, isSports } = epgRedirectionData || {};
  store.dispatch(updateEpgRedirectionData({
    ...epgRedirectionData,
    hasBeenRedirectedToEpgFromPlayer: true,
  }));

  if (fromEpg) {
    if (isSports) {
      history.push(PATHS.SPORTS);
    } else {
      history.push(PATHS.LIVE);
    }
  } else {
    goBack(history);
  }
}

/**
 * @description Method to go back from the error page.
 * @method goBackFromErrorPage
 */
export function goBackFromErrorPage() {
  window.location.replace('/');
}

/**
 * Method for building an external url (AEM)
 */

export function getExternalRedirectURL(
  url,
  country = DEFAULT_VALUES.COUNTRY,
  language = DEFAULT_VALUES.LANGUAGE,
) {
  return templateL(url)({
    country: country.toLowerCase(),
    language,
  });
}

/**
 * Method for building an external url (AEM) with redirect
 */
export function externalRedirect(
  url,
  country = DEFAULT_VALUES.COUNTRY,
  language = DEFAULT_VALUES.LANGUAGE,
  openOtherTab = true,
) {
  if (url) {
    const urlToRedirect = templateL(url)({
      country: country.toLowerCase(),
      language,
    });
    if (openOtherTab) {
      window.open(urlToRedirect, '_blank');
    } else {
      window.location = urlToRedirect;
    }
  }
}

/**
 * @description Checks if a given route param is valid
 * @method isValidRoute
 * @param {string} param
 * @param {array} validParamsArray
 */
export function isValidRoute(paramToTest, validParamsArray) {
  return validParamsArray.includes(paramToTest);
}

/**
 * @method getPrimaryPath
 * @description Method that takes a path and returns the short primary path with the first two parts
 * @param {string} path
 */
export function getPrimaryPath(path) {
  const pathArray = path.split('/');
  return `/${pathArray[1]}/${pathArray[2]}`;
}

/**
 * @method goBackFromSearch
 * @description Method that goes back to the previous lander from search
 */
export const goBackFromSearch = (history) => {
  const {
    location: { pathname },
  } = history;

  const splitPath = pathname.split('/');
  splitPath.pop();
  history.replace(splitPath.join('/'));
};

/**
 * @method getSelfCareURL
 * @description Returns the self care url for a given user
 * @param {object} user
 * @returns {string} url
 */
export function getSelfCareURL(user) {
  const { account: { msoProvider = '' } } = user;
  if (isRokuPayUser(user) || isGoogleInAppBillingUser({ msoProvider })) return '#';
  const { account: { country = DEFAULT_VALUES.COUNTRY } = {} } = user;

  const selfCareLink = APP_THEME === 'skymais' ? SELF_CARE.main : `/${country.toLowerCase() + SELF_CARE.main}`;

  return getURLWithParams(selfCareLink);
}

export const useGetSelfCareUrl = () => getSelfCareURL(useSelector(({ user }) => user));

export function getSelfCareLinkAction(args) {
  const {
    user,
    showModal,
    hideModal,
    intl: { formatMessage },
  } = args;

  if (isRokuPayUser(user)) {
    return () => showRokuModal(showModal, hideModal, formatMessage);
  }

  if (isGoogleInAppBillingUser({ msoProvider: user?.account?.msoProvider })) {
    return () => showGoogleInAppBillingModal(showModal, hideModal, formatMessage);
  }
  return () => {};
}

function showRokuModal(showModal, hideModal, formatMessage) {
  showModal({
    titleImageSrc: getImage('roku') || rokuLogo,
    message: formatMessage({
      id: 'roku.modal.message',
      defaultMessage: 'Para modificar tu suscripción,  favor de acceder a su cuenta a traves de Roku.',
    }),
    primaryText: formatMessage({
      id: 'roku.modal.primaryText',
      defaultMessage: 'Ir a roku',
    }),
    primaryAction: goToRokuSelfCare(hideModal),
    secondaryText: formatMessage({
      id: 'roku.modal.secondaryText',
      defaultMessage: 'Cancelar',
    }),
    secondaryAction: closeRokuModal(hideModal),
    onClose: closeRokuModal(hideModal),
  });
}

function showGoogleInAppBillingModal(showModal, hideModal, formatMessage) {
  showModal({
    titleImageSrc: googlePlayIcon,
    title: formatMessage({
      id: 'google.play.store.modal.title',
      defaultMessage: '¡Ups! Solo puede realizar cambios en su cuenta DGO a través de Google Play',
    }),
    message: formatMessage({
      id: 'google.play.store.modal.description',
      defaultMessage: 'Acceda a la cuenta usando este navegador o un dispositivo móvil con sistema operativo Android.',
    }),
    primaryText: formatMessage({
      id: 'google.play.store.modal.cta',
      defaultMessage: 'Ir a Google Play',
    }),
    primaryAction: goToGooglePlay(hideModal),
    secondaryText: formatMessage({
      id: 'google.play.store.modal.not.now',
      defaultMessage: 'Ahora no',
    }),
    secondaryAction: closeGoogleModal(hideModal),
    onClose: closeGoogleModal(hideModal),
  });
}

function goToRokuSelfCare(hideModal) {
  return () => {
    window.open(ROKU_SELFCARE_URL, '_self');
    closeRokuModal(hideModal);
  };
}

function goToGooglePlay(hideModal) {
  return () => {
    closeGoogleModal(hideModal)();
    window.open(GOOGLE_PLAY_SUBSCRIPTIONS_LINK, '_blank');
  };
}

function closeRokuModal(hideModal) {
  return () => hideModal();
}

function closeGoogleModal(hideModal) {
  return () => {
    navHistory.goBack();
    hideModal();
  };
}

export const useGetSelfCareLinkAction = (intl) => {
  const user = useSelector(({ user: selectedUser }) => selectedUser);
  const dispatch = useDispatch();
  const showModal = () => dispatch(showModalAction());
  const hideModal = () => dispatch(hideModalAction());

  return getSelfCareLinkAction({
    hideModal, intl, showModal, user,
  });
};

/**
 * @method getPartnerConfig
 * @description Returns the current partner config from the partners config array
 * @param {string} msoProvider
 * @param {array} partnersConfig
 * @returns {object} config
 */
export function getPartnerConfig(msoProvider, partnersConfig) {
  return partnersConfig.find(
    (partner) => partner.name.toLowerCase() === msoProvider.toLowerCase(),
  ) || {};
}

/**
 * @method getPartnerSelfCareURL
 * @description Returns an external self care url based on msoProvider
 * @param {string} msoProvider
 * @param {array} partnersConfig
 * @returns {string} url
 */
export function getPartnerSelfCareURL(msoProvider, partnersConfig) {
  const partnerConfig = getPartnerConfig(msoProvider, partnersConfig);
  const isPartnerConfigEmpty = isEmptyL(partnerConfig);
  if (isPartnerConfigEmpty) {
    console.error(`getPartnerSelfCareURL - AEM Partners Config is empty for ${msoProvider} user provider`);
  }
  return isPartnerConfigEmpty ? '' : getURLWithParams(partnerConfig.selfcareUrl);
}

/**
 * @method getPartnerChangePasswordURL
 * @description Returns an external change password url based on msoProvider
 * @param {string} msoProvider
 * @param {array} partnersConfig
 * @returns {string} url
 */
export function getPartnerChangePasswordURL(msoProvider, partnersConfig) {
  const partnerConfig = getPartnerConfig(msoProvider, partnersConfig);
  const isPartnerConfigEmpty = isEmptyL(partnerConfig);
  if (isPartnerConfigEmpty) {
    console.error(`getPartnerChangePasswordURL - AEM Partners Config is empty for ${msoProvider} user provider`);
  }
  return isPartnerConfigEmpty ? '' : getURLWithParams(partnerConfig.forgotPasswordUrl);
}

/**
 * @method buildExternalSelfCareURL
 * @description Returns an external self care url based on country, OTT or TVE users
 * @param {string} country
 * @param {boolean} isOttUser
 * @returns {string} url
 */
export function buildExternalSelfCareURL(country, isOttUser, isTVE) {
  if (country.toUpperCase() === 'CL' && isTVE) return getURLWithParams(STATIC_URLS.MI_DIRECTV.CL.selfCare);
  if (isTVE && country.toUpperCase() === 'BR') return getURLWithParams(STATIC_URLS.MI_DIRECTV.GENERIC.tveBR);
  if (isTVE) return getURLWithParams(STATIC_URLS.MI_DIRECTV.GENERIC.tve, { country });

  const ExternalSelfCareURL = isOttUser
    ? getURLWithParams(STATIC_URLS.MI_DIRECTV.GENERIC.ottSelfCare, { country })
    : getURLWithParams(STATIC_URLS.MI_DIRECTV.GENERIC.selfCare, { country });

  return ExternalSelfCareURL;
}

/**
 * @method redirectToPdp
 * @description Redirects to PDP route
 * @param {string} assetId
 * @param {string} resourceType
 * @param {string} startTime
 */
export function redirectToPdp({
  assetId, type, startTime, history, showId = '', isLive,
}) {
  if (isLive) {
    const startTimeDate = new Date(startTime);
    history.push(`/home/pdp/live/${assetId}?startTime=${startTimeDate.toISOString()}`);

    return;
  }

  let pdpType;

  switch (type) {
    case assetTypes.movie:
      pdpType = PDP_ASSET_TYPES.movies;
      break;
    case assetTypes.serie:
    case assetTypes.episode:
      pdpType = PDP_ASSET_TYPES.series;
      break;
    case assetTypes.live:
      pdpType = PDP_ASSET_TYPES.live;
      break;
    default:
      pdpType = PDP_ASSET_TYPES.vod;
      break;
  }

  const urlToRedirect = `/home/pdp/${pdpType}/${type === assetTypes.episode ? showId : assetId}`;

  history.push(urlToRedirect);
}

export function redirectToPublicPDP(country) {
  switch (country) {
    case COUNTRY_CODES.brazil: {
      const urlRedirect = '/cadastrar';
      window.location.replace(urlRedirect);
      break;
    }
    case COUNTRY_CODES.chile:
    case COUNTRY_CODES.colombia:
    case COUNTRY_CODES.ecuador:
    case COUNTRY_CODES.mexico:
    case COUNTRY_CODES.peru:
    case COUNTRY_CODES.uruguay:
    {
      const urlRedirect = `/${country}/registrar`;
      window.location.replace(urlRedirect);
      break;
    }
    case COUNTRY_CODES.argentina:
    {
      const urlRedirect = `/${country}/registrar`;
      window.location.replace(urlRedirect);
      break;
    }
    default:
      if (IS_BRAZIL) {
        window.location.replace('/cadastrar');
        break;
      }
      window.location.replace('/registrar');
  }
}

export default function useQuery() {
  const { search } = useLocation();
  return useMemo(() => queryString.parse(search), [search]);
}

export const environmentToRedirectSKY = {
  local: 'alpha',
  dev: 'dev.web',
  rt: 'alpha',
  preprod: 'beta',
  prod: 'www',
};

export const extraContentURL = {
  GRAN_HERMANO_ARGENTINA: 'http://quiero.directvgo.com/gh',
  GRAN_HERMANO_SSLA_WITHOUT_ARGENTINA: 'https://www.directvgo.com/redirect',
  OTT_DTH_TVE_ARGENTINA: 'https://www.directv.com.ar/midirectv/ingresar',
};
