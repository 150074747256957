/**
 * External
 */
import React from 'react';

/**
 * Styles
 */
import './Toast.scss';

function Toast({ message = '', title = '' }) {
  return (
    <div>
      {title && <strong>{title}</strong>}
      <div>
        <span>{message}</span>
      </div>
    </div>
  );
}

export default Toast;
