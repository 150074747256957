/* External */
import { createSlice } from '@reduxjs/toolkit';

const heartbeatSlice = createSlice({
  name: 'heartbeat',
  initialState: { heartbeatOnGoing: false },
  reducers: {
    startHeartbeat: (state) => {
      const newState = state;
      newState.heartbeatOnGoing = true;
    },
    stopHeartbeat: (state) => {
      const newState = state;
      newState.heartbeatOnGoing = false;
    },
  },
});

export const { startHeartbeat, stopHeartbeat } = heartbeatSlice.actions;

export default heartbeatSlice.reducer;
