/**
 * External
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

/**
 * Styles
 */
import './LiveIndicator.scss';

/**
 * Assets
 */
import liveIcon from '../../assets/icons/live-icon.png';

/* Others */
import { getImage } from '../../theme/images';

function LiveIndicator({ className, intl: { formatMessage } }) {
  return (
    <div className={classNames('dtv-live-indicator', className)}>
      <img className="dtv-live-indicator-icon" alt="" src={getImage('iconLive') || liveIcon} />
      <h4 className="dtv-live-indicator-text">{formatMessage({ id: 'menu.live', defaultMessage: 'En vivo' })}</h4>
    </div>
  );
}

LiveIndicator.propTypes = {
  className: PropTypes.string,
};

LiveIndicator.defaultProps = {
  className: '',
};

export default injectIntl(LiveIndicator);
