import React from 'react';
import PropTypes from 'prop-types';

import { ProgressBar as CommonProgressBar } from '@dtvgo/web-player-ui';
import { useSeeking } from '@dtvgo/player-context';

export default function ProgressBar({
  className,
  progress,
  duration,
  seekable,
  onSeek,
  children,
}) {
  return (
    <CommonProgressBar
      className={className}
      progress={progress}
      duration={duration}
      seekable={seekable}
      seeking={useSeeking().isSeeking}
      onSeek={onSeek}
    >
      <CommonProgressBar.ProgressFill />
      <CommonProgressBar.ProgressDot />
      {children}
      <CommonProgressBar.MouseOver />
    </CommonProgressBar>
  );
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number,
  duration: PropTypes.number,
  seekable: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
  }),
  onSeek: PropTypes.func,
  children: PropTypes.node,
};

ProgressBar.defaultProps = {
  className: '',
  progress: 0,
  duration: 0,
  seekable: undefined,
  onSeek: undefined,
  children: null,
};
