/* global UA */

/* External */
import React, { useEffect, useRef } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import getL from 'lodash-es/get';
import LoadingBar from 'react-redux-loading-bar';
import { datadogRum } from '@datadog/browser-rum';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';

/* Components */
import { rtkQueryTags } from '@dtvgo/rtk-query-api';
import { useGtmContext } from '../components/GtmContext';
import Landing from '../landing/Landing';
import Home from '../home/Home';
import PrivateRoute from '../privateRoute/PrivateRoute';
import IntlProviderWrapper from '../IntlProviderWrapper';
import ToastNotification from '../components/notification/toast/ToastNotification';
import BarNotification from '../components/notification/barNotification/BarNotification';
import SlowConnection from './SlowConnection';
import ErrorPage from '../errorPage/ErrorPage';
import TermsAndConditions from '../termsAndConditions/TermsAndConditions';
import TermsOfService from '../termsOfService/TermsOfService';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';
import ExpiredSession from '../pages/expiredSession/ExpiredSession';
import InsertPinTV from '../pinLogin/InsertPin';
import ResultInsertPin from '../pinLogin/ResultInsertPin';
import ChromecastConnectionBar from '../components/chromecastConnectionBar/ChromecastConnectionBar';
import SearchOverlay from '../searchOverlay/SearchOverlay';
import Modal from '../components/modal/Modal';
import LivePlayer from '../player/vrio/VrioLivePlayer';
import VodPlayer from '../player/vrio/VrioVodPlayer';
import UserProfile from '../pagesProfile/managementProfile/UserProfile';
import UserProfileManagement from '../pagesProfile/managementProfile/UserProfileManagement';
import UserProfileEdit from '../pagesProfile/managementProfile/UserProfileEdit';
import UserProfileCreate from '../pagesProfile/managementProfile/UserProfileCreate';
import FifaVideoPlayer from '../components/FifaVideoPlayer/FifaVideoPlayer';
import Onboarding from '../pagesProfile/onboarding/Onboarding';
import ProfilesErrorPage from '../pagesProfile/profilesErrorPage/ProfilesErrorPage';
import ProfileContentBlock from '../pagesProfile/profileContentBlock/ProfileContentBlock';
import PublicPdp from '../pdp/PublicPdp';
import AuthError from '../user/AuthError';

/* Ott-common */

/* Styles */
import './App.scss';
import '../theme/colors';
import '../theme/fonts';

/* Actions */
import { setAEMConfiguration } from '../state/global/globalSlice';
import { setProfiles } from '../state/profile/reducer';

/* Other */
import { Logout } from '../user/Logout';
import {
  DEFAULT_VALUES,
  IS_BRAZIL,
  isMobile,
  isUserLoggedIn,
  LOGOUT_URL,
  PATHS,
  PIN_LOGIN_URLS_BR,
  PIN_LOGIN_URLS,
  setNuanceDataVariable,
  TERMS_AND_CONDITIONS_URL,
  TERMS_OF_SERVICE_URL,
  NOTIFICATIONS_PERMISSION_LOCAL_STORAGE_NAME,
  TIME_UNITS_AS_MILLISECONDS,
  AEM_PLATFORM,
  getAuthTokensData,
  ROUTES,
  gtmConstantData,
  isBrazil,
} from '../utils';
import { backendService } from '../services';
import { PROFILES_STORAGE } from '../utils/constants';
import { api } from '../state/configureStore';
import { ChromecastProvider } from '../components/chromecastConnectionBar';
import { MqttProvider } from '../components/mqtt';

function App({
  path, country, user, location,
}) {
  const dispatch = useDispatch();
  const pinLoginUrls = IS_BRAZIL ? { ...PIN_LOGIN_URLS_BR } : { ...PIN_LOGIN_URLS };
  const { setGtmUserData } = useGtmContext();
  const { idToken } = getAuthTokensData();
  const {
    data: AEMConfiguration,
    isLoading: isAEMConfigurationLoading,
    isError: isAEMConfigurationError,
  } = api.useGetAEMConfigurationQuery(AEM_PLATFORM);
  const aemRequestInterval = useRef();
  const profiles = useSelector((state) => state.profile.profiles);

  useEffect(() => {
    const isMobileUser = isMobile().any();
    const arrayPage = ['activar-tv', 'ativar-tv', '/ativar', '/activar', 'preferences', '404', 'mobile-login', '/logout'];
    const containUrl = arrayPage.filter(
      (obj) => path?.includes(obj) || location?.pathname?.includes(obj),
    )?.length || false;
    if (isMobileUser && !containUrl) {
      window.location.href = `${window.location.origin || 'https://www.directvgo.com'}${(country && !isBrazil) ? `/${country}` : ''}/mobile-login`;
    }
  }, [country, path, location]);

  useEffect(() => {
    async function execProfiles() {
      const profilesApi = await backendService.customer.v1.getProfiles();
      if (profilesApi?.status === 200 && profilesApi?.data?.length) {
        localStorage.setItem(PROFILES_STORAGE.profiles, JSON.stringify(profilesApi?.data));
        dispatch(setProfiles(profilesApi.data));
      }
    }
    if (isUserLoggedIn() && !profiles) {
      execProfiles();
    }
  }, [user, profiles, dispatch]);

  useEffect(() => {
    aemRequestInterval.current = setInterval(
      () => dispatch(api.util.invalidateTags([rtkQueryTags.aem])),
      TIME_UNITS_AS_MILLISECONDS.HOUR,
    );
    return () => clearInterval(aemRequestInterval.current);
  }, [dispatch]);

  useEffect(() => {
    if (!isAEMConfigurationLoading && !isAEMConfigurationError && AEMConfiguration) {
      dispatch(setAEMConfiguration(AEMConfiguration));
    }
  }, [dispatch, AEMConfiguration, isAEMConfigurationLoading, isAEMConfigurationError]);

  useEffect(() => {
    setNuanceDataVariable(country);

    if (isUserLoggedIn() && idToken) {
      const { vrioCustomerId } = jwtDecode(idToken);
      const notificationsPermission = JSON.parse(
        localStorage.getItem(NOTIFICATIONS_PERMISSION_LOCAL_STORAGE_NAME),
      ) || {};
      const { allow: areNotificationsAllowed } = notificationsPermission[vrioCustomerId] || {};

      if (areNotificationsAllowed) {
        (async () => {
          const sdk = await UA;
          await sdk.register();
          sdk.channel.namedUser.set(vrioCustomerId);
        })();
      }
    }
  }, [country, user, idToken]);

  useEffect(() => {
    if (isUserLoggedIn()) {
      const {
        account: {
          uid,
          profile: { firstName, lastName, email },
        },
      } = user;
      datadogRum.setUser({
        id: uid,
        name: `${firstName} ${lastName}`.trim(),
        email,
      });
    } else {
      datadogRum.clearUser();
    }
  }, [user]);

  useEffect(() => {
    if (idToken) {
      setGtmUserData(gtmConstantData(idToken));
    }
  }, [idToken, setGtmUserData]);

  return (
    <IntlProviderWrapper>
      <ChromecastProvider>
        <MqttProvider>
          <div id="dtvApp" className={`${isUserLoggedIn() ? '' : 'dtv-public'}`}>
            {isUserLoggedIn() && <ChromecastConnectionBar />}
            {(!isAEMConfigurationLoading && (
            <>
              <div id="dtv-div-notifications" className="dtv-div-notifications" />
              <ScrollToTop />
              <SlowConnection />
              <LoadingBar className="dtv-loading-bar" />
              <ToastNotification />
              <BarNotification />
              <Modal />
              <Route exact component={SearchOverlay} path={`(.*)/${PATHS.SEARCH}`} />
              <Switch>
                <PrivateRoute exact path={pinLoginUrls.main} component={InsertPinTV} />
                <PrivateRoute
                  exact
                  path={pinLoginUrls.mainPlusResult}
                  component={ResultInsertPin}
                />
                <Redirect exact from={pinLoginUrls.basic} to={pinLoginUrls.main} />
                <Redirect exact from={`/:country${pinLoginUrls.basic}`} to={pinLoginUrls.main} />
                <Redirect exact from={`/:country${pinLoginUrls.main}`} to={pinLoginUrls.main} />
                <Route exact path={LOGOUT_URL} component={Logout} />
                <Route exact path="/login" render={() => <Landing loginOpened />} />
                <Route exact path="/expiredSession" component={ExpiredSession} />
                <Route exact path="/expiredSession/:redirectTo" component={ExpiredSession} />
                <PrivateRoute
                  exact
                  from={PATHS.HOME}
                  component={Home}
                />
                <PrivateRoute path="/home" component={Home} />
                <PrivateRoute exact path="/player/live/:channelId" component={LivePlayer} />
                <PrivateRoute exact path={`${ROUTES.hbsPlayer.path}:channelId`} component={FifaVideoPlayer} />
                <PrivateRoute exact path="/player/:assetType/:assetId" component={VodPlayer} />
                <PrivateRoute exact path="/user/profile" component={UserProfile} />
                <PrivateRoute exact path="/user/profile/management" component={UserProfileManagement} />
                <PrivateRoute exact path="/user/edit/profile/:id" component={UserProfileEdit} />
                <PrivateRoute exact path="/user/create/profile" component={UserProfileCreate} />
                <PrivateRoute exact path="/user/onboarding" component={Onboarding} />
                <PrivateRoute exact path="/user/page/error" component={ProfilesErrorPage} />
                <PrivateRoute exact path="/user/profile/content/block" component={ProfileContentBlock} />
                <Route exact path="/" component={Landing} />
                <Route exact path={`/${TERMS_AND_CONDITIONS_URL}`} component={TermsAndConditions} />
                <Route exact path={`/${TERMS_OF_SERVICE_URL}/:subpage`} component={TermsOfService} />
                <Route exact path={`/:country/${TERMS_OF_SERVICE_URL}/:subpage`} component={TermsOfService} />
                <Route exact path="/:country(br|co|cl|pe|ec|uy|mx|ar)?/:assetType(pelicula|filme|movie|serie|deportes|live)/:assetTitle/:assetId" component={PublicPdp} />
                <Route path="/404" component={ErrorPage} />
                <Route path="/user/error" component={AuthError} />
                <Route component={ErrorPage} />
              </Switch>
            </>
            )
            )}
          </div>
        </MqttProvider>
      </ChromecastProvider>
    </IntlProviderWrapper>
  );
}

function mapStateToProps({ user, router }) {
  const country = getL(user, 'account.country', DEFAULT_VALUES.COUNTRY).toLowerCase();
  const location = router?.location;

  return {
    user,
    country,
    path: location ? location?.pathname : '',
    urlSearchParams: location ? location?.search : {},
  };
}

App.propTypes = {
  path: PropTypes.string,
  country: PropTypes.string,
  user: PropTypes.shape({}),
  location: PropTypes.shape({}),
};

App.defaultProps = {
  path: '',
  country: '',
  user: {},
  location: {},
};

export default withRouter(connect(mapStateToProps, {})(App));
