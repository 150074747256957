/**
 * External
 */
import axios from 'axios';

/**
 * Other
 */
import { IS_BRAZIL } from '../../../../../utils/constants';
import { getURLWithParams } from '../../../../../utils/urls';
import { X_API_KEY_TOOLBOX, TOOLBOX_URL } from '../../../../../utils/env';
import { getDeviceId } from '../../../../../utils/localStorageState';

export const changePasswordApi = (userName, iso2Code, oldPassword, newPassword, frToken) => (
  axios.post(
    getURLWithParams(`${TOOLBOX_URL}/maverick/legacy/v2/patchUser`),
    {
      userName: userName.toLowerCase(),
      iso2Code,
      oldPassword,
      patchOperations: [
        {
          operation: 'replace',
          field: 'password',
          value: newPassword,
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${frToken}`,
        'content-type': 'application/json',
        realm: IS_BRAZIL ? 'br' : 'ssla',
        'x-api-key': X_API_KEY_TOOLBOX,
        'x-device-id': getDeviceId(),
      },
    },
  )
);
