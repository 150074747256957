/**
 * External
 */
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import './LiveTag.scss';

/**
 * Assets
 */
import liveIcon from '@/assets/theme/icons/live-icon.png';

/**
 * Other
 */
import { formatLiveStartsAt, isPastPresentOrFuture, LIVE_PROGRAM_TYPES } from '../../utils';
import { getImage } from '../../theme/images';

/**
 * Tag for live programs or stations
 */
class LiveTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pastPresentOrFuture: '',
      timeLeft: '',
    };

    this.setPastPresentOrFutureState = this.setPastPresentOrFutureState.bind(this);
    this.calculateTimeLeft = this.calculateTimeLeft.bind(this);
    this.getDisplayText = this.getDisplayText.bind(this);
    this.renderLiveIcon = this.renderLiveIcon.bind(this);
  }

  /**
   * @method componentDidMount
   * @description React lifecycle method.
   *              Used to set a timer to update timeLeft value for future programs.
   */
  componentDidMount() {
    if (this.setPastPresentOrFutureState() === LIVE_PROGRAM_TYPES.FUTURE) {
      // get a value to render immediately
      this.calculateTimeLeft();

      this.timer = setInterval(() => this.calculateTimeLeft(), 6000);
    }
  }

  /**
   * @method componentWillUnmount
   * @description React lifecycle method.
   *              Used to clear the timer if it was set.
   */
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  /**
   * @method setPastPresentOrFutureState
   * @description Method that stores in state the timing of a program
   */
  setPastPresentOrFutureState() {
    const { endTime, startTime } = this.props;

    if (endTime && startTime) {
      const pastPresentOrFuture = isPastPresentOrFuture(startTime, endTime);
      this.setState({
        pastPresentOrFuture,
      });
      return pastPresentOrFuture;
    }

    return '';
  }

  /**
   * @method getDisplayText
   * @description Method that returns the text to be displayed
   */
  getDisplayText() {
    const {
      intl: { formatMessage },
    } = this.props;
    const { pastPresentOrFuture, timeLeft } = this.state;

    if (pastPresentOrFuture === LIVE_PROGRAM_TYPES.FUTURE && timeLeft) {
      return timeLeft;
    }

    if (pastPresentOrFuture === LIVE_PROGRAM_TYPES.PRESENT) {
      return formatMessage({ id: 'menu.live', defaultMessage: 'En vivo' });
    }

    return '';
  }

  /**
   * @method calculateTimeLeft
   * @description Method that calculates timeLeft for future programs
   */
  calculateTimeLeft() {
    const {
      intl: { formatMessage, formatDate },
      startTime,
    } = this.props;

    // if the program starts to air the value in state is set to present and the timer is cleared
    if (this.setPastPresentOrFutureState() === LIVE_PROGRAM_TYPES.FUTURE) {
      this.setState({
        timeLeft: formatLiveStartsAt(new Date(startTime), formatMessage, formatDate),
      });
    } else {
      clearInterval(this.timer);
    }
  }

  /**
   * @method renderLiveIcon
   * @description Method that returns the live icon image is the program is live
   */
  renderLiveIcon() {
    const { pastPresentOrFuture } = this.state;
    const { classIcon } = this.props;
    return pastPresentOrFuture === LIVE_PROGRAM_TYPES.PRESENT ? (
      <img alt="Live" className={`live-tag-icon${classIcon ? ` ${classIcon}` : ''}`} src={getImage('iconLive') || liveIcon} />
    ) : (
      ''
    );
  }

  render() {
    const { classText } = this.props;

    return (
      <div className="live-tag">
        {this.renderLiveIcon()}
        <span className={`live-tag-text${classText ? ` ${classText}` : ''}`}>{this.getDisplayText()}</span>
      </div>
    );
  }
}

LiveTag.defaultProps = {
  classIcon: '',
  classText: '',
  endTime: '',
  startTime: '',
};

LiveTag.propTypes = {
  classIcon: PropTypes.string,
  classText: PropTypes.string,
  endTime: PropTypes.string,
  intl: intlShape.isRequired,
  startTime: PropTypes.string,
};

export default injectIntl(LiveTag);
