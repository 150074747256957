/* External */
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  setHours,
  setMinutes,
  setSeconds,
  subDays,
  subMinutes,
} from 'date-fns';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

/* Ott-common */
import InfiniteScrollContainer from '@dtvgo/infinite-scroll-container';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Components */
import ComboBoxFilter from '../components/comboBoxFilter/ComboBoxFilter';
import EpgChannels from './epgChannels/EpgChannels';
import EpgErrorMessage from './epgErrorMessage/EpgErrorMessage';
import EpgLoader from './epgLoader/EpgLoader';
import EpgSkeleton from './epgSkeleton/EpgSkeleton';
import EpgSlider from './epgSlider/EpgSlider';
import { useGtmContext } from '../components/GtmContext';
import { gtmDimensions } from '../components/GtmContext/dimensions/epg';
import EpgFilter from '../components/epgFilter/EpgFilter';
import Banner from '../components/banner/Banner';
import { useGetChannels } from '../hooks/useGetChannels';
import { useGetSchedules } from '../hooks/useGetSchedules';

/* Styles */
import './Epg.scss';

/* Other */
import {
  AEM_PLATFORM,
  EPG,
  formatContentForGtm,
  getEpgType,
  PATHS,
  IS_BRAZIL,
  getEpgFilters,
} from '../utils';
import { api } from '../state/configureStore';
import { IS_CURABLE_BANNER_ACTIVE } from '../utils/env';

const getDayFormatted = (day) => {
  const options = { weekday: 'long' };
  const location = IS_BRAZIL ? 'pt-BR' : 'es-ES';
  const dayOfWeek = day.toLocaleDateString(location, options);
  const dayOfWeekWithoutSufix = dayOfWeek.replace(/-feira$/, '');
  const formattedDay = `${dayOfWeekWithoutSufix} • ${format(day, 'dd/MM')}`;

  return formattedDay;
};

function Epg({
  filter, intl: { formatMessage }, isSports,
}) {
  // Infinite scroll
  const [shouldUseInfiniteScroll, setShouldUseInfiniteScroll] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const location = useLocation();
  const [filtersEpg, setFilterEpg] = useState([]);

  useEffect(() => {
    if (shouldUseInfiniteScroll) return () => {};

    const handleScroll = () => {
      setHasScrolled(true);
    };

    if (hasScrolled) {
      setShouldUseInfiniteScroll(true);
      window.removeEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shouldUseInfiniteScroll, hasScrolled]);

  // Slider and currentTime calculations
  const todayRef = useRef(new Date());
  const beforeYesterday = subDays(todayRef.current, 2);
  const yesterday = subDays(todayRef.current, 1);
  const tomorrow = addDays(todayRef.current, 1);
  const afterTomorrow = addDays(todayRef.current, 2);
  const leftLimit = setSeconds(setMinutes(setHours(beforeYesterday, 0), 0), 0);
  const rightLimit = setSeconds(setMinutes(setHours(addDays(todayRef.current, 3), 2), 0), 0);
  const minutes = todayRef.current.getMinutes() >= 30 ? '30' : '00';
  const startTimeRef = useRef(setSeconds(setMinutes(todayRef.current, minutes), 0));
  const endTimeRef = useRef(addMinutes(startTimeRef.current, EPG.MINUTES_TO_LOAD_INIT));

  // Recover init position
  const initialStartTimeRef = useRef(startTimeRef.current);
  const initialEndTimeRef = useRef(endTimeRef.current);

  // Schedules requests
  const schedulesStart = useRef(startTimeRef.current);
  const schedulesEnd = useRef(endTimeRef.current);
  const { data: AEMConfiguration } = api.useGetAEMConfigurationQuery(AEM_PLATFORM);
  const mostWatchedChannelsBatchSize = AEMConfiguration?.content?.epg?.numberOfFavoriteChannels;

  const { gtmUserData } = useGtmContext();
  const epgType = getEpgType(isSports);

  const selectedFilter = filtersEpg?.find((item) => item.status);

  const {
    fetchChannels, isError, channelsWithoutSchedules, channels, completeChannelsList,
  } = useGetChannels({
    channelsBatchSize: EPG.CHANNELS_TO_LOAD,
    endTime: endTimeRef.current,
    filter: filter || selectedFilter?.value,
    mostWatchedChannelsBatchSize,
    startTime: startTimeRef.current,
    schedulesPerRequest: EPG.SCHEDULES_PER_REQUEST,
    selectedFilter: selectedFilter?.value,
  });

  const filterList = useMemo(
    () => getEpgFilters(completeChannelsList, formatMessage),
    [completeChannelsList, formatMessage],
  );

  const {
    fetchSchedules,
  } = useGetSchedules({
    schedulesPerRequest: EPG.SCHEDULES_PER_REQUEST,
    channelsWithoutSchedules,
  });

  useEffect(() => {
    fetchSchedules({
      start: startTimeRef.current,
      end: endTimeRef.current,
      click: EPG.RIGHT_CLICK,
      reset: false,
    });
  }, [fetchSchedules]);

  const hasChannelsWithSchedules = channels.some(({ schedules }) => schedules);

  // force to re-fetch channels if brings the channels list but without schedules
  useEffect(() => {
    if ((channels.length > 0 && !hasChannelsWithSchedules)) {
      fetchChannels({
        end: endTimeRef.current,
        start: startTimeRef.current,
      });
    }
  }, [channels, fetchChannels, hasChannelsWithSchedules]);

  // currentTime logic
  const currentTime = useRef(new Date());
  const [currentTimePosition, setCurrentTimePosition] = useState(
    differenceInMinutes(currentTime.current, startTimeRef.current) * EPG.MINUTES_TO_PIXELS,
  );
  const timer = useRef(
    setTimeout(() => {
      currentTime.current = new Date();
      setCurrentTimePosition(currentTimePosition + EPG.MINUTES_TO_PIXELS);
    }, (60 - currentTime.current.getSeconds()) * 1000),
  );

  useEffect(() => () => clearTimeout(timer.current));

  const [currentTimeOffset, setCurrentTimeOffset] = useState(0);
  const epgSliderRef = useRef(null);
  const hideCurrentTimeLeft = isBefore(currentTime.current, startTimeRef.current);
  const hideCurrentTimeRight = epgSliderRef.current
    && currentTimePosition + currentTimeOffset > epgSliderRef.current.clientWidth * 0.9;

  // Horizontal movement
  const [channelLinesOffset, setChannelLinesOffset] = useState(0);

  // Days filter
  const daysFilterOptions = [
    {
      text: getDayFormatted(beforeYesterday),
      value: beforeYesterday,
    },
    {
      text: getDayFormatted(yesterday),
      value: yesterday,
    },
    {
      text: getDayFormatted(todayRef.current),
      value: todayRef.current,
    },
    {
      text: getDayFormatted(tomorrow),
      value: tomorrow,
    },
    {
      text: getDayFormatted(afterTomorrow),
      value: afterTomorrow,
    },
  ];

  const [daySelected, setDaySelected] = useState({
    ...daysFilterOptions[2],
    text: `${formatMessage({ id: 'common.today', defaultMessage: 'Hoy' })}, ${daysFilterOptions[2].text}`,
  });

  // Fetching channels when you change the dateSelected
  useEffect(() => {
    fetchChannels({
      end: endTimeRef.current,
      start: startTimeRef.current,
      reset: true,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected]);

  const onDaysFilterChange = (text, value, index) => {
    const { hash, eventType, dimensions } = gtmDimensions[`epg_${epgType}`].date_filter;

    const dataToSend = formatContentForGtm(
      {},
      {
        listPosition: index + 1,
        tab: `${epgType}Lander`,
      },
    );

    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: dataToSend,
    });

    let textToShow = text;
    switch (value) {
      case yesterday:
        textToShow = `${formatMessage({ id: 'common.yesterday', defaultMessage: 'Ayer' })}, ${text}`;
        break;
      case todayRef.current:
        textToShow = `${formatMessage({ id: 'common.today', defaultMessage: 'Hoy' })}, ${text}`;
        break;
      case tomorrow:
        textToShow = `${formatMessage({ id: 'common.tomorrow', defaultMessage: 'Mañana' })}, ${text}`;
        break;
      default:
        break;
    }
    setDaySelected({ text: textToShow, value });
    const newMinutes = value.getMinutes() >= 30 ? '30' : '00';
    const newStartTime = setSeconds(setMinutes(value, newMinutes), 0);
    const newEndTime = addMinutes(newStartTime, EPG.MINUTES_TO_LOAD_INIT);
    const minutesToMove = differenceInMinutes(startTimeRef.current, newStartTime);

    startTimeRef.current = newStartTime;
    endTimeRef.current = newEndTime;
    schedulesStart.current = newStartTime;
    schedulesEnd.current = newEndTime;

    setChannelLinesOffset(0);
    setCurrentTimeOffset(currentTimeOffset + minutesToMove * EPG.MINUTES_TO_PIXELS);
  };

  const updateDaySelected = (epgStart) => {
    const epgStartDay = epgStart.getDay();
    const daysFiltersDay = daySelected.value.getDay();

    if (epgStartDay !== daysFiltersDay) {
      const newDaySelected = daysFilterOptions.find((el) => el.value.getDay() === epgStartDay);
      setDaySelected(newDaySelected);
    }
  };

  const handleCurrentTimeClick = () => {
    if (startTimeRef.current !== initialStartTimeRef.current) {
      startTimeRef.current = initialStartTimeRef.current;
      endTimeRef.current = initialEndTimeRef.current;
      schedulesStart.current = initialStartTimeRef.current;
      schedulesEnd.current = initialEndTimeRef.current;
      fetchSchedules({
        start: initialStartTimeRef.current,
        end: initialEndTimeRef.current,
        reset: true,
      });
      setChannelLinesOffset(0);
      setCurrentTimeOffset(0);
      updateDaySelected(startTimeRef.current);
    }
  };

  const handleLeftClick = () => {
    const newStartTime = subMinutes(startTimeRef.current, EPG.MINUTES_TO_MOVE);

    if (!isBefore(newStartTime, leftLimit)) {
      startTimeRef.current = newStartTime;
      endTimeRef.current = subMinutes(endTimeRef.current, EPG.MINUTES_TO_MOVE);

      if (isBefore(startTimeRef.current, schedulesStart.current)) {
        const newSchedulesStart = subMinutes(schedulesStart.current, EPG.MINUTES_TO_LOAD_LEFT);
        fetchSchedules({
          start: newSchedulesStart,
          end: schedulesStart.current,
          click: EPG.LEFT_CLICK,
          reset: false,
        });
        schedulesStart.current = newSchedulesStart;
      } else {
        setChannelLinesOffset(channelLinesOffset + EPG.MINUTES_TO_MOVE * EPG.MINUTES_TO_PIXELS);
      }

      setCurrentTimeOffset(currentTimeOffset + EPG.MINUTES_TO_MOVE * EPG.MINUTES_TO_PIXELS);
      updateDaySelected(startTimeRef.current);
    }
  };

  const handleRightClick = () => {
    const newEndTime = addMinutes(endTimeRef.current, EPG.MINUTES_TO_MOVE);

    if (!isAfter(newEndTime, rightLimit)) {
      startTimeRef.current = addMinutes(startTimeRef.current, EPG.MINUTES_TO_MOVE);
      endTimeRef.current = newEndTime;

      if (isBefore(schedulesEnd.current, endTimeRef.current)) {
        const newSchedulesEnd = addMinutes(schedulesEnd.current, EPG.MINUTES_TO_LOAD_RIGHT);
        fetchSchedules({
          start: schedulesEnd.current,
          end: newSchedulesEnd,
          click: EPG.RIGHT_CLICK,
          reset: false,
        });
        schedulesEnd.current = newSchedulesEnd;
      }

      setChannelLinesOffset(channelLinesOffset - EPG.MINUTES_TO_MOVE * EPG.MINUTES_TO_PIXELS);
      setCurrentTimeOffset(currentTimeOffset - EPG.MINUTES_TO_MOVE * EPG.MINUTES_TO_PIXELS);
      updateDaySelected(startTimeRef.current);
    }
  };

  const errorOrSkeleton = isError ? (
    <EpgErrorMessage
      message={formatMessage({
        id: 'epg.emptyChannels',
        defaultMessage: '¡Ups! Ocurrió un error al cargar la programación',
      })}
    />
  ) : (
    <EpgSkeleton />
  );

  function handleFilterClick(id) {
    const updatedFilters = filtersEpg.map((item) => ({
      ...item,
      status: item.value === id,
    }));
    setFilterEpg(updatedFilters);
  }

  useEffect(() => {
    setFilterEpg(filterList);
  }, [filterList]);

  return (
    <div className="dtv-epg">
      <div className="dtv-epg__filters">
        <ComboBoxFilter
          className="epg-variation"
          onChange={onDaysFilterChange}
          options={daysFilterOptions}
          defaultValue={daySelected}
          selectedValue={daySelected}
          width="260"
        />
        { location.pathname === PATHS.LIVE && (
          filtersEpg.map((filterEpg) => (
            <EpgFilter
              key={filterEpg.value}
              title={filterEpg.title ? formatMessage({ id: filterEpg.title }) : filterEpg.value}
              status={filterEpg.status}
              onClick={() => handleFilterClick(filterEpg.value)}
            />
          )))}
      </div>
      <EpgSlider
        className="dtv-epg__slider"
        currentTime={currentTime.current}
        currentTimeDisplacement={EPG.CURRENT_TIME_MARGIN + currentTimePosition + currentTimeOffset}
        endTime={endTimeRef.current}
        hideCurrentTimeLeft={hideCurrentTimeLeft}
        hideCurrentTimeRight={hideCurrentTimeRight}
        onCurrentTimeClick={handleCurrentTimeClick}
        onLeftClick={handleLeftClick}
        onRightClick={handleRightClick}
        showCurrentTime={hasChannelsWithSchedules}
        startTime={startTimeRef.current}
        ref={epgSliderRef}
      />
      {hasChannelsWithSchedules ? (
        <>
          {!hideCurrentTimeLeft && !hideCurrentTimeRight && (
            <div
              className="dtv-epg__current-time-line"
              style={{ left: `${EPG.CURRENT_TIME_LINE_MARGIN + currentTimePosition + currentTimeOffset}px` }}
            />
          )}
          <InfiniteScrollContainer
            onBottomReached={() => {
              fetchChannels({
                start: schedulesStart.current,
                end: schedulesEnd.current,
                direction: EPG.SCHEDULES_FETCHING_DIRECTIONS.DOWN,
                shouldLoadMostWatched: false,
              });
            }}
            onTopReached={() => {
              fetchChannels({
                start: schedulesStart.current,
                end: schedulesEnd.current,
                direction: EPG.SCHEDULES_FETCHING_DIRECTIONS.UP,
                shouldLoadMostWatched: false,
              });
            }}
            CustomBottomObservableElement={<EpgLoader />}
            CustomTopObservableElement={<EpgSkeleton />}
            shouldObserveBottom={shouldUseInfiniteScroll ? !channels.at(-1)?.schedules : false}
            shouldObserveTop={shouldUseInfiniteScroll ? !channels[0]?.schedules : false}
          >
            <EpgChannels
              channelLinesOffset={channelLinesOffset}
              channels={channels}
              isSports={isSports}
              onEpgPositionRecovery={() => setShouldUseInfiniteScroll(false)}
              schedulesEnd={schedulesEnd.current}
              schedulesStart={schedulesStart.current}
            />
            {!IS_CURABLE_BANNER_ACTIVE && <Banner />}
          </InfiniteScrollContainer>
        </>
      ) : errorOrSkeleton}
    </div>
  );
}

Epg.propTypes = {
  isSports: PropTypes.bool,
  filter: PropTypes.string,
};

Epg.defaultProps = {
  isSports: undefined,
  filter: '',
};

export default injectIntl(Epg);
