/* eslint "react/require-default-props": [
  "error", { "functions": "defaultArguments", "forbidDefaultForRequired": true }
] */
/* External */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cloneDeepL from 'lodash-es/cloneDeep';

/* Assets */
import genericCard2X3 from '@/assets/theme/images/2x3-GenericCard.png';

/* Components */
import Card2X3 from '../card2X3/Card2X3';

/* Store */
import { api } from '../../state/configureStore';

/* Utils */
import { CAROUSEL_TYPES, getWebAction } from '../../utils';
import { getImage } from '../../theme/images';

function VodCollection({ className = '', collection }) {
  const action = useMemo(() => getWebAction(collection), [collection]);

  const { data: collectionCarousel } = api.useGetCarouselQuery(
    {
      source: action?.text,
      sourceCarouselId: action?.target,
      type: CAROUSEL_TYPES.PORTRAIT,
    },
    { skip: !action },
  );

  return (
    <div className={className}>
      {useMemo(() => {
        let assetWithPlaySource;
        const cards = collectionCarousel?.contents?.reduce((assetsWithPlaySource, asset) => {
          assetWithPlaySource = asset;
          const clonedAssetWithPlaySource = cloneDeepL(assetWithPlaySource);
          clonedAssetWithPlaySource.playSource = `Collection-${collection?.title}`;
          assetsWithPlaySource.push(
            <Card2X3
              infoCard={clonedAssetWithPlaySource}
              key={asset?.vrioAssetId}
              genericCard={getImage('2x3GenericCard') || genericCard2X3}
            />,
          );
          return assetsWithPlaySource;
        }, []);
        return cards;
      }, [collection?.title, collectionCarousel?.contents])}
    </div>
  );
}

VodCollection.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.shape({
    title: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({
      target: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
    })).isRequired,
  }).isRequired,
};

export default memo(VodCollection);
