/* External */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import jwtDecode from 'jwt-decode';

/* Ott-common */
import NpawMonitoring from '@dtvgo/npaw-monitoring';
import { getMSOProviderFormatted } from '@dtvgo/utils';

/* Other */
import {
  getAuthTokensData,
  NPAW_APP_VERSION,
  NPAW_APP_NAME,
  PROFILES_STORAGE,
  BUSINESS_CAMPAIGN,
  USER_TYPES,
} from '../utils';

function selector({ user: { account: { userType = '' } = {} }, player: { playSource } = {} }) {
  return {
    userType,
    playSource,
  };
}

const ACCOUNT_CODE = import.meta.env.REACT_APP_NPAW_ACCOUNT_CODE;

function NpawMonitoringContainer({
  isLive,
  asset,
  startOffset,
  channelId,
  error,
  provider,
}) {
  const { userType, playSource } = useSelector(selector, shallowEqual);
  const { idToken } = getAuthTokensData();
  const {
    vrioCustomerId, msoProvider, businessUnit, selectedSkySubscription,
  } = jwtDecode(idToken);

  const playerInfo = useMemo(() => ({
    PLAYER_NAME: NPAW_APP_NAME,
    APP_VERSION: NPAW_APP_VERSION,
  }), []);

  const channelName = isLive ? asset?.live?.channelName : undefined;

  const profileSelected = JSON.parse(localStorage.getItem(PROFILES_STORAGE.profile)) ?? '';
  const profileId = profileSelected.id ? profileSelected.id : vrioCustomerId;
  const msoFormatted = getMSOProviderFormatted({ msoProvider, businessUnit });
  const userTypeNpaw = BUSINESS_CAMPAIGN.has(msoProvider) ? USER_TYPES.FREE : userType;

  return (
    <NpawMonitoring
      isLive={isLive}
      accountCode={ACCOUNT_CODE}
      userId={vrioCustomerId}
      userType={userTypeNpaw}
      content={asset}
      playerInfo={playerInfo}
      playSource={playSource}
      channelName={channelName}
      channelId={channelId || asset?.live?.channelId}
      vodPlayStartTime={startOffset}
      profileId={profileId}
      msoProvider={msoFormatted}
      error={error}
      providerId={provider}
      subscriberId={selectedSkySubscription}
    />
  );
}

NpawMonitoringContainer.propTypes = {
  isLive: PropTypes.bool,
  asset: PropTypes.shape({
    live: PropTypes.shape({
      channelId: PropTypes.string,
      channelName: PropTypes.string,
    }),
  }),
  startOffset: PropTypes.number,
  channelId: PropTypes.string,
  error: PropTypes.shape({}),
  provider: PropTypes.string,
};

NpawMonitoringContainer.defaultProps = {
  isLive: false,
  asset: {},
  startOffset: 0,
  channelId: '',
  error: undefined,
  provider: '',
};

export default memo(NpawMonitoringContainer);
