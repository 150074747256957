/* External */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/* Components */
import TrackList from '../TrackList';

function TextTracksList({
  tracks = [],
  onTrackSelect,
  onDisableTrackSelect,
}) {
  return (
    <TrackList title={<FormattedMessage id="player.subtitles" />}>
      {useMemo(() => (
        <>
          <TrackList.Item
            active={tracks.every(({ active }) => !active)}
            text={<FormattedMessage id="player.subtitlesOff" />}
            onTrackSelect={onDisableTrackSelect}
          />
          {tracks.map(({ track, active }) => (
            <TrackList.Item
              key={track.trackId}
              track={track}
              text={<FormattedMessage id={`languages.${track.language}`} />}
              active={active}
              onTrackSelect={onTrackSelect}
            />
          ))}
        </>
      ), [onDisableTrackSelect, onTrackSelect, tracks])}
    </TrackList>
  );
}

TextTracksList.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape({
    track: PropTypes.shape({
      trackId: PropTypes.number.isRequired,
      language: PropTypes.string.isRequired,
    }),
    active: PropTypes.bool,
  })),
  onTrackSelect: PropTypes.func.isRequired,
  onDisableTrackSelect: PropTypes.func.isRequired,
};

TextTracksList.defaultProps = {
  tracks: [],
};

export default memo(TextTracksList);
