/* External */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useGetSync } from '@dtvgo/react-utils';

/* Components */
import AssetDescription from './AssetDescription';
import ErrorMessage from './ErrorMessage';
import PlayPauseButton from './PlayPauseButton';
import Progress from './Progress';
import StopButton from './StopButton';
import TracksMenu from './TracksMenu';
import VolumeControl from './VolumeControl';

/* Styles */
import './chromecastConnectionBar.scss';

/* Other */
import {
  CHROMECAST_NAMESPACE, CHROMECAST_CUSTOM_MESSAGE_TYPES, PLAYER_LANGUAGE_PREFERENCES_KEYS,
  savePlayerLanguagePreferences,
} from '../../utils';
import { history } from '../../state/configureStore';
import { useIsCastAvailable, useIsCastConnected } from './ChromecastContext';
import {
  useRemotePlayer, useActiveTrackIds, useCastDeviceName, useCurrentMediaSession,
  useCurrentSession, useOnSessionMessage, usePlayerData,
} from './utils';

function ChromecastConnectionBar() {
  const isAvailable = useIsCastAvailable();
  const isConnected = useIsCastConnected();
  const isPlayerLocation = useSelector(({ router }) => router.location.pathname.toLowerCase().includes('player'));
  const session = useCurrentSession(isAvailable);
  const mediaSession = useCurrentMediaSession(session);
  const [error, setError] = useState();
  const prevAsset = useRef();
  const player = useRemotePlayer();
  const playerData = usePlayerData(player);

  const castDeviceName = useCastDeviceName(session);
  const [activeTrackIds, changeActiveTrackIds] = useActiveTrackIds(mediaSession);

  const currentAsset = playerData.mediaInfo?.customData?.asset;
  const isLive = playerData.mediaInfo?.customData?.isLive;

  const {
    isMediaLoaded,
    playerState,
    currentTime,
    duration,
    liveSeekableRange,
    volumeLevel,
    mediaInfo,
    isMuted,
  } = playerData;

  const getLiveSeekableRange = useGetSync(liveSeekableRange);

  const liveDuration = useMemo(() => {
    if (!isLive || !liveSeekableRange) return null;
    if (!currentAsset?.live) return liveSeekableRange.end - liveSeekableRange.start;

    const { startTime, endTime } = currentAsset?.live ?? {};
    return (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000;
  }, [currentAsset?.live, isLive, liveSeekableRange]);

  const getLiveEdgeProgress = useCallback(
    () => (Date.now() - new Date(currentAsset?.live?.startTime).getTime()) / 1000,
    [currentAsset?.live?.startTime],
  );

  const liveProgress = useMemo(() => {
    if (!isLive || !liveSeekableRange) return null;

    const liveEdgeProgress = getLiveEdgeProgress();
    if (Number.isNaN(liveEdgeProgress)) return currentTime - liveSeekableRange.start;

    const liveDelay = liveSeekableRange.end - currentTime;
    return liveEdgeProgress - liveDelay;
  }, [currentTime, getLiveEdgeProgress, isLive, liveSeekableRange]);

  const seekable = useMemo(() => {
    if (!isLive || !liveSeekableRange) return null;

    const liveEdgeProgress = getLiveEdgeProgress();
    if (Number.isNaN(liveEdgeProgress)) return null;

    const maxDelay = liveSeekableRange.end - liveSeekableRange.start;

    return {
      start: Math.max(0, liveEdgeProgress - maxDelay),
      end: Math.min(liveEdgeProgress, liveDuration),
    };
  }, [isLive, liveSeekableRange, getLiveEdgeProgress, liveDuration]);

  const { audioTracks, textTracks } = useMemo(
    () => {
      const splittedTracks = {
        audioTracks: [],
        textTracks: [],
      };

      if (!mediaInfo?.tracks) return splittedTracks;

      for (const track of mediaInfo.tracks) {
        if (track.type === window.chrome.cast.media.TrackType.AUDIO) {
          splittedTracks.audioTracks.push({
            track,
            active: activeTrackIds.includes(track.trackId),
          });
        } else if (track.type === window.chrome.cast.media.TrackType.TEXT) {
          splittedTracks.textTracks.push({
            track,
            active: activeTrackIds.includes(track.trackId),
          });
        }
      }

      return splittedTracks;
    },
    [activeTrackIds, mediaInfo?.tracks],
  );

  const playOrPause = useCallback(() => {
    if (!player?.controller) return;
    player.controller.playOrPause();
  }, [player]);

  const stop = useCallback(() => {
    if (!player?.controller) return;
    player.controller.stop();
  }, [player]);

  const setVolumeLevel = useCallback((value) => {
    if (!player?.controller) return;
    player.volumeLevel = value;
    player.controller.setVolumeLevel();
  }, [player]);

  const muteOrUnmute = useCallback(() => {
    if (!player?.controller) return;
    player.controller.muteOrUnmute();
  }, [player]);

  const seek = useCallback((value) => {
    if (!player?.controller) return;

    let timeToSeekTo;
    const currentLiveSeekableRange = getLiveSeekableRange();
    const liveEdgeProgress = getLiveEdgeProgress();

    if (!isLive) timeToSeekTo = value;
    else if (!currentLiveSeekableRange) return;
    else if (Number.isNaN(liveEdgeProgress)) {
      timeToSeekTo = currentLiveSeekableRange.start + value;
    } else {
      timeToSeekTo = value - liveEdgeProgress + currentLiveSeekableRange.end;
    }

    player.currentTime = timeToSeekTo;
    player.controller.seek();
  }, [getLiveEdgeProgress, getLiveSeekableRange, isLive, player]);

  const getAudioTracks = useGetSync(audioTracks);
  const getTextTracks = useGetSync(textTracks);

  const selectTrack = useCallback(
    /**
     * @param {chrome.cast.media.Track | undefined} track
     * @param {{ track: chrome.cast.media.Track }[]} currentTracks
     * @param {string} localStorageTrackType
     */
    (track, tracksOfSpecificType, localStorageTrackType) => {
      changeActiveTrackIds((currentTrackIds) => {
        if (track && currentTrackIds.includes(track.trackId)) return currentTrackIds;

        const activeTrackIdsNotInSpecificTracks = currentTrackIds.filter(
          (id) => !tracksOfSpecificType.map(({ track: { trackId } }) => trackId).includes(id),
        );

        if (!track) return activeTrackIdsNotInSpecificTracks;

        return [...activeTrackIdsNotInSpecificTracks, track.trackId];
      });

      if (track) {
        savePlayerLanguagePreferences(isLive, localStorageTrackType, track.language);
      }
    },
    [changeActiveTrackIds, isLive],
  );

  const selectAudioTrack = useCallback(
    /** @param {chrome.cast.media.Track | undefined} track */
    (track) => selectTrack(track, getAudioTracks(), PLAYER_LANGUAGE_PREFERENCES_KEYS.AUDIO),
    [selectTrack, getAudioTracks],
  );

  const selectTextTrack = useCallback(
    /** @param {chrome.cast.media.Track | undefined} track */
    (track) => selectTrack(track, getTextTracks(), PLAYER_LANGUAGE_PREFERENCES_KEYS.SUBTITLES),
    [selectTrack, getTextTracks],
  );

  useOnSessionMessage(
    session,
    CHROMECAST_NAMESPACE,
    useCallback((namespace, { type, error_data: errorData }) => {
      if (type === CHROMECAST_CUSTOM_MESSAGE_TYPES.ERROR) {
        setError({ title: errorData?.error_title, message: errorData?.error_subtitle });
      }
    }, []),
  );

  useEffect(() => {
    if (isMediaLoaded) {
      setError(null);
    }
  }, [isMediaLoaded]);

  useEffect(() => {
    prevAsset.current = currentAsset;
  }, [currentAsset]);

  /* Exit player when in player and connected */
  useEffect(() => {
    if (!isConnected || !isPlayerLocation) return;

    history.replace('/home');
  }, [isConnected, isPlayerLocation]);

  /* End the session if any when the component unmounts */
  useEffect(() => () => {
    if (window.cast) window.cast.framework.CastContext.getInstance().endCurrentSession(true);
  }, []);

  if (!isConnected) return null;
  const { PlayerState } = window.chrome.cast.media;

  return (
    <div className="dtv-chromecast-bar-container">
      <div className={`dtv-chromecast-bar-info-data ${isMediaLoaded && 'mb-3'}`}>
        {playerState === PlayerState.IDLE && error ? (
          <ErrorMessage title={error.title} message={error.message} />
        ) : (
          <AssetDescription
            isMediaLoaded={isMediaLoaded}
            playerState={playerState}
            castDeviceName={castDeviceName}
            asset={currentAsset}
            isLive={isLive}
          />
        )}
      </div>

      {isMediaLoaded && (
        <div className="dtv-chromecast-bar-player">
          <PlayPauseButton
            isPlaying={playerState === PlayerState.PLAYING}
            playOrPause={playOrPause}
          />
          <StopButton stop={stop} />
          <Progress
            isLive={isLive}
            progress={isLive ? liveProgress : currentTime}
            duration={isLive ? liveDuration : duration}
            seekable={seekable}
            onSeek={seek}
          />
          <VolumeControl
            volumeLevel={volumeLevel}
            setVolumeLevel={setVolumeLevel}
            isMuted={isMuted}
            muteOrUnmute={muteOrUnmute}
          />
          <TracksMenu
            audioTracksListElement={
              <TracksMenu.AudioTracksList tracks={audioTracks} onTrackSelect={selectAudioTrack} />
            }
            textTracksListElement={(
              <TracksMenu.TextTracksList
                tracks={textTracks}
                onTrackSelect={selectTextTrack}
                onDisableTrackSelect={selectTextTrack}
              />
            )}
          />
          <google-cast-launcher class="dtv-cast-button" />
        </div>
      )}
    </div>
  );
}

export default ChromecastConnectionBar;
