/**
 * External
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';
import { injectIntl } from 'react-intl';

/**
 * Other
 */
import { getTimeRemaining, buildPlayerAssetMetadata } from '../../utils';

/**
 * Component DockDescription
 * @description Component to display content description on PlayerDock
 */
class DockDescription extends Component {
  /**
   * @memberof buildSchedule
   * @description Build the schedule of live content including reamaining time
   * @returns {string} The schedule of live content
   */
  buildSchedule() {
    const {
      asset: {
        live: { startTime: rawStartTime, endTime: rawEndTime } = {},
      } = {},
      isDelayed,
      intl: { formatMessage },
      isLive,
    } = this.props;

    if (!isLive || isDelayed) return '';

    const startTime = new Date(rawStartTime);
    const endTime = new Date(rawEndTime);

    if (!isValid(startTime) || !isValid(endTime)) return '';

    const startTimeFormatted = format(startTime, 'HH:mm') || '';
    const endTimeFormatted = format(endTime, 'HH:mm') || '';
    const timeRemaining = getTimeRemaining(rawEndTime, formatMessage);

    return `${startTimeFormatted} - ${endTimeFormatted} | ${timeRemaining}`;
  }

  render() {
    const {
      asset = {},
      intl: { formatMessage },
    } = this.props;

    const description = buildPlayerAssetMetadata(asset, formatMessage);
    const schedule = this.buildSchedule();

    return (
      <>
        {(description) && (
          <span className="dtv-dock-description-text">
            {description}
          </span>
        )}

        {schedule && <span className="dtv-dock-description-schedule">{schedule}</span>}
      </>
    );
  }
}

DockDescription.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isDelayed: PropTypes.bool,
  isLive: PropTypes.bool,
};

DockDescription.defaultProps = {
  isDelayed: false,
  isLive: false,
};

export default injectIntl(DockDescription);
