export const ACTION_TYPES_ADD = {
  ADD: 'myList/ADD',
  REQUEST: 'myList/ADD_REQUEST',
  SUCCESS: 'myList/ADD_SUCCESS',
  FAILURE: 'myList/ADD_FAILURE',
  RESET: 'myList/ADD_RESET',
};

export const ACTION_TYPES_REMOVE = {
  REMOVE: 'myList/REMOVE',
  REQUEST: 'myList/REMOVE_REQUEST',
  SUCCESS: 'myList/REMOVE_SUCCESS',
  FAILURE: 'myList/REMOVE_FAILURE',
  RESET: 'myList/REMOVE_RESET',
};

export const ACTION_TYPES_REMOVE_MULTIPLE = {
  REMOVE: 'myList/REMOVE_MULTIPLE',
  REQUEST: 'myList/REMOVE_MULTIPLE_REQUEST',
  SUCCESS: 'myList/REMOVE_MULTIPLE_SUCCESS',
  FAILURE: 'myList/REMOVE_MULTIPLE_FAILURE',
  RESET: 'myList/REMOVE_MULTIPLE_RESET',
};
