/* External */
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

/* Ott-common */
import MqttComponent from '@dtvgo/mqtt';

/* Other */
import {
  APP_THEME,
  ENVIRONMENT,
  MQTT_FLAG,
  MQTT_REALTIME_ENTITLEMENT_FLAG,
  SERVICES_CLIENT_ID,
  SERVICES_CLIENT_ID_PREPROD,
  getAuthTokensData,
  isUserLoggedIn,
} from '../../utils';
import {
  authInterceptor,
  refreshIdToken,
} from '../../services/auth';

const MqttContext = createContext();

async function initializeMqtt() {
  let auth;
  let client;
  const host = import.meta.env.REACT_APP_BACKEND_SERVICE_HOST;
  const mqttComponentInstance = new MqttComponent(authInterceptor);
  const { idToken } = getAuthTokensData();
  const { vrioCustomerId, iat } = jwtDecode(idToken);

  try {
    auth = await mqttComponentInstance.authMqtt(
      idToken,
      host,
      APP_THEME,
      document.location.origin.includes('preprod.directvgo') ? SERVICES_CLIENT_ID_PREPROD : SERVICES_CLIENT_ID, // clientId
      ENVIRONMENT,
    );
    client = await mqttComponentInstance.connectMqtt(
      auth?.clientId,
      auth?.url,
      vrioCustomerId,
      (iat * 1000), // date in miliseconds;
      refreshIdToken,
      MQTT_REALTIME_ENTITLEMENT_FLAG,
      MQTT_FLAG,
    );
  } catch (error) {
    console.error(error);
  }

  return client;
}

export function Provider({ children }) {
  const [contextValue, setContextValue] = useState('');
  const isLoggedIn = isUserLoggedIn();

  useEffect(() => {
    const initialize = async () => {
      try {
        if (MQTT_FLAG && isUserLoggedIn()) {
          const result = await initializeMqtt();
          setContextValue(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    initialize();
  }, [isLoggedIn]);

  return (
    <MqttContext.Provider value={contextValue}>
      {children}
    </MqttContext.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useMqttContext() {
  return useContext(MqttContext);
}
