/**
 * External
 */
import React from 'react';

/**
 * Other
 */
import { STATIC_URLS, getURLWithParams } from '../../utils';

function Pixel({ url, showPixel }) {
  return showPixel && (
    <img alt="" width="0" height="0" src={getURLWithParams(STATIC_URLS.PIXEL[url])} style={{ display: 'block' }} />
  );
}

export default Pixel;
