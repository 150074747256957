/**
 * External
 */
import { connect } from 'react-redux';
import React from 'react';
import isEmptyL from 'lodash-es/isEmpty';
import ReactHtmlParser from 'react-html-parser';

/**
 * Styles
 */
import './TermsOfService.scss';

/**
 * Other
 */
import { TERMS_OF_SERVICE_SUBPAGES } from '../utils';

function TermsOfService(props) {
  const {
    match,
    termsAndConditions,
    termsAndConditions: { termsAndConditionsItems, privacyPolicyItems } = {},
  } = props;
  const { subpage = TERMS_OF_SERVICE_SUBPAGES.TERMS_AND_CONDITIONS } = match.params;
  let content = '';
  let title = '';
  let subtitle = '';
  if (!isEmptyL(termsAndConditions)) {
    if (subpage === TERMS_OF_SERVICE_SUBPAGES.TERMS_AND_CONDITIONS) {
      const termsAndConditionsToUse = termsAndConditionsItems[0];
      if (termsAndConditionsItems) {
        content = termsAndConditionsToUse.content;
        title = termsAndConditionsToUse.title;
        subtitle = termsAndConditionsToUse.subtitle;
      }
    } else {
      const privacyPolicy = privacyPolicyItems[0];
      if (privacyPolicy) {
        content = privacyPolicy.content;
        title = privacyPolicy.title;
        subtitle = privacyPolicy.subtitle;
      }
    }
  }

  return (
    <div className="dtv-detail-terms-conditions">
      <div className="dtv-dtc-info">
        <h1 className="dtv-dtc-title">{title}</h1>
        <h4 className="mb-4">{subtitle}</h4>
        {content && (
        <div className="dtv-dtc-content">
          <div>{ReactHtmlParser(content)}</div>
        </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps({
  global: {
    configurationAEM: {
      data: { policies: termsAndConditions },
    },
  },
}) {
  return {
    termsAndConditions,
  };
}

export default connect(mapStateToProps)(TermsOfService);
