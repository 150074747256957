/* External */
import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useRouteMatch } from 'react-router';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Components */
import ProgressBar from '../VOD/progressBar/ProgressBar';
import LockIcon, { CARD_TYPES } from '../lockIcon/LockIcon';
import AssetToPlay from '../assetToPlay/AssetToPlay';
import AgeRating from '../ageRating/AgeRating';
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/pdp';

/* Styles */
import './Card4X3.scss';

/* Other */
import {
  buildEpisodeMainDataString,
  formatSeasonAndEpisode,
  formatSeconds,
  joinArrayWithSeparator,
  useImage,
  formatContentForGtm,
  playerType,
  lockIconSelector,
} from '../../utils';
import { gtmWatchCarouselEventHandler } from '../GtmContext/dimensions/carousels/eventHandlers';

/** Horizontal card. Used for series episodes and continue watching */
function Card4X3({
  infoCard: asset,
  infoCard: {
    images,
    type = '',
    episode,
    entitled = true,
    vrioAssetId,
    rating = '',
    description = '',
    genres = [],
    playSource,
    season = 0,
    title,
    vod,
    shouldDisplayLockIcon = false,
  } = {},
  genericCard,
  gradient,
  intl: { formatMessage },
  firstLine,
  secondLine,
  lander = false,
  currentSeason,
  episodeReproducing,
  fireCallbackOnImage,
  hideEllipsis,
  showPlayIconOnHover,
  isContinueWatching,
  isPublic,
  carouselInfo = {},
}) {
  const { params: locationParams } = useRouteMatch();
  const { imageRef, imageStyle } = useImage(images, genericCard, gradient, '16:9', false);
  const { pathname: path = '' } = useSelector((state) => state.router?.location || {});
  const { duration, progress, releaseYear } = vod || {};
  const { number: episodeNumber, seasonNumber, showName } = episode || {};
  const {
    gtmUserData,
    gtmContent,
    setGtmPlaySource,
    setGtmSectionName,
    setGtmComponentType,
    setGtmCarouselVerticalPosition,
  } = useGtmContext();

  const { shouldDisplayLockIcon: displayLockIcon, lockIconType } = lockIconSelector({
    shouldDisplayLockIcon,
    assetRating: asset.rating,
  });

  /**
   * @description Method that returns the second line of the card
   * @method getSecondLine
   * @param {string} firstPart
   * @param {string} rating
   */
  const getSecondLine = (firstPart) => (firstPart || rating ? (
    <>
      <AgeRating rating={rating} />
      {firstPart}
    </>
  ) : (
    ''
  ));

  /**
   * @description Method that loads asset information.
   * @method getData
   */
  const getData = () => {
    let data = { firstLine: '', secondLine: '', thirdLine: '' };

    if (type === assetTypes.episode) {
      const seasonAndEpisode = formatSeasonAndEpisode(seasonNumber, episodeNumber, formatMessage);
      const formattedDuration = duration && formatSeconds(duration);
      const firstPart = buildEpisodeMainDataString(
        seasonAndEpisode,
        title,
        formattedDuration,
        lander,
      );

      data = {
        firstLine: firstLine || (lander ? showName || title : title),
        secondLine: secondLine || getSecondLine(firstPart),
        thirdLine: lander ? '' : description,
      };

      return data;
    }

    const genre = genres?.[0];
    const firstPart = joinArrayWithSeparator([genre, releaseYear]);

    return {
      firstLine: title,
      secondLine: getSecondLine(firstPart),
      thirdLine: '',
    };
  };

  const data = getData();
  const assetProps = {
    asset: {
      assetId: vrioAssetId,
      type,
      playSource,
    },
    replace: !!episodeReproducing,
    path,
    entitled,
  };

  const containerClassName = classNames({
    'dtv-card-4X3': true,
    'dtv-text-left': true,
    'dtv-no-pointer-events': !showPlayIconOnHover,
    'highlight-card': episodeReproducing === episode && Number(currentSeason) === Number(season),
  });

  const handleClick = () => {
    if (gtmContent) {
      const sendToGtm = formatContentForGtm(
        { ...asset },
        { contentPlayerType: playerType(type) },
      );
      gtmEvent({
        hash: gtmDimensions.pdp_series.episode.hash,
        eventType: gtmDimensions.pdp_series.episode.eventType,
        userData: gtmUserData,
        dimensions: gtmDimensions.pdp_series.episode.dimensions,
        data: sendToGtm,
      });
    } else {
      setGtmCarouselVerticalPosition(carouselInfo.carouselVerticalPosition);
      setGtmPlaySource(playSource);
      setGtmSectionName(carouselInfo.sectionTitle);
      setGtmComponentType(carouselInfo.type);

      const dataToSend = formatContentForGtm(
        { ...asset },
        {
          episodeName: title,
        },
      );

      gtmWatchCarouselEventHandler({
        asset: dataToSend,
        gtmUserData,
        locationParams,
        carouselInfo,
      });
    }
  };

  const image = (
    <div className="dtv-card-4X3-image" ref={imageRef} style={imageStyle}>
      {(showPlayIconOnHover && !displayLockIcon) && (
        <div className="dtv-card-4X3-image-hover">
          {!isPublic && <span className="dtv-icon-play" />}
        </div>
      )}

      {(displayLockIcon || isPublic) && (
        <LockIcon cardType={CARD_TYPES.card4X3} iconType={lockIconType} />
      )}

      {!!(isContinueWatching && duration && progress) && (
        <ProgressBar styles={{ margin: '0 10px', paddingBottom: '10px' }} duration={duration} progress={progress} />
      )}
    </div>
  );

  const metadata = (
    <>
      {data.firstLine && <p className="dtv-card-4X3-first-line">{data.firstLine}</p>}
      {data.secondLine && <p className="dtv-card-4X3-second-line">{data.secondLine}</p>}
      {data.thirdLine && (
        hideEllipsis ? (
          <p className="dtv-card-4X3-third-line">{data.thirdLine}</p>
        ) : (
          <div className="dtv-card-4X3-fade-out-ellipsis">
            <p className="dtv-card-4X3-third-line">{data.thirdLine}</p>
          </div>
        )
      )}
    </>
  );

  return fireCallbackOnImage ? (
    <div className={containerClassName}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- Asset props are defined in
      this file */}
      <AssetToPlay className="dtv-card-4X3-image-wrapper" {...assetProps} onClick={handleClick}>
        {image}
      </AssetToPlay>

      {metadata}
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading -- Asset props are defined in this file
    <AssetToPlay className={containerClassName} {...assetProps} onClick={handleClick}>
      {image}
      {metadata}
    </AssetToPlay>
  );
}

Card4X3.propTypes = {
  showPlayIconOnHover: PropTypes.bool,
  genericCard: PropTypes.string,
  gradient: PropTypes.string,
  infoCard: PropTypes.shape(),
  isPublic: PropTypes.bool,
  carouselInfo: PropTypes.shape(),
};

Card4X3.defaultProps = {
  showPlayIconOnHover: true,
  genericCard: '',
  gradient: '',
  infoCard: {},
  isPublic: false,
  carouselInfo: {},
};

export default injectIntl(Card4X3);
