/* External */
import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useGetSync } from '@dtvgo/react-utils';

import {
  AEM_PLATFORM, APP_VERSION, NPAW_APP_NAME, RECEIVER_ID,
} from '../../utils';
import { api } from '../../state/configureStore';
import { getCredentials, useCastState } from './utils';

const CastAvailableContext = createContext(false);
const CastStateContext = createContext(false);
const CastActionsContext = createContext({ loadMedia: () => {} });

export function Provider({ children }) {
  const [isAvailable, setIsAvailable] = useState(false);
  const castState = useCastState(isAvailable);

  const {
    data: { country },
    isLoading: isLoadingConfig,
  } = api.useGetAEMConfigurationQuery(AEM_PLATFORM, {
    selectFromResult: ({ data, isLoading }) => ({
      data: {
        country: data?.userCountry,
      },
      isLoading,
    }),
  });

  const getCountry = useGetSync(country);

  const loadMedia = useCallback(async ({ assetId, isLive, playSource }) => {
    const currentSession = window.cast?.framework.CastContext.getInstance().getCurrentSession();

    // eslint-disable-next-line unicorn/no-useless-undefined
    if (!currentSession) return undefined;

    const credentials = await getCredentials();

    return currentSession.loadMedia(new window.chrome.cast.media.LoadRequest({
      customData: {
        ...credentials,
        senderName: NPAW_APP_NAME,
        senderVersion: APP_VERSION,
        assetId,
        isLive,
        playSource,
      },
    })).catch((error) => {
      console.error('[ChromecastContext>loadMedia] error loading media', error);
    });
  }, []);

  useEffect(() => {
    if (isLoadingConfig) return;

    function initializeCastApi() {
      const context = window.cast.framework.CastContext.getInstance();

      context.setOptions({
        receiverApplicationId: RECEIVER_ID,
        autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
        androidReceiverCompatible: true,
      });

      setIsAvailable(true);

      getCredentials()
        .then((credentials) => {
          const credentialsData = new window.chrome.cast.CredentialsData(
            JSON.stringify(credentials),
          );
          context.setLaunchCredentialsData(credentialsData);

          return credentials;
        })
        .catch((error) => {
          console.error('[ChromecastContext] Error while getting and setting credentials', error);
        });
    }
    if (window.isGCastApiAvailable) initializeCastApi();
    else {
      // eslint-disable-next-line no-underscore-dangle
      window.__onGCastApiAvailable = (isCastAvailable) => {
        if (isCastAvailable) initializeCastApi();
      };
    }
  }, [getCountry, isLoadingConfig]);

  return (
    <CastActionsContext.Provider value={useMemo(() => ({ loadMedia }), [loadMedia])}>
      <CastAvailableContext.Provider value={isAvailable}>
        <CastStateContext.Provider value={castState}>
          {children}
        </CastStateContext.Provider>
      </CastAvailableContext.Provider>
    </CastActionsContext.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useIsCastAvailable() {
  return useContext(CastAvailableContext);
}

export function useIsCastConnected() {
  const castState = useContext(CastStateContext);
  return window.cast ? castState === window.cast.framework.CastState.CONNECTED : false;
}

export function useCastActions() {
  return useContext(CastActionsContext);
}
