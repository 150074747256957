import { userConstants } from './userConstants';

export const gtmDimensions = {
  component_type: 'parental_control',
  edit_profile: {
    page_view: {
      hash: 'p-042.143.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    back: {
      hash: 'e-042.143.001.000.000.028',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    continue: {
      hash: 'e-042.143.001.000.000.101',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
      ],
    },
  },
  profile_live: {
    page_view: {
      hash: 'p-041.023.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_channel_name',
        'v_serie_name',
        'v_season',
        'v_error_code',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_error_type',
        'v_component_type',
        'v_channel_id',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
  },
  profile_vod: {
    page_view: {
      hash: 'p-041.023.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_video_language',
        'v_video_subtitle',
        'v_error_code',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_error_type',
        'v_component_type',
        'v_content_player_type',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
  },
  profile_series: {
    page_view: {
      hash: 'p-041.023.000.000.000.000',
      eventType: 'pages',
      dimensions: [
        ...userConstants.constans,
        'v_content_name',
        'v_content_type',
        'v_content_id',
        'v_genre',
        'v_play_source',
        'v_start_progress_bar',
        'v_serie_name',
        'v_season',
        'v_video_language',
        'v_video_subtitle',
        'v_error_code',
        'v_section_name',
        'v_transmission_type',
        'v_collection_name',
        'v_error_type',
        'v_component_type',
        'v_episode_number',
        'v_content_player_type',
        'v_episode_name',
        'v_vertical_position_carousel',
        'v_is_sports',
        'v_content_age_rating',
        'v_age_restricted_content',
        'v_video_duration',
        'v_start_video_percentual',
      ],
    },
  },
  error: {
    back: {
      hash: 'e-041.143.001.000.000.028',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
      ],
    },
    ok: {
      hash: 'e-041.023.001.000.000.154',
      eventType: 'interactions',
      dimensions: [
        ...userConstants.constans,
      ],
    },
  },
};
