/* External */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';

/* Ott-common */
import { assetTypes } from '@dtvgo/rtk-query-api';
import { gtmEvent } from '@dtvgo/gtm-event-report';

/* Components */
import { useGtmContext } from '../GtmContext';
import { gtmDimensions } from '../GtmContext/dimensions/myList';
import AssetToPlay from '../assetToPlay/AssetToPlay';
import LockIcon, { CARD_TYPES } from '../lockIcon/LockIcon';
import AgeRating from '../ageRating/AgeRating';

/* Styles */
import './Card2X3.scss';

/* Other */
import {
  buildEpisodeSecondaryDataString,
  joinArrayWithSeparator,
  redirectToPdp,
  showMyListFlag,
  useImage,
  getGtmEventConfig,
  formatContentForGtm,
  lockIconSelector,
} from '../../utils';
import { history, api } from '../../state/configureStore';
import { gtmWatchCarouselEventHandler } from '../GtmContext/dimensions/carousels/eventHandlers';

function Card2X3({
  className,
  genericCard,
  gradient,
  infoCard,
  intl: { formatMessage },
  isInMyList,
  myListEditMode,
  myListEditModeModeSelected,
  onEditModeCardClick,
  path,
  carouselInfo,
  hideHorizontalDots,
}) {
  const { data: { contents: myListAssets = [] } = {} } = api.useGetMyListQuery(undefined, {
    skip: isInMyList,
  });

  const [removeFromMyList] = api.useRemoveFromMyListMutation();
  const {
    setGtmPlaySource,
    setGtmSectionName,
    setGtmComponentType,
    setGtmCarouselVerticalPosition,
    gtmUserData,
  } = useGtmContext();

  const { params: locationParams } = useRouteMatch();

  const { imageRef, imageStyle } = useImage(
    infoCard.images,
    genericCard,
    gradient,
    '2:3',
  );
  const [data, setData] = useState(null);

  const { shouldDisplayLockIcon, lockIconType } = lockIconSelector({
    shouldDisplayLockIcon: infoCard.shouldDisplayLockIcon,
    assetRating: infoCard.rating,
  });

  const assetPropObject = {
    assetId: infoCard.vrioAssetId,
    type: infoCard.type,
    stationId: infoCard.live?.channelId,
    playSource: infoCard.playSource,
  };
  const assetProps = {
    shouldDisplayLockIcon,
    asset: assetPropObject,
    path,
    redirectToDetailsFlag: data?.redirectToDetails,
    entitled: infoCard.entitled,
  };

  /**
   * @description Sets title, subtitle and redirectToDetails values
   */
  useEffect(() => {
    if (data) return;

    switch (infoCard.type) {
      case assetTypes.episode:
        setData({
          title: infoCard?.episode?.showName,
          subtitle: buildEpisodeSecondaryDataString(
            infoCard.episode?.seasonNumber,
            infoCard.episode?.number,
            infoCard?.title,
            formatMessage,
          ),
        });
        break;
      case assetTypes.movie:
        setData({
          title: infoCard.title,
          subtitle: joinArrayWithSeparator(
            [
              infoCard.genres?.[0],
              infoCard.vod?.releaseYear,
            ],
            ', ',
          ),
        });
        break;
      case assetTypes.serie:
        setData({
          title: infoCard.title,
          subtitle: formatMessage({ id: 'common.series', defaultMessage: 'Serie' }),
          redirectToDetails: true,
        });
        break;
      default:
        setData({
          title: infoCard.title,
          subtitle: infoCard.genres?.[0],
        });
        break;
    }
  }, [infoCard, data, formatMessage]);

  const handleClickHorizontalDots = () => {
    if (isInMyList) {
      const { hash, eventType, dimensions } = gtmDimensions.content_info;

      gtmEvent({
        hash,
        eventType,
        dimensions,
        userData: gtmUserData,
        data: {},
      });
    } else {
      try {
        setGtmCarouselVerticalPosition(carouselInfo.carouselVerticalPosition);
        setGtmPlaySource(infoCard.playSource);
        setGtmSectionName(carouselInfo.sectionTitle);
        setGtmComponentType(carouselInfo.type);
      } catch (error) {
        console.error(`An error occurred: ${error.message}`);
      }
    }

    redirectToPdp({
      assetId: infoCard.vrioAssetId,
      type: infoCard.type,
      showId: infoCard?.episode?.showId,
      history,
    });
  };

  const handleClickAsset = () => {
    if (isInMyList) {
      const { hash, dimensions, eventType } = getGtmEventConfig(
        infoCard.type,
        gtmDimensions,
        'card',
        'watch',
      );

      const dataToSend = formatContentForGtm(
        infoCard,
        { listPosition: carouselInfo.listPosition },
      );

      gtmEvent({
        hash,
        eventType,
        dimensions,
        userData: gtmUserData,
        data: dataToSend,
      });
    } else {
      try {
        setGtmCarouselVerticalPosition(carouselInfo.carouselVerticalPosition);
        setGtmPlaySource(infoCard.playSource);
        setGtmSectionName(carouselInfo.sectionTitle);
        setGtmComponentType(carouselInfo.type);

        gtmWatchCarouselEventHandler({
          asset: infoCard,
          gtmUserData,
          locationParams,
          carouselInfo,
        });
      } catch (error) {
        console.warn(`An error occurred: ${error.message}`);
      }
    }
  };

  const handleClickRemoveFromMyList = ({ id, type }) => {
    removeFromMyList({
      id,
      type,
    });

    const { hash, eventType, dimensions } = gtmDimensions.delete_content;

    gtmEvent({
      hash,
      eventType,
      dimensions,
      userData: gtmUserData,
      data: {},
    });
  };

  /**
   * @description Returns the markup for the card's hover
   * @param {Object} assetProps
   * @param {Boolean} redirectToDetails
   * @param {Boolean} hasLabelEntitlement
   */
  function getHoverMarkup() {
    if (myListEditMode) {
      return (
        <div
          className="dtv-card-2X3-edit"
          onClick={() => onEditModeCardClick(infoCard.vrioAssetId)}
        >
          {myListEditModeModeSelected ? (
            <div className="dtv-card-2X3-edit-check">
              <span className="dtv-icon-check" />
            </div>
          ) : (
            <div className="dtv-card-2X3-edit-circle" />
          )}
        </div>
      );
    }

    return (
      <div className="dtv-card-2X3-image-hover">
        {showMyListFlag
          && (
            isInMyList || myListAssets.find((asset) => asset.vrioAssetId === infoCard.vrioAssetId)
          )
          && (
            <button
              type="button"
              className="dtv-card-2X3-actions dtv-card-2X3-add-remove"
              onClick={() => handleClickRemoveFromMyList({
                id: infoCard.type === assetTypes.movie ? infoCard.vmsId : infoCard.vrioAssetId,
                type: infoCard.type,
              })}
            >
              <span className="dtv-icon-bookmark-filled" />
              <span className="dtv-icon-close" />
              <span className="dtv-label">{formatMessage({ id: 'myList.remove', defaultMessage: 'Remover' })}</span>
            </button>
          )}
        {!hideHorizontalDots && (
          <span className="dtv-card-2X3-actions dtv-card-2X3-more">
            <span
              className="dtv-icon-three-dots-horizontal"
              id="dtv-icon-three-dots-horizontal"
              onClick={handleClickHorizontalDots}
            />
          </span>
        )}
        {shouldDisplayLockIcon ? (
          // Asset props are defined in this file
          // eslint-disable-next-line react/jsx-props-no-spreading
          <AssetToPlay isSpan className={`dtv-${lockIconType}-icon-card`} {...assetProps} />
        ) : (
          // Asset props are defined in this file
          // eslint-disable-next-line react/jsx-props-no-spreading
          !data?.redirectToDetails && <AssetToPlay isSpan className="dtv-icon-play" {...assetProps} onClick={handleClickAsset} />
        )}
        {/* Asset props are defined in this file */}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <AssetToPlay className="dtv-card-2X3-hover" {...assetProps} onClick={handleClickAsset} />
      </div>
    );
  }

  return (
    <div className={`dtv-card-2X3${className ? ` ${className}` : ''}`}>
      {getHoverMarkup()}
      <div className="dtv-card-2X3-image" ref={imageRef} style={imageStyle}>
        {shouldDisplayLockIcon && (
          <LockIcon className="dtv-card-2X3-img-oval" cardType={CARD_TYPES.card2X3} iconType={lockIconType} />
        )}
      </div>
      {/* Asset props are defined in this file */}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <AssetToPlay isSpan {...assetProps} onClick={handleClickAsset}>
        {data?.title && <p className="dtv-card-2X3-first-line dtv-text-left">{data?.title}</p>}
        {data?.subtitle
          && (
            <p className="dtv-card-2X3-second-line dtv-text-left">
              <AgeRating rating={infoCard.rating} />
              {data?.subtitle}
            </p>
          )}
      </AssetToPlay>
    </div>
  );
}

Card2X3.propTypes = {
  className: PropTypes.string,
  genericCard: PropTypes.string,
  gradient: PropTypes.string,
  infoCard: PropTypes.shape(),
  myListEditMode: PropTypes.bool,
  myListEditModeModeSelected: PropTypes.bool,
  onEditModeCardClick: PropTypes.func,
  carouselInfo: PropTypes.shape(),
  hideHorizontalDots: PropTypes.bool,
};

Card2X3.defaultProps = {
  className: '',
  genericCard: '',
  gradient: '',
  infoCard: {},
  myListEditMode: false,
  myListEditModeModeSelected: false,
  onEditModeCardClick: () => {},
  carouselInfo: {},
  hideHorizontalDots: false,
};

const mapStateToProps = ({ router }) => ({ path: router.location ? router.location.pathname : '' });
export default connect(mapStateToProps)(injectIntl(Card2X3));
