/**
 * External
 */
import {
  call, put, all, takeLatest, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import jwtDecode from 'jwt-decode';

/* Ott-common */
import { getUserTypeByBusinessUnit } from '@dtvgo/utils';

/**
 * Actions
 */
import * as actions from './actions';
import { updateUser } from './actions';

/**
 * Services
 */
import { authService } from '../../services';
import { setUserData } from './services';

/**
 * Other
 */
import { generateRandomStringId } from '../../utils/metadata';
import { USER_TYPES } from '../../utils/constants';
import { setAuthTokensData } from '../../utils/localStorageState';

function* login({ payload }) {
  yield put(actions.loginRequest());
  yield put(showLoading());
  try {
    const deviceId = generateRandomStringId();

    const response = yield call(
      authService.v3.login,
      payload.userName,
      payload.password,
      payload.tokenRecaptcha,
    );

    const { data } = response;
    setUserData(data);

    const {
      refresh_token: refreshToken = '',
      id_token: idToken = '',
      access_token: accessToken = '',
    } = data;

    const {
      businessUnit,
      msoProvider,
      vrioCustomerId,
      iso2Code,
      sub,
      givenName,
      familyName,
    } = jwtDecode(idToken);
    const npawAccountType = getUserTypeByBusinessUnit(businessUnit, msoProvider);

    const userAccountData = {
      userType: npawAccountType,
      isTVE: npawAccountType === USER_TYPES.TVE,
      uid: vrioCustomerId,
      userName: sub,
      profile: {
        iso2country: iso2Code,
        email: sub,
        ...yield select((state) => state.user.account.profile),
      },
      country: iso2Code,
      allowedCountry: true,
      businessUnit,
      iso2Code,
      msoProvider,
      givenName,
      familyName,
      email: sub,
    };

    const tokensData = {
      accessToken,
      idToken,
      refreshToken,
    };

    setAuthTokensData(tokensData);
    yield put(updateUser('account', userAccountData));
    yield put(updateUser('deviceId', deviceId));
    yield put(actions.loginSuccess());
    const { redirectTo } = payload;
    if (redirectTo) yield put(push(redirectTo));
  } catch (error) {
    console.error(`userSaga-login: ${error}`);
    yield put(actions.loginFailure(error));
  } finally {
    yield put(hideLoading());
  }
}

export default function* root() {
  yield all([
    takeLatest(actions.ACTION_TYPES.LOGIN, login),
  ]);
}
