/* External */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */
import './EpgErrorMessage.scss';

function EpgErrorMessage({ children, message }) {
  return (
    <div className="dtv-epg-error">
      <h2 className="dtv-epg-error__text">{message}</h2>
      {children}
    </div>
  );
}

EpgErrorMessage.propTypes = {
  message: PropTypes.string,
};

EpgErrorMessage.defaultProps = {
  message: '',
};

export default EpgErrorMessage;
